import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { ContraventionService } from 'apps/driver/src/app/shared/services/contravention.service';
import { PaymentBaseComponent } from '../payment-base.component';
import { PaymentResponse } from '@apis/shared/models/payment-response.model';
import { finalize } from 'rxjs/operators';
import { ContraventionStatusTypes, ReviewStatusTypes } from '@apis/shared/enums/app.enum';
import { ContraventionPageComponent } from '../../contravention-page/contravention-page.component';

declare var $: any

@Component({
  selector: 'app-payment-review',
  templateUrl: './payment-review.component.html',
  styleUrls: ['./payment-review.component.scss'],
})
export class PaymentReviewComponent extends PaymentBaseComponent
  implements OnInit {
  @ViewChild('masterPage') masterPage: ContraventionPageComponent;

  isPaymentFailed: boolean;
  isConsentAccepted = false;
  isTermsAcknowledged = false;
  hasValidToken = false;
  errorMessage = '';
  isTransactionComplete = true;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    localStorage: LocalStorageService,
    public contraventionService: ContraventionService
  ) {
    super(router, route, localStorage);
  }

  ngOnInit(): void {
    var cardLogoDiv = document.getElementById('cardLogoDiv') as HTMLImageElement;
    cardLogoDiv.style.backgroundImage = this.paymentRequest.cardLogo;

    this.hasValidToken = this.paymentRequest.paymentToken != null;
  }

  onSubmitPaymentClick(isValid: boolean) {
    this.isTransactionComplete = false;
    if (isValid) {
      var isReviewScheduled = this.paymentRequest.lineItems
        .some(li => this.contraventionOverview.contraventions
          .find(c => c.contraventionNumber == li.recordNumber).contraventionStatusTypeId == ContraventionStatusTypes.InReview);

      if (isReviewScheduled) {
          this.isConsentAccepted = false;
          $('#cancelReviewModal').modal('show');
      }
      else {
        $('#giveUpRightToReviewModal').modal('show');
      }
    }
  }

  onCancelClick() {
    this.localStorage.setContraventionPaymentRequest(null); // TO DO: Link this to the "Back to Contravention Overview" link on each payment page
    this.router.navigate(['../../'], { relativeTo: this.route });
  }

  onConfirmClick() {
    this.submitPayment();
  }

  submitPayment() {
    this.masterPage.showSpinner();

    var pr = Object.assign({}, this.paymentRequest);

    pr.paymentAmount = Number.parseFloat(
      this.paymentRequest.paymentAmount.toString()
    );
    pr.lineItems.forEach(li => {
      li.amount = Number.parseFloat(li.amount?.toString());
    });

    pr.lineItems = this.paymentRequest.lineItems.filter(li => li.amount > 0 && li.isChecked);

    this.contraventionService
      .processPayment(pr)
      .pipe(
        finalize(() => {
          // Clear payment token if transaction is unsuccessfull. This is to force the user to re-enter payment details.
          this.paymentRequest.paymentToken = null;          
        })
      )
      .subscribe((result) => {
        if (!result.isTransactionApproved) {          
          this.hasValidToken = false;
          this.isPaymentFailed = true;

          // If overpayment then set error message and clear session
          if(result.errorCode == 6002) {
            this.errorMessage = result.errorMessage;
            this.localStorage.setContraventionPaymentRequest(null);
          }

          this.masterPage.hideSpinner();

          window.scroll(0, 0);
        } else {
          this.masterPage.hideSpinner();

          this.localStorage.setContraventionPaymentRequest(null);

          this.router.navigate(['../success'], {
            relativeTo: this.route,
            queryParams: { transId: result.financialTransactionId },
          });
        }
        this.isTransactionComplete = true;
      });
  }
}
