<!-- Driver Portal View -->
<div class="d-flex flex-column" *ngIf="vehicleSeizure && !isRSGPortal">
    <div class="card goa-callout goa--information mb-4 mt-0 mobile-callout" *ngIf="isImmidiatelyCancelled">
        <h6 class="mt-0">Seizure Cancelled</h6>
        <p>
            This seizure was immediately cancelled on {{cancelledDate}} as it was submitted more than 7 days from issuance.
        </p>
    </div>
    
    <h1 class="mb-3">Vehicle Seizure Details</h1>
    <div class="d-flex">
    <h2 class="m-0">{{ seizureGroupName }}</h2>
        <span class="badge rounded-pill badge-status" 
        [class.badge-warning]="vehicleSeizure.seizureStatusTypeId == 1"
        [class.badge-review]="vehicleSeizure.seizureStatusTypeId == 2"
        [class.badge-success]="vehicleSeizure.seizureStatusTypeId == 3"
        [class.badge-secondary]="vehicleSeizure.seizureStatusTypeId == 4">{{ seizureStatusTypeName | uppercase}}</span>
        <span *ngIf="vehicleSeizure.isJudicialReviewServed" class="jr-badge">
            <img class="jr-badge-flag" src="assets/images/triangular-flag-white.svg" />
            JR Served
        </span>
    </div>

    <h3 class="mt-0">Seizure No. {{ vehicleSeizure.seizureNumber }}</h3>

    <div class="summary-item-container border-bottom pb-4">
        <div class="info-item">
            <div class="d-flex flex-row">
                <div>
                    <div class="icon-circle issue-date">
                    </div>
                </div>
                <div class="d-flex flex-column ms-3 pt-1">
                    <div class="summary-item-label">Date Seized</div>
                    <div class="summary-item-value">{{ vehicleSeizure.seizureDate | date:'mediumDate' }}</div>
                </div>
            </div>
        </div>    
        <div class="info-item">
            <div class="d-flex flex-row">
                <div>
                    <div class="icon-circle calendar">
                    </div>
                </div>
                <div class="d-flex flex-column ms-3 pt-1">
                    <div class="summary-item-label">Immediate Seizure Duration</div>
                    <div class="summary-item-value">{{ vehicleSeizure.seizureDuration }} {{ vehicleSeizure.seizureDurationType }}</div>
                </div>
            </div>
        </div>    
        <div class="info-item">
            <div class="d-flex flex-row">
                <div>
                    <div class="icon-circle car-key">
                    </div>
                </div>
                <div class="d-flex flex-column ms-3 pt-1">
                    <div class="summary-item-label">Release Date</div>
                    <div class="summary-item-value">{{ vehicleSeizure.vehicleReleaseDate | date:'mediumDate' }}</div>
                </div>
            </div>
        </div> 
        <div class="ms-auto">
            <div class="d-flex flex-row justify-content-end">
                <button class="btn-block goa-button mt-0" 
                 *ngIf="vehicleSeizure.contraventionNumber && +seizureUserType == SeizureUserType.Driver"
                 [routerLink]="['../../contravention', vehicleSeizure.contraventionNumber]">View Linked Contravention(s)</button>
                 <button class="btn-block goa-button mt-0" 
                 *ngIf="vehicleSeizure.secondarySeizureNumber && +seizureUserType != SeizureUserType.Driver"
                 [routerLink]="['../../vehicle-seizures', vehicleSeizure.secondarySeizureNumber]">View Linked Seizure</button>
            </div>
        </div> 
    </div>

    <p class="disclaimer">
        Due to the potential for delayed or missed messages sent via email and SMS, 
        it is your responsibility to check the status of your request in the SafeRoads portal. 
        Please contact SafeRoads Alberta at <a href="mailto:saferoads@gov.ab.ca">saferoads&#64;gov.ab.ca</a> if you have any questions.
    </p>

    <div class="content mt-4">
        <div class="me-4 section">
            <div class="card goa-callout goa--information mb-4 mt-0 desktop-callout" *ngIf="isImmidiatelyCancelled">
                <h6 class="mt-0">Seizure Cancelled</h6>
                <p>
                    This seizure was immediately cancelled on {{cancelledDate}} as it was submitted more than 7 days from issuance.
                </p>
            </div>
            <div id="accordion" class="accordion">
                <div class="card" [ngClass]="{'card-large': (+seizureUserType == SeizureUserType.Driver && vehicleSeizure.contraventionId != null)}">
                    <ng-container>
                        <div class="card-header" data-bs-toggle="collapse" href="#collapseThree">
                                Vehicle Seizure Details
                        </div>
                        <div id="collapseThree" class="card-body collapse show" >
                            <app-section-details sectionTitle="Seizure Information" [sectionDetails]="seizureInformation"></app-section-details>
                            <app-section-details *ngIf="isChangeDisplayedInformationActive" sectionTitle="Police Narrative" [sectionDetails]="policeNarrativeDetails"></app-section-details>
                            <div class="mb-3 row" *ngIf="vehicleSeizure?.seizureTypeId == SeizureTypes.SuspendedDriversProgramFirstOccurrence || vehicleSeizure?.seizureTypeId == SeizureTypes.SuspendedDriversProgramSecondOccurrence">
                                <div class="col-12">
                                   <div class="label">Please visit <a href="https://www.alberta.ca/vehicle-seizure-programs.aspx" target="_blank">Vehicle Seizure Programs</a> for information about the Suspended Driver Vehicle Seizure.</div>
                                </div>
                            </div>

                            <label *ngIf="this.vehicleSeizure?.vehicle?.movesLookupDate">The following vehicle information was generated from the Motor Vehicle System (MOVES) on {{vehicleSeizure?.vehicle?.movesLookupDate | date:'mediumDate'}} at {{vehicleSeizure?.vehicle?.movesLookupDate | date:'HH:mm'}}</label>               
                    
                            <ng-container *ngIf="this.vehicleSeizure?.vehicle?.movesLookupDate && this.vehicleSeizure?.vehicle?.isStolenPlate">
                                <app-section-details sectionTitle="Vehicle Information" [sectionDetails]="movesVehicleInformation"></app-section-details>
                                <label>The information above did not match the vehicle. The following information is a description of the vehicle involved in the contravention.</label>
                                <app-section-details sectionTitle="" [sectionDetails]="vehicleInformation"></app-section-details>
                            </ng-container>
        
                            <ng-container *ngIf="(this.vehicleSeizure?.vehicle?.movesLookupDate && !this.vehicleSeizure?.vehicle?.isStolenPlate) || !this.vehicleSeizure?.vehicle?.movesLookupDate">
                                <app-section-details sectionTitle="Vehicle Information" [sectionDetails]="vehicleInformation"></app-section-details>
                            </ng-container>
                            <app-section-details sectionTitle="Trailer Information" [sectionDetails]="trailerInformation"></app-section-details>

                            <ng-container *ngIf="+seizureUserType == SeizureUserType.RegisteredOwner">
                                <label *ngIf="vehicleSeizure?.vehicle?.movesLookupDate && !vehicleSeizure?.vehicle?.registeredOwner?.isSameAsDriver && !this.vehicleSeizure?.vehicle?.isNoRegisteredOwner && !this.vehicleSeizure?.vehicle?.isStolenPlate">The following registered owner information was generated from the Motor Vehicle System (MOVES) on {{vehicleSeizure?.vehicle?.movesLookupDate | date:'mediumDate'}} at {{vehicleSeizure?.vehicle?.movesLookupDate | date:'HH:mm'}}</label>
                                <app-section-details sectionTitle="Registered Owner Information" [sectionDetails]="registeredOwnerInformation"></app-section-details>
                            </ng-container>
                            
                            <app-section-details sectionTitle="Vehicle Location & Tow Lot Information" [sectionDetails]="towLotInformation"></app-section-details>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="isChangeDisplayedInformationActive">
                        <div class="card-header" data-bs-toggle="collapse" href="#collapseOne">
                                Driver Information
                        </div>
                        <div id="collapseOne" class="card-body collapse show" >
                            <label *ngIf="this.recipient?.movesLookupDate">The following driver information and contact information was generated from the Motor Vehicle System (MOVES) on {{recipient?.movesLookupDate | date:'mediumDate'}} at {{recipient?.movesLookupDate | date:'HH:mm'}}</label>
                            <app-section-details sectionTitle="Driver Information" [sectionDetails]="recipientInformation"></app-section-details>
                            <app-section-details *ngIf="+seizureUserType == SeizureUserType.Driver" sectionTitle="Driver Licence Information" [sectionDetails]="recipientIdentificationInformation"></app-section-details>

                            <ng-container *ngIf="this.recipient?.movesLookupDate && this.recipient?.recipientContact?.isAddressDifferentFromMOVES">
                                <app-section-details sectionTitle="Driver Contact Information" [sectionDetails]="movesRecipientContactInformation"></app-section-details>
                                <label>The following contact information was provided by the recipient to the officer</label>
                                <app-section-details sectionTitle="" [sectionDetails]="recipientContactInformation"></app-section-details>
                            </ng-container>

                            <ng-container *ngIf="(this.recipient?.movesLookupDate && !this.recipient?.recipientContact?.isAddressDifferentFromMOVES) || !this.recipient?.movesLookupDate">
                                <app-section-details sectionTitle="Driver Contact Information" [sectionDetails]="recipientContactInformation"></app-section-details>
                            </ng-container>
                        </div>
                    </ng-container>

                    <ng-container>
                        <div class="card-header" data-bs-toggle="collapse" href="#collapseTwo">
                            Supporting Documents
                        </div>
                        <div id="collapseTwo" class="card-body mx-3 collapse show">
                            <div class="row py-2 border-bottom">
                                <ng-container *ngIf="showPublishedDate; else showUploadDateHeader">
                                    <ng-container *ngIf="showDocumentSubmitter; else hideDocumentSubmitter">
                                        <div class="col-3 ps-0 fw-bold">Type</div>
                                        <div class="col-2 fw-bold">Filename</div>
                                        <div class="col-2 fw-bold">Published Date</div>
                                        <div class="col-2 fw-bold">Upload Date</div>
                                        <div class="col-2 fw-bold">Submitter</div>
                                        <div class="col-1 fw-bold">File Size</div>
                                    </ng-container>
                                    <ng-template #hideDocumentSubmitter>
                                        <div class="col-4 ps-0 fw-bold">Type</div>
                                        <div class="col-4 fw-bold">Filename</div>
                                        <div class="col-2 fw-bold">Published Date</div>
                                        <div class="col-2 fw-bold">Upload Date</div>
                                    </ng-template>
                                </ng-container>
                                <ng-template #showUploadDateHeader>
                                    <div class="col-4 ps-0 fw-bold">Type</div>
                                    <div class="col-6 fw-bold">Filename</div>
                                    <div class="col-2 fw-bold">Date</div>
                                </ng-template>
                            </div>
                            <div *ngFor="let document of supportingDocuments">
                                <div class="row py-3 border-bottom doc-content">
                                    <ng-container *ngIf="showPublishedDate; else showUploadDateHeader">
                                        <ng-container *ngIf="showDocumentSubmitter; else hideDocumentSubmitter">
                                            <div class="col-3 ps-0">{{getDocumentTypeName(document)}}</div>
                                            <div class="col-2 text-truncate">
                                                <a class="btn-link doc-content" title="{{document.documentName}}" (click)="downloadDocument(document)">
                                                    {{document.documentName}}
                                                </a>
                                            </div>
                                            <div class="col-2 pe-0 doc-content">
                                                {{document.publishedDate | date:'mediumDate' }}
                                            </div>
                                            <div class="col-2 pe-0 doc-content">
                                                {{document.uploadedDate | date:'mediumDate' }}
                                            </div>
                                            <div class="col-2">{{document.uploadedBy}}</div>
                                            <div class="col-1">{{document.documentSize}}</div>
                                        </ng-container>
                                        <ng-template #hideDocumentSubmitter>
                                            <div class="col-4 ps-0">{{getDocumentTypeName(document)}}</div>
                                            <div class="col-4 text-truncate">
                                                <a class="btn-link doc-content" title="{{document.documentName}}" (click)="downloadDocument(document)">
                                                    {{document.documentName}}
                                                </a>
                                            </div>
                                            <div class="col-2 pe-0 doc-content">
                                                {{document.publishedDate | date:'mediumDate' }}
                                            </div>
                                            <div class="col-2 pe-0 doc-content">
                                                {{document.uploadedDate | date:'mediumDate' }}
                                            </div>
                                        </ng-template>
                                    </ng-container>
                                    <ng-template #showUploadDateHeader>
                                        <div class="col-4 ps-0">{{getDocumentTypeName(document)}}</div>
                                        <div class="col-6 text-truncate">
                                            <a class="btn-link doc-content" title="{{document.documentName}}" (click)="downloadDocument(document)">
                                                {{document.documentName}}
                                            </a>
                                        </div>
                                        <div class="col-2 pe-0 doc-content"
                                        *ngIf="document.documentTypeId == DocumentTypes.WitnessStatements && document.publishedDate != null; else showUploadDate">
                                        {{document.publishedDate | date:'mediumDate' }}
                                        </div>
                                        <ng-template #showUploadDate>
                                            <div class="col-2 pe-0 doc-content">
                                            {{document.uploadedDate | date:'mediumDate' }}
                                            </div>
                                        </ng-template>
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                        <div id="collapse2" class="card-body mx-3 collapse show mobile-callout p-0">
                            <div *ngFor="let document of supportingDocuments">
                                <div class="row py-3 border-bottom doc-content">
                                    <div class="col-12 label">Type</div>                                
                                    <div class="col-12 text-wrap">
                                        {{document.documentType}} <span *ngIf="document.isRequired" class="label-hint">(Required)</span>
                                    </div>
                                    <div class="col-12 label">Filename</div>
                                    <div class="col-12 text-truncate text-wrap">
                                        <a class="btn-link" title="{{document.documentName}}" (click)="downloadDocument(document)">
                                            {{document.documentName}}
                                        </a>
                                    </div>
                                    
                                    <ng-container *ngIf="showPublishedDate; else showUploadDateHeader">
                                        <div class="col-12 label">Published Date</div>
                                        <div class="col-12">
                                            {{document.publishedDate | date:'mediumDate' }}
                                        </div>
    
                                        <div class="col-12 label">Upload Date</div>
                                        <div class="col-12">
                                            {{document.uploadedDate | date:'mediumDate' }}
                                        </div>
                                    </ng-container>
                                    <ng-template #showUploadDateHeader>
                                        <div class="col-12 label">Date</div>
                                        <div class="col-12" 
                                        *ngIf="document.documentTypeId == DocumentTypes.WitnessStatements && document.publishedDate != null; else showUploadDate">
                                            {{document.publishedDate | date:'mediumDate' }}
                                        </div>
                                        <ng-template #showUploadDate>
                                            <div class="col-12">
                                                {{document.uploadedDate | date:'mediumDate' }}
                                            </div>
                                        </ng-template>
                                    </ng-template>

                                    <ng-container *ngIf="showDocumentSubmitter">
                                        <div class="col-12 label">Submitter</div>
                                        <div class="col-12">
                                            {{document.uploadedBy }}
                                        </div>
                                        <div class="col-12 label">File Size</div>
                                        <div class="col-12">
                                            {{document.documentSize }}
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="showSupportingDocumentDeclarations">
                        <div class="card-header" data-bs-toggle="collapse" href="#collapseSix">
                            Supporting Document Declarations
                        </div>
                        <div id="collapseSix" class="card-body collapse show" >
                            <app-section-details sectionTitle="" [sectionDetails]="supportingDocumentDeclarations"></app-section-details>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="!isChangeDisplayedInformationActive && +seizureUserType == SeizureUserType.Driver">
                        <div class="card-header" data-bs-toggle="collapse" href="#collapseOne">
                                Driver Information
                        </div>
                        <div id="collapseOne" class="card-body collapse show" >
                            <label *ngIf="this.recipient?.movesLookupDate">The following driver information and contact information was generated from the Motor Vehicle System (MOVES) on {{recipient?.movesLookupDate | date:'mediumDate'}} at {{recipient?.movesLookupDate | date:'HH:mm'}}</label>
                            <app-section-details sectionTitle="Driver Information" [sectionDetails]="recipientInformation"></app-section-details>
                            <app-section-details sectionTitle="Driver Licence Information" [sectionDetails]="recipientIdentificationInformation"></app-section-details>
                            <app-section-details sectionTitle="Police Narrative" [sectionDetails]="policeNarrativeDetails"></app-section-details>

                            <ng-container *ngIf="this.recipient?.movesLookupDate && this.recipient?.recipientContact?.isAddressDifferentFromMOVES">
                                <app-section-details sectionTitle="Driver Contact Information" [sectionDetails]="movesRecipientContactInformation"></app-section-details>
                                <label>The following contact information was provided by the recipient to the officer</label>
                                <app-section-details sectionTitle="" [sectionDetails]="recipientContactInformation"></app-section-details>    
                            </ng-container>

                            <ng-container *ngIf="(this.recipient?.movesLookupDate && !this.recipient?.recipientContact?.isAddressDifferentFromMOVES) || !this.recipient?.movesLookupDate">
                                <app-section-details sectionTitle="Driver Contact Information" [sectionDetails]="recipientContactInformation"></app-section-details>
                            </ng-container>
                        </div>
                    </ng-container>

                    
                    <ng-container *ngIf="!isChangeDisplayedInformationActive && +seizureUserType != SeizureUserType.Driver">
                        <div class="card-header" data-bs-toggle="collapse" href="#collapseOne">
                                Driver Information
                        </div>
                        <div id="collapseOne" class="card-body collapse show" >
                            <app-section-details sectionTitle="Driver Information" [sectionDetails]="recipientInformation"></app-section-details>
                        </div>
                    </ng-container>

                    <ng-container>
                        <div class="card-header" data-bs-toggle="collapse" href="#collapseFive">
                                Police Service Information
                        </div>
                        <div id="collapseFive" class="card-body collapse show" >
                            <app-section-details sectionTitle="" [sectionDetails]="policeServiceInformation"></app-section-details>
                            <app-section-details sectionTitle="" [sectionDetails]="officerDeclarations"></app-section-details>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>

        <div *ngIf="+seizureUserType == SeizureUserType.RegisteredOwner || (vehicleSeizure.contraventionId == null && +seizureUserType == SeizureUserType.Driver)">
            <div *ngIf="+seizureUserType == SeizureUserType.RegisteredOwner">
                <!-- Seizure status -->
                <div class="card card-action mb-4" *ngIf="vehicleSeizure.reliefState == ReviewState.ReviewScheduled
                        || vehicleSeizure.reliefState == ReviewState.ReviewPendingDecision
                        || vehicleSeizure.reliefState == ReviewState.ReviewCancelled
                        || vehicleSeizure.reliefState == ReviewState.ReviewDecisionAvailable
                        || isReviewAbandoned || isReviewCancelled">
                    <div class="card-header">
                            Review Status
                    </div>
                    <div class="card-body" *ngIf="vehicleSeizure.reliefState == ReviewState.ReviewCancelled || isReviewCancelled">
                        <p class="mt-0">Your review has been cancelled.</p>
                        <div>
                            <button class="btn-block goa-button goa--secondary" (click)="viewReviewDetails()">View Review Details</button>
                        </div>
                    </div>
                    <div class="card-body" *ngIf="vehicleSeizure.reliefState == ReviewState.ReviewPendingDecision">
                        <div class="d-flex align-items-center">
                            <div class="p-2 icon-circle clock">
                            </div>
                            <div class="ms-3">
                                <p>Your review decision is pending.</p>
                            </div>
                        </div>
                        <div>
                            <button class="btn-block goa-button goa--secondary" (click)="viewReviewDetails()">View Review Details</button>
                        </div>
                    </div>
                    <div class="card-body" *ngIf="vehicleSeizure.reliefState == ReviewState.ReviewDecisionAvailable || isReviewAbandoned">
                        <p class="mt-0">Your review decision is available.</p>
                        <div class="my-4 d-flex align-items-center">
                            <div class="p-2 icon-circle review-scales">
                            </div>
                            <div class="d-flex flex-column ms-3">
                                <div class="summary-item-label">Review Date</div>
                                <div class="summary-item-value">
                                    {{ vehicleSeizure.relief.reviewDate | date:"mediumDate" }} 
                                </div>
                            </div>
                        </div>
                        <div>
                            <button class="btn-block goa-button goa--secondary" (click)="viewReviewDetails()">View Review Decision</button>
                        </div>
                    </div>
                    <div class="card-body" *ngIf="vehicleSeizure.reliefState == ReviewState.ReviewScheduled">
                        <p class="mt-0">Your review date has been scheduled.</p>
                        <div class="my-4 d-flex align-items-center">
                            <div class="p-2 icon-circle review-scales">
                            </div>
                            <div class="d-flex flex-column ms-3">
                                <div class="summary-item-label">Review Date</div>
                                <div class="summary-item-value">
                                    {{ vehicleSeizure.relief.reviewDate | date:"mediumDate" }} 
                                </div>
                            </div>
                        </div>
                        <div>
                            <button class="btn-block goa-button goa--secondary" (click)="viewReviewDetails()">View Review Details</button>
                        </div>
                    </div>
                </div>
        
                <div class="card card-action mb-4" *ngIf="vehicleSeizure.reliefState == ReviewState.ReviewRequested && vehicleSeizure.relief.isReReview">
                    <div class="card-header">
                        Re-Review
                    </div>
                    <div class="card-body">
                        <div class="d-flex align-items-center">
                            <div class="p-2 icon-circle review-scales">
                            </div>
                            <div class="ms-3">
                                <p class="ms-3 mt-0">Your 
                                    <a [routerLink]="['activity-history']">Re-Review is available.</a> You may now schedule your review.</p>
                            </div>
                        </div>
                        <div>
                            <button class="btn-block goa-button goa--secondary" (click)="viewReviewDetails()">Schedule Review</button>
                        </div>
                    </div>
                </div>
                <div class="card card-action mb-4" *ngIf="vehicleSeizure.reliefState == ReviewState.ReviewAvailable || vehicleSeizure.reliefState == ReviewState.ReviewAlreadyApplied">
                    <div class="card-header">
                        Apply for a Relief
                    </div>
                    <div class="card-body" *ngIf="vehicleSeizure.secondaryReliefDecisionTypeId == DecisionTypes.Cancel, else elseBlock">
                        <p>You have already applied for relief from the seizure and the application was granted.</p>
                    </div>
                    <ng-template #elseBlock>
                        <div class="card-body" *ngIf="vehicleSeizure.secondaryReliefDecisionTypeId == DecisionTypes.Confirm, else elseBlock2">
                            <p>You have already applied for relief from the seizure and the application was denied.</p>
                        </div>
                    </ng-template>
                    <ng-template #elseBlock2>
                        <div class="card-body" *ngIf="vehicleSeizure.reliefState == ReviewState.ReviewAlreadyApplied">
                            <p>An application for relief has been requested on the seizure.</p>
                        </div>
                    </ng-template>
                    <div class="card-body" *ngIf="vehicleSeizure.reliefState == ReviewState.ReviewAvailable">
                        <p class="mt-0">If you are the registered owner of the vehicle and you were not driving, you can seek relief from the seizure.</p>
                        <div class="my-4 d-flex align-items-center">
                            <div class="p-2 icon-circle review-scales">
                            </div>
                            <div class="d-flex flex-column ms-3">
                                <div class="summary-item-label">Relief Request Deadline</div>
                                <div class="summary-item-value">{{ vehicleSeizure.reliefDeadLine | date:"mediumDate" }}</div>
                            </div>
                        </div>
                        <div>
                            <button class="btn-block goa-button goa--secondary" data-bs-toggle="modal" data-bs-target="#confirmReviewModal">Apply for a Relief</button>
                        </div>
                    </div>                    
                </div>
            </div>

            <div *ngIf="+seizureUserType == SeizureUserType.Driver">
                
                <!-- Seizure status -->
                <div class="card card-action mb-4" *ngIf="vehicleSeizure.reviewState == ReviewState.ReviewScheduled
                        || vehicleSeizure.reviewState == ReviewState.ReviewPendingDecision
                        || vehicleSeizure.reviewState == ReviewState.ReviewCancelled
                        || vehicleSeizure.reviewState == ReviewState.ReviewDecisionAvailable
                        || isReviewAbandoned || isReviewCancelled">
                    <div class="card-header">
                            Review Status
                    </div>
                    <div class="card-body" *ngIf="vehicleSeizure.reviewState == ReviewState.ReviewScheduled">
                        <p class="mt-0">Your review has been scheduled.</p>
                        <div class="my-4 d-flex align-items-center">
                            <div class="p-2 icon-circle review-scales">
                            </div>
                            <div class="d-flex flex-column ms-3">
                                <div class="summary-item-label">Review Date</div>
                                <div class="summary-item-value">
                                    {{ vehicleSeizure.review.reviewDate | date:"mediumDate" }} 
                                </div>
                            </div>
                        </div>
                        <div>
                            <button class="btn-block goa-button goa--secondary" (click)="viewReviewDetails()">View Review Details</button>
                        </div>
                    </div>
                    <div class="card-body" *ngIf="vehicleSeizure.reviewState == ReviewState.ReviewPendingDecision">
                        <div class="d-flex align-items-center">
                            <div class="p-2 icon-circle clock">
                            </div>
                            <div class="ms-3">
                                <p>Your review decision is pending.</p>
                            </div>
                        </div>
                        <div>
                            <button class="btn-block goa-button goa--secondary" (click)="viewReviewDetails()">View Review Details</button>
                        </div>
                    </div>
                    <div class="card-body" *ngIf="vehicleSeizure.reviewState == ReviewState.ReviewCancelled || isReviewCancelled">
                        <p class="mt-0">Your review has been cancelled.</p>
                        <div>
                            <button class="btn-block goa-button goa--secondary" (click)="viewReviewDetails()">View Review Details</button>
                        </div>
                    </div>
                    <div class="card-body" *ngIf="vehicleSeizure.reviewState == ReviewState.ReviewDecisionAvailable || isReviewAbandoned">
                        <p class="mt-0">Your review decision is available.</p>
                        <div class="my-4 d-flex align-items-center">
                            <div class="p-2 icon-circle review-scales">
                            </div>
                            <div class="d-flex flex-column ms-3">
                                <div class="summary-item-label">Review Date</div>
                                <div class="summary-item-value">
                                    {{ vehicleSeizure.review.reviewDate | date:"mediumDate" }} 
                                </div>
                            </div>
                        </div>
                        <div>
                            <button class="btn-block goa-button goa--secondary" (click)="viewReviewDetails()">View Review Decision</button>
                        </div>
                    </div>
                </div>

                <div class="card card-action mb-4" *ngIf="vehicleSeizure.reviewState == ReviewState.ReviewRequested && vehicleSeizure.review.isReReview">
                    <div class="card-header">
                        Re-Review
                    </div>
                    <div class="card-body">
                        <div class="d-flex align-items-center">
                            <div class="p-2 icon-circle review-scales">
                            </div>
                            <div class="ms-3">
                                <p class="ms-3 mt-0">Your 
                                    <a [routerLink]="['activity-history']">Re-Review is available.</a> You may now schedule your review.</p>
                            </div>
                        </div>
                        <div>
                            <button class="btn-block goa-button goa--secondary" (click)="viewReviewDetails()">Schedule Review</button>
                        </div>
                    </div>
                </div>

                <div *ngIf="vehicleSeizure.reviewState != ReviewState.ReviewScheduled && vehicleSeizure.reviewState != ReviewState.ReviewPendingDecision && vehicleSeizure.reviewState != ReviewState.ReviewDecisionAvailable">
                    <div class="card card-action mb-4" *ngIf="vehicleSeizure.reviewState != ReviewState.ReviewUnAvailable  && vehicleSeizure.reviewState != ReviewState.ReviewRequested">
                        <div class="card-header">
                            Apply for a Review
                        </div>
                        <div class="card-body" *ngIf="vehicleSeizure.reviewState == ReviewState.ReviewAvailable">
                            <p class="mt-0">Apply for a review if you wish to dispute your penalty.</p>
                            <div class="my-4 d-flex align-items-center">
                                <div class="p-2 icon-circle review-scales">
                                </div>
                                <div class="d-flex flex-column ms-3">
                                    <div class="summary-item-label">Review Request Deadline</div>
                                    <div class="summary-item-value">{{ vehicleSeizure.reviewDeadLine | date:"mediumDate" }}</div>
                                </div>
                            </div>
                            <div>
                                <button class="btn-block goa-button goa--secondary" data-bs-toggle="modal" data-bs-target="#confirmReviewModal">Apply for a Review</button>
                            </div>
                        </div>

                        <div class="card-body" *ngIf="vehicleSeizure.reviewState == ReviewState.LateReviewAvailable
                            || vehicleSeizure.reviewState == ReviewState.LateReviewAvailableAfterCancellation">
                            <p class="mt-0" *ngIf="isReviewAbandoned">Your review was deemed abandoned. To dispute your penalty at this time, you must submit a request for late review.</p>
                            <p class="mt-0" *ngIf="vehicleSeizure.reviewState == ReviewState.LateReviewAvailableAfterCancellation">
                                Your review has been cancelled.
                            </p>
                            <p class="mt-0" *ngIf="!isReviewAbandoned">Submit a late review request if you wish to dispute your penalty.</p>
                            <div class="my-4 d-flex align-items-center">
                                <div class="p-2 icon-circle review-scales">
                                </div>
                                <div class="d-flex flex-column ms-3">
                                    <div class="summary-item-label">Late Review Request Deadline</div>
                                    <div class="summary-item-value">{{ vehicleSeizure.reviewDeadLine | date:"mediumDate" }}</div>
                                </div>
                            </div>
                            <div>
                                <button class="btn-block goa-button goa--secondary" (click)="startLateReviewRequest()">Request a Late Review</button>
                            </div>
                        </div>
                        <div class="card-body" *ngIf="vehicleSeizure.reviewState == ReviewState.LateReviewRequested">
                            <div class="d-flex align-items-center">
                                <div class="p-2 icon-circle clock">
                                </div>
                                <div class="ms-3">
                                    <p>Your late review request is pending.</p>
                                </div>
                            </div>
                        </div>
                        <div class="card-body" *ngIf="vehicleSeizure.reviewState == ReviewState.LateReviewDenied">
                            <p class="mt-0">Your Late Review Request Has Been Denied.</p>
                            <div>
                                <button class="btn-block goa-button goa--secondary" [routerLink]="['late-review-request/decision']">View Decision</button>
                            </div>
                        </div>
                        <div class="card-body" *ngIf="vehicleSeizure.reviewState == ReviewState.LateReviewApproved">
                            <p class="mt-0">Your Late Review Request has been 
                                <a [routerLink]="['late-review-request/decision']">approved.</a> You may now apply for a review if you wish to dispute your penalty.</p>
                            <div class="my-4 d-flex align-items-center">
                                <div class="p-2 icon-circle review-scales">
                                </div>
                                <div class="d-flex flex-column ms-3">
                                    <div class="summary-item-label">Review Request Deadline</div>
                                    <div class="summary-item-value">{{ vehicleSeizure.reviewDeadLine | date:"mediumDate" }}</div>
                                </div>
                            </div>
                            <div>
                                <button class="btn-block goa-button goa--secondary" data-bs-toggle="modal" data-bs-target="#confirmReviewModal">Apply for a Review</button>
                            </div>
                        </div>                    
                    </div>
                </div>
            </div>

            <div class="info-btn-container mb-4">
                <button class="btn-block goa-button goa--secondary mt-0" (click)="viewActivityHistory()">View Activity History</button>
            </div>
        </div>
    </div>
</div>

<!-- Registry Portal View -->
<div class="d-flex flex-column" *ngIf="vehicleSeizure && isRSGPortal">
    <div class="mb-4 not-for-print">
        <a class="lnk-back" [routerLink]="['../../vehicle-seizures/search']">Back to Seizure Lookup</a>
    </div>
    <div class="card goa-callout goa--emergency mb-4 mt-0" *ngIf="vehicleSeizure.isPaymentInProgress">
        <h2 class="my-0">Takes time to reset, return to MOVES/try again</h2>
    </div>
    <div class="card goa-callout goa--information mb-4 mt-0 mobile-callout" *ngIf="isImmidiatelyCancelled">
        <h6 class="mt-0">Seizure Cancelled</h6>
        <p>
            This seizure was immediately cancelled on {{cancelledDate}} as it was submitted more than 7 days from issuance.
        </p>
    </div>
    
    <div class="row">
        <div class="col-12 col-sm-8">        
            <h1 class="mb-3">Vehicle Seizure Details</h1>
        </div>
        <div class="col-12 col-sm-4 d-flex align-self-end justify-content-end">
            <div class="float-end">
                <div class="print-icon not-for-print" (click)="printSummary()"></div>
            </div>
        </div>
    </div>

    <div class="d-flex">
    <h2 class="m-0">{{ seizureGroupName }}</h2>
        <span class="badge rounded-pill badge-status" 
        [class.badge-warning]="vehicleSeizure.seizureStatusTypeId == 1"
        [class.badge-review]="vehicleSeizure.seizureStatusTypeId == 2"
        [class.badge-success]="vehicleSeizure.seizureStatusTypeId == 3"
        [class.badge-secondary]="vehicleSeizure.seizureStatusTypeId == 4">{{ seizureStatusTypeName | uppercase}}</span>
        <span *ngIf="vehicleSeizure.isJudicialReviewServed" class="jr-badge">
            <img class="jr-badge-flag" src="assets/images/triangular-flag-white.svg" />
            JR Served
        </span>
    </div>

    <h3 class="mt-0">Seizure No. {{ vehicleSeizure.seizureNumber }}</h3>

    <div class="summary-item-container border-bottom pb-4">
        <div class="info-item">
            <div class="d-flex flex-row">
                <div>
                    <div class="icon-circle issue-date">
                    </div>
                </div>
                <div class="d-flex flex-column ms-3 pt-1">
                    <div class="summary-item-label">Date Seized</div>
                    <div class="summary-item-value">{{ vehicleSeizure.seizureDate | date:'mediumDate' }}</div>
                </div>
            </div>
        </div>    
        <div class="info-item">
            <div class="d-flex flex-row">
                <div>
                    <div class="icon-circle calendar">
                    </div>
                </div>
                <div class="d-flex flex-column ms-3 pt-1">
                    <div class="summary-item-label">Immediate Seizure Duration</div>
                    <div class="summary-item-value">{{ vehicleSeizure.seizureDuration }} {{ vehicleSeizure.seizureDurationType }}</div>
                </div>
            </div>
        </div>    
        <div class="info-item">
            <div class="d-flex flex-row">
                <div>
                    <div class="icon-circle car-key">
                    </div>
                </div>
                <div class="d-flex flex-column ms-3 pt-1">
                    <div class="summary-item-label">Release Date</div>
                    <div class="summary-item-value">{{ vehicleSeizure.vehicleReleaseDate | date:'mediumDate' }}</div>
                </div>
            </div>
        </div> 
        <div class="ms-auto not-for-print">
            <div class="d-flex flex-row justify-content-end">
                <button class="btn-block goa-button mt-0" 
                 *ngIf="vehicleSeizure.contraventionNumber && +seizureUserType == SeizureUserType.Driver"
                 [routerLink]="['../../contravention', vehicleSeizure.contraventionNumber]">View Linked Contravention(s)</button>
                 <button class="btn-block goa-button mt-0" 
                 *ngIf="vehicleSeizure.secondarySeizureNumber && +seizureUserType != SeizureUserType.Driver"
                 [routerLink]="['../../vehicle-seizures', vehicleSeizure.secondarySeizureNumber]">View Linked Seizure</button>
            </div>
        </div> 
    </div>

    <div class="content mt-4">
        <div class="me-4 section">
            <div class="card goa-callout goa--information mb-4 mt-0 desktop-callout" *ngIf="isImmidiatelyCancelled">
                <h6 class="mt-0">Seizure Cancelled</h6>
                <p>
                    This seizure was immediately cancelled on {{cancelledDate}} as it was submitted more than 7 days from issuance.
                </p>
            </div>
            <div id="accordion" class="accordion">
                <div class="card" [ngClass]="{'card-large': (+seizureUserType == SeizureUserType.Driver && vehicleSeizure.contraventionId != null)}">
                    <ng-container *ngIf="!isChangeDisplayedInformationActive && +seizureUserType == SeizureUserType.Driver">
                        <div class="card-header" data-bs-toggle="collapse" href="#collapseOne">
                                Driver Information
                        </div>
                        <div id="collapseOne" class="card-body collapse show" >
                            <label *ngIf="this.recipient?.movesLookupDate">The following driver information and contact information was generated from the Motor Vehicle System (MOVES) on {{recipient?.movesLookupDate | date:'mediumDate'}} at {{recipient?.movesLookupDate | date:'HH:mm'}}</label>
                            <app-section-details sectionTitle="Driver Information" [sectionDetails]="recipientInformation"></app-section-details>
                            <app-section-details sectionTitle="Driver Licence Information" [sectionDetails]="recipientIdentificationInformation"></app-section-details>
                            <app-section-details sectionTitle="Police Narrative" [sectionDetails]="policeNarrativeDetails"></app-section-details>

                            <ng-container *ngIf="this.recipient?.movesLookupDate && this.recipient?.recipientContact?.isAddressDifferentFromMOVES">
                                <app-section-details sectionTitle="Driver Contact Information" [sectionDetails]="movesRecipientContactInformation"></app-section-details>
                                <label>The following contact information was provided by the recipient to the officer</label>
                                <app-section-details sectionTitle="" [sectionDetails]="recipientContactInformation"></app-section-details>
                            </ng-container>

                            <ng-container *ngIf="(this.recipient?.movesLookupDate && !this.recipient?.recipientContact?.isAddressDifferentFromMOVES) || !this.recipient?.movesLookupDate">
                                <app-section-details sectionTitle="Driver Contact Information" [sectionDetails]="recipientContactInformation"></app-section-details>
                            </ng-container>
                        </div>
                    </ng-container>

                    <ng-container>
                        <div class="card-header" data-bs-toggle="collapse" href="#collapseThree">
                                Vehicle Seizure Details
                        </div>
                        <div id="collapseThree" class="card-body collapse show">
                            <app-section-details sectionTitle="Seizure Information" [sectionDetails]="seizureInformation"></app-section-details>
                            <app-section-details *ngIf="isChangeDisplayedInformationActive" sectionTitle="Police Narrative" [sectionDetails]="policeNarrativeDetails"></app-section-details>
                            <div class="mb-3 row" *ngIf="vehicleSeizure?.seizureTypeId == SeizureTypes.SuspendedDriversProgramFirstOccurrence || vehicleSeizure?.seizureTypeId == SeizureTypes.SuspendedDriversProgramSecondOccurrence">
                                <div class="col-12">
                                    <div class="label">Please visit <a href="https://www.alberta.ca/vehicle-seizure-programs.aspx" target="_blank">Vehicle Seizure Programs</a> for information about the Suspended Driver Vehicle Seizure.</div>
                                </div>
                            </div>
                            <label *ngIf="this.vehicleSeizure?.vehicle?.movesLookupDate">The following vehicle information was generated from the Motor Vehicle System (MOVES) on {{vehicleSeizure?.vehicle?.movesLookupDate | date:'mediumDate'}} at {{vehicleSeizure?.vehicle?.movesLookupDate | date:'HH:mm'}}</label>               
                    
                            <ng-container *ngIf="this.vehicleSeizure?.vehicle?.movesLookupDate && this.vehicleSeizure?.vehicle?.isStolenPlate">
                                <app-section-details sectionTitle="Vehicle Information" [sectionDetails]="movesVehicleInformation"></app-section-details>
                                <label>The information above did not match the vehicle. The following information is a description of the vehicle involved in the contravention.</label>
                                <app-section-details sectionTitle="" [sectionDetails]="vehicleInformation"></app-section-details>
                            </ng-container>
        
                            <ng-container *ngIf="(this.vehicleSeizure?.vehicle?.movesLookupDate && !this.vehicleSeizure?.vehicle?.isStolenPlate) || !this.vehicleSeizure?.vehicle?.movesLookupDate">
                                <app-section-details sectionTitle="Vehicle Information" [sectionDetails]="vehicleInformation"></app-section-details>
                            </ng-container>
                            <app-section-details sectionTitle="Trailer Information" [sectionDetails]="trailerInformation"></app-section-details>

                            <ng-container *ngIf="+seizureUserType == SeizureUserType.RegisteredOwner">
                                <label *ngIf="vehicleSeizure?.vehicle?.movesLookupDate && !vehicleSeizure?.vehicle?.registeredOwner?.isSameAsDriver && !this.vehicleSeizure?.vehicle?.isNoRegisteredOwner && !this.vehicleSeizure?.vehicle?.isStolenPlate">The following registered owner information was generated from the Motor Vehicle System (MOVES) on {{vehicleSeizure?.vehicle?.movesLookupDate | date:'mediumDate'}} at {{vehicleSeizure?.vehicle?.movesLookupDate | date:'HH:mm'}}</label>
                                <app-section-details sectionTitle="Registered Owner Information" [sectionDetails]="registeredOwnerInformation"></app-section-details>
                            </ng-container>
                            
                            <app-section-details sectionTitle="Vehicle Location & Tow Lot Information" [sectionDetails]="towLotInformation"></app-section-details>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="isChangeDisplayedInformationActive">
                        <div class="card-header" data-bs-toggle="collapse" href="#collapseOne">
                                Driver Information
                        </div>
                        <div id="collapseOne" class="card-body collapse show" >
                            <label *ngIf="this.recipient?.movesLookupDate">The following driver information and contact information was generated from the Motor Vehicle System (MOVES) on {{recipient?.movesLookupDate | date:'mediumDate'}} at {{recipient?.movesLookupDate | date:'HH:mm'}}</label>
                            <app-section-details sectionTitle="Driver Information" [sectionDetails]="recipientInformation"></app-section-details>
                            <app-section-details *ngIf="+seizureUserType == SeizureUserType.Driver" sectionTitle="Driver Licence Information" [sectionDetails]="recipientIdentificationInformation"></app-section-details>

                            <ng-container *ngIf="this.recipient?.movesLookupDate && this.recipient?.recipientContact?.isAddressDifferentFromMOVES">
                                <app-section-details sectionTitle="Driver Contact Information" [sectionDetails]="movesRecipientContactInformation"></app-section-details>
                                <label>The following contact information was provided by the recipient to the officer</label>
                                <app-section-details sectionTitle="" [sectionDetails]="recipientContactInformation"></app-section-details>
                            </ng-container>

                            <ng-container *ngIf="(this.recipient?.movesLookupDate && !this.recipient?.recipientContact?.isAddressDifferentFromMOVES) || !this.recipient?.movesLookupDate">
                                <app-section-details sectionTitle="Driver Contact Information" [sectionDetails]="recipientContactInformation"></app-section-details>
                            </ng-container>
                        </div>
                    </ng-container>

                    <ng-container>
                        <div class="card-header" data-bs-toggle="collapse" href="#collapseFour">
                            Supporting Documents
                        </div>
                        <div id="collapse2" class="card-body mx-3 collapse show  desktop-callout">
                            <div class="row py-2 border-bottom">
                                <div class="col-4 ps-0 fw-bold">Type</div>
                                <ng-container *ngIf="showPublishedDate; else showUploadDateHeader">
                                    <div class="col-4 fw-bold">Filename</div>
                                    <div class="col-2 fw-bold">Published Date</div>
                                    <div class="col-2 fw-bold">Upload Date</div>
                                </ng-container>
                                <ng-template #showUploadDateHeader>
                                    <div class="col-6 fw-bold">Filename</div>
                                    <div class="col-2 fw-bold">Date</div>
                                </ng-template>
                            </div>
                            <div *ngFor="let document of supportingDocuments">
                                <div class="row py-3 border-bottom doc-content">
                                    <div class="col-4 ps-0">
                                        {{document.documentType}} <span *ngIf="document.isRequired" class="label-hint">(Required)</span>
                                    </div>
                                    <div [ngClass]="showPublishedDate ? 'col-4' : 'col-6'" class="text-truncate">
                                        <a class="btn-link doc-content" title="{{document.documentName}}" (click)="downloadDocument(document)">
                                            {{document.documentName}}
                                        </a>
                                    </div>
                                    <ng-container *ngIf="showPublishedDate">
                                        <div class="col-2 pe-0 doc-content">
                                            {{document.publishedDate | date:'mediumDate' }}
                                        </div>
                                        <div class="col-2 pe-0 doc-content">
                                            {{document.uploadedDate | date:'mediumDate' }}
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="!showPublishedDate">
                                        <div class="col-2 pe-0 doc-content" 
                                        *ngIf="document.documentTypeId == DocumentTypes.WitnessStatements && document.publishedDate != null; else showUploadDate">
                                        {{document.publishedDate | date:'mediumDate' }}
                                        </div>
                                        <ng-template class="col-2 pe-0 doc-content" #showUploadDate>
                                            <div>{{document.uploadedDate | date:'mediumDate' }}</div>
                                        </ng-template>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <div id="collapse2" class="card-body mx-3 collapse show mobile-callout p-0">
                            <div *ngFFor="let document of supportingDocuments">
                                <div class="row py-3 border-bottom doc-content">
                                    <div class="col-12 label">Type</div>                                
                                    <div class="col-12 text-wrap">
                                        {{document.documentType}} <span *ngIf="document.isRequired" class="label-hint">(Required)</span>
                                    </div>
                                    <div class="col-12 label">Filename</div>
                                    <div class="col-12 text-truncate text-wrap">
                                        <a class="btn-link" title="{{document.documentName}}" (click)="downloadDocument(document)">
                                            {{document.documentName}}
                                        </a>
                                    </div>

                                    <ng-container *ngIf="showPublishedDate; else showUploadDateHeader">
                                        <div class="col-12 label">Published Date</div>
                                        <div class="col-12">
                                            {{document.publishedDate | date:'mediumDate' }}
                                        </div>
    
                                        <div class="col-12 label">Upload Date</div>
                                        <div class="col-12">
                                            {{document.uploadedDate | date:'mediumDate' }}
                                        </div>
                                    </ng-container>
                                    <ng-template #showUploadDateHeader>
                                        <div class="col-12 label">Date</div>
                                        <div class="col-12" 
                                        *ngIf="document.documentTypeId == DocumentTypes.WitnessStatements && document.publishedDate != null; else showUploadDate">
                                            {{document.publishedDate | date:'mediumDate' }}
                                        </div>
                                        <ng-template #showUploadDate>
                                            <div class="col-12">
                                                {{document.uploadedDate | date:'mediumDate' }}
                                            </div>
                                        </ng-template>
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="showSupportingDocumentDeclarations">
                        <div class="card-header" data-bs-toggle="collapse" href="#collapseSix">
                            Supporting Document Declarations
                        </div>
                        <div id="collapseSix" class="card-body collapse show" >
                            <app-section-details sectionTitle="" [sectionDetails]="supportingDocumentDeclarations"></app-section-details>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="!isChangeDisplayedInformationActive && +seizureUserType != SeizureUserType.Driver">
                        <div class="card-header" data-bs-toggle="collapse" href="#collapseOne">
                            Driver Information
                        </div>
                        <div id="collapseOne" class="card-body collapse show" >
                            <app-section-details sectionTitle="Driver Information" [sectionDetails]="recipientInformation"></app-section-details>
                        </div>
                    </ng-container>

                    <ng-container>
                        <div class="card-header" data-bs-toggle="collapse" href="#collapseFive">
                                Police Service Information
                        </div>
                        <div id="collapseFive" class="card-body collapse show" >
                            <app-section-details sectionTitle="" [sectionDetails]="policeServiceInformation"></app-section-details>
                            <app-section-details sectionTitle="" [sectionDetails]="officerDeclarations"></app-section-details>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>

        <div class="not-for-print" *ngIf="+seizureUserType == SeizureUserType.RegisteredOwner || (vehicleSeizure.contraventionId == null && +seizureUserType == SeizureUserType.Driver)">
            <div *ngIf="+seizureUserType == SeizureUserType.RegisteredOwner">
                <div class="card card-action mb-4" *ngIf="vehicleSeizure.reliefState == ReviewState.ReviewScheduled
                                || vehicleSeizure.reliefState == ReviewState.ReviewPendingDecision
                                || vehicleSeizure.reliefState == ReviewState.ReviewCancelled
                                || vehicleSeizure.reliefState == ReviewState.ReviewDecisionAvailable
                                || isReviewAbandoned || isReviewCancelled">
                    <div class="card-header">
                        Review Status
                    </div>
                    <div class="card-body" *ngIf="vehicleSeizure.reliefState == ReviewState.ReviewRequested">
                        <div class="d-flex align-items-center">
                            <div class="p-2 icon-circle clock">
                            </div>
                            <div class="ms-3">
                                <p>The review is being scheduled.</p>
                            </div>
                        </div>
                        <div>
                            <p>If the client needs to reschedule a review, advise them to contact <a class="link" role="button" (click)="onSafeRoadsContactClick()">SafeRoads Alberta</a>.</p>
                        </div>
                        <div>
                            <button class="btn-block goa-button goa--secondary" (click)="viewReviewDetails()">View Review Details</button>
                        </div>
                    </div>
                    <div class="card-body" *ngIf="vehicleSeizure.reliefState == ReviewState.ReviewScheduled">
                        <p class="mt-0">The review has been scheduled.</p>
                        <div class="my-4 d-flex align-items-center">
                            <div class="p-2 icon-circle review-scales">
                            </div>
                            <div class="d-flex flex-column ms-3">
                                <div class="summary-item-label">Review Date</div>
                                <div class="summary-item-value">
                                    {{ vehicleSeizure.relief.reviewDate | date:"mediumDate" }} 
                                </div>
                            </div>
                        </div>
                        <div>
                            <button class="btn-block goa-button goa--secondary" (click)="viewReviewDetails()">View Review Details</button>
                        </div>
                        <div>
                            <p>If the client needs to reschedule a review, advise them to contact <a class="link" role="button" (click)="onSafeRoadsContactClick()">SafeRoads Alberta</a>.</p>
                        </div>
                    </div>
                    <div class="card-body" *ngIf="vehicleSeizure.reliefState == ReviewState.ReviewPendingDecision">
                        <div class="d-flex align-items-center">
                            <div class="p-2 icon-circle clock">
                            </div>
                            <div class="ms-3">
                                <p>The review decision is pending.</p>
                            </div>
                        </div>
                        <div>
                            <button class="btn-block goa-button goa--secondary" (click)="viewReviewDetails()">View Review Details</button>
                        </div>
                    </div>
                    <div class="card-body" *ngIf="vehicleSeizure.reliefState == ReviewState.ReviewCancelled">
                        <p class="mt-0">The review has been cancelled.</p>
                        <div>
                            <button class="btn-block goa-button goa--secondary" (click)="viewReviewDetails()">View Review Details</button>
                        </div>
                    </div>
                    <div class="card-body" *ngIf="vehicleSeizure.reliefState == ReviewState.ReviewDecisionAvailable || isReviewAbandoned">
                        <p class="mt-0">The review decision is available.</p>
                        <div>
                            <button class="btn-block goa-button goa--secondary" (click)="viewReviewDetails()">View Review Decision</button>
                        </div>
                    </div>
                </div>


                <div class="card card-action mb-4" *ngIf="vehicleSeizure.reliefState == ReviewState.ReviewRequested && vehicleSeizure.relief.isReReview">
                    <div class="card-header">
                        Re-Review
                    </div>
                    <div class="card-body">
                        <div class="d-flex align-items-center">
                            <div class="p-2 icon-circle review-scales">
                            </div>
                            <div class="ms-3">
                                <p class="ms-3 mt-0">
                                    <a [routerLink]="['activity-history']">Re-Review is available.</a> The registered owner of the vehicle may now schedule their review.
                                </p>
                            </div>
                        </div>
                        <div>
                            <button class="btn-block goa-button goa--secondary" (click)="viewReviewDetails()">Schedule Review</button>
                        </div>
                    </div>
                </div>
                <div class="card card-action mb-4" *ngIf="vehicleSeizure.reliefState == ReviewState.ReviewAvailable || vehicleSeizure.reliefState == ReviewState.ReviewAlreadyApplied">
                    <div class="card-header">
                        Apply for a Relief
                    </div>
                    <div class="card-body" *ngIf="vehicleSeizure.secondaryReliefDecisionTypeId == DecisionTypes.Cancel, else elseBlock">
                        <p>You have already applied for relief from the seizure and the application was granted.</p>
                    </div>
                    <ng-template #elseBlock>
                        <div class="card-body" *ngIf="vehicleSeizure.secondaryReliefDecisionTypeId == DecisionTypes.Confirm, else elseBlock2">
                            <p>You have already applied for relief from the seizure and the application was denied.</p>
                        </div>
                    </ng-template>
                    <ng-template #elseBlock2>
                        <div class="card-body" *ngIf="vehicleSeizure.reliefState == ReviewState.ReviewAlreadyApplied">
                            <p>An application for relief has been requested on the seizure.</p>
                        </div>
                    </ng-template>
                    <div class="card-body" *ngIf="vehicleSeizure.reliefState == ReviewState.ReviewAvailable">
                        <p class="mt-0">If the client is the registered owner of the vehicle and they were not driving, they can seek relief from the seizure.</p>
                        <div class="my-4 d-flex align-items-center">
                            <div class="p-2 icon-circle review-scales">
                            </div>
                            <div class="d-flex flex-column ms-3">
                                <div class="summary-item-label">Relief Deadline</div>
                                <div class="summary-item-value">{{ vehicleSeizure.reliefDeadLine | date:"mediumDate" }}</div>
                            </div>
                        </div>
                        <div>
                            <button *ngIf="hasTransactionId && !vehicleSeizure.isPaymentInProgress" class="btn-block goa-button goa--secondary" data-bs-toggle="modal" data-bs-target="#confirmReviewModal">Apply for a Relief</button>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="+seizureUserType == SeizureUserType.Driver">

                <!-- Seizure status -->
                <div class="card card-action mb-4" *ngIf="vehicleSeizure.reviewState == ReviewState.ReviewScheduled
                        || vehicleSeizure.reviewState == ReviewState.ReviewPendingDecision
                        || vehicleSeizure.reviewState == ReviewState.ReviewCancelled
                        || vehicleSeizure.reviewState == ReviewState.ReviewDecisionAvailable
                        || isReviewAbandoned || isReviewCancelled">
                    <div class="card-header">
                            Review Status
                    </div>
                    <div class="card-body" *ngIf="vehicleSeizure.reviewState == ReviewState.ReviewScheduled">
                        <p class="mt-0">Your review has been scheduled.</p>
                        <div class="my-4 d-flex align-items-center">
                            <div class="p-2 icon-circle review-scales">
                            </div>
                            <div class="d-flex flex-column ms-3">
                                <div class="summary-item-label">Review Date</div>
                                <div class="summary-item-value">
                                    {{ vehicleSeizure.review.reviewDate | date:"mediumDate" }} 
                                </div>
                            </div>
                        </div>
                        <div>
                            <button class="btn-block goa-button goa--secondary" (click)="viewReviewDetails()">View Review Details</button>
                        </div>
                    </div>
                    <div class="card-body" *ngIf="vehicleSeizure.reviewState == ReviewState.ReviewPendingDecision">
                        <div class="d-flex align-items-center">
                            <div class="p-2 icon-circle clock">
                            </div>
                            <div class="ms-3">
                                <p>Your review decision is pending.</p>
                            </div>
                        </div>
                        <div>
                            <button class="btn-block goa-button goa--secondary" (click)="viewReviewDetails()">View Review Details</button>
                        </div>
                    </div>
                    <div class="card-body" *ngIf="vehicleSeizure.reviewState == ReviewState.ReviewCancelled || isReviewCancelled">
                        <p class="mt-0">Your review has been cancelled.</p>
                        <div>
                            <button class="btn-block goa-button goa--secondary" (click)="viewReviewDetails()">View Review Details</button>
                        </div>
                    </div>
                    <div class="card-body" *ngIf="vehicleSeizure.reviewState == ReviewState.ReviewDecisionAvailable || isReviewAbandoned">
                        <p class="mt-0">Your review decision is available.</p>
                        <div class="my-4 d-flex align-items-center">
                            <div class="p-2 icon-circle review-scales">
                            </div>
                            <div class="d-flex flex-column ms-3">
                                <div class="summary-item-label">Review Date</div>
                                <div class="summary-item-value">
                                    {{ vehicleSeizure.review.reviewDate | date:"mediumDate" }} 
                                </div>
                            </div>
                        </div>
                        <div>
                            <button class="btn-block goa-button goa--secondary" (click)="viewReviewDetails()">View Review Decision</button>
                        </div>
                    </div>
                </div>

                <div class="card card-action mb-4" *ngIf="vehicleSeizure.reviewState == ReviewState.ReviewRequested && vehicleSeizure.review.isReReview">
                    <div class="card-header">
                        Re-Review
                    </div>
                    <div class="card-body">
                        <div class="d-flex align-items-center">
                            <div class="p-2 icon-circle review-scales">
                            </div>
                            <div class="ms-3">
                                <p class="ms-3 mt-0">
                                    <a [routerLink]="['activity-history']">Re-Review is available.</a> The driver may now schedule their review.
                                </p>
                            </div>
                        </div>
                        <div>
                            <button class="btn-block goa-button goa--secondary" (click)="viewReviewDetails()">Schedule Review</button>
                        </div>
                    </div>
                </div>

                <div *ngIf="vehicleSeizure.reviewState != ReviewState.ReviewScheduled && vehicleSeizure.reviewState != ReviewState.ReviewPendingDecision && vehicleSeizure.reviewState != ReviewState.ReviewDecisionAvailable">
                    <div class="card card-action mb-4" *ngIf="vehicleSeizure.reviewState != ReviewState.ReviewUnAvailable  && vehicleSeizure.reviewState != ReviewState.ReviewRequested">
                        <div class="card-header">
                            Apply for a Review
                        </div>
                        <div class="card-body" *ngIf="vehicleSeizure.reviewState == ReviewState.ReviewAvailable">
                            <p class="mt-0">The driver can apply for a review if they wish to dispute the penalty.</p>
                            <div class="my-4 d-flex align-items-center">
                                <div class="p-2 icon-circle review-scales">
                                </div>
                                <div class="d-flex flex-column ms-3">
                                    <div class="summary-item-label">Review Deadline</div>
                                    <div class="summary-item-value">{{ vehicleSeizure.reviewDeadLine | date:"mediumDate" }}</div>
                                </div>
                            </div>
                            <div>
                                <button *ngIf="hasTransactionId && !vehicleSeizure.isPaymentInProgress" class="btn-block goa-button goa--secondary" data-bs-toggle="modal" data-bs-target="#confirmReviewModal">Apply for a Review</button>
                            </div>
                        </div>
                        <div class="card-body" *ngIf="vehicleSeizure.reviewState == ReviewState.LateReviewAvailable">
                            <p class="mt-0" *ngIf="isReviewAbandoned">The review was deemed abandoned. The driver can submit a request for late review if they wish to dispute the penalty.</p>
                            <p class="mt-0" *ngIf="!isReviewAbandoned">The driver can submit a late review request if they wish to dispute the penalty.</p>
                            <div class="my-4 d-flex align-items-center">
                                <div class="p-2 icon-circle review-scales">
                                </div>
                                <div class="d-flex flex-column ms-3">
                                    <div class="summary-item-label">Late Review Request Deadline</div>
                                    <div class="summary-item-value">{{ vehicleSeizure.reviewDeadLine | date:"mediumDate" }}</div>
                                </div>
                            </div>
                            <div>
                                <button *ngIf="hasTransactionId && !vehicleSeizure.isPaymentInProgress" class="btn-block goa-button goa--secondary" (click)="startLateReviewRequest()">Request a Late Review</button>
                            </div>
                        </div>
                        <div class="card-body" *ngIf="vehicleSeizure.reviewState == ReviewState.LateReviewRequested">
                            <div class="d-flex align-items-center">
                                <div class="p-2 icon-circle clock">
                                </div>
                                <div class="ms-3">
                                    <p>The late review request is pending.</p>
                                </div>
                            </div>
                        </div>
                        <div class="card-body" *ngIf="vehicleSeizure.reviewState == ReviewState.LateReviewDenied">
                            <p class="mt-0">The late review request has been denied.</p>
                            <div>
                                <button class="btn-block goa-button goa--secondary" [routerLink]="['late-review-request/decision']">View Decision</button>
                            </div>
                        </div>
                        <div class="card-body" *ngIf="vehicleSeizure.reviewState == ReviewState.LateReviewApproved">
                            <p class="mt-0">The late review request has been 
                                <a [routerLink]="['late-review-request/decision']">approved.</a> The driver can now apply for a review if they wish to dispute the penalty.</p>
                            <div class="my-4 d-flex align-items-center">
                                <div class="p-2 icon-circle review-scales">
                                </div>
                                <div class="d-flex flex-column ms-3">
                                    <div class="summary-item-label">Review Deadline</div>
                                    <div class="summary-item-value">{{ vehicleSeizure.reviewDeadLine | date:"mediumDate" }}</div>
                                </div>
                            </div>
                            <div>
                                <button *ngIf="hasTransactionId && !vehicleSeizure.isPaymentInProgress" class="btn-block goa-button goa--secondary" data-bs-toggle="modal" data-bs-target="#confirmReviewModal">Apply for a Review</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="info-btn-container mb-4">
                <button class="btn-block goa-button goa--secondary mt-0" (click)="viewActivityHistory()">View Activity History</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="confirmReviewModal" tabindex="-1" role="dialog" aria-labelledby="confirmReviewModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content p-4">
            <div class="modal-header border-0 pt-2">
                    <h6 class="mt-0 fw-bold">
                        Financial hardship and inconvenience are not grounds for cancellation of your Seizure Notice
                    </h6>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                    <i class="far fa-times-circle fa-lg"></i>
                </button>
            </div>
            <div class="modal-body py-0">
                <p>An adjudicator cannot consider arguments such as:</p>
                <ul>
                    <li>
                        You do not have the financial means to pay for the tow fees.
                    </li>
                    <li>
                        You need your vehicle to get to and from work.
                    </li>
                    <li>
                        You need your vehicle to take your kids to school or a family member to medical appointments.
                    </li>
                </ul>
            </div>
            <div class="modal-footer border-0 d-flex justify-content-between">
                <button type="button" class="goa-button goa--secondary mt-2 btn-confirm" 
                    data-bs-dismiss="modal">Close</button>
                <button type="button" class="goa-button mt-2 btn-confirm" 
                    data-bs-dismiss="modal" (click)="startReviewApplication()">Continue</button>
            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<app-contact-saferoads-modal></app-contact-saferoads-modal>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-fussion" [fullScreen]="true"><p style="color: white" > Loading... </p></ngx-spinner>      