<!-- Driver Portal View -->
<app-vehicle-seizure-page #masterPage *ngIf="!isRSGPortal" (onBeforeNavigate)="cancelRequest()">
    <ng-container content>
        <div class="d-flex flex-column">
            <h1>Application Summary</h1>
            <div class="d-flex w-100 border-bottom mb-2">
                <h3>No. {{review.seizureNumber}}</h3>
                <div class="countdown">
                    <countdown-timer #cd [key]="countdownKey" [lapseMessage]="lapseMessage"></countdown-timer>
                </div>
            </div>

            <div class="card goa-callout goa--emergency mt-3" *ngIf="isPaymentFailed">
                <h6 class="mt-0">Payment Failed</h6>
                <p>
                    Your payment was unsuccessful. Please re-enter your <a [routerLink]="['../payment-method']">Payment Method Details</a> and try again.
                </p>
            </div>

            <div class="card goa-callout goa--emergency mt-3" *ngIf="isRequestAppliedAfterDeadline">
                <h6 class="mt-0">{{reviewContext}} Request Failed</h6>
                <p>
                    Request could not be submitted. Submission deadline has passed. <a [routerLink]="['../../../']">Return back to Seizure Details.</a>
                </p>
            </div>

            <div class="card goa-callout goa--emergency mt-3" *ngIf="isReviewRequestNotAvailable">
                <h6 class="mt-0">{{reviewContext}} Request Failed</h6>
                <p>
                    Request could not be submitted. <a [routerLink]="['../../../']">Return back to Seizure Details.</a>
                </p>
            </div>

            <div class="card goa-callout goa--emergency mt-3" *ngIf="isRequestFailed">
                <h6 class="mt-0">{{reviewContext}} Request Failed</h6>
                <p>
                    Your request can't be completed right now. Please try again later. 
                </p>
            </div>

            <div class="card goa-callout goa--emergency mt-3" *ngIf="isReviewTimeExpired">
                <b>
                    <p class="mt-0">
                        Reserved review time expired. Please <a [routerLink]="['../schedule-review']">select a new timeslot</a>.
                    </p>
                </b>
            </div>

            <div class="card border-0">
                <p>
                    Review the details of your application below before submitting. 
                </p>

                <div class="goa-callout goa--important mt-1">
                    <h6 class="mt-0">Payments are non-refundable</h6>
                    <p>
                        Be advised that all payments to SafeRoads Alberta are final. No refunds will be issued.
                    </p>
                </div>
            </div>

            <div class="card border-0 mt-2" *ngIf="review.reviewTypeId == ReviewTypes.ThirdPartySeizureReview && vehicleSeizure.secondarySeizureNumber">
                <div class="goa-callout goa--important mt-1">
                    <h6 class="mt-0">Your vehicle was seized as a result of two seizure notices</h6>
                    <p>
                        This application for relief will address both seizure notices
                    </p>
                </div>
            </div>

            <div class="card mt-4">
                <div class="card-header">
                    Application Summary
                </div>
                <div class="card-body">  
                    <app-section-details sectionTitle="Representation Type" [sectionDetails]="reviewTypeInformation"></app-section-details>            
                    <app-section-details *ngIf="!review.isSelfSubmit"
                        sectionTitle="Representation" [sectionDetails]="representationInformation"></app-section-details>
                    <app-section-details *ngIf="review.reviewTypeId == ReviewTypes.ThirdPartySeizureReview"
                        sectionTitle="Applicant Information" [sectionDetails]="applicantInformation"></app-section-details>
                    <app-section-details *ngIf="review.reviewTypeId == ReviewTypes.ThirdPartySeizureReview"
                        sectionTitle="Registered Owner Information" [sectionDetails]="registeredOwnerInformation"></app-section-details>
                    <app-section-details *ngIf="review.reviewTypeId == ReviewTypes.SuspendedDriverVehicleSeizureReview"
                        sectionTitle="Driver Information" [sectionDetails]="recipientInformation"></app-section-details>
                    <app-section-details *ngIf="review.reviewTypeId == ReviewTypes.SuspendedDriverVehicleSeizureReview"
                        sectionTitle="Driver Licence Information" [sectionDetails]="recipientIdentificationInformation"></app-section-details>
                    <app-section-details sectionTitle="Vehicle Seizure Details" [sectionDetails]="vehicleSeizureDetails"></app-section-details>
                    <app-section-details sectionTitle="Vehicle Information" [sectionDetails]="vehicleInformation"></app-section-details>
                    <app-section-details sectionTitle="Review Details" [sectionDetails]="reviewsDetailsInformation"></app-section-details>            
                    <app-section-details sectionTitle="Preferred Contact Information" [sectionDetails]="preferredContactInformation"></app-section-details>
                    <app-section-details sectionTitle="Terms & Conditions" [sectionDetails]="termsConditionsInformation"></app-section-details>
                </div>
            </div>

            <div class="card mt-4">
                <div class="card-header">
                    Review Date
                </div>
                <div *ngIf="review.reviewMethodTypeId==1" class="card-body">
                    <div>You have scheduled a <b>{{ reviewMethod }} Review</b> for 
                        <b>{{ review.reviewDate | date:'fullDate':'UTC' }}.</b></div>
                    <div>A confirmation will be sent via the preferred contact method listed above.</div>
                </div>

                <div *ngIf="review.reviewMethodTypeId==2" class="card-body">
                    <div>You have scheduled a <b>{{ reviewMethod }} Review</b> for 
                        <b>{{ review.reviewDate | date:'fullDate':'UTC' }}</b> at <b>{{ reviewTime }}.</b></div>
                    <div>A confirmation will be sent via the preferred contact method listed above.</div>
                </div>
            </div>

            <div class="card mt-4">
                <div class="card-header">
                    Supporting Documents
                </div>
                <div class="card-body">  
                    <div *ngFor="let document of supportingDocuments">
                        <div class="row">
                            <div class="col-12 col-sm-12 mb-0 pb-0">
                                <label>{{document.documentType}} <span *ngIf="document.isRequired" class="label-hint">(Required)</span></label>
                            </div>
                            <div class="col-12 col-sm-12 mb-4">
                                <button class="btn-link" (click)="DownloadDocument(document)">
                                    {{document.documentName}}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card mt-4">
                <div class="card-header">
                    Payment Details
                </div>
                <div class="card-body">  
                    <div class="d-flex justify-content-between align-items-center">
                        <div>Application for {{reviewContext}}</div>
                        <div><h5>{{review.paymentRequest.paymentAmount | currency}}</h5></div>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="d-flex justify-content-between align-items-center">
                        <div><h5>Amount Total</h5></div>
                        <div><h5>{{review.paymentRequest.paymentAmount | currency}}</h5></div>
                    </div>
                </div>
            </div>

            <div class="card mt-4">
                <div class="card-header">
                    Payment Method
                </div>
                <div class="card-body">  
                    <div id="cardLogoDiv"></div>
                    <div>
                        ending in {{review.paymentRequest.cardLastFour}}
                    </div>
                </div>
            </div>

            <div class="mb-3">
                <div class="row">
                    <div class="col-12 col-sm-6">                
                        <button class="goa-button goa--secondary btn-block" [routerLink]="['../payment-method']">Return to Payment Method</button>
                    </div>
                    <div class="col-12 col-sm-6">                
                        <button class="goa-button btn-block" [disabled]="isPaymentFailed == true" (click)="submitRequest()">Pay & Submit</button>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</app-vehicle-seizure-page>

<!-- Registry Portal View -->
<app-vehicle-seizure-page #masterPage *ngIf="isRSGPortal" (onBeforeNavigate)="cancelRequest()">
    <ng-container content>
        <div class="d-flex flex-column">
            <div class="row">
                <div class="col-12 col-sm-11">        
                <h1>Application for {{reviewContext}} Summary</h1>
                </div>
                <div class="col-12 col-sm-1 d-flex align-self-end justify-content-end">
                    <div class="float-end">
                        <div class="print-icon not-for-print" (click)="printSummary()"></div>
                    </div>
                </div>
            </div>

            <div class="d-flex w-100 border-bottom mb-2">
                <h3>No. {{review.seizureNumber}}</h3>
                <div class="countdown not-for-print">
                    <countdown-timer #cd [key]="countdownKey" [lapseMessage]="lapseMessage"></countdown-timer>
                </div>
            </div>

            <div class="card goa-callout goa--emergency mt-3" *ngIf="isRequestAppliedAfterDeadline">
                <h6 class="mt-0">{{reviewContext}} Request Failed</h6>
                <p>
                    Request could not be submitted. Submission deadline has passed. <a [routerLink]="['../../../']">Return back to Seizure Details.</a>
                </p>
            </div>

            <div class="card goa-callout goa--emergency mt-3" *ngIf="isReviewRequestNotAvailable">
                <h6 class="mt-0">{{reviewContext}} Request Failed</h6>
                <p>
                    Request could not be submitted. <a [routerLink]="['../../../']">Return back to Seizure Details.</a>
                </p>
            </div>

            <div class="card goa-callout goa--emergency mt-3" *ngIf="isRequestFailed">
                <h6 class="mt-0">{{reviewContext}} Request Failed</h6>
                <p>
                    Your request can't be completed right now. Please try again later. 
                </p>
            </div>

            <div class="card goa-callout goa--emergency mt-3" *ngIf="isReviewTimeExpired">
                <b>
                    <p class="mt-0">
                        Reserved review time expired. Please <a [routerLink]="['../schedule-review']">select a new timeslot</a>.
                    </p>
                </b>
            </div>

            <div class="card border-0 not-for-print">
                <div class="goa-callout goa--information mt-2">
                    <h6 class="mt-0">Print Application for {{reviewContext}} Summary</h6>
                    <p>Print this page and have the client confirm the application details before submitting.</p>
                </div>
            </div>

            <div class="card border-0 mt-2" *ngIf="review.reviewTypeId == ReviewTypes.ThirdPartySeizureReview && vehicleSeizure.secondarySeizureNumber">
                <div class="goa-callout goa--important mt-1">
                    <h6 class="mt-0">Your vehicle was seized as a result of two seizure notices</h6>
                    <p>
                        This application for relief will address both seizure notices
                    </p>
                </div>
            </div>

            <div class="card mt-4">
                <div class="card-header">
                    Application Summary
                </div>
                <div class="card-body">  
                    <app-section-details sectionTitle="Applicant" [sectionDetails]="requesterInformation"></app-section-details>
                    <app-section-details *ngIf="!review.isSelfSubmit && (review.reviewTypeId !== ReviewTypes.ThirdPartySeizureReview)" sectionTitle="Representation" [sectionDetails]="representationInformation"></app-section-details>
                    <app-section-details *ngIf="review.reviewTypeId == ReviewTypes.ThirdPartySeizureReview"
                        sectionTitle="Registered Owner Information" [sectionDetails]="registeredOwnerInformation"></app-section-details>
                    <app-section-details *ngIf="review.reviewTypeId == ReviewTypes.SuspendedDriverVehicleSeizureReview"
                        sectionTitle="Driver Information" [sectionDetails]="recipientInformation"></app-section-details>
                    <app-section-details *ngIf="review.reviewTypeId == ReviewTypes.SuspendedDriverVehicleSeizureReview"
                        sectionTitle="Driver Licence Information" [sectionDetails]="recipientIdentificationInformation"></app-section-details>
                    <app-section-details sectionTitle="Vehicle Seizure Details" [sectionDetails]="vehicleSeizureDetails"></app-section-details>
                    <app-section-details sectionTitle="Vehicle Information" [sectionDetails]="vehicleInformation"></app-section-details>
                    <app-section-details sectionTitle="Review Details" [sectionDetails]="reviewsDetailsInformation"></app-section-details>            
                    <app-section-details sectionTitle="Preferred Contact Information" [sectionDetails]="preferredContactInformation"></app-section-details>
                    <app-section-details sectionTitle="Terms & Conditions" [sectionDetails]="termsConditionsInformation"></app-section-details>
                </div>
            </div>

            <div class="card mt-4">
                <div class="card-header">
                    Review Date
                </div>                
                <div *ngIf="review.reviewMethodTypeId==2" class="card-body">
                    <div>You have scheduled a <b>{{ reviewMethod }} Review</b> for 
                        <b>{{ review.reviewDate | date:'fullDate':'UTC' }}</b> at <b>{{ reviewTime }}.</b></div>
                    <div>A confirmation will be sent via the preferred contact method listed above.</div>
                </div>
                <div *ngIf="review.reviewMethodTypeId==1" class="card-body">
                    <div>You have scheduled a <b>{{ reviewMethod }} Review</b> for
                        <b>{{ review.reviewDate | date:'fullDate':'UTC' }}.</b></div>
                    <div>A confirmation will be sent via the preferred contact method listed above.</div>
                </div>               
            </div>

            <div class="card mt-4">
                <div class="card-header">
                    Supporting Documents
                </div>
                <div class="card-body">  
                    <div *ngFor="let document of supportingDocuments">
                        <div class="row">
                            <div class="col-12 col-sm-12 mb-0 pb-0">
                                <label>{{document.documentType}} <span *ngIf="document.isRequired" class="label-hint">(Required)</span></label>
                            </div>
                            <div class="col-12 col-sm-12 mb-4">
                                <button class="btn-link" (click)="DownloadDocument(document)">
                                    {{document.documentName}}
                                </button>
                            </div>
                        </div>
                    </div>
                    <p>Any supporting documents you wish to have considered as part of the review must be uploaded to the SafeRoads portal by 11:59 PM on the day before the scheduled review date. You can add or modify documents online at <a href="https://www.saferoads.com" target="_blank">www.saferoads.com</a> or return to an Alberta Registry Agent for assistance.</p>
                </div>
            </div>

            <div class="card mt-4">
                <div class="card-header">
                    Payment Details
                </div>
                <div class="card-body">  
                    <div class="d-flex justify-content-between align-items-center">
                        <div>Transaction ID {{review.paymentRequest.transactionId}}</div>
                        <div><h5>{{review.paymentRequest.paymentAmount | currency}}</h5></div>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="d-flex justify-content-between align-items-center">
                        <div><h5>Amount Total</h5></div>
                        <div><h5>{{review.paymentRequest.paymentAmount | currency}}</h5></div>
                    </div>
                </div>
            </div>

            <div class="checkbox mt-4 not-for-print">
                <input required type="checkbox" id="summaryPrinted" name="summaryPrinted" class="me-2"
                    #summaryPrinted="ngModel" [(ngModel)]="isSummaryPrinted" [ngClass]="{'is-invalid': isSubmitClicked && summaryPrinted.invalid }">
                <label class="required" for="summaryPrinted">
                    <b>A copy of this application summary was printed for the client to review</b>
                </label>        
                <div class="invalid-feedback">
                    <div>Please indicate that a copy of this application summary was printed for the driver to review</div>
                </div>
            </div>

            <div class="mb-3 not-for-print">
                <div class="row">
                    <div class="col-12 col-sm-6">                
                        <button class="goa-button goa--secondary btn-block" [routerLink]="['../payment-method']">Return to Payment</button>
                    </div>
                    <div class="col-12 col-sm-6">                
                        <button class="goa-button btn-block" (click)="submitRegistryRequest(!summaryPrinted.invalid)">Submit</button>
                    </div>
                </div>
            </div>

            <div class="card border-0">
                <div class="mb-3">
                    <div class="row">
                        <div class="col-12">
                            <p>Due to the potential for delayed or missed messages sent via email and SMS, it is your responsibility to check the status of your request. You can do this online in the SafeRoads portal or at an Alberta Registry Agent. Please contact SafeRoads Alberta at <a href="mailto:saferoads@gov.ab.ca">saferoads&#64;gov.ab.ca</a> if you have any questions.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</app-vehicle-seizure-page>

