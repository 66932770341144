<!-- Driver Portal View -->
<app-contravention-page #masterPage *ngIf="!isRSGPortal">
<ng-container content>
<form #requestForm="ngForm" [mustMatch]="['contactEmailAddress', 'confirmContactEmailAddress', 'contactPhoneNumber', 'confirmContactPhoneNumber']">
<div class="d-flex flex-column" *ngIf="contraventionOverview">
    <h1>Request Time to Pay</h1>    
    <h3 class="border-bottom pb-4 mb-2">Notice(s): {{paymentTimeRequest.contraventionList.join(', ')}}</h3>

    <div class="card goa-callout goa--emergency mt-3" *ngIf="isRequestFailed">
        <h6 class="mt-0">Error</h6>
        <p>
            An error occurred while processing your request. Please try again.
        </p>
    </div>

    <div class="card border-0">
        <p>
            By submitting this request to SafeRoads, you will be automatically granted a 90 day payment extension.
        </p>
    </div>

    <div class="card mt-3 mt-lg-4">
        <div class="card-header">
            Representation Type
        </div>
        <div class="card-body">
            <div class="mb-3 row">
                <div class="col-lg-12">
                    <label for="reqRepresentationType">Are you the Recipient of the Contravention Notice?</label>
                    <select class="form-select" id="reqRepresentationType" name="reqRepresentationType" 
                        [(ngModel)]="paymentTimeRequest.isSelfSubmit" (change)="onRepresentationTypeChange()">
                        <option [ngValue]="true">Yes, I am submitting this application on behalf of myself</option>
                        <option [ngValue]="false">No, I am submitting this application on behalf of another</option>
                    </select>
                </div>
            </div>
        </div>
    </div>

    <app-representation *ngIf="!paymentTimeRequest.isSelfSubmit" [model]="paymentTimeRequest.representation"
        [isSubmitClicked]="requestForm.submitted" [consentRequired]="false"
        [isYouth]="contraventionOverview.isRecipientYouth"></app-representation>

    <div class="mt-3 mb-3 card">
        <div class="card-header">
            Contravention Information
        </div>
        <div class="card-row border-bottom line-item-row">
            Issuance Date: {{contraventionOverview.issueDate | date:'longDate'}}
        </div>
        <div class="row line-item-row-heading">
            <div class="col-3">Contravention Number</div>
            <div class="col-9">Traffic Safety Act Section</div>
        </div>
        <div class="row line-item-row" *ngFor="let contraventionInformation of contraventions;">
            <div class="col-3">
                {{contraventionInformation.contraventionNumber}}
            </div>
            <div class="col-9">
                <div>
                    {{getChargeName(contraventionInformation.contraventionTypeId)}} 
                </div>
                <div *ngIf="contraventionInformation.secondaryContraventionTypeId">
                    {{getChargeName(contraventionInformation.secondaryContraventionTypeId)}} 
                </div>
                <div *ngIf="contraventionInformation.tertiaryContraventionTypeId">
                    {{getChargeName(contraventionInformation.tertiaryContraventionTypeId)}} 
                </div>
            </div>
        </div>
    </div>

    <div class="card border-0">
        <app-foip></app-foip>
    </div>

    <div class="mb-3">
        <div class="row">
            <div class="col-12 col-sm-6">                
                <button class="goa-button goa--secondary btn-block" [routerLink]="['../../']">Back to Contravention Overview</button>
            </div>
            <div class="col-12 col-sm-6">                
                <button class="goa-button btn-block" (click)="submitRequest(requestForm.valid)">Submit Request</button>
            </div>
        </div>
    </div>
</div>
</form>
</ng-container>
</app-contravention-page>

<!-- Registry Portal View -->
<app-contravention-page #masterPage *ngIf="isRSGPortal">
    <ng-container content>
        <form #requestForm="ngForm" [mustMatch]="['contactEmailAddress', 'confirmContactEmailAddress', 'contactPhoneNumber', 'confirmContactPhoneNumber']">
            <div class="d-flex flex-column" *ngIf="contraventionOverview">
                <h1>Request Time to Pay</h1>    
                <h3 class="border-bottom pb-4 mb-2">Notice(s): {{paymentTimeRequest.contraventionList.join(', ')}}</h3>
            
                <div class="card goa-callout goa--emergency mt-3" *ngIf="isRequestFailed">
                    <h6 class="mt-0">Error</h6>
                    <p>
                        An error occurred while processing your request. Please try again.
                    </p>
                </div>
            
                <div class="card border-0">
                    <p>
                        By submitting this request to SafeRoads, the driver will be automatically granted a 90-day payment extension.
                    </p>
                </div>
            
                <app-registry-agent-information [registryAgentInformation]="registryAgentInformation"></app-registry-agent-information>

                <app-registry-payer-information
                [registryAgentInformation]="registryAgentInformation"
                [isFormSubmitted]="requestForm.submitted"
                sectionHeader="Requester"
                sectionLabel="Who is requesting time to pay?">
                </app-registry-payer-information>

                <div class="mt-3 mb-3 card">
                    <div class="card-header">
                        Contravention Information
                    </div>
                    <div class="card-row border-bottom line-item-row">
                        Issuance Date: {{contraventionOverview.issueDate | date:'longDate'}}
                    </div>
                    <div class="row line-item-row-heading">
                        <div class="col-3">Contravention Number</div>
                        <div class="col-9">Traffic Safety Act Section</div>
                    </div>
                    <div class="row line-item-row" *ngFor="let contraventionInformation of contraventions;">
                        <div class="col-3">
                            {{contraventionInformation.contraventionNumber}}
                        </div>
                        <div class="col-9">
                            <div>
                                {{getChargeName(contraventionInformation.contraventionTypeId)}} 
                            </div>
                            <div *ngIf="contraventionInformation.secondaryContraventionTypeId">
                                {{getChargeName(contraventionInformation.secondaryContraventionTypeId)}} 
                            </div>
                            <div *ngIf="contraventionInformation.tertiaryContraventionTypeId">
                                {{getChargeName(contraventionInformation.tertiaryContraventionTypeId)}} 
                            </div>
                        </div>
                    </div>
                </div>
            
                <div class="mb-3">
                    <div class="row">
                        <div class="col-12 col-sm-6">                
                            <button class="goa-button goa--secondary btn-block" [routerLink]="['../../']">Back to Contravention Overview</button>
                        </div>
                        <div class="col-12 col-sm-6">                
                            <button class="goa-button btn-block" (click)="submitRequest(requestForm.valid)">Submit Request</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </ng-container>
</app-contravention-page>