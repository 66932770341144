import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ContraventionService } from '../../../shared/services/contravention.service';
import { ContraventionActivityHistory } from '@apis/shared/models/contravention-activity-history.model';
import { ActivityType } from '@apis/shared/enums/app.enum';
import * as fileSaver from 'file-saver';
import { DocumentService } from '@apis/shared/services/document.service';
import { Document } from '@apis/shared/models/document.model';
import { Activity } from '@apis/shared/models/activity.model';
import { CommonUtil } from '@apis/shared/helpers/common-util';
import { DriverBaseComponent } from '../../driver-base.component';
import { ContraventionOverview } from '@apis/shared/models/contravention-overview.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { Constants } from '@apis/shared/helpers/constants';

@Component({
  selector: 'app-contravention-activity-history',
  templateUrl: './contravention-activity-history.component.html',
  styleUrls: ['./contravention-activity-history.component.scss']
})
export class ContraventionActivityHistoryComponent extends DriverBaseComponent implements OnInit {
  contraventionNumber: string;
  notices: string;
  activityHistory: ContraventionActivityHistory;
  contraventionOverview: ContraventionOverview;

  ActivityType = ActivityType;

  constructor(
    router: Router,
    private activatedRoute: ActivatedRoute,
    private contraventionService: ContraventionService,
    private documentService: DocumentService,
    private readonly spinner: NgxSpinnerService,
    public localStorage: LocalStorageService,
    private datePipe: DatePipe) {
    super(router);
  }

  ngOnInit(): void {
    this.spinner.show();
    this.activatedRoute.paramMap.subscribe((paramMap) => {
      this.contraventionNumber = paramMap.get('contraventionNumber');

      this.contraventionOverview = this.localStorage.getItem<ContraventionOverview>(
        'ContraventionOverview'
      );

      this.contraventionOverview.contraventions.forEach(c => {
        if (this.notices) {
          this.notices += `, ${c.contraventionNumber}`;
        } else {
          this.notices = c.contraventionNumber;
        }
      });

      this.loadActivityHistory();
    });
  }

  loadActivityHistory() {
    this.spinner.show();

    this.contraventionService.getActivityHistory(this.contraventionNumber)
      .subscribe((contraventionActivityHistory: ContraventionActivityHistory) => {
        this.activityHistory = contraventionActivityHistory;

        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
      });
  }

  downloadActivityDocument(activity: Activity) {
    var storageFileName = `${activity.document.contentGuid}.${activity.document.documentExtension}`;
    var documentRefTypeName = this.getDocumenRefTypeNameByActivityType(+activity.activityType);
    var documentRefTypeNumber = activity.recordNumber;

    if (activity.document.version == Constants.DocumentVersion.VERSION3)
    {
      this.documentService.getDocumentSasUri("", documentRefTypeName, documentRefTypeNumber, storageFileName, activity.document.documentName)
      .subscribe((result: any) => {
        window.open(result.documentSasUri, "_blank");
      });

      return;
    }

    this.documentService
      .downloadDocument(
        '',
        documentRefTypeName,
        documentRefTypeNumber,
        storageFileName,
        activity.document.documentName
      )
      .subscribe((result: any) => {
        if (result) {
          fileSaver.saveAs(new Blob([result]), activity.document.documentName);
        }
      });
  }

  viewReviewFeePaymentReceipt(activity: Activity) {
    this.spinner.show();
    this.contraventionService.getReviewPaymentReceipt(this.contraventionNumber, activity.recordNumber)
    .subscribe((result: any) => {
      if (result)
      { 
        fileSaver.saveAs(new Blob([result]), `Contravention Review - ${activity.recordNumber} - Payment Receipt.pdf`);
      }
      this.spinner.hide();
    },
    (error) => {
      this.spinner.hide();
    });
  }

  viewRequestFeePaymentReceipt(activity: Activity) {
    this.spinner.show();
    this.contraventionService.getLateReviewRequestPaymentReceipt(this.contraventionNumber, activity.recordNumber)
    .subscribe((result: any) => {
      if (result)
      { 
        fileSaver.saveAs(new Blob([result]), `Contravention Late Review - ${activity.recordNumber} - Payment Receipt.pdf`);
      }
      this.spinner.hide();
    },
    (error) => {
      this.spinner.hide();
    });
  }

  getDocumenRefTypeNameByActivityType(activityType: number): string {
    if ([ActivityType.LateReviewDecisionUploaded, ActivityType.AdditionalTimeToPayDecisionUploaded].includes(activityType)){
      return "Requests";
    } else if ([ActivityType.ReviewDecisionUploaded].includes(activityType)){
      return "Reviews";
    } else if ([ActivityType.JudicialReviewDecisionIssued].includes(activityType)){
      return "JudicialReviews";
    }
  }
}
