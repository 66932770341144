<!-- Driver Portal View -->
<app-vehicle-seizure-page [suppressConfirmation]="true" *ngIf="review && !isRSGPortal">
    <ng-container content>
        <div class="d-flex flex-column">
            <h1>Supporting Documents</h1>
            <h3 class="border-bottom pb-4 mb-2">No. {{review.reviewNumber}}</h3>

            <div class="card border-0">
                <p>For more information on what supporting documents may be required or useful to support your review, access the 
                    <a class="extern" href="https://www.alberta.ca/vehicle-seizure-review#jumplinks-2" target="_blank">Supporting Documents Requirements</a>
                </p>

                <div class="goa-callout goa--information mt-1">
                    <h6 class="mt-0">Adding and Removing Supporting Documents</h6>
                    <p>
                        You are able to add or remove supporting documents up until 11:59 pm on the day before the scheduled review date.
                    </p>
                </div>
            </div>
            
            <div class="card mt-4">
                <div class="card-header">
                    Supporting Documents
                </div>
                <div class="card-body">  
                    <div *ngFor="let doc of documents; let i = index; trackBy: trackDocument">
                        <file-upload [ngClass]="{'d-none': doc == null}" [document]="doc" [fileUploadModel]="fileUploadModel" 
                            [isPrimaryDocument]="doc.forcePrimaryDocument"
                            (onCancelEvent)="onCancelDcoument($event)"
                            (onRemoveEvent)="onRemoveDcoument($event)"
                            [onDocumentBeforeUpload]="onDocumentBeforeUpload"
                            [onDocumentUploaded]="onDcoumentUploaded"
                            [onDocumentBeforeRemove]="onDocumentBeforeRemove">
                        </file-upload>
                    </div>
                    
                    <div class="mb-3 row">
                        <div class="col-lg-12">                    
                            <button class="goa-button goa--secondary float-end" (click)="AddOptionalDocument()">Add Additional Document</button>                    
                        </div>
                    </div> 
                </div>
            </div>
            <div *ngIf="errorMessage.length > 0" class="mt-2">
                <span class="error">{{errorMessage}}</span>
            </div>
            <div class="mb-3">
                <div class="row">
                    <div class="col-12 col-sm-6">
                        <button class="goa-button goa--secondary btn-block" [routerLink]="['../details']">Return to Review Details</button>                
                    </div>
                    <div class="col-12 col-sm-6 text-end">                
                        <button class="goa-button btn-block" [routerLink]="['../details']">Finish</button>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</app-vehicle-seizure-page>

<!-- Registry Portal View -->
<app-vehicle-seizure-page [suppressConfirmation]="true" *ngIf="review && isRSGPortal">
    <ng-container content>
        <div class="d-flex flex-column">
            <h1>Supporting Documents</h1>
            <h3 class="border-bottom pb-4 mb-2">No. {{review.reviewNumber}}</h3>

            <div class="card border-0">
                <div class="goa-callout goa--information mt-1" *ngIf="this.review.reviewTypeId == ReviewTypes.SuspendedDriverVehicleSeizureReview">
                    <h6 class="mt-0">Add Documents</h6>
                    <p>
                        Please inform the client that any supporting documents they wish to have considered as part of the review must be uploaded to the SafeRoads portal by 11:59 PM on the day before the scheduled review. 
                        They can by added or modified online at <a href="https://www.saferoads.com" target="_blank">www.saferoads.com</a> or by a registry agent.
                    </p>
                    <p>
                        Supporting documents may include:
                    </p>
                    <ul class="list ms-4">
                        <li>their written submission/argument (maximum 1 PDF, DOC or DOCX file; not to exceed 40 single-sided pages or 5MB)</li>
                        <li>photographs (up to 20 GIF, JPG, JPEG or PNG files; each file not to exceed 5 MB)</li>
                        <li>video and/or audio recordings (up to 4 AVI, MKV, MOV, MP3, MP4, MPG, WAV, WMA or WMV files; each file not to exceed 2 GB)</li>
                        <li>witness statement(s) (up to 10 PDF, DOC, DOCX, GIF, JPG, JPEG or PNG files; each file not to exceed 5 MB)</li>
                        <li>technical materials (up to 5 PDF, DOC or DOCX files; each file not to exceed 5 MB)</li>
                    </ul>
                </div>
                <div class="goa-callout goa--information mt-1" *ngIf="this.review.reviewTypeId == ReviewTypes.ThirdPartySeizureReview">
                    <h6 class="mt-0">Add Documents</h6>
                    <p>
                        Please inform the client that any supporting documents they wish to have considered as part of the review must be uploaded to the SafeRoads portal by 11:59 PM on the day before the scheduled review. 
                        They can by added or modified online at <a href="https://www.saferoads.com" target="_blank">www.saferoads.com</a> or by a registry agent.
                    </p>
                    <p>
                        Depending on the grounds for review, the client may be required to provide one or more of the following:
                    </p>
                    <ul>
                        <li>
                            Sworn affidavit or statement detailing:
                            <ul>
                                <li>The circumstances in which the driver was provided access to the vehicle;</li>
                                <li>The relationship between owner and driver including whether they reside in the same household and whether the vehicle was owned for the driver’s benefit;</li>
                                <li>Where the owner is a corporation, whether the driver had joint ownership or control of the corporation, holds a significant number of shares or otherwise has significant control; and documentary proof thereof;</li>
                                <li>Whether the owner verified that the driver had a valid and subsisting operator’s license before providing the driver with access to the vehicle.</li>
                            </ul>
                        </li>
                        <li>
                            Stolen vehicle – stolen vehicle report
                        </li>
                        <li>
                            Commercial carrier vehicle – driver abstract
                        </li>
                        <li>
                            Rental vehicle – rental agreement including terms and conditions
                        </li>
                        <li>
                            Proof that the driver is not a registered owner of the vehicle 
                        </li>                                            
                    </ul>
                </div>
            </div>
            
            <div class="card mt-4">
                <div class="card-header">
                    Supporting Documents
                </div>
                <div class="card-body">  
                    <p class="mt-0">If the client has any hard copy supporting documents to support the review, please scan and upload them.</p>
                    <div *ngFor="let doc of documents; let i = index; trackBy: trackDocument">
                        <file-upload [ngClass]="{'d-none': doc == null}" [document]="doc" [fileUploadModel]="fileUploadModel" 
                            [isPrimaryDocument]="doc.forcePrimaryDocument"
                            (onCancelEvent)="onCancelDcoument($event)"
                            (onRemoveEvent)="onRemoveDcoument($event)"
                            [onDocumentBeforeUpload]="onDocumentBeforeUpload"
                            [onDocumentUploaded]="onDcoumentUploaded"
                            [onDocumentBeforeRemove]="onDocumentBeforeRemove">
                        </file-upload>
                    </div>
                    
                    <div class="mb-3 row">
                        <div class="col-lg-12">                    
                            <button class="goa-button goa--secondary float-end" (click)="AddOptionalDocument()">Add Additional Document</button>                    
                        </div>
                    </div> 
                </div>
            </div>
            <div *ngIf="errorMessage.length > 0" class="mt-2">
                <span class="error">{{errorMessage}}</span>
            </div>
            <div class="mb-3">
                <div class="row">
                    <div class="col-12 col-sm-6">
                        <button class="goa-button goa--secondary btn-block" [routerLink]="['../details']">Return to Review Details</button>                
                    </div>
                    <div class="col-12 col-sm-6 text-end">                
                        <button class="goa-button btn-block" [routerLink]="['../details']">Submit Documents</button>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</app-vehicle-seizure-page>

