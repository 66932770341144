import { Component, OnInit, ViewChild } from '@angular/core';
import { Review } from '@apis/shared/models/review.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { NgForm, NgModel } from '@angular/forms';
import { BsDatepickerConfig, DatepickerDateCustomClasses } from 'ngx-bootstrap/datepicker';
import { DatePipe } from "@angular/common";
import { TimePipe } from "@apis/shared/pipes/time.pipe";
import { AvailabilityView } from '@apis/shared/models/availability-view';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { SeizureReviewDetailsBaseComponent } from '../seizure-review-details-base.component';
import { VehicleSeizureService } from 'apps/driver/src/app/shared/services/vehicle-seizure.service';
import { ReviewService } from 'apps/driver/src/app/shared/services/review.service';
import { AvailabilityInformation } from '@apis/shared/models/availability-information';
import { addHours, parseJSON } from 'date-fns';
import { DateUtil } from '@apis/shared/helpers/date-util';
import { MeetingTypes, ReviewMethodTypes, ReviewStatusTypes, ReviewTypes } from '@apis/shared/enums/app.enum';
import { SchedulerService } from 'apps/driver/src/app/shared/services/scheduler.service';
import { ScheduledEvent } from '@apis/shared/models/scheduled-event.model';
import { Observable } from 'rxjs';
import { Constants } from '@apis/shared/helpers/constants';
declare var $: any

@Component({
  selector: 'app-seizure-review-details-reschedule',
  templateUrl: './seizure-review-details-reschedule.component.html',
  styleUrls: ['./seizure-review-details-reschedule.component.scss']
})
export class SeizureReviewDetailsRescheduleComponent extends SeizureReviewDetailsBaseComponent implements OnInit {
  formSubmitted: boolean = false;
  datePickerConfig: Partial<BsDatepickerConfig>;
  dateCustomClasses: DatepickerDateCustomClasses[];
  datePipe: DatePipe = new DatePipe("en-US");
  timePipe: TimePipe = new TimePipe();  
  availabilityView: AvailabilityView;
  isRequestFailed: boolean;
  requestFailedMessage: string = null;
  availabilityViewForWritten:AvailabilityInformation[];

  newReviewDateUtc: Date;
  selAvailableInfo: AvailabilityInformation;  
  isAvailabilityRequestFailed: boolean;
  documentSubmissionCutoffDate: Date;
  DateUtil = DateUtil;
  isSubmitDisabled = true;

  ReviewStatusTypes = ReviewStatusTypes;
  ReviewTypes = ReviewTypes;
  Constants = Constants;

  @ViewChild("rescheduleReviewForm") rescheduleReviewForm: NgForm;
  @ViewChild("reviewDate") reviewDate: NgModel;
  @ViewChild("reviewTime") reviewTime: NgModel;

  constructor(public router: Router,
    public activatedRoute: ActivatedRoute,
    public localStorageService: LocalStorageService,
    private vehicleSeizureService: VehicleSeizureService,
    public reviewService: ReviewService,
    private readonly spinner: NgxSpinnerService,
    private readonly schedulerService: SchedulerService
  ) {
    super(router, activatedRoute, localStorageService, reviewService);
    this.datePickerConfig = Object.assign({},
      {
        containerClass: 'theme-dark-blue',
        showWeekNumbers: false,
        dateInputFormat: 'MMM DD, YYYY',
        isAnimated: true
      });

    this.dateCustomClasses = [
      { date: new Date(), classes: ["bg-secondary", "text-white"] }
    ];
    //this.setDocumentSubmissionCutoffDate();
  }

  ngOnInit(): void {
    super.onInit();
  }

  onInitComplete(): void {
    if (this.review.reviewDate) {
      this.newReviewDateUtc = parseJSON(this.review.reviewDate);
    }

    this.getReviewSchedule();
  }

  onScheduleRefreshClick(): void {
    this.getReviewSchedule();
  }
  
  onDateSelected(availableInfo:AvailabilityInformation)
  {   
    this.isSubmitDisabled = true;   
    this.selAvailableInfo = availableInfo;

    this.review.reviewTime = availableInfo.timeSlots[0];
    this.newReviewDateUtc = parseJSON(availableInfo.date);

    $('#confirmDateModal').modal();
  }

  selectReviewDate()
  {
    this.isSubmitDisabled = false;
    this.setDocumentSubmissionCutoffDate();
  }

  setDocumentSubmissionCutoffDate() {
    this.documentSubmissionCutoffDate = null;

    if (this.newReviewDateUtc) {
      const newReviewDateUtc = new Date(this.newReviewDateUtc);
      this.documentSubmissionCutoffDate = addHours(newReviewDateUtc, -24); // 1 day before review. Do not use addDays, because it will subtract 23 or 25 hours on a daylight savings change.
    }
  }

  onRescheduleClick(ev: any): void {
    if (this.rescheduleReviewForm.invalid) {
      return;
    }

    this.formSubmitted = true;

    const currentTermsConditionsVersion = this.localStorageService.getTermsAndConditionsList().find(t => t.expiryDate == null);

    const review = new Review({
      reviewId: +this.review.reviewId,
      reviewNumber: this.review.reviewNumber,
      reviewDate: this.newReviewDateUtc,
      reviewTime: this.review.reviewTime,
      reviewTypeId: this.review.reviewTypeId,
      reviewMethodTypeId: +this.review.reviewMethodTypeId,
      scheduledEventId: +this.review.scheduledEventId,
      termsConditionsVersion: currentTermsConditionsVersion.version
    });    

    this.spinner.show();
    
    // Reserve time slot
    this.reserveEvent().subscribe((result: ScheduledEvent) => {
      if (result.scheduledEventId != 0) {
        review.scheduledEventId = result.scheduledEventId;
        review.scheduledEvent = Object.assign({}, result);
      }

      if (this.review.reviewStatusTypeId == ReviewStatusTypes.New) {
        this.reviewService.scheduleReReview(review)
        .subscribe(
          (result: Review) => {
            this.review = result;

            this.spinner.hide();        
            this.router.navigate(['../details'], {
              relativeTo: this.activatedRoute,
            });
          },
          (error: any) => {
            this.formSubmitted = false;
            this.isRequestFailed = true;
            this.spinner.hide();
            window.scroll(0, 0);
          });
      } else {
        this.reviewService.reScheduleReview(review)
          .subscribe(
            (result: Review) => {
              this.review = result;          

              this.spinner.hide();

              this.router.navigate(['../reschedule-complete'], {
                relativeTo: this.activatedRoute,
              });
            },
            (error: any) => {
              this.requestFailedMessage = null;
              if (error.status === 400) {
                this.requestFailedMessage = error.error;
              }

              this.formSubmitted = false;
              this.isRequestFailed = true;
              this.spinner.hide();
              window.scroll(0, 0);
            });
      }
    });
  }

  reserveEvent() : Observable<ScheduledEvent> {
    var meetingTypeId = MeetingTypes.SeizureReview;

    var event = new ScheduledEvent({
      eventDate: this.datePipe.transform(this.newReviewDateUtc, 'M/d/yyyy', 'UTC'),
      eventTime: this.review.reviewTime,
      duration: 30,
      meetingTypeId: meetingTypeId,
      title: this.getEventTitle(),
      attendees: this.getAttendee(),
      recordNumber: this.review.seizureNumber,
      complexity: this.review.complexity,
      scheduledEventId: this.review.scheduledEventId != null ? +this.review.scheduledEventId : 0,
      specificUserId: null
    });

    return this.schedulerService.reserveEvent(event);    
  }

  getAttendee() {
    if (this.review.reviewTypeId == ReviewTypes.ThirdPartySeizureReview) {
      return `${this.review.reviewApplicant.lastName} ${this.review.reviewApplicant.firstName} ${this.review.reviewApplicant.otherName??''}`;
    } else {
      return `${this.vehicleSeizure.recipient.lastName} ${this.vehicleSeizure.recipient.firstName} ${this.vehicleSeizure.recipient.otherName??''}`;
    }
  }

  getEventTitle() {
    let recipientLastName = this.vehicleSeizure.recipient.lastName;
    if (this.review.reviewTypeId == ReviewTypes.ThirdPartySeizureReview) {
      recipientLastName = this.review.reviewApplicant.lastName;
    }

    return `${this.vehicleSeizure.seizureNumber} ${recipientLastName}`;
  }  

  private getReviewSchedule() {
    this.spinner.show();
    this.isAvailabilityRequestFailed = false;

    this.vehicleSeizureService.getReviewAvailability(
      this.vehicleSeizure.seizureNumber,
      this.review.reviewTypeId,
      this.review.reviewMethodTypeId,
      this.review.scheduledEventId,
      true)
      .subscribe((result: AvailabilityView) => {
        this.availabilityView = result;
        this.availabilityViewForWritten = this.availabilityView?.availabilityInformationList?.filter(x=>x.timeSlots.length>0);
        if (this.newReviewDateUtc) {
          this.selAvailableInfo = this.availabilityViewForWritten.find(x => this.datePipe.transform(new Date(x.date),'dd MMM, yyyy') == this.datePipe.transform(new Date(this.newReviewDateUtc),'dd MMM, yyyy', 'UTC'));
        }
        this.spinner.hide();
      }, (error: any) => {         
        this.spinner.hide();
        this.isAvailabilityRequestFailed = true;
      });
  }

}
