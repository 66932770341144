<form #lookupForm="ngForm">
<div class="d-flex flex-column">
    <h1>Find a Vehicle Seizure</h1>
    <div class="card border-0 mt-3">
        <div class="goa-callout goa--information mt-0 mb-3">
            <p class="mt-0">
                <b>Important:</b> Not all browsers work the same, so there may be some variation in user experience depending on what browser and device is used. Clear your browser’s cache and history regularly or update the browser’s setting to clear the cache automatically when the browser is closed (if that setting is available).
            </p>
        </div>
        
        <h3 class="mt-0">Provide your details as they appear on your seizure notice</h3>
        
        <div class="goa-callout goa--emergency mt-0 mb-3" *ngIf="isAccessForbidden">
            <h6 class="mt-0">Error</h6>
            <p>
                Error verifying reCAPTCHA, please try again.
            </p>
        </div>

        <div class="goa-callout goa--emergency mt-0 mb-3" *ngIf="isServerErr">
            <h6 class="mt-0">Error</h6>
            <p>
                {{errorMessage}}
            </p>
        </div>

        <div class="goa-callout goa--emergency mt-0 mb-3" *ngIf="isSeziureNotAvailable">
            <h6 class="mt-0">Seizure Notice Not Yet Available</h6>
            <p>
                The details of this Seizure Notice have not yet been added to the system. 
                It will be available within 48 hours. For help, contact 
                <a href="#contactModal" data-bs-toggle="modal" data-bs-target="#contactModal">SafeRoads Alberta.</a>
            </p>
        </div>

        <div class="goa-callout goa--emergency mt-0 mb-3" *ngIf="isSeizureNotFound">
            <h6 class="mt-0">Seizure Notice Not Found</h6>
            <p>
                The details provided did not find a match. 
                For help, contact                 
                <a href="#contactModal" data-bs-toggle="modal" data-bs-target="#contactModal">SafeRoads Alberta.</a>
            </p>
        </div>
    </div>  
    <div class="card border-0">
        <div class="mb-3 row">
            <div class="col-12 col-sm-12">
                <label for="seizureNumber" class="required mb-0">Vehicle Seizure Number</label>
                <div class="pb-2">Input full 17-digit seizure number</div>
                <input type="text" required class="form-control" id="seizureNumber" name="seizureNumber"
                    #seizureNumber="ngModel" minlength="17" maxlength="17"
                    [(ngModel)]="searchCriteria.seizureNumber"
                    [ngClass]="{ 'is-invalid': lookupForm.submitted && seizureNumber.invalid }">
                <div class="invalid-feedback">
                    <div *ngIf="seizureNumber.errors?.required">Vehicle Seizure Number is required</div>
                    <div *ngIf="seizureNumber.errors?.invalidSeizureNumber && !seizureNumber.errors?.required">Invalid Vehicle Seizure Number</div>
                </div>
            </div>
        </div>
        <div class="mb-3 row">
            <div class="col-12 col-sm-12">
                <label for="seizureDate" class="required">Vehicle Seizure Date</label>
                <input type="text" required class="form-control" id="seizureDate" name="seizureDate" placeholder="YYYY-MM-DD"
                    autocomplete="off"
                    isLessThanStartDate="2020-12-01" bsDatepicker [bsConfig]="seizureDatePickerConfig"
                    [(ngModel)]="vehicleSeizureDate" #seizureDate="ngModel" 
                    [ngClass]="{ 'is-invalid': lookupForm.submitted && seizureDate.invalid }">
                <div class="invalid-feedback">
                    <div *ngIf="seizureDate.hasError('required')">Vehicle Seizure Date is required</div>
                    <div *ngIf="!seizureDate.hasError('required') && seizureDate.hasError('EarlierDate')">Vehicle Seizure Date can't be earlier than 01 Dec, 2020</div>
                    <div *ngIf="!seizureDate.hasError('required') && !seizureDate.hasError('EarlierDate')">Invalid Vehicle Seizure Date</div>
                </div>
            </div>
        </div>        
        <div class="mb-3 row">
            <div class="col-12 col-sm-12">
                <label for="seizureTime" class="required">Vehicle Seizure Time</label>
                <input required #seizureTime="ngModel" type="text" [patterns]="Constants.Mask.TIME_MASK_PATTERNS" mask="{{Constants.Mask.TIME_MASK}}" [showMaskTyped]="true" [dropSpecialCharacters]="false"
                    pattern="^(([[0|1]\d)|(2[0-3]))[:]([0-5]\d)$" class="form-control" id="seizureTime" name="seizureTime" placeholder="--:--"
                    [(ngModel)]="searchCriteria.seizureTime" 
                    [ngClass]="{'is-invalid': lookupForm.submitted && seizureTime.invalid}">
                <div class="invalid-feedback">
                    <div *ngIf="seizureTime.errors?.required">Vehicle Seizure Time is required</div>
                    <div *ngIf="!seizureTime.errors?.required">Invalid Vehicle Seizure Time</div>
                </div>
            </div>
        </div>

        <div class="row mb-3">
            <div class="col-12 col-sm-12">  
                <button class="btn-block goa-button mt-2"
                (click)="onSearchClick(lookupForm.valid)">
                    Search
                </button>
            </div>
        </div>
    </div>

    <div class="mt-2">
        <a href="#contactModal" data-bs-toggle="modal" data-bs-target="#contactModal">Need Help Accessing Your Vehicle Seizure?</a>
    </div>

    <!-- Modal -->
    <div class="modal" id="contactModal" tabindex="-1" role="dialog" aria-labelledby="contactModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content p-4">
                <div class="modal-header border-0 pt-2">
                    <span class="modal-title" id="contactModalLabel">
                        Need Help Accessing Your Vehicle Seizure?
                    </span>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                        <i class="far fa-times-circle fa-lg"></i>
                    </button>
                </div>
                <div class="modal-body py-0">
                    <p>For support accessing the details of your Seizure Notice or help scheduling a review, uploading supporting
                        documents, or any technical issues experienced with the SafeRoads portal, please contact:</p>
                    <p>
                        <b>SafeRoads Alberta</b>
                        <br>Phone: <a href="tel:780-427-7233">780-427-7233</a>
                        <br>Toll free: <a href="tel:310-0000">310-0000</a> before the number (in Alberta)
                        <br>Email: <a href="mailto:saferoads@gov.ab.ca">saferoads&#64;gov.ab.ca</a>
                    </p>
                    <p>SafeRoads Alberta is open from 8:15 a.m. to 12:00 p.m. and 1:00 p.m. to 4:30 p.m., Monday through Friday.
                        We are closed weekends and on Alberta Public Service paid holidays.</p>
                    <p>Be advised that you will be required to provide personal identifying information for verification purposes
                        and to assist with your enquiry.</p>
                </div>
                <div class="modal-footer border-0">
                    <button type="button" class="btn-modal goa-button mt-2" data-bs-dismiss="modal">Okay</button>
                </div>
            </div>
        </div>
    </div>
</div>
</form>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-fussion" [fullScreen]="true"><p style="color: white" > Searching... </p></ngx-spinner>
