<div class="d-flex flex-column" *ngIf="paymentTransaction">
    <h1>Payment Successful</h1>
    <div class="header-container">
        <div>
            <h3 class="mb-0">Transaction ID #{{paymentTransaction.financialTransactionId | transactionid}}</h3>
        </div>
        <div>
            <button class="btn-block goa-button btn-print" (click)="onPrintReceiptClick()">Download Receipt</button>   
        </div>                 
    </div>

    <div class="pb-2 border-bottom">
    <p class="card border-0">
        A receipt of this transaction has been sent to {{paymentTransaction.recipientEmail}}.
        Print or save a copy of this transaction for your records. 
        If you can not find the email in your inbox, check your spam or junk folder.
    </p>
    </div>

    <div class="card mt-4">
        <div class="card-header">
            <div><h5>Payment Receipt</h5></div>            
        </div>
        <div class="card-body">  
            <div class="d-flex justify-content-between align-items-center">
                <div>
                    Payment Amount
                </div>
                <div><h5>{{paymentTransaction.amount | currency}}</h5></div>
            </div>
            <div class="d-flex flex-column mt-3">
                <span>
                    Applied to
                </span>
                <div class="mt-2" *ngFor="let li of paymentTransaction.financialTransaction.transactionLineItems">
                    <a [routerLink]="['../../']">Contravention No. {{li.recordNumber}}</a>
                </div>
            </div>
            <div class="d-flex flex-column mt-3">
                <div>
                    Payment Method: {{paymentTransaction.cardType | cardtype}} **** {{paymentTransaction.cardLastFourDigits}}
                </div>
            </div>
        </div>
        <div class="card-footer">
            <div class="d-flex justify-content-between align-items-center">
                <div><h5>Total Paid</h5></div>
                <div><h5>{{paymentTransaction.amount | currency}}</h5></div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12 col-sm-12">                
            <button class="goa-button goa--primary float-end me-0" [routerLink]="['../../']">Return to Contravention Overview</button>
        </div>
    </div>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-fussion" [fullScreen]="true"><p style="color: white" > Loading... </p></ngx-spinner>      
