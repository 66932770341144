<p>
  The personal information on this form is collected under the authority of the
  <i>Traffic Safety Act</i> and Section 33 of the
  <i>Freedom of Information and Protection of Privacy (FOIP) Act</i> and will be
  used for the administration of programs under the <i>Traffic Safety Act</i>.
  Inquiries may be directed to SafeRoads Alberta, Alberta Justice, Third Floor,
  Twin Atria Building, 4999 - 98 Avenue NW, Edmonton, Alberta T6B 2X3,
  at telephone number
  <a href="tel:780-427-7233">780-427-7233</a> (Edmonton and area) and toll free
  <a href="tel:310-0000">310-0000</a> (in Alberta).
</p>
