<!-- Driver Portal View -->
<form #requestForm="ngForm" *ngIf="!isRSGPortal">
    <div class="d-flex flex-column">
        <h1> {{ seizureType == SeizureTpes.IRSContraventionSelected ?  "Immediate Roadside Sanction (IRS) Seizure" : "Suspended Driver Program Seizure" }}</h1>
        <h3 class="border-bottom pb-4 mb-2">No. {{seizureNumber}}</h3>

        <div class="card mt-4">
            <div class="card-header">
                Are you the driver who received the {{ seizureType == SeizureTpes.IRSContraventionSelected ? "IRS" : "" }} Seizure Notice?
            </div>
            <div class="card-body">
                <div class="form-check custom-control-inline">
                    <input type="radio" class="form-check-input" id="driver" name="UserType" required
                        #UserType="ngModel" [value]="true" [(ngModel)]="isDriver">
                    <label class="form-check-label radio-label" for="driver">
                        Yes, I am the driver, or I am representing the driver served with the 
                        {{ seizureType == SeizureTpes.IRSContraventionSelected ? "IRS" : "Suspended Driver Program" }} 
                        Seizure Notice
                    </label>
                </div>
                <div class="mb-3 row ms-2" *ngIf="isDriver">
                    <div class="col-12 col-sm-12">
                        <label for="dBirthDate" class="required" style="display: block;">Please provide driver's date of birth:</label>
                        <input type="text" required class="date-ctl" id="dBirthDate" name="dBirthDate"
                            placeholder="YYYY-MM-DD" bsDatepicker [bsConfig]="datePickerConfig" autocomplete="off"
                            [(ngModel)]="driverBirthDate" #dBirthDate="ngModel"
                            [ngClass]="{ 'is-invalid': requestForm.submitted && dBirthDate.invalid }">                        
                        <div class="invalid-feedback">
                            <div *ngIf="dBirthDate.errors?.required">Date of birth is required</div>
                        </div>
                        <div class="text-danger">
                            <div *ngIf="invalidMatch && !dBirthDate.errors?.required">The date entered does not match
                                the driver's date of birth</div>
                        </div>                        
                    </div>
                </div>

                <div class="form-check custom-control-inline mt-2">
                    <input type="radio" class="form-check-input" id="notDriver" name="UserType" required
                        #UserType="ngModel" [value]="false" [(ngModel)]="isDriver">
                    <label class="form-check-label radio-label" for="notDriver">
                        No, I am not the driver or representing the driver
                    </label>
                </div>                
                <div class="text-danger mt-2" *ngIf="requestForm.submitted && userType == null">
                    <div>Please indicate whether you are a driver.</div>
                </div>
                <div *ngIf="isDriver == false" class="mt-2">
                    <label>Are you the <a><span class="label" data-bs-toggle="modal" data-bs-target="#registeredOwnerLabel">registered owner</span></a> of the seized vehicle?</label> 
                    <div class="modal" id="registeredOwnerLabel" tabindex="-1" role="dialog" aria-labelledby="registeredOwnerLabel" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                            <div class="modal-content p-4">
                                <div class="modal-header border-0 pt-2">
                                    <span class="modal-title h4" id="registeredOwnerLabel">
                                        Registered Owner
                                    </span>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                        <i class="far fa-times-circle fa-lg"></i>
                                    </button>
                                </div>
                                <div class="modal-body py-0">
                                    <img src="assets/images/vehicle-registration.jpg" class="img-fluid" />
                                </div>
                                <div class="modal-footer border-0">
                                    <button type="button" class="btn-modal goa-button mt-2" data-bs-dismiss="modal">Okay</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-check custom-control-inline mt-2">
                        <input type="radio" class="form-check-input" id="registeredOwner" name="UserType2" required
                            #UserType="ngModel" [value]="true" [(ngModel)]="isRegisteredOwner">
                        <label class="form-check-label radio-label" for="registeredOwner">
                            Yes, I am the registered owner, or I am representing the registered owner of the seized vehicle
                        </label>
                    </div>      
                    <div class="form-check custom-control-inline mt-2">
                        <input type="radio" class="form-check-input" id="notRegisteredOwner" name="UserType2" required
                            #UserType="ngModel" [value]="false" [(ngModel)]="isRegisteredOwner">
                        <label class="form-check-label radio-label" for="notRegisteredOwner">
                            No, I am not the registered owner or representing the registered owner
                        </label>
                    </div>      
                    <label *ngIf="isRegisteredOwner == false" class="ms-4">You are not permitted to access this Seizure Notice.</label>
                </div>
            </div>
        </div>
    </div>
    <div class="mb-3">
        <div class="row">
            <div class="col-12 col-sm-6">                
                <button class="goa-button goa--secondary btn-block" [routerLink]="['/vehicle-seizures/search']">Back to Vehicle Seizure Search</button>
            </div>
            <div class="col-12 col-sm-6 text-end" *ngIf="isDriver || isRegisteredOwner">                
                <button class="goa-button btn-block" (click)="continueRequest(requestForm.valid)">Proceed to Vehicle Seizure Details</button>
            </div>
        </div>
    </div>

</form>

<!-- Registry Portal View -->
<form #requestForm="ngForm" *ngIf="isRSGPortal">
    <div class="d-flex flex-column">
        <h1> {{ seizureType == SeizureTpes.IRSContraventionSelected ?  "Immediate Roadside Sanction Seizure" : "Suspended Driver Program Seizure" }}</h1>
        <h3 class="border-bottom pb-4 mb-2">No. {{seizureNumber}}</h3>

        <div class="card goa-callout goa--info">
            <h6 class="mt-0 boldText" *ngIf="seizureType != SeizureTpes.IRSContraventionSelected">Suspended Driver Vehicle Seizure Program Vehicle Seizure Review</h6>
            <p *ngIf="seizureType != SeizureTpes.IRSContraventionSelected">
                This type of Vehicle Seizure Review is only available to the driver involved in the contravention
                and is not the registered owner of the seized vehicle. The driver (recipient) must pay the non-
                refundable $150 fee for this application.
            </p>
            <h6 class="mt-0 boldText">Affected Person Vehicle Seizure Review</h6>
            <p>
                This type of Vehicle Seizure Review is only available to the registered owner of the seized vehicle
                if they were <u>not</u> the driver involved in the contravention.
            </p>
            <p>
                If the client would like more information, please refer them to the <a
                    href="https://kings-printer.alberta.ca/documents/Regs/2006_251.pdf" target="_blank">Vehicle Seizure and Removal Regulation</a>.
            </p>
        </div>

        <div class="card mt-4">
            <div class="card-header">
                Is the client the driver who received the {{ seizureType == SeizureTpes.IRSContraventionSelected ? "IRS" : "" }} Seizure Notice?
            </div>
            <div class="card-body">
                <div class="form-check custom-control-inline">
                    <input type="radio" class="form-check-input" id="driver" name="UserType" required
                        #UserType="ngModel" [value]="true" [(ngModel)]="isDriver">
                    <label class="form-check-label radio-label" for="driver">
                        Yes, the client is <b>the driver</b>, or is representing <b>the driver</b> served with the 
                        {{ seizureType == SeizureTpes.IRSContraventionSelected ? "IRS" : "" }} 
                        seizure notice.
                    </label>
                </div>
                <div class="form-check custom-control-inline mt-2">
                    <input type="radio" class="form-check-input" id="notDriver" name="UserType" required
                        #UserType="ngModel" [value]="false" [(ngModel)]="isDriver">
                    <label class="form-check-label radio-label" for="notDriver">
                        No, the client is not the driver or representing the driver
                    </label>
                </div>
                <div class="text-danger mt-2" *ngIf="requestForm.submitted && userType == null">
                    <div>Please indicate whether the client is driver or registered owner or representative.</div>
                </div>

                <div *ngIf="isDriver == false" class="mt-2">
                    <label>Is the client the registered owner of the seized vehicle?</label>
                    <div class="form-check custom-control-inline mt-2">
                        <input type="radio" class="form-check-input" id="registeredOwner" name="UserType2" required
                            #UserType="ngModel" [value]="true" [(ngModel)]="isRegisteredOwner">
                        <label class="form-check-label radio-label" for="registeredOwner">
                            Yes, the client is the <b>registered owner</b>, or is representing the <b>registered owner</b> of
                            the seized vehicle.
                        </label>
                    </div>    
                    
                    <div class="form-check custom-control-inline mt-2">
                        <input type="radio" class="form-check-input" id="notRegisteredOwner" name="UserType2" required
                            #UserType="ngModel" [value]="false" [(ngModel)]="isRegisteredOwner">
                        <label class="form-check-label radio-label" for="notRegisteredOwner">
                            No, the client is not the driver or representing the driver
                        </label>
                    </div>      
                    <label *ngIf="isRegisteredOwner == false" class="ms-4">You are not permitted to access this Seizure Notice.</label>
                </div>
            </div>
        </div>
    </div>
    <div class="mb-3">
        <div class="row">
            <div class="col-12 col-sm-6">                
                <button class="goa-button goa--secondary btn-block" [routerLink]="['../../../vehicle-seizures/search']">Back to Vehicle Seizure Search</button>
            </div>
            <div class="col-12 col-sm-6 text-end">                
                <button class="goa-button btn-block" *ngIf="isDriver || isRegisteredOwner" (click)="continueRequest(requestForm.valid)">Proceed to Vehicle Seizure Details</button>
            </div>
        </div>
    </div>
</form>