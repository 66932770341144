<div class="modal" id="safeRoadsContactModal" tabindex="-1" role="dialog" aria-labelledby="safeRoadsContactModal" aria-hidden="true" data-bs-backdrop='static'>
    <div class="modal-dialog modal-dialog-centered modal-md" role="document">
        <div class="modal-content p-4">
            <div class="modal-header border-0 pt-2">
                <span class="modal-title h4" id="safeRoadsContactModalLabel">
                    Contact SafeRoads Alberta
                </span>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                    <i class="far fa-times-circle fa-lg"></i>
                </button>
            </div>
            <div class="modal-body py-0">
                <p>Phone <b>780-427-SAFE (7233)</b></p>
                <p>Toll-free in Alberta dial <b>310-0000</b> followed by the number</p>
                <p>Email <b>saferoads&#64;gov.ab.ca</b></p>
                <p>They will respond within 2 business days.</p>
            </div>
        </div>
    </div>
</div>