<!-- Driver Portal View -->
<app-vehicle-seizure-page *ngIf="review && !isRSGPortal">
    <ng-container content>
        <form *ngIf="review" #rescheduleReviewForm="ngForm">
            <div class="d-flex flex-column">
                <h1>{{ review.reviewStatusTypeId == ReviewStatusTypes.New ? "Schedule Review" : "Edit / Reschedule Review" }}</h1>
                <h3 class="border-bottom pb-4 mb-2">No. {{review.reviewNumber}}</h3>
                <div class="card goa-callout goa--emergency mt-3" *ngIf="isRequestFailed">
                    <h6 class="mt-0">Reschedule Request Failed</h6>
                    <p *ngIf="requestFailedMessage; else noMessageSpecified">
                        {{requestFailedMessage}}
                    </p>
                    <ng-template #noMessageSpecified>
                        <p>
                            Your request can't be completed right now. Please try again later.
                        </p>
                    </ng-template>
                </div>
                <div class="card mt-3 border-0">                                                          
                        <div>
                            <p>
                                What day would you like your matter to be reviewed? Please note: you
                                <b><u>do not</u></b> attend a written review.
                            </p>
                            <p *ngIf="availabilityViewForWritten?.length > 0">
                                If you require assistance with your review request, please contact SafeRoads Alberta at <a href="tel:780-427-7233">780-427-SAFE (7233)</a> or by email to <a href="mailto:saferoads@gov.ab.ca">saferoads&#64;gov.ab.ca</a>.
                            </p>
                            <div *ngIf="availabilityView">
                                <h3 class="display">{{availabilityView.startDate | date:'MMM dd, yyyy'}} - {{availabilityView.endDate |
                                    date:'MMM dd, yyyy'}}</h3>
                                <button (click)="onScheduleRefreshClick()" class="goa-button goa--secondary m-0 mt-3 ms-auto refreshBtn">
                                    Refresh
                                </button>
                            </div>
                        </div>                    
    
                        <div *ngIf="availabilityViewForWritten!=null" class="card border-0">
                            <div *ngIf="availabilityViewForWritten.length == 0" class="h6">
                                There are no available review dates. Please contact SafeRoads Alberta at <a href="tel:780-427-7233">780-427-SAFE (7233)</a> or by email to <a href="mailto:saferoads@gov.ab.ca">saferoads&#64;gov.ab.ca</a> for assistance with your review request.
                            </div>
                            <ul *ngIf="availabilityViewForWritten.length > 0">
                                <li class="cal-dateslot"
                                    [ngClass]="{ 'cal-dateslot-selected': availableInfo === selAvailableInfo }"
                                    *ngFor="let availableInfo of availabilityViewForWritten"
                                    (click)="onDateSelected(availableInfo)">
                                    <span>{{ availableInfo.date | date:'longDate' }}</span>
                                    <br>
                                    <b>{{ availableInfo.date | date:'EEEE' }}</b>
                                </li>
                            </ul>
                        </div>
    
                        <div *ngIf="isAvailabilityRequestFailed" class="card border-0 text-danger">
                            <p>
                                An error occurred. Please contact SafeRoads Alberta at <a href="tel:780-427-7233">780-427-SAFE (7233)</a> or by email to <a href="mailto:saferoads@gov.ab.ca">saferoads&#64;gov.ab.ca</a> for assistance with your review request.
                            </p>
                        </div>
                        <div *ngIf="documentSubmissionCutoffDate">
                            <p>
                                By selecting this day, you must submit all your supporting documents by
                                <b>11:59pm</b> on <b>{{ documentSubmissionCutoffDate | date:'fullDate':'UTC'}}.</b>
                            </p>
                        </div>                    
                </div>

                <div class="mt-2" *ngIf="review.reviewStatusTypeId == ReviewStatusTypes.New">
                    <app-terms-conditions [isSubmitClicked]="rescheduleReviewForm.submitted"></app-terms-conditions>
                </div>

                <div class="mb-3 pt-2">
                    <div class="row pt-3">
                        <div class="col-12 col-sm-6" *ngIf="review.reviewStatusTypeId == ReviewStatusTypes.New; else NotNewReview">
                            <button class="goa-button goa--secondary btn-block" [routerLink]="['../../..']">
                                Return to Seizure Details
                            </button>
                        </div>
                        <ng-template #NotNewReview>
                            <div class="col-12 col-sm-6">
                                <button class="goa-button goa--secondary btn-block" [routerLink]="['../details']">
                                    Return to Review
                                </button>
                            </div>
                        </ng-template>
                        <div class="col-12 col-sm-6 text-end">
                            <button class="goa-button btn-block"
                                [disabled]="(rescheduleReviewForm.invalid && formSubmitted) || isSubmitDisabled"
                                (click)="onRescheduleClick($event)">
                                Save Changes
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </ng-container>
</app-vehicle-seizure-page>

<!-- Registry Portal View -->
<app-vehicle-seizure-page *ngIf="review && isRSGPortal">
    <ng-container content>
        <form *ngIf="review" #rescheduleReviewForm="ngForm">
            <div class="d-flex flex-column">
                <h1>{{ review.reviewStatusTypeId == ReviewStatusTypes.New ? "Schedule Review" : "Edit / Reschedule Review" }}</h1>
                <h3 class="border-bottom pb-4 mb-2">No. {{review.reviewNumber}}</h3>
                <div class="card goa-callout goa--emergency mt-3" *ngIf="isRequestFailed">
                    <h6 class="mt-0">Reschedule Request Failed</h6>
                    <p *ngIf="requestFailedMessage; else noMessageSpecified">
                        {{requestFailedMessage}}
                    </p>
                    <ng-template #noMessageSpecified>
                        <p>
                            Your request can't be completed right now. Please try again later.
                        </p>
                    </ng-template>
                </div>
                <div class="card mt-3 border-0">                                                          
                        <div>
                            <p>
                                Please ask the client to choose a day when they wish their matter to be reviewed. Please note: the client <b><u>does not</u></b> attend a written review.                                
                            </p>
                            <div *ngIf="availabilityView">
                                <h3 class="display">{{availabilityView.startDate | date:'MMM dd, yyyy'}} - {{availabilityView.endDate |
                                    date:'MMM dd, yyyy'}}</h3>
                                <button (click)="onScheduleRefreshClick()" class="goa-button goa--secondary m-0 mt-3 ms-auto refreshBtn">
                                    Refresh
                                </button>
                            </div>
                        </div>                    
    
                        <div *ngIf="availabilityViewForWritten!=null" class="card border-0">
                            <div *ngIf="availabilityViewForWritten.length == 0" class="h6">
                                There are no available review dates. Please contact SafeRoads Alberta at <a href="tel:780-427-7233">780-427-SAFE (7233)</a> or by email to <a href="mailto:saferoads@gov.ab.ca">saferoads&#64;gov.ab.ca</a> for assistance with your review request.
                            </div>
                            <ul *ngIf="availabilityViewForWritten.length > 0">
                                <li class="cal-dateslot"
                                    [ngClass]="{ 'cal-dateslot-selected': availableInfo === selAvailableInfo }"
                                    *ngFor="let availableInfo of availabilityViewForWritten"
                                    (click)="onDateSelected(availableInfo)">
                                    <span>{{ availableInfo.date | date:'longDate' }}</span>
                                    <br>
                                    <b>{{ availableInfo.date | date:'EEEE' }}</b>
                                </li>
                            </ul>
                        </div>
    
                        <div *ngIf="isAvailabilityRequestFailed" class="card border-0 text-danger">
                            <p>
                                An error occurred. Please contact SafeRoads Alberta at <a href="tel:780-427-7233">780-427-SAFE (7233)</a> or by email to <a href="mailto:saferoads@gov.ab.ca">saferoads&#64;gov.ab.ca</a> for assistance with your review request.
                            </p>
                        </div>
                        <div *ngIf="documentSubmissionCutoffDate">
                            <p>
                                By selecting this day, you must submit all your supporting documents by
                                <b>11:59pm</b> on <b>{{ documentSubmissionCutoffDate | date:'fullDate':'UTC'}}.</b>
                            </p>
                        </div>                    
                </div>

                <div class="mt-2" *ngIf="review.reviewStatusTypeId == ReviewStatusTypes.New">
                    <app-review-terms-conditions *ngIf="review.reviewTypeId == ReviewTypes.SuspendedDriverVehicleSeizureReview"
                    [infoSheetName]="Constants.Registry.TRANS22_INFO_SHEET_NAME"
                    [infoSheetLink]="Constants.Registry.TRANS22_INFO_SHEET_LINK"
                    [isSubmitClicked]="rescheduleReviewForm.submitted"></app-review-terms-conditions>

                    <app-review-terms-conditions *ngIf="review.reviewTypeId == ReviewTypes.ThirdPartySeizureReview"
                    [infoSheetName]="Constants.Registry.TRANS23_INFO_SHEET_NAME"
                    [infoSheetLink]="Constants.Registry.TRANS23_INFO_SHEET_LINK"
                    [isSubmitClicked]="rescheduleReviewForm.submitted"></app-review-terms-conditions>
                </div>

                <div class="mb-3 pt-2">
                    <div class="row pt-3">
                        <div class="col-12 col-sm-6" *ngIf="review.reviewStatusTypeId == ReviewStatusTypes.New; else NotNewReview">
                            <button class="goa-button goa--secondary btn-block" [routerLink]="['../../..']">
                                Return to Seizure Details
                            </button>
                        </div>
                        <ng-template #NotNewReview>
                            <div class="col-12 col-sm-6">
                                <button class="goa-button goa--secondary btn-block" [routerLink]="['../details']">
                                    Return to Review
                                </button>
                            </div>
                        </ng-template>
                        <div class="col-12 col-sm-6 text-end">
                            <button class="goa-button btn-block"
                                [disabled]="(rescheduleReviewForm.invalid && formSubmitted) || isSubmitDisabled"
                                (click)="onRescheduleClick($event)">
                                Save Changes
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </ng-container>
</app-vehicle-seizure-page>

<!--Confirm date selected modal -->
<div class="modal fade" id="confirmDateModal" tabindex="-1" role="dialog" aria-labelledby="confirmDateModal"
    aria-hidden="true" data-bs-backdrop='static'>
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content p-4">
            <div class="modal-header border-0 pt-2">
                <span class="modal-title h4" id="confirmDateModal">
                    Confirm Review
                </span>
                <div class="close-icon" data-bs-dismiss="modal">
                    <i class="far fa-times-circle fa-lg"></i>
                </div>
            </div>
            <div class="modal-body py-0">
                <div>
                    Are you sure you would like to {{ review?.reviewStatusTypeId == ReviewStatusTypes.New ? "schedule" : "reschedule" }} your review to <b>{{newReviewDateUtc | date:'fullDate':'UTC'}}</b>?
                </div>
                <div class="button-container float-end">
                    <button id="btnGoBack" type="button" class="goa-button goa--secondary"
                        data-bs-dismiss="modal">Cancel</button>
                    <button id="btnConfirm" type="button" class="goa-button goa--primary me-0" data-bs-dismiss="modal"
                        (click)="selectReviewDate()">Confirm</button>
                </div>
            </div>
        </div>
    </div>
</div>