<app-contravention-page #masterPage>
    <ng-container content>
        <form #paymentForm="ngForm">
        <div class="d-flex flex-column">
            <h1>Review Payment</h1>
            <h3 class="border-bottom mt-0 mb-0">No. {{paymentRequest.recordNumber}}</h3>
            
            <div class="card goa-callout goa--emergency mt-3" *ngIf="isPaymentFailed">
                <h6 class="mt-0">Transaction Failed</h6>
                <p *ngIf="errorMessage && errorMessage.length > 0">
                    {{ errorMessage }}
                </p>
                <p *ngIf="errorMessage == null || errorMessage == ''">
                    Your payment was unsuccessful. Please try again.
                </p>
            </div>

            <div class="card mt-4">
                <div class="card-header">
                    Payment Details
                </div>
                <div>  
                    <ng-template ngFor let-li [ngForOf]="paymentRequest.lineItems">
                        <ng-template [ngIf]="li.isChecked"> 
                            <div class="d-flex justify-content-between align-items-center border-bottom card-row line-item-row">
                                <div>Contravention {{li.recordNumber}}</div>
                                <div><h5>{{li.amount | currency}}</h5></div>
                            </div>
                        </ng-template>
                    </ng-template>
                </div>
                <div class="card-footer">
                    <div class="d-flex justify-content-between align-items-center h6">
                        <h5>Amount Total</h5>
                        <h5>{{paymentRequest.paymentAmount | currency}}</h5>
                    </div>
                </div>
            </div>

            <div *ngIf="registryAgentInformation.isPaidByRecipient==false" class="card mt-4">
                <div class="card-header">
                    Paid By
                </div>
                <div class="card-body py-0"> 
                    <app-section-details sectionTitle="" [sectionDetails]="payerInformation"></app-section-details> 
                </div>        
            </div>

            <div class="mb-3">
                <div class="row">
                    <div class="col-12 col-sm-6">                
                        <button class="goa-button goa--secondary btn-block" [routerLink]="['../information']">Back to Payment Information</button>
                    </div>
                    <div class="col-12 col-sm-6 text-end">                
                        <button class="goa-button goa--primary btn-block" [disabled]="isPaymentFailed" 
                        (click)="onSubmitPaymentClick(paymentForm.valid)">Confirm & Submit Payment</button>
                    </div>
                </div>
            </div>
        </div>
        </form>
    </ng-container>
</app-contravention-page>