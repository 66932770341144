<div class="card mt-4">
    <div class="card-header">
        Contravention Information
    </div>
    <div class="card-body">
        <div class="mb-3 row">
            <div class="col-lg-4">
                <label for="contraventionNumber">Contravention Number</label>
                <input type="text" readonly class="form-control" id="contraventionNumber" 
                    [ngModel]="contravention.contraventionNumber">
            </div>
            <div class="col-lg-4">
                <label for="issuanceDate">Issuance Date</label>
                <input type="text" readonly class="form-control" id="issuanceDate" 
                    [ngModel]="contravention.issueDate | date:'MM/dd/yyyy'">
            </div>
        </div>

        <div class="mb-3 row">
            <div class="col-12">
                <label for="contraventionType">Contravention Type</label>
                <select class="form-select" id="contraventionType" name="contraventionType" disabled
                    [ngModel]="contravention.contraventionTypeId">
                    <option [ngValue]="null">Select a Contravention Type</option>
                    <option *ngFor="let contraventionType of contraventionTypes" [value]="contraventionType.id">{{contraventionType.name}}</option>
                </select>
            </div>
        </div>
        
        <div class="mb-3 row" *ngIf="contraventionDetails && contraventionDetails.length > 0">
            <div class="col-lg-12">
                <label for="contraventionDetails">Contravention Details</label>
                <mat-select disabled class="form-control-multiselect" multiple id="contraventionDetailsMultiselect" 
                            name="contraventionDetailsMultiselect" placeholder="Select the Contravention Details" [ngModel]="contraventionDetails">
                    <mat-option *ngFor="let contraventionDetailType of contraventionDetailTypes" [value]="contraventionDetailType.id">{{contraventionDetailType.name}}</mat-option>
                </mat-select>
            </div>
        </div>
    </div>
</div>