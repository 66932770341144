<div class="d-flex flex-column">
    <h1>Contravention Details Pending</h1>
    <div class="card border-0">
        <h3 class="border-bottom pb-2">No. A-1234567-O</h3>
    </div>
    
    <div class="card border-0">
        <p class="m-0">The full details of your contravention are pending. 
            These details will be available 48 hours after your contravention issue date.</p>
    </div>
    
    <div class="card mt-4">
        <div class="container d-flex justify-content-left">
            <div class="row callout">
                <div class="col-md-1 callout-logo-info text-white">   
                    <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" class="bi bi-info-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                        <path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588z"></path>
                        <circle cx="8" cy="4.5" r="1"></circle>
                      </svg>
                </div>
                <div class="col-md-11 mt-4 mb-4">   
                    <h5>Sign Up to Be Notified</h5>
                    <p class="m-0">SafeRoads will send a notification to your preferred contact method when your contravention details are available for review. 
                        To opt in for these notifications provide your contact details below.</p>
                </div>
            </div>
        </div>
    </div>

    <div class="card mt-4">
        <div class="card-header">
            Preferred Contact Information
        </div>
        <div class="card-body">
            <div class="mb-3 row">
                <div class="col-lg-6">
                    <label for="contactMethod" class="required">Preferred Contact Method</label>
                    <select class="form-select" id="contactMethod">
                        <option value="">Select a Method</option>
                    </select>	
                </div>	                
            </div>
        </div>	
    </div>

    <div class="card border-0">
        <p>
            The personal information on this form is collected under the authority of the Traffic Safety Act and Section 33 of the Freedom of Information and Protection of Privacy (FOIP) Act and will be used for the administration of programs under the Traffic Safety Act. Inquiries may be directed to SafeRoads Alberta, Alberta Justice, Third Floor, Twin Atria Building, 4999 - 98 Avenue NW, Edmonton, Alberta T6B 2X3, at telephone number 780-427-7233 (Edmonton and area) and toll free 310-0000 (in Alberta).
        </p>
    </div>
    
    <div class="row mb-3 pt-3">
        <div class="col-12 col-sm-6 mb-2 mb-sm-0">                
            <a class="btn button-secondary btn-block" [routerLink]="['/submission-type']">Return to Contravention Lookup</a>
        </div>
        <div class="col-12 col-sm-6">                
            <a class="btn button-primary btn-block" [routerLink]="['/driver/notification-preferences-set']">Submit Notification Preferences</a>
        </div>        
    </div>
</div>

