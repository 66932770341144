<div class="mb-3 row">
    <div class="col-lg-6">
        <label for="cardHolderName" class="required">Cardholder Name</label>
        <input type="text" [(ngModel)]="paymentRequest.cardHolderName" class="form-control" 
            id="cardHolderName" name="cardHolderName" #cardHolderName="ngModel" 
            required [ngClass]="{ 'is-invalid': isSubmitClicked && cardHolderName.invalid }"
            placeholder="Enter the Cardholder Name…">
        <div class="invalid-feedback">
            <div>Cardholder Name is required</div>
        </div>
    </div>
</div>
<div class="mb-3 row">
    <div class="col-lg-6">
        <label for="cardNumber">Card Number</label>
        <div id="card-number"></div>
        <div for="card-number" class="invalid-feedback">Card Number is invalid or incomplete.</div>
    </div>
    <div class="col-lg-3">
        <label for="cardExpiry">Expiry</label>
        <div id="card-expiry"></div>
        <div for="card-expiry" class="invalid-feedback">Expiry is invalid or incomplete.</div>
    </div>
    <div class="col-lg-3">
        <label for="cardCVV">CVC/CVV</label>
        <div id="card-cvv"></div>
        <div for="card-cvv" class="invalid-feedback">CVV is invalid or incomplete.</div>
    </div>    
</div>
