<div class="d-flex flex-column">
    <h1>Review Rescheduled</h1>
    <div class="header-container border-bottom pb-4 mb-2">
        <div>
            <h3 class="mb-0">No. {{review.reviewNumber}}</h3>
        </div>
    </div>

    <!-- Note: ReviewDate taken from localstorage is in UTC, even though API returns unspecified time zone. -->
    <div class="card border-0">
        <p *ngIf="review && review.reviewMethodTypeId==1">
            Your review has been successfully rescheduled to {{review.reviewDate | date:'fullDate':'UTC'}}.
        </p>
        <p *ngIf="review && review.reviewMethodTypeId==2">
            Your review has been successfully rescheduled to {{review.reviewDate | date:'fullDate':'UTC'}} at {{review.reviewTime | time}}.
        </p>
    </div>

    <div class="mb-3">
        <div class="row">
            <div class="col-12 col-sm-12">
                <button class="goa-button float-end me-0" [routerLink]="['../details']">Return to Review
                    Details</button>
            </div>
        </div>
    </div>
</div>