<app-vehicle-seizure-page>
    <ng-container content>
        <form #tncForm="ngForm">
            <div class="d-flex flex-column">
                <h1 class="">Terms & Conditions</h1>
                <h3 class="border-bottom pb-4 mb-2">No. {{vehicleSeizure.seizureNumber}}</h3>
            
                <app-review-terms-conditions 
                [infoSheetName]="Constants.Registry.TRANS29_INFO_SHEET_NAME" 
                [infoSheetLink]="Constants.Registry.TRANS29_INFO_SHEET_LINK"
                [isSubmitClicked]="tncForm.submitted"></app-review-terms-conditions>    
        
                <div class="mb-3">
                    <div class="row pt-3">
                        <div class="col-12 col-sm-6">                
                            <button class="goa-button goa--secondary btn-block" [routerLink]="['../../../']">Return to Seizure Details</button>
                        </div>
                        <div class="col-12 col-sm-6 text-end">                
                            <button class="goa-button goa--primary btn-block" (click)="onProceedClick(tncForm.valid)">Proceed to Request Details</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </ng-container>
</app-vehicle-seizure-page>

<!-- Modal -->
<div class="modal fade" id="confirmModal" tabindex="-1" role="dialog" aria-labelledby="confirmModalLabel" aria-hidden="true" data-bs-backdrop='static'>
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content p-4">
            <div class="modal-header border-0 pt-2">
                <span class="modal-title h4" id="confirmModalLabel">
                    Late Review Request Supporting Documents
                </span>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                    <i class="far fa-times-circle fa-lg"></i>
                </button>
            </div>
            <div class="modal-body py-0">
                <p>
                    Please inform the client that if they have supporting documents that they want to have considered 
                    as part of their late review request, <b>they must be uploaded in this session</b> (step 3: Supporting Documents).
                </p>
                <p>
                    If they are awaiting further documents, <b>do not file</b> the request at this time. They can only file the request 
                    for a late review when they can upload all of the documents that they want to have considered.
                </p>
            </div>

            <div class="mb-3">
                <div class="row pt-3">
                    <div class="col-12 col-sm-6">                
                        <button type="button" class="goa-button goa--secondary btn-block" data-bs-dismiss="modal">Cancel</button>
                    </div>
                    <div class="col-12 col-sm-6 text-end">                
                        <button type="button" class="goa-button goa--primary btn-block" data-bs-dismiss="modal" (click)="onContinueClick()">Continue</button>
                    </div>
                </div>
            </div>	
        </div>
    </div>
</div>