<div class="d-flex flex-column">
    <h1>Notification Preferences Set</h1>
    <div class="card border-0">
        <h3 class="border-bottom pb-2">No. A-1234567-O</h3>
    </div>
    
    <div class="card border-0">
        <p class="m-0">
            Your preferred contact information has been set. 
            Once the details of your contravention are available for review, a notification will be sent to: +1 587 555 1234
        </p>
    </div>

    <div class="row mb-3 mt-4 pt-3">
        <div class="col-6 col-sm-6">
            <a class="btn button-primary btn-block" [routerLink]="['/driver/contravention-lookup']">Return to Contravention Lookup</a>
        </div>        
    </div>
</div>

