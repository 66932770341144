<ng-container *ngIf="impairmentScreeningModel.isImpairmentScreeningMethodSelectionRequired">
    <div class="card mt-4">
        <div class="card-header">
            {{contraventionTest.testSequence > 0 ? impairmentScreeningModel.impairmentScreeningCardLabel + " " + contraventionTest.testSequence.toString() : impairmentScreeningModel.impairmentScreeningCardLabel }}
        </div>
        <div class="card-body">
            <div class="mb-3 row">
                <div class="col-12 col-sm-8">
                    <label for="impairmentScreeningMethod{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">Test Method <span class="label-hint">(Required)</span></label>
                    <select [appSelectValidator]="true" #impairmentScreeningMethodControl="ngModel" class="form-select" id="impairmentScreeningMethod{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}" name="impairmentScreeningMethod{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}" [(ngModel)]="impairmentScreeningModel.ImpairmentScreeningMethodId" (change)="onImpairmentScreeningMethodChange($event.target.value)" [ngClass]="{'is-invalid': (impairmentScreeningMethodControl.touched || impairmentScreeningModel.isSubmitClicked) && impairmentScreeningMethodControl.errors?.defaultSelected}">
                        <option value="0">Select a Method</option>
                        <option *ngFor="let testType of testTypes" [value]="testType.id">{{testType.name}}</option>
                    </select>
                    <div class="invalid-feedback">
                        <div>Test Method is required</div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-6">
                    <button class="btn-link" (click)="onRemoveClick()">
                        Remove this test method
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="impairmentScreeningModel.ImpairmentScreeningMethodId == 0">
    <div class="card mt-4">
        <div class="card-header">
            {{contraventionTest.testSequence > 0 ? impairmentScreeningModel.impairmentScreeningResultCardLabel + " " + contraventionTest.testSequence.toString() + " Details" : impairmentScreeningModel.impairmentScreeningResultCardLabel }}
        </div>
        <div class="card-body">
            <div class="row justify-content-lg-center">
                <p class="text-muted">{{impairmentScreeningModel.selectionMessageLabel}}</p>
            </div>
        </div>
    </div>
</ng-container>

<!-- SFST -->
<ng-container *ngIf="impairmentScreeningModel.ImpairmentScreeningMethodId == 1">
  <div class="card mt-4">
      <div class="card-header">
          {{contraventionTest.testSequence > 0 ? impairmentScreeningModel.impairmentScreeningResultCardLabel + " "  + contraventionTest.testSequence.toString() + " Details" : impairmentScreeningModel.impairmentScreeningResultCardLabel }}
      </div>
      <div class="card-body">
          <div class="mb-3 row">
              <div class="col-sm-6">
                  <label for="timeOfSFSTDemand{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">Time of {{isRoadsideAppeal?"Roadside Appeal Request":"SFST Demand"}}
                    <span class="label-hint">(Required)</span>
                  </label>
                  <input required #timeOfSFSTDemandControl="ngModel" type="text" placeholder="--:--" mask="{{Constants.Mask.TIME_MASK}}" [patterns]="Constants.Mask.TIME_MASK_PATTERNS" [showMaskTyped]="true" [dropSpecialCharacters]="false"
                  pattern="^(([[0|1]\d)|(2[0-3]))[:]([0-5]\d)$" class="form-control" id="timeOfSFSTDemand{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}" name="timeOfSFSTDemand{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}" [(ngModel)]="contraventionTest.standardizedFieldSobrietyTest.testDemandTime" [ngClass]="{'is-invalid':(timeOfSFSTDemandControl.touched || impairmentScreeningModel.isSubmitClicked) && timeOfSFSTDemandControl.invalid}">
                  <div class="invalid-feedback">
                      <div *ngIf="timeOfSFSTDemandControl.errors?.required">Time of {{isRoadsideAppeal?"Roadside Appeal Request":"SFST Demand"}} is required</div>
                      <div *ngIf="!timeOfSFSTDemandControl.errors?.required">Invalid Time of {{isRoadsideAppeal?"Roadside Appeal Request":"SFST Demand"}}</div>
                  </div>
              </div>
              <div class="col-sm-6">
                <label>{{isRoadsideAppeal?"Decline?":"Refusal?"}} <span class="label-hint">(Required)</span></label>
                <div class="mt-3 ms-1">
                    <div class="form-check form-check-inline custom-radio-narrow pe-3">
                        <input
                          required type="radio"
                          class="form-check-input"
                          id="SFSTrefusalStraightRefusal{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                          name="SFSTrefusalRadio{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                          #SFSTRefusalStraitRefusalControl="ngModel"
                          [value]="1"
                          [(ngModel)]="contraventionTest.driverDeclinedToTestTypeId"
                          [ngClass]="{'is-invalid': contraventionTest.driverDeclinedToTestTypeId == null && (SFSTRefusalStraitRefusalControl.touched || SFSTRefusalFailedToComplyControl.touched || SFSTRefusalNoControl.touched || impairmentScreeningModel.isSubmitClicked)}">

                        <label
                          class="form-check-label"
                          for="SFSTrefusalStraightRefusal{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">{{isRoadsideAppeal?"Yes":"Straight refusal"}}</label>
                    </div>
                    <div class="form-check form-check-inline custom-radio-narrow pe-3">
                        <input
                          required type="radio"
                          class="form-check-input"
                          id="SFSTrefusalFailedToComply{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                          name="SFSTrefusalRadio{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                          #SFSTRefusalFailedToComplyControl="ngModel"
                          [value]="2"
                          [(ngModel)]="contraventionTest.driverDeclinedToTestTypeId"
                          [ngClass]="{'is-invalid': contraventionTest.driverDeclinedToTestTypeId == null && (SFSTRefusalStraitRefusalControl.touched || SFSTRefusalFailedToComplyControl.touched || SFSTRefusalNoControl.touched ||impairmentScreeningModel.isSubmitClicked)}">

                        <label
                          class="form-check-label"
                          for="SFSTrefusalFailedToComply{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">Failed to comply</label>
                    </div>
                    <div class="form-check form-check-inline custom-radio-narrow">
                        <input
                          required type="radio"
                          class="form-check-input"
                          id="SFSTrefusalNo{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                          name="SFSTrefusalRadio{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                          #SFSTRefusalNoControl="ngModel"
                          [value]="3"
                          [(ngModel)]="contraventionTest.driverDeclinedToTestTypeId"
                          [ngClass]="{'is-invalid': contraventionTest.driverDeclinedToTestTypeId == null && (SFSTRefusalStraitRefusalControl.touched || SFSTRefusalFailedToComplyControl.touched || SFSTRefusalNoControl.touched || impairmentScreeningModel.isSubmitClicked)}">
                        <label
                          class="form-check-label"
                          for="SFSTrefusalNo{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">No</label>
                    </div>

                    <input
                      required type="radio"
                      style="display:none;"
                      id="SFSTrefusalNo{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}Hidden"
                      name="SFSTrefusalRadio{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                      #SFSTRefusalHiddenControl="ngModel"
                      [value]="null"
                      [(ngModel)]="contraventionTest.driverDeclinedToTestTypeId"
                      [ngClass]="{'is-invalid': contraventionTest.driverDeclinedToTestTypeId == null && (SFSTRefusalStraitRefusalControl.touched || SFSTRefusalFailedToComplyControl.touched || SFSTRefusalNoControl.touched || impairmentScreeningModel.isSubmitClicked)}">
                    <div class="invalid-feedback">
                      <div>{{isRoadsideAppeal?"Decline":"Refusal"}} Information is required</div>
                  </div>
              </div>
            </div>
          </div>

          <div class="mb-3 row">
            <div class="col-sm-6" *ngIf="contraventionTest.driverDeclinedToTestTypeId == DriverDeclinedToTestTypes.FailedToComply || contraventionTest.driverDeclinedToTestTypeId == DriverDeclinedToTestTypes.No">
                <label
                    for="timeSFSTTestStarted{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">Time {{isRoadsideAppeal?"Roadside Appeal":"SFST"}} Test Started <span class="label-hint">(Required)</span></label>
                <input
                  required #timeSFSTTestStartedControl="ngModel"
                  type="text" placeholder="--:--"
                  mask="{{Constants.Mask.TIME_MASK}}" [patterns]="Constants.Mask.TIME_MASK_PATTERNS" [showMaskTyped]="true" [dropSpecialCharacters]="false"
                  pattern="^(([[0|1]\d)|(2[0-3]))[:]([0-5]\d)$"
                  class="form-control"
                  id="timeSFSTTestStarted{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}" name="timeSFSTTestStarted{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                  [(ngModel)]="contraventionTest.standardizedFieldSobrietyTest.testStartedTime"
                  [ngClass]="{'is-invalid':(timeSFSTTestStartedControl.touched || impairmentScreeningModel.isSubmitClicked) && timeSFSTTestStartedControl.invalid}">
                <div class="invalid-feedback">
                    <div *ngIf="timeSFSTTestStartedControl.errors?.required">Time {{isRoadsideAppeal?"Roadside Appeal":"SFST"}} Test Started is required</div>
                    <div *ngIf="!timeSFSTTestStartedControl.errors?.required">Invalid Time {{isRoadsideAppeal?"Roadside Appeal":"SFST"}} Test Started</div>
                </div>
            </div>
            <div class="col-sm-6" *ngIf="contraventionTest.driverDeclinedToTestTypeId == DriverDeclinedToTestTypes.No">
                <label for="SFSTResults{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">SFST Results <span class="label-hint">(Required)</span></label>
                <select
                  [appSelectValidator]="true"
                  #SFSTResultsControl="ngModel"
                  class="form-select"
                  id="SFSTResults{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                  name="SFSTResults{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                  [(ngModel)]="contraventionTest.standardizedFieldSobrietyTest.testResultTypeId"
                  [ngClass]="{'is-invalid': (SFSTResultsControl.touched || impairmentScreeningModel.isSubmitClicked) && SFSTResultsControl.errors?.defaultSelected}">
                    <option value="0">Select the SFST Result</option>
                    <option *ngFor="let testResultTypeSFST of testResultTypesSFST" [value]="testResultTypeSFST.id">{{testResultTypeSFST.name}}</option>
                </select>
                <div class="invalid-feedback">
                    <div>SFST Results are required</div>
                </div>
            </div>
            <div class="col-sm-4" *ngIf="contraventionTest.driverDeclinedToTestTypeId == DriverDeclinedToTestTypes.StraightRefusal">
                <label for="SFSTtimeOfRefusal{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">Time of {{isRoadsideAppeal?"Decline":"Refusal"}} <span class="label-hint">(Required)</span></label>
                <input
                  required
                  #SFSTtimeOfRefusalControl="ngModel"
                  type="text" placeholder="--:--" mask="{{Constants.Mask.TIME_MASK}}" [patterns]="Constants.Mask.TIME_MASK_PATTERNS" [showMaskTyped]="true" [dropSpecialCharacters]="false"
                  pattern="^(([[0|1]\d)|(2[0-3]))[:]([0-5]\d)$"
                  class="form-control"
                  id="SFSTtimeOfRefusal{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                  name="SFSTtimeOfRefusal{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                  [(ngModel)]="contraventionTest.refusalTime"
                  [ngClass]="{'is-invalid':(SFSTtimeOfRefusalControl.touched || impairmentScreeningModel.isSubmitClicked) && SFSTtimeOfRefusalControl.invalid}">
                <div class="invalid-feedback">
                    <div *ngIf="SFSTtimeOfRefusalControl.errors?.required">Time of {{isRoadsideAppeal?"Decline":"Refusal"}} is required</div>
                    <div *ngIf="!SFSTtimeOfRefusalControl.errors?.required">Invalid Time of {{isRoadsideAppeal?"Decline":"Refusal"}}</div>
                </div>
            </div>
          </div>
         
          <app-impairment-screening-alert
            [ImpairmentScreeningMethodId]="impairmentScreeningModel.ImpairmentScreeningMethodId"
            [DriverDeclinedToTestId]="contraventionTest.driverDeclinedToTestTypeId"></app-impairment-screening-alert>

      </div>
  </div>
</ng-container>

<!-- ASD -->
<ng-container *ngIf="impairmentScreeningModel.ImpairmentScreeningMethodId == 2">
    <div class="card mt-4">
        <div class="card-header">
            {{contraventionTest.testSequence > 0 ? impairmentScreeningModel.impairmentScreeningResultCardLabel + " "  + contraventionTest.testSequence.toString() + " Details" : impairmentScreeningModel.impairmentScreeningResultCardLabel }}
        </div>
        <div class="card-body">
            <div class="mb-3 row">
                <div class="col-sm-6">
                    <label for="ASDModel{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">ASD Model <span class="label-hint">(Required)</span></label>
                    <select
                        [appSelectValidator]="true"
                        #ASDModelControl="ngModel" class="form-select"
                        id="ASDModel{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                        name="ASDModel{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                        [(ngModel)]="contraventionTest.approvedScreeningDeviceTest.testModelTypeId" (change)="onASDModelChange($event.target.value)"
                        [ngClass]="{'is-invalid': (ASDModelControl.touched || impairmentScreeningModel.isSubmitClicked) && ASDModelControl.errors?.defaultSelected}">
                        <option [value]="0">Select the ASD Model</option>
                        <option
                            *ngFor="let testModelTypeASD of testModelTypesASD"
                            [value]="testModelTypeASD.id">{{testModelTypeASD.name}}</option>
                    </select>
                    <div class="invalid-feedback">
                        <div>ASD Model is required</div>
                    </div>
                </div>
                <div class="col-sm-6" *ngIf="contraventionTest.approvedScreeningDeviceTest.testModelTypeId == 2">
                    <label for="otherASDModel{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">Specify ‘Other’ Model <span class="label-hint">(Required)</span></label>
                    <input
                        required
                        #otherASDModelControl="ngModel"
                        maxlength="200"
                        type="text"
                        class="form-control"
                        id="otherASDModel{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                        name="otherASDModel{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                        placeholder="Specify ‘Other’ Model"
                        [(ngModel)]="contraventionTest.approvedScreeningDeviceTest.testModelOther"
                        [ngClass]="{'is-invalid': (otherASDModelControl.touched || impairmentScreeningModel.isSubmitClicked) && otherASDModelControl.invalid}">
                    <div class="invalid-feedback">
                        <div>‘Other’ Model is required</div>
                    </div>
                </div>
            </div>

            <div class="mb-3 row">
                <div class="col-sm-6">
                    <label *ngIf="isRoadsideAppeal" for="timeOfASDDemand{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">Time of Roadside Appeal Request <span class="label-hint">(Required)</span></label>
                    <label *ngIf="!isRoadsideAppeal" for="timeOfASDDemand{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">Time of {{isMASLabels?"MAS":"ASD"}} Demand <span class="label-hint">(Required)</span></label>
                    <input
                        required
                        #timeOfASDDemandControl="ngModel"
                        type="text" placeholder="--:--" mask="{{Constants.Mask.TIME_MASK}}" [patterns]="Constants.Mask.TIME_MASK_PATTERNS" [showMaskTyped]="true" [dropSpecialCharacters]="false"
                        pattern="^(([[0|1]\d)|(2[0-3]))[:]([0-5]\d)$"
                        class="form-control"
                        id="timeOfASDDemand{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                        name="timeOfASDDemand{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                        [(ngModel)]="contraventionTest.approvedScreeningDeviceTest.testDemandTime"
                        [ngClass]="{'is-invalid': (timeOfASDDemandControl.touched || impairmentScreeningModel.isSubmitClicked) && timeOfASDDemandControl.invalid}">
                    <div class="invalid-feedback">
                        <div *ngIf="timeOfASDDemandControl.errors?.required && isRoadsideAppeal">Time of Roadside Appeal Request is required</div>
                        <div *ngIf="!timeOfASDDemandControl.errors?.required && isRoadsideAppeal">Invalid Time of Roadside Appeal Request</div>
                        <div *ngIf="timeOfASDDemandControl.errors?.required && !isRoadsideAppeal">Time of {{isMASLabels?"MAS":"ASD"}} Demand is required</div>
                        <div *ngIf="!timeOfASDDemandControl.errors?.required && !isRoadsideAppeal">Invalid Time of {{isMASLabels?"MAS":"ASD"}} Demand</div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <label>{{isRoadsideAppeal?"Decline?":"Refusal?"}} <span class="label-hint">(Required)</span></label>
                    <div class="mt-3">
                        <div class="form-check form-check-inline custom-radio-narrow">
                            <input
                                required type="radio"
                                class="form-check-input"
                                id="ASDrefusalStraightRefusal{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                                name="ASDrefusalRadio{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                                #ASDRefusalStraightRefusalControl="ngModel"
                                [value]="1"
                                [(ngModel)]="contraventionTest.driverDeclinedToTestTypeId"
                                [ngClass]="{'is-invalid': contraventionTest.driverDeclinedToTestTypeId == null && (ASDRefusalStraightRefusalControl.touched || ASDRefusalFailedToComplyControl.touched || ASDRefusalNoControl.touched || impairmentScreeningModel.isSubmitClicked)}">
                            <label
                                class="form-check-label"
                                for="ASDrefusalStraightRefusal{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">{{isRoadsideAppeal?"Yes":"Straight refusal"}}</label>
                        </div>
                        <div class="form-check form-check-inline custom-radio-narrow">
                            <input
                                required type="radio"
                                class="form-check-input"
                                id="ASDrefusalFailedToComply{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                                name="ASDrefusalRadio{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                                #ASDRefusalFailedToComplyControl="ngModel"
                                [value]="2"
                                [(ngModel)]="contraventionTest.driverDeclinedToTestTypeId"
                                [ngClass]="{'is-invalid': contraventionTest.driverDeclinedToTestTypeId == null && (ASDRefusalStraightRefusalControl.touched || ASDRefusalFailedToComplyControl.touched || ASDRefusalNoControl.touched || impairmentScreeningModel.isSubmitClicked)}">
                            <label
                                class="form-check-label"
                                for="ASDrefusalFailedToComply{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">Failed to comply</label>
                        </div>
                        <div class="form-check form-check-inline custom-radio-narrow">
                            <input
                                required type="radio"
                                class="form-check-input"
                                id="ASDrefusalNo{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                                name="ASDrefusalRadio{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                                #ASDRefusalNoControl="ngModel"
                                [value]="3"
                                [(ngModel)]="contraventionTest.driverDeclinedToTestTypeId"
                                [ngClass]="{'is-invalid': contraventionTest.driverDeclinedToTestTypeId == null && (ASDRefusalStraightRefusalControl.touched || ASDRefusalFailedToComplyControl.touched || ASDRefusalNoControl.touched || impairmentScreeningModel.isSubmitClicked)}">
                            <label
                                class="form-check-label"
                                for="ASDrefusalNo{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">No</label>
                        </div>
                        <input
                            required type="radio"
                            style="display:none;"
                            id="ASDrefusalNo{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}Hidden"
                            name="ASDrefusalRadio{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                            #ASDRefusalHiddenControl="ngModel"
                            [value]="null"
                            [(ngModel)]="contraventionTest.driverDeclinedToTestTypeId"
                            [ngClass]="{'is-invalid': contraventionTest.driverDeclinedToTestTypeId == null && (ASDRefusalStraightRefusalControl.touched || ASDRefusalFailedToComplyControl.touched || ASDRefusalNoControl.touched || impairmentScreeningModel.isSubmitClicked)}">
                        <div class="invalid-feedback">
                            <div>{{isRoadsideAppeal?"Decline":"Refusal"}} Information is required</div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="mb-3 row">
                <div class="col-sm-6" *ngIf="contraventionTest.driverDeclinedToTestTypeId == DriverDeclinedToTestTypes.FailedToComply || contraventionTest.driverDeclinedToTestTypeId == DriverDeclinedToTestTypes.No">
                    <label *ngIf="isRoadsideAppeal"
                        for="timeASDTestStarted{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">Time Roadside Appeal Test Started
                        <span *ngIf="contraventionTest.driverDeclinedToTestTypeId == 2" class="label-hint">(Required)</span></label>
                    <label *ngIf="!isRoadsideAppeal"
                        for="timeASDTestStarted{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">Time {{isMASLabels?"MAS":"ASD"}} Test Started
                        <span *ngIf="contraventionTest.driverDeclinedToTestTypeId == 2" class="label-hint">(Required)</span></label>    
                    <input
                        required
                        #timeASDTestStartedControl="ngModel" type="text" placeholder="--:--" mask="{{Constants.Mask.TIME_MASK}}" [patterns]="Constants.Mask.TIME_MASK_PATTERNS" [showMaskTyped]="true" [dropSpecialCharacters]="false"
                        pattern="^(([[0|1]\d)|(2[0-3]))[:]([0-5]\d)$"
                        class="form-control"
                        id="timeASDTestStarted{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                        name="timeASDTestStarted{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                        [(ngModel)]="contraventionTest.approvedScreeningDeviceTest.testStartedTime"
                        [ngClass]="{'is-invalid': (timeASDTestStartedControl.touched || impairmentScreeningModel.isSubmitClicked) && timeASDTestStartedControl.invalid}">
                    <div class="invalid-feedback">
                        <div *ngIf="timeASDTestStartedControl.errors?.required && isRoadsideAppeal">Time Roadside Appeal Test Started is required</div>
                        <div *ngIf="!timeASDTestStartedControl.errors?.required && isRoadsideAppeal">Invalid Time Roadside Appeal Test Started</div>
                        <div *ngIf="timeASDTestStartedControl.errors?.required && !isRoadsideAppeal">Time {{isMASLabels?"MAS":"ASD"}} Test Started is required</div>
                        <div *ngIf="!timeASDTestStartedControl.errors?.required && !isRoadsideAppeal">Invalid Time {{isMASLabels?"MAS":"ASD"}} Test Started</div>
                    </div>
                </div>
                <div class="col-sm-6" *ngIf="contraventionTest.driverDeclinedToTestTypeId == DriverDeclinedToTestTypes.No">
                    <label
                        for="ASDResults{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">{{isMASLabels?"MAS":"ASD"}} Results <span class="label-hint">(Required)</span></label>
                    <select
                        [appSelectValidator]="true"
                        #ASDResultsControl="ngModel"
                        class="form-select"
                        id="ASDResults{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                        name="ASDResults{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                        [(ngModel)]="contraventionTest.approvedScreeningDeviceTest.testResultTypeId"
                        [ngClass]="{'is-invalid': (ASDResultsControl.touched || impairmentScreeningModel.isSubmitClicked) && ASDResultsControl.errors?.defaultSelected}">
                        <option value="0">Select the {{isMASLabels?"MAS":"ASD"}} Result</option>
                        <option *ngFor="let testResultTypeASD of testResultTypesASD"
                            [value]="testResultTypeASD.id">{{testResultTypeASD.name}}</option>
                    </select>
                    <div class="invalid-feedback">
                        <div>{{isMASLabels?"MAS":"ASD"}} Results are required</div>
                    </div>
                </div>
                <div class="col-sm-4" *ngIf="contraventionTest.driverDeclinedToTestTypeId == DriverDeclinedToTestTypes.StraightRefusal">
                    <label
                        for="ASDtimeOfRefusal{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">Time of {{isRoadsideAppeal?"Decline":"Refusal"}}
                        <span class="label-hint">(Required)</span></label>
                    <input
                        required #ASDtimeOfRefusalControl="ngModel"
                        type="text" placeholder="--:--" mask="{{Constants.Mask.TIME_MASK}}" [patterns]="Constants.Mask.TIME_MASK_PATTERNS" [showMaskTyped]="true" [dropSpecialCharacters]="false"
                        pattern="^(([[0|1]\d)|(2[0-3]))[:]([0-5]\d)$"
                        class="form-control"
                        id="ASDtimeOfRefusal{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                        name="ASDtimeOfRefusal{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                        [(ngModel)]="contraventionTest.refusalTime" [ngClass]="{'is-invalid':(ASDtimeOfRefusalControl.touched || impairmentScreeningModel.isSubmitClicked) && ASDtimeOfRefusalControl.invalid}">
                    <div class="invalid-feedback">
                        <div *ngIf="ASDtimeOfRefusalControl.errors?.required">Time of {{isRoadsideAppeal?"Decline":"Refusal"}} is required</div>
                        <div *ngIf="!ASDtimeOfRefusalControl.errors?.required">Invalid Time of {{isRoadsideAppeal?"Decline":"Refusal"}}</div>
                    </div>
                </div>
            </div>
          
            <app-impairment-screening-alert
              [ImpairmentScreeningMethodId]="impairmentScreeningModel.ImpairmentScreeningMethodId"
              [DriverDeclinedToTestId]="contraventionTest.driverDeclinedToTestTypeId"></app-impairment-screening-alert>

        </div>
    </div>
</ng-container>

<!-- ADSE -->
<ng-container *ngIf="impairmentScreeningModel.ImpairmentScreeningMethodId == 3">
    <div class="card mt-4">
        <div class="card-header">
            {{contraventionTest.testSequence > 0 ? impairmentScreeningModel.impairmentScreeningResultCardLabel + " "  + contraventionTest.testSequence.toString() + " Details" : impairmentScreeningModel.impairmentScreeningResultCardLabel }}
        </div>
        <div class="card-body">
            <div class="mb-3 row">
                <div class="col-sm-6">
                    <label for="ADSEModel{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">ADSE Model
                        <span class="label-hint">(Required)</span></label>
                    <select [appSelectValidator]="true" #ADSEModelControl="ngModel" class="form-select" id="ASDModel{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}" name="ASDModel{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}" [(ngModel)]="contraventionTest.drugScreeningEquipmentTest.testModelTypeId" (change)="onADSEModelChange($event.target.value)" [ngClass]="{'is-invalid': (ADSEModelControl.touched || impairmentScreeningModel.isSubmitClicked) && ADSEModelControl.errors?.defaultSelected}">
                        <option value="0">Select an ADSE Model</option>
                        <option *ngFor="let testModelTypeADSE of testModelTypesADSE" [value]="testModelTypeADSE.id">{{testModelTypeADSE.name}}</option>
                    </select>
                    <div class="invalid-feedback">
                        <div>ADSE Model is required</div>
                    </div>
                </div>
                <div class="col-sm-6" *ngIf="contraventionTest.drugScreeningEquipmentTest.testModelTypeId == 5">
                    <label for="otherADSEModel{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">Specify ‘Other’ Model <span class="label-hint">(Required)</span></label>
                    <input required #otherADSEModelControl="ngModel" maxlength="200" type="text" class="form-control" id="otherADSEModel{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}" name="otherADSEModel{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}" placeholder="Specify ‘Other’ Model" [(ngModel)]="contraventionTest.drugScreeningEquipmentTest.testModelOther" [ngClass]="{'is-invalid': (otherADSEModelControl.touched || impairmentScreeningModel.isSubmitClicked) && otherADSEModelControl.invalid}">
                    <div class="invalid-feedback">
                        <div>‘Other’ Model is required</div>
                    </div>
                </div>
            </div>

            <div class="mb-3 row">
                <div class="col-sm-6">
                    <label
                        for="timeOfADSEDemand{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">Time of {{isRoadsideAppeal?"Roadside Appeal Request":"ADSE Demand"}} <span class="label-hint">(Required)</span></label>
                    <input required #timeOfADSEDemandControl="ngModel" type="text" placeholder="--:--" mask="{{Constants.Mask.TIME_MASK}}" [patterns]="Constants.Mask.TIME_MASK_PATTERNS" [showMaskTyped]="true" [dropSpecialCharacters]="false"
                    pattern="^(([[0|1]\d)|(2[0-3]))[:]([0-5]\d)$" class="form-control" id="timeOfADSEDemand{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}" name="timeOfADSEDemand{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}" [(ngModel)]="contraventionTest.drugScreeningEquipmentTest.testDemandTime" [ngClass]="{'is-invalid': (timeOfADSEDemandControl.touched || impairmentScreeningModel.isSubmitClicked) && timeOfADSEDemandControl.invalid}">
                    <div class="invalid-feedback">
                        <div *ngIf="timeOfADSEDemandControl.errors?.required">Time of {{isRoadsideAppeal?"Roadside Appeal Request":"ADSE Demand"}} is required</div>
                        <div *ngIf="!timeOfADSEDemandControl.errors?.required">Invalid Time of {{isRoadsideAppeal?"Roadside Appeal Request":"ADSE Demand"}}</div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <label>{{isRoadsideAppeal?"Decline?":"Refusal?"}} <span class="label-hint">(Required)</span></label>
                    <div class="mt-3">
                        <div class="form-check form-check-inline custom-radio-narrow">
                            <input
                                required type="radio"
                                class="form-check-input"
                                id="ADSErefusalStraightRefusal{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                                name="ADSErefusalRadio{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                                #ADSERefusalStraightRefusalControl="ngModel"
                                [value]="1"
                                [(ngModel)]="contraventionTest.driverDeclinedToTestTypeId"
                                [ngClass]="{'is-invalid': contraventionTest.driverDeclinedToTestTypeId == null && (ADSERefusalStraightRefusalControl.touched || ADSERefusalFailedToComplyControl.touched || ADSERefusalNoControl.touched || impairmentScreeningModel.isSubmitClicked)}">
                            <label class="form-check-label" 
                                for="ADSErefusalStraightRefusal{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">{{isRoadsideAppeal?"Yes":"Straight refusal"}}</label>
                        </div>
                        <div class="form-check form-check-inline custom-radio-narrow">
                            <input
                                required type="radio"
                                class="form-check-input"
                                id="ADSErefusalFailedToComply{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                                name="ADSErefusalRadio{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                                #ADSERefusalFailedToComplyControl="ngModel"
                                [value]="2"
                                [(ngModel)]="contraventionTest.driverDeclinedToTestTypeId"
                                [ngClass]="{'is-invalid': contraventionTest.driverDeclinedToTestTypeId == null && (ADSERefusalStraightRefusalControl.touched || ADSERefusalFailedToComplyControl.touched || ADSERefusalNoControl.touched || impairmentScreeningModel.isSubmitClicked)}">
                            <label class="form-check-label"
                                for="ADSErefusalFailedToComply{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">Failed to comply</label>
                        </div>
                        <div class="form-check form-check-inline custom-radio-narrow">
                            <input
                                required type="radio"
                                class="form-check-input"
                                id="ADSErefusalNo{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                                name="ADSErefusalRadio{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                                #ADSERefusalNoControl="ngModel"
                                [value]="3"
                                [(ngModel)]="contraventionTest.driverDeclinedToTestTypeId"
                                [ngClass]="{'is-invalid': contraventionTest.driverDeclinedToTestTypeId == null && (ADSERefusalStraightRefusalControl.touched || ADSERefusalFailedToComplyControl.touched|| ADSERefusalNoControl.touched || impairmentScreeningModel.isSubmitClicked)}">
                            <label class="form-check-label"
                                for="ADSErefusalNo{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">No</label>
                        </div>
                        <input
                            required type="radio"
                            style="display:none;"
                            id="ADSErefusalNo{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}Hidden"
                            name="ADSErefusalRadio{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                            #ADSERefusalHiddenControl="ngModel"
                            [value]="null"
                            [(ngModel)]="contraventionTest.driverDeclinedToTestTypeId"
                            [ngClass]="{'is-invalid': contraventionTest.driverDeclinedToTestTypeId == null && (ADSERefusalStraightRefusalControl.touched || ADSERefusalFailedToComplyControl.touched|| ADSERefusalNoControl.touched  || impairmentScreeningModel.isSubmitClicked)}">
                        <div class="invalid-feedback">
                            <div>{{isRoadsideAppeal?"Decline":"Refusal"}} Information is required</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mb-3 row" *ngIf="contraventionTest.driverDeclinedToTestTypeId != DriverDeclinedToTestTypes.StraightRefusal">
                <div class="col-sm-6" *ngIf="contraventionTest.driverDeclinedToTestTypeId == DriverDeclinedToTestTypes.FailedToComply || contraventionTest.driverDeclinedToTestTypeId == DriverDeclinedToTestTypes.No">
                    <label
                        for="timeADSETestStarted{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">Time {{isRoadsideAppeal?"Roadside Appeal":"ADSE"}} Test Started <span class="label-hint">(Required)</span></label>
                    <input
                        required
                        #timeADSETestStartedControl="ngModel"
                        type="text" placeholder="--:--" mask="{{Constants.Mask.TIME_MASK}}" [patterns]="Constants.Mask.TIME_MASK_PATTERNS" [showMaskTyped]="true" [dropSpecialCharacters]="false"
                        pattern="^(([[0|1]\d)|(2[0-3]))[:]([0-5]\d)$"
                        class="form-control"
                        id="timeADSETestStarted{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                        name="timeADSETestStarted{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                        [(ngModel)]="contraventionTest.drugScreeningEquipmentTest.testStartedTime"
                        [ngClass]="{'is-invalid': (timeADSETestStartedControl.touched || impairmentScreeningModel.isSubmitClicked) && timeADSETestStartedControl.invalid}">
                    <div class="invalid-feedback">
                        <div *ngIf="timeADSETestStartedControl.errors?.required">Time {{isRoadsideAppeal?"Roadside Appeal":"ADSE"}} Test Started is required</div>
                        <div *ngIf="!timeADSETestStartedControl.errors?.required">Invalid Time {{isRoadsideAppeal?"Roadside Appeal":"ADSE"}} Test Started</div>
                    </div>
                </div>
                <div class="col-sm-6" *ngIf="contraventionTest.driverDeclinedToTestTypeId == DriverDeclinedToTestTypes.No">
                    <label for="ADSEResults{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">ADSE Results <span class="label-hint">(Required)</span>
                    </label>
                    <mat-select
                        required
                        #ADSEResultsControl="ngModel"
                        class="form-control-multiselect"
                        multiple id="ADSEResults{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                        name="ADSEResults{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                        [(ngModel)]="selectedADSEResultTypes" (selectionChange)="onADSEResultChange($event.value)"
                        [ngClass]="{'is-invalid': (ADSEResultsControl.touched || impairmentScreeningModel.isSubmitClicked) && ADSEResultsControl.invalid}">
                        <mat-option class="mat-primary" *ngFor="let testResultTypeADSE of testResultTypesADSE" [value]="testResultTypeADSE.id">{{testResultTypeADSE.name}}</mat-option>
                    </mat-select>
                    <div class="invalid-feedback">
                        <div>ADSE Results are required</div>
                    </div>
                </div>
            </div>

            <div class="mb-3 row" *ngIf="contraventionTest.driverDeclinedToTestTypeId == DriverDeclinedToTestTypes.StraightRefusal || contraventionTest.driverDeclinedToTestTypeId == DriverDeclinedToTestTypes.No">
                <div class="col-sm-6" *ngIf="selectedADSEResultTypes?.includes(10) && contraventionTest.driverDeclinedToTestTypeId == DriverDeclinedToTestTypes.No">
                    <label for="otherADSResult{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">Specify ‘Other’ Result <span *ngIf="!contraventionTest.driverDeclinedToTestTypeId" class="label-hint">(Required)</span></label>
                    <input [required]="!contraventionTest.driverDeclinedToTestTypeId" #otherADSResultControl="ngModel" maxlength="200" type="text" class="form-control" id="otherADSEResult{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}" name="otherADSEResult{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}" placeholder="Specify ‘Other’ Result" [(ngModel)]="contraventionTest.drugScreeningEquipmentTest.testResultOther"  [ngClass]="{'is-invalid': (otherADSResultControl.touched || impairmentScreeningModel.isSubmitClicked) && otherADSResultControl.invalid}">
                    <div class="invalid-feedback">
                        <div>‘Other’ Result is required</div>
                    </div>
                </div>
                <div class="col-sm-4" *ngIf="contraventionTest.driverDeclinedToTestTypeId == DriverDeclinedToTestTypes.StraightRefusal">
                  <label for="ADSEtimeOfRefusal{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">Time of {{isRoadsideAppeal?"Decline":"Refusal"}} <span class="label-hint">(Required)</span></label>
                  <input required #ADSEtimeOfRefusalControl="ngModel" type="text" placeholder="--:--" mask="{{Constants.Mask.TIME_MASK}}" [patterns]="Constants.Mask.TIME_MASK_PATTERNS" [showMaskTyped]="true" [dropSpecialCharacters]="false"
                  pattern="^(([[0|1]\d)|(2[0-3]))[:]([0-5]\d)$" class="form-control" id="ADSEtimeOfRefusal{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}" name="ADSEtimeOfRefusal{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}" [(ngModel)]="contraventionTest.refusalTime" [ngClass]="{'is-invalid':(ADSEtimeOfRefusalControl.touched || impairmentScreeningModel.isSubmitClicked) && ADSEtimeOfRefusalControl.invalid}">
                  <div class="invalid-feedback">
                      <div *ngIf="ADSEtimeOfRefusalControl.errors?.required">Time of {{isRoadsideAppeal?"Decline":"Refusal"}} is required</div>
                      <div *ngIf="!ADSEtimeOfRefusalControl.errors?.required">Invalid Time of {{isRoadsideAppeal?"Decline":"Refusal"}}</div>
                  </div>
              </div>
            </div>

            <app-impairment-screening-alert
              [ImpairmentScreeningMethodId]="impairmentScreeningModel.ImpairmentScreeningMethodId"
              [DriverDeclinedToTestId]="contraventionTest.driverDeclinedToTestTypeId"></app-impairment-screening-alert>

        </div>
    </div>
</ng-container>

<!-- DRE -->
<ng-container *ngIf="impairmentScreeningModel.ImpairmentScreeningMethodId == 4">
    <div class="card mt-4">
        <div class="card-header">
            {{contraventionTest.testSequence > 0 ? impairmentScreeningModel.impairmentScreeningResultCardLabel + " "  + contraventionTest.testSequence.toString() + " Details" : impairmentScreeningModel.impairmentScreeningResultCardLabel }}
        </div>
        <div class="card-body" *ngIf="isNewDREProcess;else oldDREProcess">
            <div class="mb-3 row">
                <div class="col-sm-6">
                    <label for="timeOfDREDemand{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">Time of {{isRoadsideAppeal?"Roadside Appeal Request":"DRE Demand"}} <span class="label-hint">(Required)</span></label>
                    <input required #timeOfDREDemandControl="ngModel" type="text" placeholder="--:--" mask="{{Constants.Mask.TIME_MASK}}" [patterns]="Constants.Mask.TIME_MASK_PATTERNS" [showMaskTyped]="true" [dropSpecialCharacters]="false"
                    pattern="^(([[0|1]\d)|(2[0-3]))[:]([0-5]\d)$" class="form-control" id="timeOfDREDemand{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}" name="timeOfDREDemand{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}" [(ngModel)]="contraventionTest.drugRecognitionEvaluationTest.testDemandTime" [ngClass]="{'is-invalid': (timeOfDREDemandControl.touched || impairmentScreeningModel.isSubmitClicked) && timeOfDREDemandControl.invalid}">
                    <div class="invalid-feedback">
                        <div *ngIf="timeOfDREDemandControl.errors?.required">Time of {{isRoadsideAppeal?"Roadside Appeal Request":"DRE Demand"}} is required</div>
                        <div *ngIf="!timeOfDREDemandControl.errors?.required">Invalid Time of {{isRoadsideAppeal?"Roadside Appeal Request":"DRE Demand"}}</div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <label>{{isRoadsideAppeal?"Decline?":"Refusal?"}} <span class="label-hint">(Required)</span></label>
                    <div class="mt-3">
                        <div class="form-check form-check-inline custom-radio-narrow">
                            <input
                                required type="radio"
                                class="form-check-input"
                                id="DRErefusalStraightRefusal{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                                name="DRErefusalRadio{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                                #DRERefusalStraightRefusalControl="ngModel"
                                [value]="1"
                                (ngModelChange)="onDRERefusalChange()"
                                [(ngModel)]="contraventionTest.driverDeclinedToTestTypeId"
                                [ngClass]="{'is-invalid': contraventionTest.driverDeclinedToTestTypeId == null && (DRERefusalStraightRefusalControl.touched || DRERefusalFailedToComplyControl.touched || DRERefusalNoControl.touched || impairmentScreeningModel.isSubmitClicked)}">
                            <label class="form-check-label"
                                for="DRErefusalStraightRefusal{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">{{isRoadsideAppeal?"Yes":"Straight refusal"}}</label>
                        </div>
                        <div class="form-check form-check-inline custom-radio-narrow">
                            <input
                                required type="radio"
                                class="form-check-input"
                                id="DRErefusalFailedToComply{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                                name="DRErefusalRadio{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                                #DRERefusalFailedToComplyControl="ngModel"
                                [value]="2"
                                (ngModelChange)="onDRERefusalChange()"
                                [(ngModel)]="contraventionTest.driverDeclinedToTestTypeId"
                                [ngClass]="{'is-invalid': contraventionTest.driverDeclinedToTestTypeId == null && (DRERefusalStraightRefusalControl.touched || DRERefusalFailedToComplyControl.touched || DRERefusalNoControl.touched || impairmentScreeningModel.isSubmitClicked)}">
                            <label class="form-check-label"
                                for="DRErefusalFailedToComply{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">Failed to comply</label>
                        </div>
                        <div class="form-check form-check-inline custom-radio-narrow">
                            <input
                                required type="radio"
                                class="form-check-input"
                                id="DRErefusalNo{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                                name="DRErefusalRadio{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                                #DRERefusalNoControl="ngModel"
                                [value]="3"
                                (ngModelChange)="onDRERefusalChange()"
                                [(ngModel)]="contraventionTest.driverDeclinedToTestTypeId"
                                [ngClass]="{'is-invalid': contraventionTest.driverDeclinedToTestTypeId == null && (DRERefusalStraightRefusalControl.touched || DRERefusalFailedToComplyControl.touched || DRERefusalNoControl.touched || impairmentScreeningModel.isSubmitClicked)}">
                            <label class="form-check-label"
                                for="DRErefusalNo{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">No</label>
                        </div>
                        <input
                            required type="radio"
                            style="display:none;"
                            id="DRErefusalNo{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}Hidden"
                            name="DRErefusalRadio{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                            #DRERefusalHiddenControl="ngModel"
                            [value]="null" [(ngModel)]="contraventionTest.driverDeclinedToTestTypeId"
                            [ngClass]="{'is-invalid': contraventionTest.driverDeclinedToTestTypeId == null && (DRERefusalStraightRefusalControl.touched || DRERefusalNoControl.touched || impairmentScreeningModel.isSubmitClicked)}">
                        <div class="invalid-feedback">
							<div>{{isRoadsideAppeal?"Decline":"Refusal"}} Information is required</div>
						</div>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="contraventionTest.driverDeclinedToTestTypeId">
                <div class="mb-3 row" *ngIf="contraventionTest.driverDeclinedToTestTypeId == DriverDeclinedToTestTypes.StraightRefusal || contraventionTest.driverDeclinedToTestTypeId == DriverDeclinedToTestTypes.FailedToComply">
                    <div class="col-12">
                        <label *ngIf="contraventionTest.driverDeclinedToTestTypeId == DriverDeclinedToTestTypes.StraightRefusal">{{isRoadsideAppeal?"Declined":"Refused"}} <span class="label-hint">(Required)</span></label>
                        <label *ngIf="contraventionTest.driverDeclinedToTestTypeId == DriverDeclinedToTestTypes.FailedToComply">Filed to Comply with: <span class="label-hint">(Required)</span></label>
                        <div class="mt-3">
                            <div class="form-check form-check-inline custom-radio-wide">
                                <input
                                    required type="radio"
                                    class="form-check-input"
                                    id="BreathSample{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                                    name="DRErefusedRadio{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                                    #BreathSampleControl="ngModel"
                                    [value]="1"
                                    (ngModelChange)="onDRERefusalTypeChange($event)"
                                    [(ngModel)]="contraventionTest.drugRecognitionEvaluationTest.refusalOrFailedClassificationId"
                                    [ngClass]="{'is-invalid': contraventionTest.drugRecognitionEvaluationTest.refusalOrFailedClassificationId == null && (BreathSampleControl.touched || DREProcessControl.touched || ToxicologicalSampleControl.touched || impairmentScreeningModel.isSubmitClicked)}">
                                <label class="form-check-label"
                                    for="BreathSample{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">Breath sample</label>
                            </div>
                            <div class="form-check form-check-inline custom-radio-wide">
                                <input
                                    required type="radio"
                                    class="form-check-input"
                                    id="DREProcess{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                                    name="DRErefusedRadio{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                                    #DREProcessControl="ngModel"
                                    [value]="2"
                                    (ngModelChange)="onDRERefusalTypeChange($event)"
                                    [(ngModel)]="contraventionTest.drugRecognitionEvaluationTest.refusalOrFailedClassificationId"
                                    [ngClass]="{'is-invalid': contraventionTest.drugRecognitionEvaluationTest.refusalOrFailedClassificationId == null && (BreathSampleControl.touched || DREProcessControl.touched || ToxicologicalSampleControl.touched || impairmentScreeningModel.isSubmitClicked)}">
                                <label class="form-check-label"
                                    for="DREProcess{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">DRE process</label>
                            </div>
                            <div class="form-check form-check-inline custom-radio-wide">
                                <input
                                    required type="radio"
                                    class="form-check-input"
                                    id="ToxicologicalSample{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                                    name="DRErefusedRadio{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                                    #ToxicologicalSampleControl="ngModel"
                                    [value]="3"
                                    (ngModelChange)="onDRERefusalTypeChange($event)"
                                    [(ngModel)]="contraventionTest.drugRecognitionEvaluationTest.refusalOrFailedClassificationId"
                                    [ngClass]="{'is-invalid': contraventionTest.drugRecognitionEvaluationTest.refusalOrFailedClassificationId == null && (BreathSampleControl.touched || DREProcessControl.touched || ToxicologicalSampleControl.touched || impairmentScreeningModel.isSubmitClicked)}">
                                <label class="form-check-label"
                                    for="ToxicologicalSample{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">Toxicological sample</label>
                            </div>
                            <input
                                required type="radio"
                                style="display:none;"
                                id="DRErefused{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}Hidden"
                                name="DRErefusedRadio{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                                #DRERefusedHiddenControl="ngModel"
                                [value]="null" [(ngModel)]="contraventionTest.drugRecognitionEvaluationTest.refusalOrFailedClassificationId"
                                [ngClass]="{'is-invalid': contraventionTest.drugRecognitionEvaluationTest.refusalOrFailedClassificationId == null && (BreathSampleControl.touched || DREProcessControl.touched || ToxicologicalSampleControl.touched || impairmentScreeningModel.isSubmitClicked)}">
                            <div class="invalid-feedback">
                                <div>{{isRoadsideAppeal?"Decline":"Refusal"}} Information is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mb-3 row">
                    <div class="col-12">
                        <label>Breath {{isRoadsideAppeal?"Request":"Demand"}} (Step 1) Made? <span class="label-hint">(Required)</span></label>
                        <div class="mt-3">
                            <div class="form-check form-check-inline custom-radio-wide">
                                <input
                                    required type="radio"
                                    class="form-check-input"
                                    id="BreathDemandYes{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                                    name="BreathDemandRadio{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                                    #BreathDemandYesControl="ngModel"
                                    [value]="true"
                                    (ngModelChange)="onBreathDemandChange()"
                                    [(ngModel)]="contraventionTest.drugRecognitionEvaluationTest.isBreathDemandMade"
                                    [ngClass]="{'is-invalid': contraventionTest.drugRecognitionEvaluationTest.isBreathDemandMade == null && (BreathDemandYesControl.touched || BreathDemandNoControl.touched || impairmentScreeningModel.isSubmitClicked)}">
                                <label class="form-check-label"
                                    for="BreathDemandYes{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">Yes</label>
                            </div>
                            <div class="form-check form-check-inline custom-radio-wide">
                                <input
                                    required type="radio"
                                    class="form-check-input"
                                    id="BreathDemandNo{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                                    name="BreathDemandRadio{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                                    #BreathDemandNoControl="ngModel"
                                    [value]="false"
                                    [disabled]="contraventionTest.driverDeclinedToTestTypeId == DriverDeclinedToTestTypes.FailedToComply && contraventionTest.drugRecognitionEvaluationTest.refusalOrFailedClassificationId == RefusalOrFailedClassification.BreathSample"
                                    (ngModelChange)="onBreathDemandChange()"
                                    [(ngModel)]="contraventionTest.drugRecognitionEvaluationTest.isBreathDemandMade"
                                    [ngClass]="{'is-invalid': contraventionTest.drugRecognitionEvaluationTest.isBreathDemandMade == null && (BreathDemandYesControl.touched || BreathDemandNoControl.touched || impairmentScreeningModel.isSubmitClicked)}">
                                <label class="form-check-label"
                                    for="BreathDemandNo{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">No</label>
                            </div>
                            <input
                                required type="radio"
                                style="display:none;"
                                id="BreathDemand{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}Hidden"
                                name="BreathDemandRadio{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                                #BreathDemandHiddenControl="ngModel"
                                [value]="null" [(ngModel)]="contraventionTest.drugRecognitionEvaluationTest.isBreathDemandMade"
                                [ngClass]="{'is-invalid': contraventionTest.drugRecognitionEvaluationTest.isBreathDemandMade == null && (BreathDemandYesControl.touched || BreathDemandNoControl.touched || impairmentScreeningModel.isSubmitClicked)}">
                            <div class="invalid-feedback">
                                <div>Selection is required</div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Breath Information -->
                <ng-container *ngIf="contraventionTest.drugRecognitionEvaluationTest.isBreathDemandMade == true">
                    <div class="mb-3 row">
                        <div class="col-sm-6">
                            <label
                                for="timeBreathDemand{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">Time of Breath {{isRoadsideAppeal?"Request":"Demand"}} <span class="label-hint">(Required)</span></label>
                            <input
                                required #timeBreathDemandControl="ngModel"
                                type="text" placeholder="--:--" mask="{{Constants.Mask.TIME_MASK}}" [patterns]="Constants.Mask.TIME_MASK_PATTERNS" [showMaskTyped]="true" [dropSpecialCharacters]="false"
                                pattern="^(([[0|1]\d)|(2[0-3]))[:]([0-5]\d)$"
                                class="form-control" id="timeBreathRequest{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                                name="timeBreathRequest{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                                [(ngModel)]="contraventionTest.drugRecognitionEvaluationTest.breathDemandTime"
                                [ngClass]="{'is-invalid': (timeBreathDemandControl.touched || impairmentScreeningModel.isSubmitClicked) && timeBreathDemandControl.invalid}">
                            <div class="invalid-feedback">
                                <div *ngIf="timeBreathDemandControl.errors?.required">Time of Breath {{isRoadsideAppeal?"Request":"Demand"}} is required</div>
                                <div *ngIf="!timeBreathDemandControl.errors?.required">Invalid Time Time of Breath {{isRoadsideAppeal?"Request":"Demand"}}</div>
                            </div>
                        </div>
                        <div class="col-sm-6" *ngIf="contraventionTest.driverDeclinedToTestTypeId == DriverDeclinedToTestTypes.StraightRefusal && contraventionTest.drugRecognitionEvaluationTest.refusalOrFailedClassificationId == RefusalOrFailedClassification.BreathSample">
                            <label for="DREtimeOfRefusal{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">Time of {{isRoadsideAppeal?"Decline":"Refusal"}}
                                <span class="label-hint">(Required)</span></label>
                            <input
                                required #DREtimeOfRefusalControl="ngModel"
                                type="text" placeholder="--:--" mask="{{Constants.Mask.TIME_MASK}}" [patterns]="Constants.Mask.TIME_MASK_PATTERNS" [showMaskTyped]="true" [dropSpecialCharacters]="false"
                                pattern="^(([[0|1]\d)|(2[0-3]))[:]([0-5]\d)$" class="form-control"
                                id="DREtimeOfRefusal{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                                name="DREtimeOfRefusal{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                                [(ngModel)]="contraventionTest.refusalTime"
                                [ngClass]="{'is-invalid':(DREtimeOfRefusalControl.touched || impairmentScreeningModel.isSubmitClicked) && DREtimeOfRefusalControl.invalid}">
                            <div class="invalid-feedback">
                                <div *ngIf="DREtimeOfRefusalControl.errors?.required">Time of {{isRoadsideAppeal?"Decline":"Refusal"}} is required</div>
                                <div *ngIf="!DREtimeOfRefusalControl.errors?.required">Invalid Time of {{isRoadsideAppeal?"Decline":"Refusal"}}</div>
                            </div>
                        </div>
                        <div class="col-sm-6" *ngIf="!((contraventionTest.driverDeclinedToTestTypeId == DriverDeclinedToTestTypes.StraightRefusal || contraventionTest.driverDeclinedToTestTypeId == DriverDeclinedToTestTypes.FailedToComply) && contraventionTest.drugRecognitionEvaluationTest.refusalOrFailedClassificationId == RefusalOrFailedClassification.BreathSample)">
                            <label for="breathTestResult{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">Breath Test Result <span class="label-hint">(Required)</span></label>
                            <input required #breathTestResultControl="ngModel" maxlength="255" type="text" class="form-control" 
                            id="breathTestResult{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}" 
                            name="breathTestResult{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}" 
                            [(ngModel)]="contraventionTest.drugRecognitionEvaluationTest.breathTestResult" 
                            [ngClass]="{'is-invalid': (breathTestResultControl.touched || impairmentScreeningModel.isSubmitClicked) && breathTestResultControl.invalid}">
                            <div class="invalid-feedback">
                                <div>Breath Test Result is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="mb-3" *ngIf="!(contraventionTest.driverDeclinedToTestTypeId == DriverDeclinedToTestTypes.StraightRefusal && contraventionTest.drugRecognitionEvaluationTest.refusalOrFailedClassificationId == RefusalOrFailedClassification.BreathSample)">
                        <app-impairment-screening-alert
                            ImpairmentScreeningMethodId="{{TestTypes.AI}}"
                            DriverDeclinedToTestId="{{DriverDeclinedToTestTypes.No}}"></app-impairment-screening-alert>
                    </div>
                </ng-container>

                <!-- DRE Information -->
                <ng-container *ngIf="contraventionTest.driverDeclinedToTestTypeId == DriverDeclinedToTestTypes.No || (contraventionTest.driverDeclinedToTestTypeId == DriverDeclinedToTestTypes.FailedToComply && contraventionTest.drugRecognitionEvaluationTest.refusalOrFailedClassificationId != RefusalOrFailedClassification.BreathSample) || (contraventionTest.driverDeclinedToTestTypeId == DriverDeclinedToTestTypes.StraightRefusal && contraventionTest.drugRecognitionEvaluationTest.refusalOrFailedClassificationId == RefusalOrFailedClassification.ToxicologicalSample)">
                    <div class="mb-3 row">
                        <div class="col-sm-6">
                            <label
                                for="timeDRETestStarted{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">Time {{isRoadsideAppeal?"Roadside Appeal":"DRE"}} Test Started <span class="label-hint">(Required)</span></label>
                            <input
                                required #timeDRETestStartedControl="ngModel"
                                type="text" placeholder="--:--" mask="{{Constants.Mask.TIME_MASK}}" [patterns]="Constants.Mask.TIME_MASK_PATTERNS" [showMaskTyped]="true" [dropSpecialCharacters]="false"
                                pattern="^(([[0|1]\d)|(2[0-3]))[:]([0-5]\d)$"
                                class="form-control" id="timeDRETestStarted{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                                name="timeDRETestStarted{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                                [(ngModel)]="contraventionTest.drugRecognitionEvaluationTest.testStartedTime"
                                [ngClass]="{'is-invalid': (timeDRETestStartedControl.touched || impairmentScreeningModel.isSubmitClicked) && timeDRETestStartedControl.invalid}">
                            <div class="invalid-feedback">
                                <div *ngIf="timeDRETestStartedControl.errors?.required">Time {{isRoadsideAppeal?"Roadside Appeal":"DRE"}} Test Started is required</div>
                                <div *ngIf="!timeDRETestStartedControl.errors?.required">Invalid Time {{isRoadsideAppeal?"Roadside Appeal":"DRE"}} Test Started</div>
                            </div>
                        </div>
                    </div>
                    <div class="mb-3">
                        <app-impairment-screening-alert
                            [ImpairmentScreeningMethodId]="impairmentScreeningModel.ImpairmentScreeningMethodId"
                            [DriverDeclinedToTestId]="3"></app-impairment-screening-alert>
                    </div>
                </ng-container>

                <!-- Toxicological Information -->
                <ng-container *ngIf="contraventionTest.driverDeclinedToTestTypeId == DriverDeclinedToTestTypes.No || ((contraventionTest.driverDeclinedToTestTypeId == DriverDeclinedToTestTypes.StraightRefusal || contraventionTest.driverDeclinedToTestTypeId == DriverDeclinedToTestTypes.FailedToComply) && contraventionTest.drugRecognitionEvaluationTest.refusalOrFailedClassificationId == RefusalOrFailedClassification.ToxicologicalSample)">
                    <div class="mb-3 row">
                        <div class="col-12">
                            <label>Type of Sample {{isRoadsideAppeal?"Requested":"Demanded"}}? <span class="label-hint">(Required)</span></label>
                            <div class="mt-3">
                                <div class="form-check form-check-inline custom-radio-wide">
                                    <input
                                        required type="radio"
                                        class="form-check-input"
                                        id="SampleDemandBlood{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                                        name="SampleDemandRadio{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                                        #SampleDemandBloodControl="ngModel"
                                        [value]="SampleClassification.Blood"
                                        [(ngModel)]="contraventionTest.drugRecognitionEvaluationTest.sampleClassificationId"
                                        [ngClass]="{'is-invalid': contraventionTest.drugRecognitionEvaluationTest.sampleClassificationId == null && (SampleDemandBloodControl.touched || SampleDemandUrineControl.touched || impairmentScreeningModel.isSubmitClicked)}">
                                    <label class="form-check-label"
                                        for="SampleDemandBlood{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">Blood</label>
                                </div>
                                <div class="form-check form-check-inline custom-radio-wide">
                                    <input
                                        required type="radio"
                                        class="form-check-input"
                                        id="SampleDemandUrine{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                                        name="SampleDemandRadio{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                                        #SampleDemandUrineControl="ngModel"
                                        [value]="SampleClassification.Urine"
                                        [(ngModel)]="contraventionTest.drugRecognitionEvaluationTest.sampleClassificationId"
                                        [ngClass]="{'is-invalid': contraventionTest.drugRecognitionEvaluationTest.sampleClassificationId == null && (SampleDemandBloodControl.touched || SampleDemandUrineControl.touched || impairmentScreeningModel.isSubmitClicked)}">
                                    <label class="form-check-label"
                                        for="SampleDemandUrine{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">Urine</label>
                                </div>
                                <input
                                    required type="radio"
                                    style="display:none;"
                                    id="SampleDemand{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}Hidden"
                                    name="SampleDemandRadio{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                                    #SampleDemandHiddenControl="ngModel"
                                    [value]="null" [(ngModel)]="contraventionTest.drugRecognitionEvaluationTest.sampleClassificationId"
                                    [ngClass]="{'is-invalid': contraventionTest.drugRecognitionEvaluationTest.sampleClassificationId == null && (SampleDemandBloodControl.touched || SampleDemandUrineControl.touched || impairmentScreeningModel.isSubmitClicked)}">
                                <div class="invalid-feedback">
                                    <div>Selection is required</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ng-container *ngIf="contraventionTest.drugRecognitionEvaluationTest.sampleClassificationId">
                        <div class="mb-3 row">
                            <div class="col-sm-6">
                                <label
                                    for="timeSampleDemand{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">Time {{contraventionTest.drugRecognitionEvaluationTest.sampleClassificationId == SampleClassification.Blood?"Blood":"Urine"}} {{isRoadsideAppeal?"Request":"Demand"}} <span class="label-hint">(Required)</span></label>
                                <input
                                    required #timeSampleDemandControl="ngModel"
                                    type="text" placeholder="--:--" mask="{{Constants.Mask.TIME_MASK}}" [patterns]="Constants.Mask.TIME_MASK_PATTERNS" [showMaskTyped]="true" [dropSpecialCharacters]="false"
                                    pattern="^(([[0|1]\d)|(2[0-3]))[:]([0-5]\d)$"
                                    class="form-control" id="timeSampleDemand{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                                    name="timeSampleDemand{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                                    [(ngModel)]="contraventionTest.drugRecognitionEvaluationTest.sampleDemandTime"
                                    [ngClass]="{'is-invalid': (timeSampleDemandControl.touched || impairmentScreeningModel.isSubmitClicked) && timeSampleDemandControl.invalid}">
                                <div class="invalid-feedback">
                                    <div *ngIf="timeSampleDemandControl.errors?.required">Time {{contraventionTest.drugRecognitionEvaluationTest.sampleClassificationId == SampleClassification.Blood?"Blood":"Urine"}} {{isRoadsideAppeal?"Request":"Demand"}} is required</div>
                                    <div *ngIf="!timeSampleDemandControl.errors?.required">Invalid Time {{contraventionTest.drugRecognitionEvaluationTest.sampleClassificationId == SampleClassification.Blood?"Blood":"Urine"}} {{isRoadsideAppeal?"Request":"Demand"}}</div>
                                </div>
                            </div>
                            <div class="col-sm-6" *ngIf="contraventionTest.driverDeclinedToTestTypeId == DriverDeclinedToTestTypes.StraightRefusal && contraventionTest.drugRecognitionEvaluationTest.refusalOrFailedClassificationId == RefusalOrFailedClassification.ToxicologicalSample">
                                <label for="DREtimeOfRefusal{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">Time of {{isRoadsideAppeal?"Decline":"Refusal"}}
                                    <span class="label-hint">(Required)</span></label>
                                <input
                                    required #DREtimeOfRefusalControl="ngModel"
                                    type="text" placeholder="--:--" mask="{{Constants.Mask.TIME_MASK}}" [patterns]="Constants.Mask.TIME_MASK_PATTERNS" [showMaskTyped]="true" [dropSpecialCharacters]="false"
                                    pattern="^(([[0|1]\d)|(2[0-3]))[:]([0-5]\d)$" class="form-control"
                                    id="DREtimeOfRefusal{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                                    name="DREtimeOfRefusal{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                                    [(ngModel)]="contraventionTest.refusalTime"
                                    [ngClass]="{'is-invalid':(DREtimeOfRefusalControl.touched || impairmentScreeningModel.isSubmitClicked) && DREtimeOfRefusalControl.invalid}">
                                <div class="invalid-feedback">
                                    <div *ngIf="DREtimeOfRefusalControl.errors?.required">Time of {{isRoadsideAppeal?"Decline":"Refusal"}} is required</div>
                                    <div *ngIf="!DREtimeOfRefusalControl.errors?.required">Invalid Time of {{isRoadsideAppeal?"Decline":"Refusal"}}</div>
                                </div>
                            </div>
                        </div>
                        <ng-container *ngIf="contraventionTest.driverDeclinedToTestTypeId == DriverDeclinedToTestTypes.No">
                            <div class="mb-3 row">
                                <div class="col-sm-6">
                                    <label
                                        for="timeSampleCollected{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">Sample Time <span class="label-hint">(Required)</span></label>
                                    <input
                                        required #timeSampleCollectedControl="ngModel"
                                        type="text" placeholder="--:--" mask="{{Constants.Mask.TIME_MASK}}" [patterns]="Constants.Mask.TIME_MASK_PATTERNS" [showMaskTyped]="true" [dropSpecialCharacters]="false"
                                        pattern="^(([[0|1]\d)|(2[0-3]))[:]([0-5]\d)$"
                                        class="form-control" id="timeSampleCollected{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                                        name="timeSampleCollected{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                                        [(ngModel)]="contraventionTest.drugRecognitionEvaluationTest.sampleCollectionTime"
                                        [ngClass]="{'is-invalid': (timeSampleCollectedControl.touched || impairmentScreeningModel.isSubmitClicked) && timeSampleCollectedControl.invalid}">
                                    <div class="invalid-feedback">
                                        <div *ngIf="timeSampleCollectedControl.errors?.required">Sample Time is required</div>
                                        <div *ngIf="!timeSampleCollectedControl.errors?.required">Invalid Sample Time</div>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <label for="sampleLabType{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">Sent to Provincial or National Lab? <span class="label-hint">(Required)</span></label>
                                    <select
                                      [appSelectValidator]="true"
                                      #sampleLabTypeControl="ngModel"
                                      class="form-select"
                                      id="sampleLabType{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                                      name="sampleLabType{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                                      [(ngModel)]="contraventionTest.drugRecognitionEvaluationTest.labTypeId"
                                      [ngClass]="{'is-invalid': (sampleLabTypeControl.touched || impairmentScreeningModel.isSubmitClicked) && sampleLabTypeControl.errors?.defaultSelected}">
                                        <option [value]="null">Select Lab Type</option>
                                        <option *ngFor="let labType of labTypes" [value]="labType.id">{{labType.name}}</option>
                                    </select>
                                    <div class="invalid-feedback">
                                        <div>Lab Type is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="mb-3">
                                <app-impairment-screening-alert
                                    ImpairmentScreeningMethodId="{{TestTypes.Blood}}"
                                    [DriverDeclinedToTestId]="contraventionTest.driverDeclinedToTestTypeId"></app-impairment-screening-alert>
                            </div>
                        </ng-container>
                    </ng-container>
                </ng-container>

                <!-- Refusal Information -->
                <ng-container *ngIf="contraventionTest.driverDeclinedToTestTypeId == DriverDeclinedToTestTypes.StraightRefusal && ((contraventionTest.drugRecognitionEvaluationTest.refusalOrFailedClassificationId == RefusalOrFailedClassification.BreathSample && contraventionTest.drugRecognitionEvaluationTest.isBreathDemandMade == false) || contraventionTest.drugRecognitionEvaluationTest.refusalOrFailedClassificationId == RefusalOrFailedClassification.DREProcess)">
                    <div class="mb-3 row">
                        <div class="col-sm-6">
                            <label for="DREtimeOfRefusal{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">Time of {{isRoadsideAppeal?"Decline":"Refusal"}}
                                <span class="label-hint">(Required)</span></label>
                            <input
                                required #DREtimeOfRefusalControl="ngModel"
                                type="text" placeholder="--:--" mask="{{Constants.Mask.TIME_MASK}}" [patterns]="Constants.Mask.TIME_MASK_PATTERNS" [showMaskTyped]="true" [dropSpecialCharacters]="false"
                                pattern="^(([[0|1]\d)|(2[0-3]))[:]([0-5]\d)$" class="form-control"
                                id="DREtimeOfRefusal{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                                name="DREtimeOfRefusal{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                                [(ngModel)]="contraventionTest.refusalTime"
                                [ngClass]="{'is-invalid':(DREtimeOfRefusalControl.touched || impairmentScreeningModel.isSubmitClicked) && DREtimeOfRefusalControl.invalid}">
                            <div class="invalid-feedback">
                                <div *ngIf="DREtimeOfRefusalControl.errors?.required">Time of {{isRoadsideAppeal?"Decline":"Refusal"}} is required</div>
                                <div *ngIf="!DREtimeOfRefusalControl.errors?.required">Invalid Time of {{isRoadsideAppeal?"Decline":"Refusal"}}</div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </div>
        <ng-template #oldDREProcess>
            <div class="card-body">
                <div class="mb-3 row">
                    <div class="col-sm-6">
                        <label for="timeOfDREDemand{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">Time of {{isRoadsideAppeal?"Roadside Appeal Request":"DRE Demand"}} <span class="label-hint">(Required)</span></label>
                        <input required #timeOfDREDemandControl="ngModel" type="text" placeholder="--:--" mask="{{Constants.Mask.TIME_MASK}}" [patterns]="Constants.Mask.TIME_MASK_PATTERNS" [showMaskTyped]="true" [dropSpecialCharacters]="false"
                        pattern="^(([[0|1]\d)|(2[0-3]))[:]([0-5]\d)$" class="form-control" id="timeOfDREDemand{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}" name="timeOfDREDemand{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}" [(ngModel)]="contraventionTest.drugRecognitionEvaluationTest.testDemandTime" [ngClass]="{'is-invalid': (timeOfDREDemandControl.touched || impairmentScreeningModel.isSubmitClicked) && timeOfDREDemandControl.invalid}">
                        <div class="invalid-feedback">
                            <div *ngIf="timeOfDREDemandControl.errors?.required">Time of {{isRoadsideAppeal?"Roadside Appeal Request":"DRE Demand"}} is required</div>
                            <div *ngIf="!timeOfDREDemandControl.errors?.required">Invalid Time of {{isRoadsideAppeal?"Roadside Appeal Request":"DRE Demand"}}</div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <label>{{isRoadsideAppeal?"Decline?":"Refusal?"}} <span class="label-hint">(Required)</span></label>
                        <div class="mt-3">
                            <div class="form-check form-check-inline custom-radio-narrow">
                                <input
                                    required type="radio"
                                    class="form-check-input"
                                    id="DRErefusalStraightRefusal{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                                    name="DRErefusalRadio{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                                    #DRERefusalStraightRefusalControl="ngModel"
                                    [value]="1"
                                    [(ngModel)]="contraventionTest.driverDeclinedToTestTypeId"
                                    [ngClass]="{'is-invalid': contraventionTest.driverDeclinedToTestTypeId == null && (DRERefusalStraightRefusalControl.touched || DRERefusalFailedToComplyControl.touched || DRERefusalNoControl.touched || impairmentScreeningModel.isSubmitClicked)}">
                                <label class="form-check-label"
                                    for="DRErefusalStraightRefusal{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">{{isRoadsideAppeal?"Yes":"Straight refusal"}}</label>
                            </div>
                            <div class="form-check form-check-inline custom-radio-narrow">
                                <input
                                    required type="radio"
                                    class="form-check-input"
                                    id="DRErefusalFailedToComply{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                                    name="DRErefusalRadio{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                                    #DRERefusalFailedToComplyControl="ngModel"
                                    [value]="2"
                                    [(ngModel)]="contraventionTest.driverDeclinedToTestTypeId"
                                    [ngClass]="{'is-invalid': contraventionTest.driverDeclinedToTestTypeId == null && (DRERefusalStraightRefusalControl.touched || DRERefusalFailedToComplyControl.touched || DRERefusalNoControl.touched || impairmentScreeningModel.isSubmitClicked)}">
                                <label class="form-check-label"
                                    for="DRErefusalFailedToComply{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">Failed to comply</label>
                            </div>
                            <div class="form-check form-check-inline custom-radio-narrow">
                                <input
                                    required type="radio"
                                    class="form-check-input"
                                    id="DRErefusalNo{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                                    name="DRErefusalRadio{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                                    #DRERefusalNoControl="ngModel"
                                    [value]="3"
                                    [(ngModel)]="contraventionTest.driverDeclinedToTestTypeId"
                                    [ngClass]="{'is-invalid': contraventionTest.driverDeclinedToTestTypeId == null && (DRERefusalStraightRefusalControl.touched || DRERefusalFailedToComplyControl.touched || DRERefusalNoControl.touched || impairmentScreeningModel.isSubmitClicked)}">
                                <label class="form-check-label"
                                    for="DRErefusalNo{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">No</label>
                            </div>
                            <input
                                required type="radio"
                                style="display:none;"
                                id="DRErefusalNo{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}Hidden"
                                name="DRErefusalRadio{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                                #DRERefusalHiddenControl="ngModel"
                                [value]="null" [(ngModel)]="contraventionTest.driverDeclinedToTestTypeId"
                                [ngClass]="{'is-invalid': contraventionTest.driverDeclinedToTestTypeId == null && (DRERefusalStraightRefusalControl.touched || DRERefusalNoControl.touched || impairmentScreeningModel.isSubmitClicked)}">
                            <div class="invalid-feedback">
                                <div>{{isRoadsideAppeal?"Decline":"Refusal"}} Information is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mb-3 row">
                    <div class="col-sm-6" *ngIf="contraventionTest.driverDeclinedToTestTypeId == DriverDeclinedToTestTypes.FailedToComply || contraventionTest.driverDeclinedToTestTypeId == DriverDeclinedToTestTypes.No">
                        <label
                            for="timeDRETestStarted{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">Time {{isRoadsideAppeal?"Roadside Appeal":"DRE"}} Test Started <span class="label-hint">(Required)</span></label>
                        <input
                            required #timeDRETestStartedControl="ngModel"
                            type="text" placeholder="--:--" mask="{{Constants.Mask.TIME_MASK}}" [patterns]="Constants.Mask.TIME_MASK_PATTERNS" [showMaskTyped]="true" [dropSpecialCharacters]="false"
                            pattern="^(([[0|1]\d)|(2[0-3]))[:]([0-5]\d)$"
                            class="form-control" id="timeDRETestStarted{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                            name="timeDRETestStarted{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                            [(ngModel)]="contraventionTest.drugRecognitionEvaluationTest.testStartedTime"
                            [ngClass]="{'is-invalid': (timeDRETestStartedControl.touched || impairmentScreeningModel.isSubmitClicked) && timeDRETestStartedControl.invalid}">
                        <div class="invalid-feedback">
                            <div *ngIf="timeDRETestStartedControl.errors?.required">Time {{isRoadsideAppeal?"Roadside Appeal":"DRE"}} Test Started is required</div>
                            <div *ngIf="!timeDRETestStartedControl.errors?.required">Invalid Time {{isRoadsideAppeal?"Roadside Appeal":"DRE"}} Test Started</div>
                        </div>
                    </div>
                    <div class="col-sm-4" *ngIf="contraventionTest.driverDeclinedToTestTypeId == DriverDeclinedToTestTypes.StraightRefusal">
                        <label for="DREtimeOfRefusal{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">Time of {{isRoadsideAppeal?"Decline":"Refusal"}}
                            <span class="label-hint">(Required)</span></label>
                        <input
                            required #DREtimeOfRefusalControl="ngModel"
                            type="text" placeholder="--:--" mask="{{Constants.Mask.TIME_MASK}}" [patterns]="Constants.Mask.TIME_MASK_PATTERNS" [showMaskTyped]="true" [dropSpecialCharacters]="false"
                            pattern="^(([[0|1]\d)|(2[0-3]))[:]([0-5]\d)$" class="form-control"
                            id="DREtimeOfRefusal{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                            name="DREtimeOfRefusal{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                            [(ngModel)]="contraventionTest.refusalTime"
                            [ngClass]="{'is-invalid':(DREtimeOfRefusalControl.touched || impairmentScreeningModel.isSubmitClicked) && DREtimeOfRefusalControl.invalid}">
                        <div class="invalid-feedback">
                            <div *ngIf="DREtimeOfRefusalControl.errors?.required">Time of {{isRoadsideAppeal?"Decline":"Refusal"}} is required</div>
                            <div *ngIf="!DREtimeOfRefusalControl.errors?.required">Invalid Time of {{isRoadsideAppeal?"Decline":"Refusal"}}</div>
                        </div>
                    </div>
                </div>

                <app-impairment-screening-alert
                [ImpairmentScreeningMethodId]="impairmentScreeningModel.ImpairmentScreeningMethodId"
                [DriverDeclinedToTestId]="contraventionTest.driverDeclinedToTestTypeId"></app-impairment-screening-alert>
            </div>
        </ng-template>
    </div>
</ng-container>

<!-- AI -->
<ng-container *ngIf="impairmentScreeningModel.ImpairmentScreeningMethodId == 5">
    <div class="card mt-4">
        <div class="card-header">
            {{contraventionTest.testSequence > 0 ? impairmentScreeningModel.impairmentScreeningResultCardLabel + " "  + contraventionTest.testSequence.toString() + " Details" : impairmentScreeningModel.impairmentScreeningResultCardLabel }}
        </div>
        <div class="card-body">
            <div class="mb-3 row">
                <div class="col-sm-6">
                    <label for="AIModel{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">AI Model
                        <span class="label-hint">(Required)</span></label>
                    <select
                        [appSelectValidator]="true" #AIModelControl="ngModel" class="form-select" id="AIModel{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}" name="AIModel{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}" [(ngModel)]="contraventionTest.approvedInstrumentTest.testModelTypeId" (change)="onAIModelChange($event.target.value)" [ngClass]="{'is-invalid': (AIModelControl.touched || impairmentScreeningModel.isSubmitClicked) && AIModelControl.errors?.defaultSelected}">
                        <option value="0">Select an AI Model</option>
                        <option *ngFor="let testModelTypeAI of testModelTypesAI"
                          [value]="testModelTypeAI.id">{{testModelTypeAI.name}}</option>
                    </select>
                    <div class="invalid-feedback">
                        <div>AI Model is required</div>
                    </div>
                </div>
                <div class="col-sm-6" *ngIf="contraventionTest.approvedInstrumentTest.testModelTypeId == 7">
                    <label for="otherAIModel{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">Specify ‘Other’ AI <span class="label-hint">(Required)</span></label>
                    <input required #otherAIModelControl="ngModel" maxlength="200" type="text" class="form-control" id="otherAIModel{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}" name="otherAIModel{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}" placeholder="Specify ‘Other’ AI" [(ngModel)]="contraventionTest.approvedInstrumentTest.testModelOther" [ngClass]="{'is-invalid': (otherAIModelControl.touched || impairmentScreeningModel.isSubmitClicked) && otherAIModelControl.invalid}">
                    <div class="invalid-feedback">
                        <div>‘Other’ AI is required</div>
                    </div>
                </div>
            </div>

            <div class="mb-3 row">
                <div class="col-sm-6">
                    <label for="timeOfFirstBreathAIDemand{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">Time of {{isRoadsideAppeal?"Roadside Appeal Request":"1st Breath AI Demand"}}
                      <span class="label-hint">(Required)</span>
                    </label>
                    <input required #timeOfFirstBreathAIDemandControl="ngModel"
                      type="text" placeholder="--:--" mask="{{Constants.Mask.TIME_MASK}}" [patterns]="Constants.Mask.TIME_MASK_PATTERNS" [showMaskTyped]="true" [dropSpecialCharacters]="false"
                      pattern="^(([[0|1]\d)|(2[0-3]))[:]([0-5]\d)$"
                      class="form-control" id="timeOfFirstBreathAIDemand{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                      name="timeOfFirstBreathAIDemand{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                      [(ngModel)]="contraventionTest.approvedInstrumentTest.firstBreathDemandTime"
                      [ngClass]="{'is-invalid': (timeOfFirstBreathAIDemandControl.touched || impairmentScreeningModel.isSubmitClicked) && timeOfFirstBreathAIDemandControl.invalid}">
                      <div class="invalid-feedback">
                        <div *ngIf="timeOfFirstBreathAIDemandControl.errors?.required">Time of {{isRoadsideAppeal?"Roadside Appeal Request":"1st Breath AI Demand"}} is required</div>
                        <div *ngIf="!timeOfFirstBreathAIDemandControl.errors?.required">Invalid Time of {{isRoadsideAppeal?"Roadside Appeal Request":"1st Breath AI Demand"}}</div>
                      </div>
                </div>
                <div class="col-sm-6">
                    <label>{{isRoadsideAppeal?"Decline?":"Refusal?"}} <span class="label-hint">(Required)</span></label>
                    <div class="mt-3">
                        <div class="form-check form-check-inline custom-radio-narrow">
                            <input
                                required type="radio"
                                class="form-check-input"
                                id="AIrefusalStraightRefusal{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                                name="AIrefusalRadio{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                                #AIRefusalStraightRefusalControl="ngModel"
                                [value]="1"
                                [(ngModel)]="contraventionTest.driverDeclinedToTestTypeId"
                                [ngClass]="{'is-invalid': contraventionTest.driverDeclinedToTestTypeId == null && (AIRefusalStraightRefusalControl.touched || AIRefusalFailedToComplyControl.touched || AIRefusalNoControl.touched || impairmentScreeningModel.isSubmitClicked)}">
                            <label class="form-check-label"
                                for="AIrefusalStraightRefusal{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">{{isRoadsideAppeal?"Yes":"Straight refusal"}}</label>
                        </div>
                        <div class="form-check form-check-inline custom-radio-narrow">
                            <input
                                required type="radio"
                                class="form-check-input"
                                id="AIrefusalFailedToComply{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                                name="AIrefusalRadio{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                                #AIRefusalFailedToComplyControl="ngModel"
                                [value]="2"
                                [(ngModel)]="contraventionTest.driverDeclinedToTestTypeId"
                                [ngClass]="{'is-invalid': contraventionTest.driverDeclinedToTestTypeId == null && (AIRefusalStraightRefusalControl.touched || AIRefusalFailedToComplyControl.touched || AIRefusalNoControl.touched || impairmentScreeningModel.isSubmitClicked)}">
                            <label class="form-check-label"
                                for="AIrefusalFailedToComply{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">Failed to Comply</label>
                        </div>
                        <div class="form-check form-check-inline custom-radio-narrow">
                            <input
                                required type="radio"
                                class="form-check-input"
                                id="AIrefusalNo{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                                name="AIrefusalRadio{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                                #AIRefusalNoControl="ngModel"
                                [value]="3"
                                [(ngModel)]="contraventionTest.driverDeclinedToTestTypeId"
                                [ngClass]="{'is-invalid': contraventionTest.driverDeclinedToTestTypeId == null && (AIRefusalStraightRefusalControl.touched || AIRefusalFailedToComplyControl.touched || AIRefusalNoControl.touched || impairmentScreeningModel.isSubmitClicked)}">
                            <label class="form-check-label"
                                for="AIrefusalNo{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">No</label>
                        </div>
                        <input
                            required type="radio"
                            style="display:none;" id="AIrefusalNo{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}Hidden"
                            name="AIrefusalRadio{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                            #AIRefusalHiddenControl="ngModel"
                            [value]="null"
                            [(ngModel)]="contraventionTest.driverDeclinedToTestTypeId"
                            [ngClass]="{'is-invalid': contraventionTest.driverDeclinedToTestTypeId == null && (AIRefusalStraightRefusalControl.touched || AIRefusalFailedToComplyControl.touched || AIRefusalNoControl.touched || impairmentScreeningModel.isSubmitClicked)}">
                        <div class="invalid-feedback">
							<div>{{isRoadsideAppeal?"Decline":"Refusal"}} Information is required</div>
						</div>
                    </div>
                </div>

            </div>

            <div class="mb-3 row" *ngIf="contraventionTest.driverDeclinedToTestTypeId == DriverDeclinedToTestTypes.FailedToComply && isAIThirdTestVisible">
                <div class="col-sm-6">
                    <label for="timeOfAITestStarted{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">Time {{isRoadsideAppeal?"Roadside Appeal":"AI"}} Test Started
                      <span class="label-hint">(Required)</span>
                    </label>
                    <input required #timeOfAITestStartedControl="ngModel"
                      type="text" placeholder="--:--" mask="{{Constants.Mask.TIME_MASK}}" [patterns]="Constants.Mask.TIME_MASK_PATTERNS" [showMaskTyped]="true" [dropSpecialCharacters]="false"
                      pattern="^(([[0|1]\d)|(2[0-3]))[:]([0-5]\d)$"
                      class="form-control" id="timeOfAITestStarted{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                      name="timeOfAITestStarted{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                      [(ngModel)]="contraventionTest.approvedInstrumentTest.testStartedTime"
                      [ngClass]="{'is-invalid': (timeOfAITestStartedControl.touched || impairmentScreeningModel.isSubmitClicked) && timeOfAITestStartedControl.invalid}">
                      <div class="invalid-feedback">
                        <div *ngIf="timeOfAITestStartedControl.errors?.required">Time {{isRoadsideAppeal?"Roadside Appeal":"AI"}} Test Started is required</div>
                        <div *ngIf="!timeOfAITestStartedControl.errors?.required">Invalid Time {{isRoadsideAppeal?"Roadside Appeal":"AI"}} Test Started</div>
                      </div>
                </div>
            </div>

            <div class="mb-3 row" *ngIf="contraventionTest.driverDeclinedToTestTypeId == DriverDeclinedToTestTypes.No">
                <div class="col-sm-6" *ngIf="isAIThirdTestVisible">
                    <label for="timeOfAITestStarted{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">Time {{isRoadsideAppeal?"Roadside Appeal":"AI"}} Test Started
                      <span class="label-hint">(Required)</span>
                    </label>
                    <input required #timeOfAITestStartedControl="ngModel"
                      type="text" placeholder="--:--" mask="{{Constants.Mask.TIME_MASK}}" [patterns]="Constants.Mask.TIME_MASK_PATTERNS" [showMaskTyped]="true" [dropSpecialCharacters]="false"
                      pattern="^(([[0|1]\d)|(2[0-3]))[:]([0-5]\d)$"
                      class="form-control" id="timeOfAITestStarted{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                      name="timeOfAITestStarted{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                      [(ngModel)]="contraventionTest.approvedInstrumentTest.testStartedTime"
                      [ngClass]="{'is-invalid': (timeOfAITestStartedControl.touched || impairmentScreeningModel.isSubmitClicked) && timeOfAITestStartedControl.invalid}">
                      <div class="invalid-feedback">
                        <div *ngIf="timeOfAITestStartedControl.errors?.required">Time {{isRoadsideAppeal?"Roadside Appeal":"AI"}} Test Started is required</div>
                        <div *ngIf="!timeOfAITestStartedControl.errors?.required">Invalid Time {{isRoadsideAppeal?"Roadside Appeal":"AI"}} Test Started</div>
                      </div>
                </div>
                <div class="col-sm-6">
                    <label for="firstEvidentiaryTestResult{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">{{isRoadsideAppeal?"":"1st "}}Evidentiary Test Result <span *ngIf="!contraventionTest.driverDeclinedToTestTypeId" class="label-hint">(Required)</span></label>
                    <input required #firstEvidentiaryTestResultControl="ngModel" maxlength="255" type="text" class="form-control" id="FirstEvidentiaryTestResult{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}" name="firstEvidentiaryTestResult{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}" [(ngModel)]="contraventionTest.approvedInstrumentTest.firstEvidentiaryTestResult" [ngClass]="{'is-invalid': (firstEvidentiaryTestResultControl.touched || impairmentScreeningModel.isSubmitClicked) && firstEvidentiaryTestResultControl.invalid}">
                    <div class="invalid-feedback">
                        <div>{{isRoadsideAppeal?"":"1st "}}Evidentiary Test Result is required</div>
                    </div>
                </div>
            </div>

            <div class="mb-3 row" *ngIf="contraventionTest.driverDeclinedToTestTypeId == DriverDeclinedToTestTypes.No && !isRoadsideAppeal">
                <div class="col-sm-6">
                    <label
                      for="timeOfSecondBreathAIDemand{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">Time of 2nd Breath AI {{isAIThirdTestVisible?"Request":"Demand"}} <span class="label-hint">(Required)</span></label>
                    <input
                      required
                      #timeOfSecondBreathAIDemandControl="ngModel"
                      type="text" placeholder="--:--" mask="{{Constants.Mask.TIME_MASK}}" [patterns]="Constants.Mask.TIME_MASK_PATTERNS" [showMaskTyped]="true" [dropSpecialCharacters]="false"
                      pattern="^(([[0|1]\d)|(2[0-3]))[:]([0-5]\d)$"
                      class="form-control"
                      id="timeOfSecondBreathAIDemand{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                      name="timeOfSecondBreathAIDemand{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                      [(ngModel)]="contraventionTest.approvedInstrumentTest.secondBreathDemandTime" [ngClass]="{'is-invalid': (timeOfSecondBreathAIDemandControl.touched || impairmentScreeningModel.isSubmitClicked) && timeOfSecondBreathAIDemandControl.invalid}">
                    <div class="invalid-feedback">
                        <div *ngIf="timeOfSecondBreathAIDemandControl.errors?.required">Time of 2nd Breath AI {{isAIThirdTestVisible?"Request":"Demand"}} is required</div>
                        <div *ngIf="!timeOfSecondBreathAIDemandControl.errors?.required">Invalid Time of 2nd Breath AI {{isAIThirdTestVisible?"Request":"Demand"}}</div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <label for="secondEvidentiaryTestResult{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">2nd Evidentiary Test Result <span class="label-hint">(Required)</span></label>
                    <input required #secondEvidentiaryTestResultControl="ngModel" maxlength="255" type="text" class="form-control" id="secondEvidentiaryTestResult{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}" name="secondEvidentiaryTestResult{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}" [(ngModel)]="contraventionTest.approvedInstrumentTest.secondEvidentiaryTestResult" [ngClass]="{'is-invalid': (secondEvidentiaryTestResultControl.touched || impairmentScreeningModel.isSubmitClicked) && secondEvidentiaryTestResultControl.invalid}">
                    <div class="invalid-feedback">
                        <div>2nd Evidentiary Test Result is required</div>
                    </div>
                </div>
            </div>

            <div class="mb-3 row" *ngIf="contraventionTest.driverDeclinedToTestTypeId == DriverDeclinedToTestTypes.No && !isRoadsideAppeal && isAIThirdTestVisible">
                <div class="col-sm-6">
                    <label
                      for="timeOfThirdBreathAIDemand{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">Time of 3rd Breath AI Request <span class="label-hint">(If Applicable)</span></label>
                    <input
                      #timeOfThirdBreathAIDemandControl="ngModel"
                      type="text" placeholder="--:--" mask="{{Constants.Mask.TIME_MASK}}" [patterns]="Constants.Mask.TIME_MASK_PATTERNS" [showMaskTyped]="true" [dropSpecialCharacters]="false"
                      pattern="^(([[0|1]\d)|(2[0-3]))[:]([0-5]\d)$"
                      class="form-control"
                      id="timeOfThirdBreathAIDemand{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                      name="timeOfThirdBreathAIDemand{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                      [(ngModel)]="contraventionTest.approvedInstrumentTest.thirdBreathDemandTime" [ngClass]="{'is-invalid': (timeOfThirdBreathAIDemandControl.touched || impairmentScreeningModel.isSubmitClicked) && timeOfThirdBreathAIDemandControl.invalid}">
                    <div class="invalid-feedback">
                        <div>Invalid Time of 3rd Breath AI Request</div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <label for="thirdEvidentiaryTestResult{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">3rd Evidentiary Test Result <span class="label-hint">(If Applicable)</span></label>
                    <input #thirdEvidentiaryTestResultControl="ngModel" maxlength="255" type="text" class="form-control" id="thirdEvidentiaryTestResult{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}" name="thirdEvidentiaryTestResult{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}" [(ngModel)]="contraventionTest.approvedInstrumentTest.thirdEvidentiaryTestResult">
                </div>
            </div>

            <div class="mb-3 row" *ngIf="contraventionTest.driverDeclinedToTestTypeId == DriverDeclinedToTestTypes.StraightRefusal">
                <div class="col-sm-4">
                    <label for="AItimeOfRefusal{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">Time of {{isRoadsideAppeal?"Decline":"Refusal"}} <span class="label-hint">(Required)</span></label>
                    <input required #AItimeOfRefusalControl="ngModel" type="text" placeholder="--:--" mask="{{Constants.Mask.TIME_MASK}}" [patterns]="Constants.Mask.TIME_MASK_PATTERNS" [showMaskTyped]="true" [dropSpecialCharacters]="false"
                    pattern="^(([[0|1]\d)|(2[0-3]))[:]([0-5]\d)$" class="form-control" id="AItimeOfRefusal{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}" name="AItimeOfRefusal{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}" [(ngModel)]="contraventionTest.refusalTime" [ngClass]="{'is-invalid':(AItimeOfRefusalControl.touched || impairmentScreeningModel.isSubmitClicked) && AItimeOfRefusalControl.invalid}">
                    <div class="invalid-feedback">
                        <div *ngIf="AItimeOfRefusalControl.errors?.required">Time of {{isRoadsideAppeal?"Decline":"Refusal"}} is required</div>
                        <div *ngIf="!AItimeOfRefusalControl.errors?.required">Invalid Time of {{isRoadsideAppeal?"Decline":"Refusal"}}</div>
                    </div>
                </div>
            </div>

            <app-impairment-screening-alert
              [ImpairmentScreeningMethodId]="impairmentScreeningModel.ImpairmentScreeningMethodId"
              [DriverDeclinedToTestId]="contraventionTest.driverDeclinedToTestTypeId"
              [isTechCertificateVisible]="!isAIThirdTestVisible || (!(contraventionTest.approvedInstrumentTest.thirdEvidentiaryTestResult || contraventionTest.approvedInstrumentTest.thirdBreathDemandTime) && !isRoadsideAppeal)"></app-impairment-screening-alert>

        </div>
    </div>
</ng-container>

<!-- Blood Sample -->
<ng-container *ngIf="impairmentScreeningModel.ImpairmentScreeningMethodId == 6">
    <div class="card mt-4">
        <div class="card-header">
            {{contraventionTest.testSequence > 0 ? impairmentScreeningModel.impairmentScreeningResultCardLabel + " "  + contraventionTest.testSequence.toString() + " Details" : impairmentScreeningModel.impairmentScreeningResultCardLabel }}
        </div>
        <div class="card-body" *ngIf="(isNewDREProcess && !isRoadsideAppeal);else oldBloodProcess">
            <div class="row">
                <div class="col-12">
                    <label for="typeOfSample{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">Type of Sample <span class="label-hint">(Required)</span></label>
                </div>
            </div>
            <!--Blood Demand-->
            <div class="mb-3 row">
                <div class="col-12">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="bloodDemandCheck{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}" 
                        name="bloodDemandCheck{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}" 
                        [required]="!contraventionTest.bloodSampleTest.isBloodWarrant && !contraventionTest.bloodSampleTest.isSearchWarrant && !contraventionTest.bloodSampleTest.isHospitalRecord"
                        [(ngModel)]="contraventionTest.bloodSampleTest.isBloodDemand" (ngModelChange)="clearBloodDemandFields(true)">
                        <label class="form-check-label" for="bloodDemandCheck">
                            Blood Demand (320.28)
                        </label>
                    </div>
                </div>
            </div>

            <ng-continer *ngIf="contraventionTest.bloodSampleTest.isBloodDemand">
                <div class="mb-3 row">
                    <div class="col-sm-6">
                        <label>{{isRoadsideAppeal?"Decline?":"Refusal?"}} <span class="label-hint">(Required)</span></label>
                        <div class="mt-3">
                            <div class="form-check form-check-inline custom-radio-narrow">
                                <input
                                    required type="radio"
                                    class="form-check-input"
                                    id="bloodSampleRefusalStraightRefusal{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                                    name="bloodSampleRefusalRadio{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                                    #bloodSampleRefusalStraightRefusalControl="ngModel"
                                    [value]="1"
                                    [(ngModel)]="contraventionTest.driverDeclinedToTestTypeId"
                                    (ngModelChange)="clearBloodDemandFields(false)"
                                    [ngClass]="{'is-invalid': contraventionTest.driverDeclinedToTestTypeId == null && (bloodSampleRefusalStraightRefusalControl.touched || bloodSampleRefusalFailedToComplyControl.touched || bloodSampleRefusalNoControl.touched || impairmentScreeningModel.isSubmitClicked)}">
                                <label class="form-check-label"
                                    for="bloodSampleRefusalStraightRefusal{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">{{isRoadsideAppeal?"Yes":"Straight refusal"}}</label>
                            </div>
                            <div class="form-check form-check-inline custom-radio-narrow">
                                <input
                                    required type="radio"
                                    class="form-check-input"
                                    id="bloodSampleRefusalFailedToComply{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                                    name="bloodSampleRefusalFailedToComplyRadio{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                                    #bloodSampleRefusalFailedToComplyControl="ngModel"
                                    [value]="2"
                                    [(ngModel)]="contraventionTest.driverDeclinedToTestTypeId"
                                    (ngModelChange)="clearBloodDemandFields(false)"
                                    [ngClass]="{'is-invalid': contraventionTest.driverDeclinedToTestTypeId == null && (bloodSampleRefusalStraightRefusalControl.touched || bloodSampleRefusalFailedToComplyControl.touched || bloodSampleRefusalNoControl.touched || impairmentScreeningModel.isSubmitClicked)}">
                                <label class="form-check-label"
                                    for="bloodSampleRefusalFailedToComply{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">Failed to comply</label>
                            </div>
                            <div class="form-check form-check-inline custom-radio-narrow">
                                <input
                                    required type="radio"
                                    class="form-check-input"
                                    id="bloodSampleRefusalNo{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                                    name="bloodSampleRefusalRadio{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                                    #bloodSampleRefusalNoControl="ngModel"
                                    [value]="3"
                                    [(ngModel)]="contraventionTest.driverDeclinedToTestTypeId"
                                    (ngModelChange)="clearBloodDemandFields(false)"
                                    [ngClass]="{'is-invalid': contraventionTest.driverDeclinedToTestTypeId == null && (bloodSampleRefusalStraightRefusalControl.touched || bloodSampleRefusalFailedToComplyControl.touched || bloodSampleRefusalNoControl.touched || impairmentScreeningModel.isSubmitClicked)}">
                                <label class="form-check-label"
                                    for="bloodSampleRefusalNo{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">No</label>
                            </div>
                            <input
                                required type="radio"
                                style="display:none;"
                                id="bloodSampleRefusalNo{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}Hidden"
                                name="bloodSampleRefusalRadio{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                                #bloodSampleRefusalHiddenControl="ngModel"
                                [value]="null"
                                [(ngModel)]="contraventionTest.driverDeclinedToTestTypeId"
                                [ngClass]="{'is-invalid': contraventionTest.driverDeclinedToTestTypeId == null && (bloodSampleRefusalStraightRefusalControl.touched || bloodSampleRefusalFailedToComplyControl.touched  || bloodSampleRefusalNoControl.touched || impairmentScreeningModel.isSubmitClicked)}">
                            <div class="invalid-feedback">
                                <div>{{isRoadsideAppeal?"Decline":"Refusal"}} Information is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <label for="bloodSampleDemandTime{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">Time of Demand <span class="label-hint">(Required)</span></label>
                        <input required #bloodSampleDemandTimeControl="ngModel" type="text" placeholder="--:--" mask="{{Constants.Mask.TIME_MASK}}" [patterns]="Constants.Mask.TIME_MASK_PATTERNS" [showMaskTyped]="true" [dropSpecialCharacters]="false"
                        pattern="^(([[0|1]\d)|(2[0-3]))[:]([0-5]\d)$" class="form-control" 
                        id="bloodSampleDemandTime{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}" 
                        name="bloodSampleDemandTime{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}" 
                        [(ngModel)]="contraventionTest.bloodSampleTest.bloodSampleDemandTime" 
                        [ngClass]="{'is-invalid': (bloodSampleDemandTimeControl.touched || impairmentScreeningModel.isSubmitClicked) && bloodSampleDemandTimeControl.invalid}">
                        <div class="invalid-feedback">
                            <div *ngIf="bloodSampleDemandTimeControl.errors?.required">Time of Demand is required</div>
                            <div *ngIf="!bloodSampleDemandTimeControl.errors?.required">Invalid Time of Demand</div>
                        </div>
                    </div>
                </div>

                <div class="mb-3 row" *ngIf="contraventionTest.driverDeclinedToTestTypeId == DriverDeclinedToTestTypes.StraightRefusal">
                    <div class="col-sm-6">
                        <label
                            for="bloodSampleTimeOfRefusal{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">Time of {{isRoadsideAppeal?"Decline":"Refusal"}}
                            <span class="label-hint">(Required)</span></label>
                        <input
                            required #bloodSampleTimeOfRefusalControl="ngModel"
                            type="text" placeholder="--:--" mask="{{Constants.Mask.TIME_MASK}}" [patterns]="Constants.Mask.TIME_MASK_PATTERNS" [showMaskTyped]="true" [dropSpecialCharacters]="false"
                            pattern="^(([[0|1]\d)|(2[0-3]))[:]([0-5]\d)$"
                            class="form-control"
                            id="bloodSampleTimeOfRefusal{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                            name="bloodSampleTimeOfRefusal{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                            [(ngModel)]="contraventionTest.refusalTime"
                            [ngClass]="{'is-invalid':(bloodSampleTimeOfRefusalControl.touched || impairmentScreeningModel.isSubmitClicked) && bloodSampleTimeOfRefusalControl.invalid}">
                        <div class="invalid-feedback">
                            <div *ngIf="bloodSampleTimeOfRefusalControl.errors?.required">Time of {{isRoadsideAppeal?"Decline":"Refusal"}} is required</div>
                            <div *ngIf="!bloodSampleTimeOfRefusalControl.errors?.required">Invalid Time of {{isRoadsideAppeal?"Decline":"Refusal"}}</div>
                        </div>
                    </div>
                </div>

                <div class="mb-3 row" *ngIf="contraventionTest.driverDeclinedToTestTypeId == DriverDeclinedToTestTypes.No">
                    <div class="col-sm-6">
                        <label for="bloodSampleTime{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">Blood Sample Time <span class="label-hint">(Required)</span></label>
                        <input required #bloodSampleTimeControl="ngModel" type="text" placeholder="--:--" mask="{{Constants.Mask.TIME_MASK}}" [patterns]="Constants.Mask.TIME_MASK_PATTERNS" [showMaskTyped]="true" [dropSpecialCharacters]="false"
                        pattern="^(([[0|1]\d)|(2[0-3]))[:]([0-5]\d)$" class="form-control" id="bloodSampleTime{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}" name="bloodSampleTime{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}" [(ngModel)]="contraventionTest.bloodSampleTest.bloodSampleTime" [ngClass]="{'is-invalid': (bloodSampleTimeControl.touched || impairmentScreeningModel.isSubmitClicked) && bloodSampleTimeControl.invalid}">
                        <div class="invalid-feedback">
                            <div *ngIf="bloodSampleTimeControl.errors?.required">Blood Sample Time is required</div>
                            <div *ngIf="!bloodSampleTimeControl.errors?.required">Invalid Blood Sample Time</div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <label
                            for="labType{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">Sent to Provincial or National Lab? <span class="label-hint">(Required)</span></label>
                        <select
                            [appSelectValidator]="true"
                            #labTypeControl="ngModel"
                            class="form-select"
                            id="labType{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                            name="labType{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                            [(ngModel)]="contraventionTest.bloodSampleTest.labTypeId"
                            [ngClass]="{'is-invalid': (labTypeControl.touched || impairmentScreeningModel.isSubmitClicked) && labTypeControl.errors?.defaultSelected}">
                            <option [value]="null">Select Lab Type</option>
                            <option *ngFor="let labType of labTypes" [value]="labType.id">{{labType.name}}</option>
                        </select>
                        <div class="invalid-feedback">
                            <div>Lab Type is required</div>
                        </div>
                    </div>
                </div>

                <app-impairment-screening-alert
                [ImpairmentScreeningMethodId]="impairmentScreeningModel.ImpairmentScreeningMethodId"
                [DriverDeclinedToTestId]="contraventionTest.driverDeclinedToTestTypeId"></app-impairment-screening-alert>
            </ng-continer>

            <!--Blood Warrant-->
            <div class="mb-3 row mt-4">
                <div class="col-12">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="bloodWarrantCheck{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}" 
                        name="bloodWarrantCheck{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}" 
                        [disabled]="contraventionTest.bloodSampleTest.isSearchWarrant"
                        [(ngModel)]="contraventionTest.bloodSampleTest.isBloodWarrant" (ngModelChange)="clearBloodWarrantFields()"
                        [required]="!contraventionTest.bloodSampleTest.isBloodDemand && !contraventionTest.bloodSampleTest.isSearchWarrant && !contraventionTest.bloodSampleTest.isHospitalRecord">
                        <label class="form-check-label" for="bloodWarrantCheck">
                            Blood Warrant (320.29)
                        </label>
                    </div>
                </div>
            </div>

            <ng-continer *ngIf="contraventionTest.bloodSampleTest.isBloodWarrant">
                <div class="mb-3 row">
                    <div class="col-sm-6">
                        <label for="bloodWarrantSampleTime{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">Blood Sample Time <span class="label-hint">(Required)</span></label>
                        <input required #bloodWarrantSampleTimeControl="ngModel" type="text" placeholder="--:--" mask="{{Constants.Mask.TIME_MASK}}" [patterns]="Constants.Mask.TIME_MASK_PATTERNS" [showMaskTyped]="true" [dropSpecialCharacters]="false"
                        pattern="^(([[0|1]\d)|(2[0-3]))[:]([0-5]\d)$" class="form-control" 
                        id="bloodWarrantSampleTime{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}" 
                        name="bloodWarrantSampleTime{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}" 
                        [(ngModel)]="contraventionTest.bloodSampleTest.bloodWarrantSampleTime" 
                        [ngClass]="{'is-invalid': (bloodWarrantSampleTimeControl.touched || impairmentScreeningModel.isSubmitClicked) && bloodWarrantSampleTimeControl.invalid}">
                        <div class="invalid-feedback">
                            <div *ngIf="bloodWarrantSampleTimeControl.errors?.required">Blood Sample Time is required</div>
                            <div *ngIf="!bloodWarrantSampleTimeControl.errors?.required">Invalid Blood Sample Time</div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <label
                            for="bloodWarrantLabType{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">Sent to Provincial or National Lab? <span class="label-hint">(Required)</span></label>
                        <select
                            [appSelectValidator]="true"
                            #bloodWarrantLabTypeControl="ngModel"
                            class="form-select"
                            id="bloodWarrantLabType{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                            name="bloodWarrantLabType{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                            [(ngModel)]="contraventionTest.bloodSampleTest.bloodWarrantLabTypeId"
                            [ngClass]="{'is-invalid': (bloodWarrantLabTypeControl.touched || impairmentScreeningModel.isSubmitClicked) && bloodWarrantLabTypeControl.errors?.defaultSelected}">
                            <option [value]="null">Select Lab Type</option>
                            <option *ngFor="let labType of labTypes" [value]="labType.id">{{labType.name}}</option>
                        </select>
                        <div class="invalid-feedback">
                            <div>Lab Type is required</div>
                        </div>
                    </div>
                </div>

                <app-impairment-screening-alert
                [ImpairmentScreeningMethodId]="impairmentScreeningModel.ImpairmentScreeningMethodId"
                DriverDeclinedToTestId="{{DriverDeclinedToTestTypes.No}}"></app-impairment-screening-alert>
            </ng-continer>

            <!--Search Warrant-->
            <div class="mb-3 row mt-4">
                <div class="col-12">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" 
                        id="searchWarrantCheck{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}" 
                        name="searchWarrantCheck{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}" 
                        [(ngModel)]="contraventionTest.bloodSampleTest.isSearchWarrant"
                        [disabled]="contraventionTest.bloodSampleTest.isBloodWarrant"
                        [required]="!contraventionTest.bloodSampleTest.isBloodDemand && !contraventionTest.bloodSampleTest.isBloodWarrant && !contraventionTest.bloodSampleTest.isHospitalRecord">
                        <label class="form-check-label" for="searchWarrantCheck">
                            Search Warrant (487)
                        </label>
                    </div>
                </div>
            </div>

            <ng-continer *ngIf="contraventionTest.bloodSampleTest.isSearchWarrant">
                <app-impairment-screening-alert
                [ImpairmentScreeningMethodId]="impairmentScreeningModel.ImpairmentScreeningMethodId"
                DriverDeclinedToTestId="{{DriverDeclinedToTestTypes.No}}"></app-impairment-screening-alert>
            </ng-continer>

            <!--Hospital Records-->
            <div class="mb-3 row mt-4">
                <div class="col-12">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" 
                        id="hospitalRecordCheck{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}" 
                        name="hospitalRecordCheck{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}" 
                        [(ngModel)]="contraventionTest.bloodSampleTest.isHospitalRecord"
                        [required]="!contraventionTest.bloodSampleTest.isBloodDemand && !contraventionTest.bloodSampleTest.isBloodWarrant && !contraventionTest.bloodSampleTest.isSearchWarrant">
                        <label class="form-check-label" for="hospitalRecordCheck">
                            Hospital Records (Production Order or Consent)
                        </label>
                    </div>
                </div>
            </div>

            <ng-continer *ngIf="contraventionTest.bloodSampleTest.isHospitalRecord">
                <app-impairment-screening-alert
                [ImpairmentScreeningMethodId]="impairmentScreeningModel.ImpairmentScreeningMethodId"
                BloodSampleTypeId="{{BloodSampleTypes.HospitalRecords}}"
                DriverDeclinedToTestId="{{DriverDeclinedToTestTypes.No}}"></app-impairment-screening-alert>
            </ng-continer>

            <!--Validation-->
            <div class="mb-3 row">
                <div class="col-12">
                    <div class="error" *ngIf="!contraventionTest.bloodSampleTest.isBloodDemand && !contraventionTest.bloodSampleTest.isBloodWarrant && !contraventionTest.bloodSampleTest.isSearchWarrant && !contraventionTest.bloodSampleTest.isHospitalRecord">
                        Type of Sample is required
                    </div>
                </div>
            </div>
        </div>
        <ng-template #oldBloodProcess>
            <div class="card-body">
                <div class="mb-3 row">
                    <div class="col-sm-6">
                        <label>{{isRoadsideAppeal?"Decline?":"Refusal?"}} <span class="label-hint">(Required)</span></label>
                        <div class="mt-3">
                            <div class="form-check form-check-inline custom-radio-narrow">
                                <input
                                    required type="radio"
                                    class="form-check-input"
                                    id="bloodSampleRefusalStraightRefusal{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                                    name="bloodSampleRefusalRadio{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                                    #bloodSampleRefusalStraightRefusalControl="ngModel"
                                    [value]="1"
                                    [(ngModel)]="contraventionTest.driverDeclinedToTestTypeId"
                                    [ngClass]="{'is-invalid': contraventionTest.driverDeclinedToTestTypeId == null && (bloodSampleRefusalStraightRefusalControl.touched || bloodSampleRefusalFailedToComplyControl.touched || bloodSampleRefusalNoControl.touched || impairmentScreeningModel.isSubmitClicked)}">
                                <label class="form-check-label"
                                    for="bloodSampleRefusalStraightRefusal{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">{{isRoadsideAppeal?"Yes":"Straight refusal"}}</label>
                            </div>
                            <div class="form-check form-check-inline custom-radio-narrow">
                                <input
                                    required type="radio"
                                    class="form-check-input"
                                    id="bloodSampleRefusalFailedToComply{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                                    name="bloodSampleRefusalFailedToComplyRadio{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                                    #bloodSampleRefusalFailedToComplyControl="ngModel"
                                    [value]="2"
                                    [(ngModel)]="contraventionTest.driverDeclinedToTestTypeId"
                                    [ngClass]="{'is-invalid': contraventionTest.driverDeclinedToTestTypeId == null && (bloodSampleRefusalStraightRefusalControl.touched || bloodSampleRefusalFailedToComplyControl.touched || bloodSampleRefusalNoControl.touched || impairmentScreeningModel.isSubmitClicked)}">
                                <label class="form-check-label"
                                    for="bloodSampleRefusalFailedToComply{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">Failed to comply</label>
                            </div>
                            <div class="form-check form-check-inline custom-radio-narrow">
                                <input
                                    required type="radio"
                                    class="form-check-input"
                                    id="bloodSampleRefusalNo{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                                    name="bloodSampleRefusalRadio{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                                    #bloodSampleRefusalNoControl="ngModel"
                                    [value]="3"
                                    [(ngModel)]="contraventionTest.driverDeclinedToTestTypeId"
                                    [ngClass]="{'is-invalid': contraventionTest.driverDeclinedToTestTypeId == null && (bloodSampleRefusalStraightRefusalControl.touched || bloodSampleRefusalFailedToComplyControl.touched || bloodSampleRefusalNoControl.touched || impairmentScreeningModel.isSubmitClicked)}">
                                <label class="form-check-label"
                                    for="bloodSampleRefusalNo{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">No</label>
                            </div>
                            <input
                                required type="radio"
                                style="display:none;"
                                id="bloodSampleRefusalNo{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}Hidden"
                                name="bloodSampleRefusalRadio{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                                #bloodSampleRefusalHiddenControl="ngModel"
                                [value]="null"
                                [(ngModel)]="contraventionTest.driverDeclinedToTestTypeId"
                                [ngClass]="{'is-invalid': contraventionTest.driverDeclinedToTestTypeId == null && (bloodSampleRefusalStraightRefusalControl.touched || bloodSampleRefusalFailedToComplyControl.touched  || bloodSampleRefusalNoControl.touched || impairmentScreeningModel.isSubmitClicked)}">
                            <div class="invalid-feedback">
                                <div>{{isRoadsideAppeal?"Decline":"Refusal"}} Information is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6" *ngIf="isNewDREProcess">
                        <label for="bloodSampleDemandTime{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">Time of {{isRoadsideAppeal?"Roadside Appeal Request":"Demand"}} <span class="label-hint">(Required)</span></label>
                        <input required #bloodSampleDemandTimeControl="ngModel" type="text" placeholder="--:--" mask="{{Constants.Mask.TIME_MASK}}" [patterns]="Constants.Mask.TIME_MASK_PATTERNS" [showMaskTyped]="true" [dropSpecialCharacters]="false"
                        pattern="^(([[0|1]\d)|(2[0-3]))[:]([0-5]\d)$" class="form-control" 
                        id="bloodSampleDemandTime{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}" 
                        name="bloodSampleDemandTime{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}" 
                        [(ngModel)]="contraventionTest.bloodSampleTest.bloodSampleDemandTime" 
                        [ngClass]="{'is-invalid': (bloodSampleDemandTimeControl.touched || impairmentScreeningModel.isSubmitClicked) && bloodSampleDemandTimeControl.invalid}">
                        <div class="invalid-feedback">
                            <div *ngIf="bloodSampleDemandTimeControl.errors?.required">Time of {{isRoadsideAppeal?"Roadside Appeal Request":"Demand"}} is required</div>
                            <div *ngIf="!bloodSampleDemandTimeControl.errors?.required">Invalid Time of {{isRoadsideAppeal?"Roadside Appeal Request":"Demand"}}</div>
                        </div>
                    </div>
                </div>

                <div class="mb-3 row" *ngIf="contraventionTest.driverDeclinedToTestTypeId == DriverDeclinedToTestTypes.StraightRefusal">
                    <div class="col-sm-6">
                        <label
                            for="bloodSampleTimeOfRefusal{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">Time of {{isRoadsideAppeal?"Decline":"Refusal"}}
                            <span class="label-hint">(Required)</span></label>
                        <input
                            required #bloodSampleTimeOfRefusalControl="ngModel"
                            type="text" placeholder="--:--" mask="{{Constants.Mask.TIME_MASK}}" [patterns]="Constants.Mask.TIME_MASK_PATTERNS" [showMaskTyped]="true" [dropSpecialCharacters]="false"
                            pattern="^(([[0|1]\d)|(2[0-3]))[:]([0-5]\d)$"
                            class="form-control"
                            id="bloodSampleTimeOfRefusal{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                            name="bloodSampleTimeOfRefusal{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                            [(ngModel)]="contraventionTest.refusalTime"
                            [ngClass]="{'is-invalid':(bloodSampleTimeOfRefusalControl.touched || impairmentScreeningModel.isSubmitClicked) && bloodSampleTimeOfRefusalControl.invalid}">
                        <div class="invalid-feedback">
                            <div *ngIf="bloodSampleTimeOfRefusalControl.errors?.required">Time of {{isRoadsideAppeal?"Decline":"Refusal"}} is required</div>
                            <div *ngIf="!bloodSampleTimeOfRefusalControl.errors?.required">Invalid Time of {{isRoadsideAppeal?"Decline":"Refusal"}}</div>
                        </div>
                    </div>
                </div>

                <div class="mb-3 row" *ngIf="contraventionTest.driverDeclinedToTestTypeId == DriverDeclinedToTestTypes.No">
                    <div class="col-sm-6">
                        <label for="bloodSampleTime{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">Blood Sample Time <span class="label-hint">(Required)</span></label>
                        <input required #bloodSampleTimeControl="ngModel" type="text" placeholder="--:--" mask="{{Constants.Mask.TIME_MASK}}" [patterns]="Constants.Mask.TIME_MASK_PATTERNS" [showMaskTyped]="true" [dropSpecialCharacters]="false"
                        pattern="^(([[0|1]\d)|(2[0-3]))[:]([0-5]\d)$" class="form-control" id="bloodSampleTime{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}" name="bloodSampleTime{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}" [(ngModel)]="contraventionTest.bloodSampleTest.bloodSampleTime" [ngClass]="{'is-invalid': (bloodSampleTimeControl.touched || impairmentScreeningModel.isSubmitClicked) && bloodSampleTimeControl.invalid}">
                        <div class="invalid-feedback">
                            <div *ngIf="bloodSampleTimeControl.errors?.required">Blood Sample Time is required</div>
                            <div *ngIf="!bloodSampleTimeControl.errors?.required">Invalid Blood Sample Time</div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <label
                            for="labType{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">Sent to Provincial or National Lab? <span class="label-hint">(Required)</span></label>
                        <select
                            [appSelectValidator]="true"
                            #labTypeControl="ngModel"
                            class="form-select"
                            id="labType{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                            name="labType{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                            [(ngModel)]="contraventionTest.bloodSampleTest.labTypeId"
                            [ngClass]="{'is-invalid': (labTypeControl.touched || impairmentScreeningModel.isSubmitClicked) && labTypeControl.errors?.defaultSelected}">
                            <option [value]="null">Select Lab Type</option>
                            <option *ngFor="let labType of labTypes" [value]="labType.id">{{labType.name}}</option>
                        </select>
                        <div class="invalid-feedback">
                            <div>Lab Type is required</div>
                        </div>
                    </div>
                </div>

                <app-impairment-screening-alert
                [ImpairmentScreeningMethodId]="impairmentScreeningModel.ImpairmentScreeningMethodId"
                [DriverDeclinedToTestId]="contraventionTest.driverDeclinedToTestTypeId"></app-impairment-screening-alert>
            </div>
        </ng-template>
    </div>
</ng-container>

<!-- Urine Sample -->
<ng-container *ngIf="impairmentScreeningModel.ImpairmentScreeningMethodId == 9">
  <div class="card mt-4">
      <div class="card-header">
          {{contraventionTest.testSequence > 0 ? impairmentScreeningModel.impairmentScreeningResultCardLabel + " "  + contraventionTest.testSequence.toString() + " Details" : impairmentScreeningModel.impairmentScreeningResultCardLabel }}
      </div>
      <div class="card-body">
          <div class="mb-3 row">
              <div class="col-sm-6">
                  <label>{{isRoadsideAppeal?"Decline?":"Refusal?"}} <span class="label-hint">(Required)</span></label>
                  <div class="mt-3">
                      <div class="form-check form-check-inline custom-radio-narrow">
                          <input
                            required type="radio"
                            class="form-check-input"
                            id="urineSampleRefusalStraightRefusal{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                            name="urineSampleRefusalRadio{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                            #urineSampleRefusalStraightRefusalControl="ngModel"
                            [value]="1"
                            [(ngModel)]="contraventionTest.driverDeclinedToTestTypeId"
                            [ngClass]="{'is-invalid': contraventionTest.driverDeclinedToTestTypeId == null && (urineSampleRefusalStraightRefusalControl.touched || urineSampleRefusalFailedToComplyControl.touched || urineSampleRefusalNoControl.touched || impairmentScreeningModel.isSubmitClicked)}">
                          <label class="form-check-label"
                            for="urineSampleRefusalStraightRefusal{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">{{isRoadsideAppeal?"Yes":"Straight refusal"}}</label>
                      </div>
                      <div class="form-check form-check-inline custom-radio-narrow">
                          <input
                            required type="radio"
                            class="form-check-input"
                            id="urineSampleRefusalFailedToComply{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                            name="urineSampleRefusalRadio{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                            #urineSampleRefusalFailedToComplyControl="ngModel"
                            [value]="2"
                            [(ngModel)]="contraventionTest.driverDeclinedToTestTypeId"
                            [ngClass]="{'is-invalid': contraventionTest.driverDeclinedToTestTypeId == null && (urineSampleRefusalStraightRefusalControl.touched || urineSampleRefusalFailedToComplyControl.touched || urineSampleRefusalNoControl.touched || impairmentScreeningModel.isSubmitClicked)}">
                          <label class="form-check-label"
                            for="urineSampleRefusalFailedToComply{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">Failed to comply</label>
                      </div>
                      <div class="form-check form-check-inline custom-radio-narrow">
                          <input
                            required type="radio"
                            class="form-check-input"
                            id="urineSampleRefusalNo{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                            name="urineSampleRefusalRadio{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                            #urineSampleRefusalNoControl="ngModel"
                            [value]="3"
                            [(ngModel)]="contraventionTest.driverDeclinedToTestTypeId"
                            [ngClass]="{'is-invalid': contraventionTest.driverDeclinedToTestTypeId == null && (urineSampleRefusalStraightRefusalControl.touched || urineSampleRefusalFailedToComplyControl.touched || urineSampleRefusalNoControl.touched || impairmentScreeningModel.isSubmitClicked)}">
                          <label class="form-check-label"
                            for="urineSampleRefusalNo{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">No</label>
                      </div>
                      <input
                        required
                        type="radio"
                        style="display:none;"
                        id="urineSampleRefusalNo{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}Hidden"
                        name="urineSampleRefusalRadio{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                        #urineSampleRefusalHiddenControl="ngModel"
                        [value]="null"
                        [(ngModel)]="contraventionTest.driverDeclinedToTestTypeId"
                        [ngClass]="{'is-invalid': contraventionTest.driverDeclinedToTestTypeId == null && (urineSampleRefusalStraightRefusalControl.touched || urineSampleRefusalFailedToComplyControl.touched || urineSampleRefusalNoControl.touched || impairmentScreeningModel.isSubmitClicked)}">
                        <div class="invalid-feedback">
                            <div>{{isRoadsideAppeal?"Decline":"Refusal"}} Information is required</div>
                        </div>
                  </div>
              </div>
              <div class="col-sm-6" *ngIf="isNewDREProcess">
                <label for="urineSampleDemandTime{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">Time of {{isRoadsideAppeal?"Roadside Appeal Request":"Demand"}} <span class="label-hint">(Required)</span></label>
                <input required #urineSampleDemandTimeControl="ngModel" type="text" placeholder="--:--" mask="{{Constants.Mask.TIME_MASK}}" [patterns]="Constants.Mask.TIME_MASK_PATTERNS" [showMaskTyped]="true" [dropSpecialCharacters]="false"
                pattern="^(([[0|1]\d)|(2[0-3]))[:]([0-5]\d)$" class="form-control" 
                id="urineSampleDemandTime{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}" 
                name="urnieSampleDemandTime{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}" 
                [(ngModel)]="contraventionTest.urineSampleTest.urineSampleDemandTime" 
                [ngClass]="{'is-invalid': (urineSampleDemandTimeControl.touched || impairmentScreeningModel.isSubmitClicked) && urineSampleDemandTimeControl.invalid}">
                <div class="invalid-feedback">
                    <div *ngIf="urineSampleDemandTimeControl.errors?.required">Time of {{isRoadsideAppeal?"Roadside Appeal Request":"Demand"}} is required</div>
                    <div *ngIf="!urineSampleDemandTimeControl.errors?.required">Invalid Time of {{isRoadsideAppeal?"Roadside Appeal Request":"Demand"}}</div>
                </div>
            </div>
          </div>

          <div class="mb-3 row" *ngIf="contraventionTest.driverDeclinedToTestTypeId == DriverDeclinedToTestTypes.StraightRefusal">
            <div class="col-sm-6" *ngIf="contraventionTest.driverDeclinedToTestTypeId == DriverDeclinedToTestTypes.StraightRefusal">
                <label
                  for="urineSampleTimeOfRefusal{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">Time of {{isRoadsideAppeal?"Decline":"Refusal"}} <span class="label-hint">(Required)</span></label>
                <input
                  required
                  #urineSampleTimeOfRefusalControl="ngModel"
                  type="text" placeholder="--:--" mask="{{Constants.Mask.TIME_MASK}}" [patterns]="Constants.Mask.TIME_MASK_PATTERNS" [showMaskTyped]="true" [dropSpecialCharacters]="false"
                  pattern="^(([[0|1]\d)|(2[0-3]))[:]([0-5]\d)$"
                  class="form-control" id="urineSampleTimeOfRefusal{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                  name="urineSampleTimeOfRefusal{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                  [(ngModel)]="contraventionTest.refusalTime"
                  [ngClass]="{'is-invalid':(urineSampleTimeOfRefusalControl.touched || impairmentScreeningModel.isSubmitClicked) && urineSampleTimeOfRefusalControl.invalid}">
                <div class="invalid-feedback">
                    <div *ngIf="urineSampleTimeOfRefusalControl.errors?.required">Time of {{isRoadsideAppeal?"Decline":"Refusal"}} is required</div>
                    <div *ngIf="!urineSampleTimeOfRefusalControl.errors?.required">Invalid Time of {{isRoadsideAppeal?"Decline":"Refusal"}}</div>
                </div>
            </div>
          </div>

          <div class="mb-3 row" *ngIf="contraventionTest.driverDeclinedToTestTypeId == DriverDeclinedToTestTypes.No">
            <div class="col-sm-6">
                <label for="urineSampleTime{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">Urine Sample Time <span class="label-hint">(Required)</span></label>
                <input
                  required #urineSampleTimeControl="ngModel"
                  type="text" placeholder="--:--" mask="{{Constants.Mask.TIME_MASK}}" [patterns]="Constants.Mask.TIME_MASK_PATTERNS" [showMaskTyped]="true" [dropSpecialCharacters]="false"
                  pattern="^(([[0|1]\d)|(2[0-3]))[:]([0-5]\d)$"
                  class="form-control" id="urineSampleTime{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                  name="urineSampleTime{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                  [(ngModel)]="contraventionTest.urineSampleTest.urineSampleTime"
                  [ngClass]="{'is-invalid': (urineSampleTimeControl.touched || impairmentScreeningModel.isSubmitClicked) && urineSampleTimeControl.invalid}">
                <div class="invalid-feedback">
                    <div *ngIf="urineSampleTimeControl.errors?.required">Urine Sample Time is required</div>
                    <div *ngIf="!urineSampleTimeControl.errors?.required">Invalid Urine Sample Time</div>
                </div>
            </div>
              <div class="col-sm-6">
                  <label for="urineLabType{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}">Sent to Provincial or National Lab? <span class="label-hint">(Required)</span></label>
                  <select
                    [appSelectValidator]="true"
                    #urineLabTypeControl="ngModel"
                    class="form-select"
                    id="urineLabType{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                    name="urineLabType{{impairmentScreeningModel.controlSuffixText}}{{contraventionTest.testSequence}}"
                    [(ngModel)]="contraventionTest.urineSampleTest.labTypeId"
                    [ngClass]="{'is-invalid': (urineLabTypeControl.touched || impairmentScreeningModel.isSubmitClicked) && urineLabTypeControl.errors?.defaultSelected}">
                      <option value="0">Select Lab Type</option>
                      <option *ngFor="let labType of labTypes" [value]="labType.id">{{labType.name}}</option>
                  </select>
                  <div class="invalid-feedback">
                      <div>Lab Type is required</div>
                  </div>
              </div>
          </div>
          <app-impairment-screening-alert
            [ImpairmentScreeningMethodId]="impairmentScreeningModel.ImpairmentScreeningMethodId"
            [DriverDeclinedToTestId]="contraventionTest.driverDeclinedToTestTypeId"></app-impairment-screening-alert>

      </div>
  </div>
</ng-container>

<!-- No Test -->
<ng-container *ngIf="impairmentScreeningModel.ImpairmentScreeningMethodId == 7 || impairmentScreeningModel.ImpairmentScreeningMethodId == 8">
    <div class="card mt-4">
        <div class="card-header">
            {{contraventionTest.testSequence > 0 ? impairmentScreeningModel.impairmentScreeningResultCardLabel + " "  + contraventionTest.testSequence.toString() + " Details" : impairmentScreeningModel.impairmentScreeningResultCardLabel }}
        </div>
        <div class="card-body">
            <div class="row justify-content-lg-center">
                <p class="text-muted">{{impairmentScreeningModel.noSelectionMessageLabel}}</p>
            </div>
        </div>
    </div>
</ng-container>
