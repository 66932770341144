<!-- Driver Portal View -->
<app-contravention-page *ngIf="!isRSGPortal">
    <ng-container content>
        <form *ngIf="review" #requestForm="ngForm" [mustMatch]="['contactEmailAddress', 'confirmContactEmailAddress', 'contactPhoneNumber', 'confirmContactPhoneNumber']">
        <div class="d-flex flex-column">
            <h1>Edit Review Details</h1>
            <h3 class="border-bottom pb-4 mb-2">No. {{review.reviewNumber}}</h3>
            <div class="card goa-callout goa--emergency mt-3" *ngIf="isRequestFailed">
                <h6 class="mt-0">Edit Request Failed</h6>
                <p *ngIf="requestFailedMessage; else noMessageSpecified">
                    {{requestFailedMessage}} <a [routerLink]="['../details']">Return back to Review Details.</a>
                </p>
                <ng-template #noMessageSpecified>
                    <p>
                        Your request can't be completed right now. Please try again later.
                    </p>
                </ng-template>
            </div>

            <div class="card mt-3 mt-lg-4" *ngIf="!review.isSubmittedByRegistry || registryAgentInformation.isPaidByRecipient">
                <div class="card-header">
                    Representation Type
                </div>
                <div class="card-body">
                    <div class="mb-3 row">
                        <div class="col-lg-12">
                            <label for="reviewType">Are you the Recipient of the Contravention Notice?</label>
                            <select class="form-select" id="reviewType" name="reviewType"
                                [(ngModel)]="review.isSelfSubmit" (change)="onReviewTypeChange()">
                                <option [ngValue]="true">Yes, I am submitting this application on behalf of myself</option>
                                <option [ngValue]="false">No, I am submitting this application on behalf of another</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>


            <app-representation *ngIf="!review.isSelfSubmit" [model]="review.representation"
                [isSubmitClicked]="requestForm.submitted"
                [isYouth]="contraventionOverview.isRecipientYouth"></app-representation>

            <div class="card mt-4" *ngIf="this.review.reviewTypeId == ReviewTypes.IrsReview">
                <div class="card-header">                    
                    Impaired Roadside Sanction (IRS) Contravention Review
                </div>
                <div class="card-body">
                    <div class="mb-3 row">
                        <div class="col-lg-12">
                            <label>Contravention Number: {{getContraventionNumber(this.review.reviewItems[0].contraventionId)}}</label>
                            <div class="contravention-description">
                                {{getPrimaryCharge(this.review.reviewItems[0].contraventionId)}}
                            </div>
                        </div>
                    </div>
                    <div class="mb-3 row">
                        <div class="col-lg-6">
                            <label for="reviewMethod" class="required">Review Method</label>
                            <div class="ms-1 question-icon" data-bs-toggle="modal" data-bs-target="#aboutRreviewMethodModal">
                                <i class="far fa-question-circle fa-lg"></i>
                            </div>
                            <select disabled class="form-select" id="reviewMethod" name="reviewMethod" required
                                [ngModel]="review.reviewMethodTypeId"
                                #reviewMethod="ngModel" [ngClass]="{ 'is-invalid': requestForm.submitted && reviewMethod.invalid }">
                                <option [ngValue]="null">Select a Method</option>
                                <option *ngFor="let reviewMethodType of reviewMethodTypes" [value]="reviewMethodType.id">{{reviewMethodType.name}}</option>
                            </select>
                            <div class="ps-2" *ngIf="review.reviewMethodTypeId == 2">
                                Oral reviews will be recorded by SafeRoads Alberta.
                            </div>
                            <div class="invalid-feedback">
                                <div>Review Method is required</div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <label for="hearingFeeAmount">Selected Review Fee Amount</label>
                            <input readonly type="text" class="form-control" value="{{review.reviewFeeAmount | currency}}" >
                        </div>
                    </div>
                    <div class="mb-3 row" *ngIf="failedAttendanceDeclarationActive && review.reviewMethodTypeId == ReviewMethodTypes.Oral">
                        <div class="col-12">
                            <app-failed-attendance-declaration
                            [review]="review"
                            [isFormSubmitted]="requestForm.submitted"
                            [isDisabled]="!review.isRescheduleAllowed"
                            [isRSGPortal]="isRSGPortal">
                            </app-failed-attendance-declaration>
                        </div>
                    </div>
                    <div class="mb-3 row">
                        <div class="col-12">
                            <label for="reviewGrounds" class="required">Grounds for Review</label>
                            <p class="mt-0 mb-1">
                                Financial hardship and inconvenience are not grounds for cancellation of your Notice of Administrative Penalty.
                                An adjudicator cannot consider arguments such as:
                            </p>
                            <ul class="mb-1">
                                <li>You do not have the financial means to pay for the fine and/or tow fees.</li>
                                <li>You need your vehicle to get to and from work.</li>
                                <li>You need your vehicle to take your kids to school or a family member to medical appointments.</li>
                            </ul>
                            <p class="mt-0 mb-2">
                                For more information on the grounds to cancel a Notice of Administrative Penalty, 
                                please refer to Section 4 of the <a href="https://kings-printer.alberta.ca/documents/Regs/2020_224.pdf" target="_blank">SafeRoads Alberta Regulation</a>.
                            </p>
                            <textarea class="form-control" id="reviewGrounds" name="reviewGrounds"
                                rows="6" maxlength="5000"
                                [disabled]="!review.isEditReviewGroundsAllowed"
                                required noWhiteSpace #reviewGrounds="ngModel" [(ngModel)]="this.review.reviewItems[0].reviewGrounds"
                                [ngClass]="{ 'is-invalid': requestForm.submitted && reviewGrounds.invalid }"
                                placeholder="Please Provide Your Reason For Requesting a Review (Max 5000 Characters)"></textarea>
                            <div class="invalid-feedback">
                                <div>Grounds for Review is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="mb-3 row">
                        <div class="col-12">
                            <label for="additionalNotes">Additional Request Notes</label>
                            <textarea class="form-control" id="additionalNotes" name="additionalNotes"
                                [disabled]="!review.isEditReviewGroundsAllowed"
                                rows="6" maxlength="5000" [(ngModel)]="review.additionalRequestNotes" 
                                placeholder="Please Provide Any Additional Notes for Your Request (Max 5000 Characters)">
                            </textarea>
                        </div>
                    </div>
                    <div class="mb-3 row">
                        <div class="col-12">
                            <label for="hasInterpreter" class="required">Will You Be Accompanied by an Interpreter?</label>
                            <div class="mt-2">
                                <div class="form-check custom-control-inline">
                                    <input type="radio" class="form-check-input" id="accompainedYes" name="hasInterpreter"
                                        required #hasInterpreter="ngModel" [value]="true" [(ngModel)]="review.isAccompainedByInterpreter">
                                    <label class="form-check-label" for="accompainedYes">Yes</label>
                                </div>
                                <div class="form-check custom-control-inline">
                                    <input type="radio" class="form-check-input" id="accompainedNo" name="hasInterpreter"
                                        required [value]="false" [(ngModel)]="review.isAccompainedByInterpreter">
                                    <label class="form-check-label" for="accompainedNo">No</label>
                                </div>
                                <div>
                                    <div class="text-danger" *ngIf="requestForm.submitted && hasInterpreter.errors?.required">Please indicate whether you will be accompanied by an interpreter</div>
                                </div>
                                <div>
                                    <p>
                                        You are permitted to bring an interpreter to the oral review if you require translation to the English language. You are responsible for securing an interpreter prior to the review and responsible for any costs incurred in obtaining an interpreter.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card mt-4" *ngIf="this.review.reviewTypeId == ReviewTypes.TrafficReview || this.review.reviewTypeId == ReviewTypes.AteReview">
                <div class="card-header">                    
                    Traffic Contravention(s) Review
                </div>
                <div class="card-body">
                    <div *ngFor="let reviewItem of this.review.reviewItems; let i = index;" class="border-bottom mb-3">
                        <div class="mb-3 row">
                            <div class="col-lg-12">
                                <div class="checkbox-lg">
                                    <label for="chkReviewItem_{{reviewItem.contraventionId}}">Contravention Number: {{getContraventionNumber(reviewItem.contraventionId)}}</label>
                                </div>
                                <div class="contravention-description mt-2">
                                    {{getPrimaryCharge(reviewItem.contraventionId)}}
                                </div>
                                <div class="contravention-description mt-2">
                                    {{getSecondaryCharge(reviewItem.contraventionId)}}
                                </div>
                                <div class="contravention-description mt-2">
                                    {{getTertiaryCharge(reviewItem.contraventionId)}}
                                </div>
                            </div>
                        </div>
                        <div class="mb-3 row">
                            <div class="col-12">
                                <div class="d-flex justify-content-between">
                                    <div>
                                        <label for="reviewGrounds" class="required">Grounds for Review</label>
                                    </div>
                                </div>
                                <p class="mt-0 mb-1">
                                    Financial hardship and inconvenience are not grounds for cancellation of your Notice of Administrative Penalty.
                                    An adjudicator cannot consider arguments such as:
                                </p>
                                <ul class="mb-1">
                                    <li>You do not have the financial means to pay for the fine and/or tow fees.</li>
                                    <li>You need your vehicle to get to and from work.</li>
                                    <li>You need your vehicle to take your kids to school or a family member to medical appointments.</li>
                                </ul>
                                <p class="mt-0 mb-2">
                                    For more information on the grounds to cancel a Notice of Administrative Penalty, 
                                    please refer to Section 4 of the <a href="https://kings-printer.alberta.ca/documents/Regs/2020_224.pdf" target="_blank">SafeRoads Alberta Regulation</a>.
                                </p>
                                <textarea class="form-control" 
                                    id="reviewGrounds_{{reviewItem.contraventionId}}" 
                                    name="reviewGrounds_{{reviewItem.contraventionId}}"
                                    rows="6" maxlength="5000"                                    
                                    [disabled]="!review.isEditReviewGroundsAllowed"
                                    [required]="reviewItem.isSelected"
                                    noWhiteSpace #reviewGrounds="ngModel" [(ngModel)]="reviewItem.reviewGrounds"
                                    [ngClass]="{ 'is-invalid': requestForm.submitted && reviewGrounds.invalid }"
                                    placeholder="Please Provide Your Reason For Requesting a Review (Max 5000 Characters)"></textarea>
                                <div class="invalid-feedback">
                                    <div>Grounds for Review is required</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mb-3 row">
                        <div class="col-lg-6">
                            <label for="reviewMethod" class="required">Review Method</label>
                            <div class="ms-1 question-icon" data-bs-toggle="modal" data-bs-target="#aboutRreviewMethodModal">
                                <i class="far fa-question-circle fa-lg"></i>
                            </div>
                            <select disabled class="form-select" id="reviewMethod" name="reviewMethod" required
                                [ngModel]="review.reviewMethodTypeId"
                                #reviewMethod="ngModel" [ngClass]="{ 'is-invalid': requestForm.submitted && reviewMethod.invalid }">
                                <option [ngValue]="null">Select a Method</option>
                                <option *ngFor="let reviewMethodType of reviewMethodTypes" [value]="reviewMethodType.id">{{reviewMethodType.name}}</option>
                            </select>
                            <div class="ps-2" *ngIf="review.reviewMethodTypeId == 2">
                                Oral reviews will be recorded by SafeRoads Alberta.
                            </div>
                            <div class="invalid-feedback">
                                <div>Review Method is required</div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <label for="hearingFeeAmount">Selected Review Fee Amount</label>
                            <input readonly type="text" class="form-control" value="{{review.reviewFeeAmount | currency}}" >
                        </div>
                    </div>
                    <div class="mb-3 row" *ngIf="failedAttendanceDeclarationActive && review.reviewMethodTypeId == ReviewMethodTypes.Oral">
                        <div class="col-12">
                            <app-failed-attendance-declaration
                            [review]="review"
                            [isFormSubmitted]="requestForm.submitted"
                            [isDisabled]="!review.isRescheduleAllowed"
                            [isRSGPortal]="isRSGPortal">
                            </app-failed-attendance-declaration>
                        </div>
                    </div>
                    <div class="mb-3 row">
                        <div class="col-12">
                            <label for="additionalNotes">Additional Request Notes</label>
                            <textarea class="form-control" id="additionalNotes" name="additionalNotes"
                                [disabled]="!review.isEditReviewGroundsAllowed"
                                rows="6" maxlength="5000" [(ngModel)]="review.additionalRequestNotes" 
                                placeholder="Please Provide Any Additional Notes for Your Request (Max 5000 Characters)">
                            </textarea>
                        </div>
                    </div>
                    <div class="mb-3 row">
                        <div class="col-12">
                            <label for="hasInterpreter" class="required">Will You Be Accompanied by an Interpreter?</label>
                            <div class="mt-2">
                                <div class="form-check custom-control-inline">
                                    <input type="radio" class="form-check-input" id="accompainedYes" name="hasInterpreter"
                                        required #hasInterpreter="ngModel" [value]="true" [(ngModel)]="review.isAccompainedByInterpreter">
                                    <label class="form-check-label" for="accompainedYes">Yes</label>
                                </div>
                                <div class="form-check custom-control-inline">
                                    <input type="radio" class="form-check-input" id="accompainedNo" name="hasInterpreter"
                                        required [value]="false" [(ngModel)]="review.isAccompainedByInterpreter">
                                    <label class="form-check-label" for="accompainedNo">No</label>
                                </div>
                                <div>
                                    <div class="text-danger" *ngIf="requestForm.submitted && hasInterpreter.errors?.required">Please indicate whether you will be accompanied by an interpreter</div>
                                </div>
                                <div>
                                    <p>
                                        You are permitted to bring an interpreter to the oral review if you require translation to the English language. You are responsible for securing an interpreter prior to the review and responsible for any costs incurred in obtaining an interpreter.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div>
                <span class="error">{{errorMessage}}</span>
            </div>
            
            <app-contact-information [model]="review" [isSubmitClicked]="requestForm.submitted"></app-contact-information>

            <div class="card border-0">
                <app-foip></app-foip>
            </div>

            <div class="mt-2">
                <app-terms-conditions [version]="review.termsConditionsVersion" [isSubmitClicked]="requestForm.submitted"></app-terms-conditions>
            </div>            

            <div class="mb-3">
                <div class="row pt-3">
                    <div class="col-12 col-sm-6">                
                        <button class="goa-button goa--secondary btn-block" [routerLink]="['../details']">Return to Review</button>
                    </div>
                    <div class="col-12 col-sm-6 text-end">                
                        <button class="goa-button btn-block" (click)="onSaveClick(requestForm.valid)">Save Changes</button>
                    </div>
                </div>
            </div>	
        </div>
        </form>
    </ng-container>
</app-contravention-page>

<!-- Registry Portal View -->
<app-contravention-page *ngIf="isRSGPortal">
    <ng-container content>
        <form *ngIf="review" #requestForm="ngForm" [mustMatch]="['contactEmailAddress', 'confirmContactEmailAddress', 'contactPhoneNumber', 'confirmContactPhoneNumber']">
            <div class="d-flex flex-column">
                <h1>Edit Review Details</h1>
                <h3 class="border-bottom pb-4 mb-2">No. {{review.reviewNumber}}</h3>
                <div class="card goa-callout goa--emergency mt-3" *ngIf="isRequestFailed">
                    <h6 class="mt-0">Edit Request Failed</h6>
                    <p *ngIf="requestFailedMessage; else noMessageSpecified">
                        {{requestFailedMessage}} <a [routerLink]="['../details']">Return back to Review Details.</a>
                    </p>
                    <ng-template #noMessageSpecified>
                        <p>
                            Your request can't be completed right now. Please try again later.
                        </p>
                    </ng-template>
                </div>

                <div *ngIf="review.isSubmittedByRegistry">
                    <app-registry-payer-information
                        [registryAgentInformation]="registryAgentInformation"
                        [isFormSubmitted]="requestForm.submitted"
                        sectionHeader="Requester"
                        sectionLabel="Who is applying for review?"
                        [disabled]="true">
                    </app-registry-payer-information>
                </div>

                <app-representation *ngIf="!review.isSelfSubmit" [model]="review.representation"
                [isSubmitClicked]="requestForm.submitted"
                [hideRepresenationMessage]="review.reviewMethodTypeId == ReviewMethodTypes.Written"
                [isYouth]="contraventionOverview.isRecipientYouth"
                [isRSGPortal]="isRSGPortal"
                [reviewTypeId]="review.reviewTypeId"></app-representation>

    
                <div class="card mt-4" *ngIf="this.review.reviewTypeId == ReviewTypes.IrsReview">
                    <div class="card-header">                    
                        Impaired Roadside Sanction (IRS) Contravention Review
                    </div>
                    <div class="card-body">
                        <div class="mb-3 row">
                            <div class="col-lg-12">
                                <label>Contravention Number: {{getContraventionNumber(this.review.reviewItems[0].contraventionId)}}</label>
                                <div class="contravention-description">
                                    {{getPrimaryCharge(this.review.reviewItems[0].contraventionId)}}
                                </div>
                            </div>
                        </div>
                        <div class="mb-3 row">
                            <div class="col-lg-6">
                                <label for="reviewMethod" class="required">Review Method</label>
                                <div class="ms-1 question-icon" data-bs-toggle="modal" data-bs-target="#aboutRreviewMethodModal">
                                    <i class="far fa-question-circle fa-lg"></i>
                                </div>
                                <select disabled class="form-select" id="reviewMethod" name="reviewMethod" required
                                    [ngModel]="review.reviewMethodTypeId"
                                    #reviewMethod="ngModel" [ngClass]="{ 'is-invalid': requestForm.submitted && reviewMethod.invalid }">
                                    <option [ngValue]="null">Select a Method</option>
                                    <option *ngFor="let reviewMethodType of reviewMethodTypes" [value]="reviewMethodType.id">{{reviewMethodType.name}}</option>
                                </select>
                                <div class="ps-2" *ngIf="review.reviewMethodTypeId == 2">
                                    Oral reviews will be recorded by SafeRoads Alberta.
                                </div>
                                <div class="invalid-feedback">
                                    <div>Review Method is required</div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <label for="hearingFeeAmount">Selected Review Fee Amount</label>
                                <input readonly type="text" class="form-control" value="{{review.reviewFeeAmount | currency}}" >
                            </div>
                        </div>
                        <div class="mb-3 row" *ngIf="failedAttendanceDeclarationActive && review.reviewMethodTypeId == ReviewMethodTypes.Oral">
                            <div class="col-12">
                                <app-failed-attendance-declaration
                                [review]="review"
                                [isFormSubmitted]="requestForm.submitted"
                                [isDisabled]="!review.isRescheduleAllowed"
                                [isRSGPortal]="isRSGPortal">
                                </app-failed-attendance-declaration>
                            </div>
                        </div>
                        <div class="mb-3 row">
                            <div class="col-12">
                                <label for="reviewGrounds" class="required">Grounds for Review</label>
                                <p class="mt-0 mb-1">
                                    Financial hardship and inconvenience are not grounds for cancellation of your Notice of Administrative Penalty.
                                    An adjudicator cannot consider arguments such as:
                                </p>
                                <ul class="mb-1">
                                    <li>You do not have the financial means to pay for the fine and/or tow fees.</li>
                                    <li>You need your vehicle to get to and from work.</li>
                                    <li>You need your vehicle to take your kids to school or a family member to medical appointments.</li>
                                </ul>
                                <p class="mt-0 mb-2">
                                    For more information on the grounds to cancel a Notice of Administrative Penalty, 
                                    please refer to Section 4 of the <a href="https://kings-printer.alberta.ca/documents/Regs/2020_224.pdf" target="_blank">SafeRoads Alberta Regulation</a>.
                                </p>
                                <textarea class="form-control" id="reviewGrounds" name="reviewGrounds"
                                    rows="6" maxlength="5000"
                                    [disabled]="!review.isEditReviewGroundsAllowed"
                                    required noWhiteSpace #reviewGrounds="ngModel" [(ngModel)]="this.review.reviewItems[0].reviewGrounds"
                                    [ngClass]="{ 'is-invalid': requestForm.submitted && reviewGrounds.invalid }"
                                    placeholder="Please Provide Your Reason For Requesting a Review (Max 5000 Characters)"></textarea>
                                <div class="invalid-feedback">
                                    <div>Grounds for Review is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="mb-3 row">
                            <div class="col-12">
                                <label for="additionalNotes">Additional Request Notes</label>
                                <textarea class="form-control" id="additionalNotes" name="additionalNotes"
                                    [disabled]="!review.isEditReviewGroundsAllowed"
                                    rows="6" maxlength="5000" [(ngModel)]="review.additionalRequestNotes" 
                                    placeholder="Please Provide Any Additional Notes for Your Request (Max 5000 Characters)">
                                </textarea>
                            </div>
                        </div>
                        <div class="mb-3 row">
                            <div class="col-12">
                                <label for="hasInterpreter" class="required">Will You Be Accompanied by an Interpreter?</label>
                                <div class="mt-2">
                                    <div class="form-check custom-control-inline">
                                        <input type="radio" class="form-check-input" id="accompainedYes" name="hasInterpreter"
                                            required #hasInterpreter="ngModel" [value]="true" [(ngModel)]="review.isAccompainedByInterpreter">
                                        <label class="form-check-label" for="accompainedYes">Yes</label>
                                    </div>
                                    <div class="form-check custom-control-inline">
                                        <input type="radio" class="form-check-input" id="accompainedNo" name="hasInterpreter"
                                            required [value]="false" [(ngModel)]="review.isAccompainedByInterpreter">
                                        <label class="form-check-label" for="accompainedNo">No</label>
                                    </div>
                                    <div>
                                        <div class="text-danger" *ngIf="requestForm.submitted && hasInterpreter.errors?.required">Please indicate whether you will be accompanied by an interpreter</div>
                                    </div>
                                    <div>
                                        <p>
                                            You are permitted to bring an interpreter to the oral review if you require translation to the English language. You are responsible for securing an interpreter prior to the review and responsible for any costs incurred in obtaining an interpreter.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    
                <div class="card mt-4" *ngIf="this.review.reviewTypeId == ReviewTypes.TrafficReview || this.review.reviewTypeId == ReviewTypes.AteReview">
                    <div class="card-header">                    
                        Traffic Contravention(s) Review
                    </div>
                    <div class="card-body">
                        <div *ngFor="let reviewItem of this.review.reviewItems; let i = index;" class="border-bottom mb-3">
                            <div class="mb-3 row">
                                <div class="col-lg-12">
                                    <div class="checkbox-lg">
                                        <label for="chkReviewItem_{{reviewItem.contraventionId}}">Contravention Number: {{getContraventionNumber(reviewItem.contraventionId)}}</label>
                                    </div>
                                    <div class="contravention-description mt-2">
                                        {{getPrimaryCharge(reviewItem.contraventionId)}}
                                    </div>
                                    <div class="contravention-description mt-2">
                                        {{getSecondaryCharge(reviewItem.contraventionId)}}
                                    </div>
                                    <div class="contravention-description mt-2">
                                        {{getTertiaryCharge(reviewItem.contraventionId)}}
                                    </div>
                                </div>
                            </div>
                            <div class="mb-3 row">
                                <div class="col-12">
                                    <div class="d-flex justify-content-between">
                                        <div>
                                            <label for="reviewGrounds" class="required">Grounds for Review</label>
                                        </div>
                                    </div>
                                    <p class="mt-0 mb-1">
                                        Financial hardship and inconvenience are not grounds for cancellation of your Notice of Administrative Penalty.
                                        An adjudicator cannot consider arguments such as:
                                    </p>
                                    <ul class="mb-1">
                                        <li>You do not have the financial means to pay for the fine and/or tow fees.</li>
                                        <li>You need your vehicle to get to and from work.</li>
                                        <li>You need your vehicle to take your kids to school or a family member to medical appointments.</li>
                                    </ul>
                                    <p class="mt-0 mb-2">
                                        For more information on the grounds to cancel a Notice of Administrative Penalty, 
                                        please refer to Section 4 of the <a href="https://kings-printer.alberta.ca/documents/Regs/2020_224.pdf" target="_blank">SafeRoads Alberta Regulation</a>.
                                    </p>
                                    <textarea class="form-control" 
                                        id="reviewGrounds_{{reviewItem.contraventionId}}" 
                                        name="reviewGrounds_{{reviewItem.contraventionId}}"
                                        rows="6" maxlength="5000"                                    
                                        [disabled]="!review.isEditReviewGroundsAllowed"
                                        [required]="reviewItem.isSelected"
                                        noWhiteSpace #reviewGrounds="ngModel" [(ngModel)]="reviewItem.reviewGrounds"
                                        [ngClass]="{ 'is-invalid': requestForm.submitted && reviewGrounds.invalid }"
                                        placeholder="Please Provide Your Reason For Requesting a Review (Max 5000 Characters)"></textarea>
                                    <div class="invalid-feedback">
                                        <div>Grounds for Review is required</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mb-3 row">
                            <div class="col-lg-6">
                                <label for="reviewMethod" class="required">Review Method</label>
                                <div class="ms-1 question-icon" data-bs-toggle="modal" data-bs-target="#aboutRreviewMethodModal">
                                    <i class="far fa-question-circle fa-lg"></i>
                                </div>
                                <select disabled class="form-select" id="reviewMethod" name="reviewMethod" required
                                    [ngModel]="review.reviewMethodTypeId"
                                    #reviewMethod="ngModel" [ngClass]="{ 'is-invalid': requestForm.submitted && reviewMethod.invalid }">
                                    <option [ngValue]="null">Select a Method</option>
                                    <option *ngFor="let reviewMethodType of reviewMethodTypes" [value]="reviewMethodType.id">{{reviewMethodType.name}}</option>
                                </select>
                                <div class="ps-2" *ngIf="review.reviewMethodTypeId == 2">
                                    Oral reviews will be recorded by SafeRoads Alberta.
                                </div>
                                <div class="invalid-feedback">
                                    <div>Review Method is required</div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <label for="hearingFeeAmount">Selected Review Fee Amount</label>
                                <input readonly type="text" class="form-control" value="{{review.reviewFeeAmount | currency}}" >
                            </div>
                        </div>                    
                        <div class="mb-3 row" *ngIf="failedAttendanceDeclarationActive && review.reviewMethodTypeId == ReviewMethodTypes.Oral">
                            <div class="col-12">
                                <app-failed-attendance-declaration
                                [review]="review"
                                [isFormSubmitted]="requestForm.submitted"
                                [isDisabled]="!review.isRescheduleAllowed"
                                [isRSGPortal]="isRSGPortal">
                                </app-failed-attendance-declaration>
                            </div>
                        </div>
                        <div class="mb-3 row">
                            <div class="col-12">
                                <label for="additionalNotes">Additional Request Notes</label>
                                <textarea class="form-control" id="additionalNotes" name="additionalNotes"
                                    [disabled]="!review.isEditReviewGroundsAllowed"
                                    rows="6" maxlength="5000" [(ngModel)]="review.additionalRequestNotes" 
                                    placeholder="Please Provide Any Additional Notes for Your Request (Max 5000 Characters)">
                                </textarea>
                            </div>
                        </div>
                        <div class="mb-3 row">
                            <div class="col-12">
                                <label for="hasInterpreter" class="required">Will You Be Accompanied by an Interpreter?</label>
                                <div class="mt-2">
                                    <div class="form-check custom-control-inline">
                                        <input type="radio" class="form-check-input" id="accompainedYes" name="hasInterpreter"
                                            required #hasInterpreter="ngModel" [value]="true" [(ngModel)]="review.isAccompainedByInterpreter">
                                        <label class="form-check-label" for="accompainedYes">Yes</label>
                                    </div>
                                    <div class="form-check custom-control-inline">
                                        <input type="radio" class="form-check-input" id="accompainedNo" name="hasInterpreter"
                                            required [value]="false" [(ngModel)]="review.isAccompainedByInterpreter">
                                        <label class="form-check-label" for="accompainedNo">No</label>
                                    </div>
                                    <div>
                                        <div class="text-danger" *ngIf="requestForm.submitted && hasInterpreter.errors?.required">Please indicate whether you will be accompanied by an interpreter</div>
                                    </div>
                                    <div>
                                        <p>
                                            You are permitted to bring an interpreter to the oral review if you require translation to the English language. You are responsible for securing an interpreter prior to the review and responsible for any costs incurred in obtaining an interpreter.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div>
                    <span class="error">{{errorMessage}}</span>
                </div>
                
                <app-contact-information [model]="review" [isSubmitClicked]="requestForm.submitted"></app-contact-information>
    
                <div class="mb-3">
                    <div class="row pt-3">
                        <div class="col-12 col-sm-6">                
                            <button class="goa-button goa--secondary btn-block" [routerLink]="['../details']">Return to Review</button>
                        </div>
                        <div class="col-12 col-sm-6 text-end">                
                            <button class="goa-button btn-block" (click)="onSaveClick(requestForm.valid)">Save Changes</button>
                        </div>
                    </div>
                </div>	
            </div>
            </form>
    </ng-container>
</app-contravention-page>

<!-- Modal -->
<div class="modal" id="aboutRreviewMethodModal" tabindex="-1" role="dialog" aria-labelledby="aboutRreviewMethodModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content p-4">
            <div class="modal-header border-0 pt-2">
                <span class="modal-title h4" id="aboutRreviewMethodModal">
                    Oral vs. Written Review Methods
                </span>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                    <i class="far fa-times-circle fa-lg"></i>
                </button>
            </div>
            <div class="modal-body py-0" *ngIf="!isRSGPortal">
                <h4>Oral Review</h4>
                <p>You will meet with an adjudicator by videoconference or by phone. 
                    A videoconferencing link or telephone number will be provided to connect at the scheduled review time. 
                    Your review will be 30 minutes in length.</p>
                <h4>Written Review</h4>
                <p>You will provide a written submission with your reasons for disputing the Notice of Administrative Penalty, 
                    along with other supporting documents, if applicable. 
                    You do not meet with an adjudicator or attend the review.</p>
                <h4>Decision</h4>
                <p>For both review methods, the adjudicator will make their decision to confirm 
                    or cancel your Notice of Administrative Penalty in writing, 
                    within 30 days from the date you were issued the Notice(s).</p>
                <p>
                    For details, visit <a target="_blank" href="https://www.saferoads.com">SafeRoads Alberta</a>.
                </p>
            </div>
            <div class="modal-body py-0" *ngIf="isRSGPortal">
                <h4>Oral Review</h4>
                <p>The client will meet with an adjudicator by videoconference or by phone. 
                    A videoconferencing link or telephone number will be provided to connect at the scheduled review time. 
                    The review will be 30 minutes in length.</p>
                <h4>Written Review</h4>
                <p>The client will provide a written submission with their reasons for disputing the Notice of Administrative Penalty, 
                    along with other supporting documents, if applicable. 
                    The client does not meet with an adjudicator or attend the review.</p>
                <h4>Decision</h4>
                <p>For both review methods, the adjudicator will make their decision to confirm or cancel the Notice 
                    of Administrative Penalty in writing, within 30 days from the date the client was issued the Notice(s).</p>
            </div>
        </div>
    </div>
</div>