<!-- Driver Portal View -->
<app-contravention-page *ngIf="!isRSGPortal" (onBeforeNavigate)="cancelRequest()">
    <ng-container content>
        <form *ngIf="review" #requestForm="ngForm">
            <div class="d-flex flex-column">
                <h1>Schedule Review</h1>
                <div class="d-flex w-100 border-bottom mb-2">
                    <h3>Notice(s). {{this.getContraventionsInReview()}}</h3>
                    <div class="countdown">
                        <countdown-timer #cd [key]="countdownKey" [lapseMessage]="lapseMessage"></countdown-timer>
                    </div>
                </div>

                <ng-container *ngIf="review.reviewMethodTypeId===2">
                    <div class="card border-0">
                        <p>
                            Please select a timeslot below to schedule a review.
                        </p>
                        <p *ngIf="availabilityView?.availabilityInformationList?.length > 0">
                            If you require assistance with your review request, please contact SafeRoads Alberta at <a href="tel:780-427-7233">780-427-SAFE (7233)</a> or by email to <a href="mailto:saferoads@gov.ab.ca">saferoads&#64;gov.ab.ca</a>.
                        </p>
                    </div>

                    <div class="card border-0">
                        <app-auto-scheduler [scheduleDate]="review.reviewDate" [scheduleTime]="review.reviewTime"
                            [schedule]="availabilityView" (scheduleDateSelected)="onScheduleDateSelected($event)"
                            (onRefresh)="onSchedulerRefresh()">
                        </app-auto-scheduler>
                    </div>

                    <div *ngIf="isAvailabilityRequestFailed" class="card border-0 text-danger">
                        <p>
                            An error occurred. Please contact SafeRoads Alberta at <a href="tel:780-427-7233">780-427-SAFE (7233)</a> or by email to <a href="mailto:saferoads@gov.ab.ca">saferoads&#64;gov.ab.ca</a> for assistance with your review request.
                        </p>
                    </div>
                    <div class="card border-0 text-danger">
                        <p>
                            {{ errorMessage }}
                        </p>
                    </div>

                    <div class="card border-0" *ngIf="documentSubmissionCutoffDate">
                        <p>
                            By selecting this day, you must submit all your supporting documents by
                            <b>11:59pm</b> on <b>{{ documentSubmissionCutoffDate | date:'fullDate':'UTC' }}</b>.
                        </p>
                    </div>
                </ng-container>

                <ng-container *ngIf="review.reviewMethodTypeId===1">
                    <div class="card border-0">
                        <p>
                            What day would you like your matter to be reviewed? Please note: you
                            <b><u>do not</u></b> attend a written review
                        </p>
                        <p *ngIf="availabilityViewForWritten?.length > 0">
                            If you require assistance with your review request, please contact SafeRoads Alberta at <a href="tel:780-427-7233">780-427-SAFE (7233)</a> or by email to <a href="mailto:saferoads@gov.ab.ca">saferoads&#64;gov.ab.ca</a>.
                        </p>
                        <div *ngIf="availabilityView">
                            <h3 class="display">{{availabilityView.startDate | date:'MMM dd, yyyy'}} - {{availabilityView.endDate |
                                date:'MMM dd, yyyy'}}</h3>
                            <button (click)="onRefreshClick()" class="goa-button goa--secondary m-0 mt-3 ml-auto refreshBtn">
                                Refresh
                            </button>
                        </div>
                    </div>                    

                    <div *ngIf="availabilityViewForWritten != null" class="card border-0">
                        <div *ngIf="availabilityViewForWritten.length == 0" class="h6">
                            There are no available review dates. Please contact SafeRoads Alberta at <a href="tel:780-427-7233">780-427-SAFE (7233)</a> or by email to <a href="mailto:saferoads@gov.ab.ca">saferoads&#64;gov.ab.ca</a> for assistance with your review request.
                        </div>
                        <ul *ngIf="availabilityViewForWritten.length > 0">
                            <li class="cal-dateslot"
                                [ngClass]="{ 'cal-dateslot-selected': availableInfo === selAvailableInfoForWritten }"
                                *ngFor="let availableInfo of availabilityViewForWritten"
                                (click)="onDateSelected(availableInfo)">
                                <span>{{ availableInfo.date | date:'longDate' }}</span>
                                <br>
                                <b>{{ availableInfo.date | date:'EEEE' }}</b>
                            </li>
                        </ul>
                    </div>

                    <div *ngIf="isAvailabilityRequestFailed" class="card border-0 text-danger">
                        <p>
                            An error occurred. Please contact SafeRoads Alberta at <a href="tel:780-427-7233">780-427-SAFE (7233)</a> or by email to <a href="mailto:saferoads@gov.ab.ca">saferoads&#64;gov.ab.ca</a> for assistance with your review request.
                        </p>
                    </div>
                    <div class="card border-0 text-danger">
                        <p>
                            {{ errorMessage }}
                        </p>
                    </div>

                    <div class="card border-0" *ngIf="documentSubmissionCutoffDate">
                        <p>
                            By selecting this day, you must submit all your supporting documents by
                            <b>11:59pm</b> on <b>{{ documentSubmissionCutoffDate | date:'fullDate':'UTC' }}</b>.
                        </p>
                    </div>
                </ng-container>

                <div class="form-group">
                    <div class="row">
                        <div class="col-12 col-sm-6">
                            <button class="goa-button goa--secondary btn-block"
                                [routerLink]="['../details']">Return to Review Details</button>
                        </div>
                        <div class="col-12 col-sm-6 text-right">
                            <button class="goa-button btn-block" (click)="continueRequest(requestForm.valid)">Proceed to
                                Payment Method</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </ng-container>
</app-contravention-page>

<!-- Registry Portal View -->
<app-contravention-page *ngIf="isRSGPortal" (onBeforeNavigate)="cancelRequest()">
    <ng-container content>
        <form *ngIf="review" #requestForm="ngForm">
            <div class="d-flex flex-column">
                <h1>Schedule Review</h1>
                <div class="d-flex w-100 border-bottom mb-2">
                    <h3>Notice(s). {{this.getSelectedContraventionsInReview()}}</h3>
                    <div class="countdown">
                        <countdown-timer #cd [key]="countdownKey" [lapseMessage]="lapseMessage"></countdown-timer>
                    </div>
                </div>

                <ng-container *ngIf="review.reviewMethodTypeId===2">
                <div class="card border-0">
                    <div class="goa-callout goa--information mt-1">
                        <h6 class="mt-0">Schedule the Review</h6>                    
                        <p>
                            Please ask the client to choose a timeslot when they wish their contravention to be reviewed.
                        </p>  
                    </div>
                </div>
               
                <div class="card border-0">
                    <app-auto-scheduler [scheduleDate]="review.reviewDate" [scheduleTime]="review.reviewTime"
                        [schedule]="availabilityView" (scheduleDateSelected)="onScheduleDateSelected($event)"
                        (onRefresh)="onSchedulerRefresh()">
                    </app-auto-scheduler>
                </div>

                <div *ngIf="isAvailabilityRequestFailed" class="card border-0 text-danger">
                    <p>
                        An error occurred. Please contact SafeRoads Alberta at <a href="tel:780-427-7233">780-427-SAFE (7233)</a> or by email to <a href="mailto:saferoads@gov.ab.ca">saferoads&#64;gov.ab.ca</a> for assistance with your review request.
                    </p>
                </div>
                <div class="card border-0 text-danger">
                    <p>
                        {{ errorMessage }}
                    </p>
                </div>

                <div class="card border-0" *ngIf="documentSubmissionCutoffDate">
                    <p>
                        By selecting this day, you must submit all your supporting documents by
                        <b>11:59pm</b> on <b>{{ documentSubmissionCutoffDate | date:'fullDate':'UTC' }}</b>.
                    </p>
                </div>
                </ng-container>
                
                <ng-container *ngIf="review.reviewMethodTypeId===1">
                    <div class="card border-0">
                        <div class="goa-callout goa--information mt-1">
                            <h6 class="mt-0">Schedule the Review</h6>                    
                            <p>
                                Please ask the client to choose a day when they wish their matter to be reviewed.
                            </p>  
                            <p>
                                Please note: the client <b><u>does not</u></b> attend a written review.
                            </p>
                        </div>
                    </div>
                    <div class="card border-0">
                        <div *ngIf="availabilityView">
                            <h3 class="display">{{availabilityView.startDate | date:'MMM dd, yyyy'}} - {{availabilityView.endDate |
                                date:'MMM dd, yyyy'}}</h3>
                            <button (click)="onRefreshClick()" class="goa-button goa--secondary m-0 mt-3 ml-auto refreshBtn">
                                Refresh
                            </button>
                        </div>                     
                    </div>     
    
                    <div *ngIf="availabilityViewForWritten != null" class="card border-0">
                        <div *ngIf="availabilityViewForWritten.length == 0" class="h6">
                            There are no available review dates. Please contact SafeRoads Alberta at <a href="tel:780-427-7233">780-427-SAFE (7233)</a> or by email to <a href="mailto:saferoads@gov.ab.ca">saferoads&#64;gov.ab.ca</a> for assistance with your review request.
                        </div>
                        <ul *ngIf="availabilityViewForWritten.length > 0">
                            <li class="cal-dateslot"
                                [ngClass]="{ 'cal-dateslot-selected': availableInfo === selAvailableInfoForWritten }"
                                *ngFor="let availableInfo of availabilityViewForWritten"
                                (click)="onDateSelected(availableInfo)">
                                <span>{{ availableInfo.date | date:'longDate' }}</span>
                                <br>
                                <b>{{ availableInfo.date | date:'EEEE' }}</b>
                            </li>
                        </ul>
                    </div>

                    <div *ngIf="isAvailabilityRequestFailed" class="card border-0 text-danger">
                        <p>
                            An error occurred. Please contact SafeRoads Alberta at <a href="tel:780-427-7233">780-427-SAFE (7233)</a> or by email to <a href="mailto:saferoads@gov.ab.ca">saferoads&#64;gov.ab.ca</a> for assistance with your review request.
                        </p>
                    </div>
                    <div class="card border-0 text-danger">
                        <p>
                            {{ errorMessage }}
                        </p>
                    </div>

    
                    <div class="card border-0" *ngIf="documentSubmissionCutoffDate">
                        <p>
                            By selecting this day, you must submit all your supporting documents by 
                            <b>11:59pm</b> on <b>{{ documentSubmissionCutoffDate | date:'fullDate':'UTC' }}</b>.
                        </p>
                    </div>
                </ng-container>   

                <div class="form-group">
                    <div class="row">
                        <div class="col-12 col-sm-6">
                            <button class="goa-button goa--secondary btn-block"
                                [routerLink]="['../tech-material-library']">Return to Technical Materials Library</button>
                        </div>
                        <div class="col-12 col-sm-6 text-right">
                            <button class="goa-button btn-block" (click)="continueRequest(requestForm.valid)">Proceed to
                                Payment Method</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </ng-container>
</app-contravention-page>