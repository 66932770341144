<div class="card mt-4">
    <div class="card-header">
        Vehicle Seizure Details
    </div>
    <div class="card-body">
        <div class="mb-3 row">
            <div class="col-lg-4">
                <label for="seizureNumber">Seizure Number</label>
                <input type="text" readonly class="form-control" id="seizureNumber" 
                    [ngModel]="vehicleSeizure.seizureNumber">
            </div>

            <div class="col-lg-4">
                <label for="seizureDate">Seizure Date</label>
                <input type="text" readonly class="form-control" id="seizureDate"
                    value="{{vehicleSeizure.seizureDate | date:'MM/dd/yyyy'}}">
            </div>

            <div class="col-lg-4">
                <label for="seizureTime">Seizure Time</label>
                <input type="text" readonly class="form-control" id="seizureTime"
                    value="{{vehicleSeizure.seizureTime | time}}">
            </div>				
        </div>
        <div class="mb-3 row">
            <div class="col-lg-4">
                <label for="seizureDuration">Immediate Seizure Duration</label>
                <input type="text" readonly class="form-control" id="seizureDuration" 
                    [ngModel]="seizureDuration">
            </div>

            <div class="col-lg-4">
                <label for="vehicleReleaseDate">Vehicle Release Date</label>
                <input type="text" readonly class="form-control" id="vehicleReleaseDate"
                    value="{{vehicleSeizure.vehicleReleaseDate | date:'MM/dd/yyyy'}}">
            </div>
        </div>
        <div class="mb-3 row">
            <div class="col-lg-8">
                <label for="locationTypeName">Location of Seizure</label>
                <input type="text" readonly class="form-control" id="locationTypeName" 
                    [ngModel]="locationTypeName">
            </div>
        </div>
    </div>
</div>