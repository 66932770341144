<form #lookupForm="ngForm">
<div class="d-flex flex-column">
    <h1>Find a Contravention</h1>
    <div class="card border-0 mt-3">
        <div class="goa-callout goa--information mt-0 mb-3">
            <p class="mt-0">
                <b>Important:</b> Not all browsers work the same, so there may be some variation in user experience depending on what browser and device is used. Clear your browser’s cache and history regularly or update the browser’s setting to clear the cache automatically when the browser is closed (if that setting is available).
            </p>
        </div>

        <h3 class="mt-0">Provide your details as they appear on your notice of administrative penalty</h3>
        
        <div class="goa-callout goa--emergency mt-0 mb-3" *ngIf="isAccessForbidden">
            <h6 class="mt-0">Error</h6>
            <p>
                Error verifying reCAPTCHA, please try again.
            </p>
        </div>

            <div class="goa-callout goa--emergency mt-0 mb-3" *ngIf="isServerErr">
                <h6 class="mt-0">Error</h6>
                <p>
                    {{errorMessage}}
                </p>
            </div>

            <div class="goa-callout goa--emergency mt-0 mb-3" *ngIf="isContraventiondetailsNotFound">
                <h6 class="mt-0">Contravention Not Found</h6>
                <p>
                    The details provided did not find a match.
                    For help, contact
                    <a href="#contactModal" data-bs-toggle="modal" data-bs-target="#contactModal">SafeRoads Alberta.</a>
                </p>
            </div>

            <div class="goa-callout goa--emergency mt-0 mb-3" *ngIf="isContraventionNotFound">
                <h6 class="mt-0">Contravention Not Found</h6>
                <p>
                    The number provided did not find a match.
                    For help, contact
                    <a href="#contactModal" data-bs-toggle="modal" data-bs-target="#contactModal">SafeRoads Alberta.</a>
                </p>
            </div>
        </div>
        <div class="card border-0">
            <form #validateContraventionForm="ngForm">
                <div class="mb-3 row">
                    <div class="col-12 col-sm-8">
                        <label for="contraventionNumber" class="required">Contravention Number</label>
                        <input type="text" required appContraventionNumberValidator=true class="form-control uppercase" id="contraventionNumber"
                            name="contraventionNumber" #contraventionNumber="ngModel"
                            [(ngModel)]="searchCriteria.contraventionNumber" (ngModelChange)="clearSearchResult()"
                            [ngClass]="{ 'is-invalid': validateContraventionForm.submitted && contraventionNumber.invalid }"
                            minlength="10" maxlength="10">
                        <div class="invalid-feedback">
                            <div *ngIf="contraventionNumber.errors?.invalidLength">A valid 10-digit contravention number starting with "A" or "C" is required</div>
                            <div *ngIf="contraventionNumber.errors?.invalidContraventionNumber">Invalid contravention number</div>
                        </div>
                    </div>

                    <div class="col-12 col-sm-4">
                        <button class="btn-block goa-button mt-2 lookupBtn"
                            (click)="onLookupClick(validateContraventionForm.valid,lookupForm)">
                            Lookup
                        </button>
                    </div>
                </div>
            </form>

            <hr>

            <ng-container *ngIf="searchResult!=null">
                <ng-container *ngIf="searchResult?.isIssuedToRegisteredOwner">
                    <div class="mb-3 row">
                        <div class="col-12 col-sm-12">
                            <label for="licencePlate" class="required">Licence Plate</label>
                            <input type="text" required class="form-control" id="licencePlate" name="licencePlate"
                                placeholder="Enter the licence plate number on your Notice" maxlength="10"
                                [(ngModel)]="searchCriteria.licencePlateNumber" #licencePlate="ngModel"
                                (ngModelChange)="resetFlags()"
                                [ngClass]="{ 'is-invalid': lookupForm.submitted && licencePlate.invalid || isContraventiondetailsNotFound}">
                            <div class="invalid-feedback">
                                <div *ngIf="licencePlate.errors?.required">Licence Plate Number is required</div>
                                <div *ngIf="!licencePlate.errors?.required">The licence plate number must match the one
                                    on the Notice</div>
                            </div>
                        </div>
                    </div>
                    <div class="mb-3 row">
                        <div class="col-12 col-sm-12">
                            <label for="occurrenceDate" class="required">Occurrence Date</label>
                            <input type="text" required class="form-control" id="occurrenceDate" name="occurrenceDate"
                                placeholder="YYYY-MM-DD" bsDatepicker [bsConfig]="datePickerConfig" autocomplete="off"
                                [(ngModel)]="contraventionOccurrenceDate" #occurrenceDate="ngModel"
                                (ngModelChange)="resetFlags()"
                                [ngClass]="{ 'is-invalid': lookupForm.submitted && occurrenceDate.invalid || isContraventiondetailsNotFound}">
                            <div class="invalid-feedback">                                
                                <div *ngIf="occurrenceDate.errors?.required">Occurrence Date is required</div>
                                <div *ngIf="!occurrenceDate.errors?.required">This date must match the Occurrence Date
                                    on the Notice</div>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="!searchResult?.isIssuedToRegisteredOwner">
                    <div class="mb-3 row">
                        <div class="col-12 col-sm-12">
                            <label for="lastName" class="required">Last Name</label>
                            <input type="text" required class="form-control" id="lastName" name="lastName"
                                placeholder="Last Name" maxlength="50" [(ngModel)]="searchCriteria.lastName"
                                (ngModelChange)="resetFlags()" #lastName="ngModel"
                                [ngClass]="{ 'is-invalid': lookupForm.submitted && lastName.invalid || isContraventiondetailsNotFound}">
                            <div class="invalid-feedback">
                                <div *ngIf="lastName.errors?.required">Last Name is required</div>
                                <div *ngIf="!lastName.errors?.required">The last name must match the one on the Notice
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mb-3 row">
                        <div class="col-12 col-sm-12">
                            <label for="birthDate" class="required">Date of Birth</label>
                            <input type="text" required class="form-control" id="birthDate" name="birthDate"
                                placeholder="YYYY-MM-DD" bsDatepicker [bsConfig]="datePickerConfig" autocomplete="off"
                                [(ngModel)]="driverBirthDate" #birthDate="ngModel" (ngModelChange)="resetFlags()"
                                [ngClass]="{ 'is-invalid': lookupForm.submitted && birthDate.invalid || isContraventiondetailsNotFound}">
                            <div class="invalid-feedback">
                                <div *ngIf="birthDate.errors?.required">Date of Birth is required</div>
                                <div *ngIf="!birthDate.errors?.required">The date of birth must match the one on the
                                    Notice</div>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <div class="mb-3 row">
                    <div class="col-12 col-sm-12">
                        <label for="occurrenceTime" class="required">Occurrence Time</label>
                        <input required #occurrenceTimeControl="ngModel" type="text" [patterns]="Constants.Mask.TIME_MASK_PATTERNS" mask="{{Constants.Mask.TIME_MASK}}" [showMaskTyped]="true" [dropSpecialCharacters]="false"
                            pattern="^(([[0|1]\d)|(2[0-3]))[:]([0-5]\d)$" class="form-control" id="occurrenceTime"
                            name="occurrenceTime" placeholder="--:--" [(ngModel)]="searchCriteria.occurrenceTime"
                            (ngModelChange)="resetFlags()"
                            [ngClass]="{'is-invalid':lookupForm.submitted && occurrenceTimeControl.invalid || isContraventiondetailsNotFound}">
                        <div class="invalid-feedback">
                            <div *ngIf="occurrenceTimeControl.errors?.required">Occurrence Time is required</div>
                            <div *ngIf="!occurrenceTimeControl.errors?.required">This time must match the Occurrence time
                                on the Notice</div>
                        </div>
                    </div>
                </div>
            </ng-container>

            <div class="row mb-3">
                <div class="col-12 col-sm-12">
                    <button class="btn-block goa-button mt-2 disabled"
                        [disabled]="searchResult==null || isContraventiondetailsNotFound"
                        (click)="onSearchClick(lookupForm.valid)">
                        Search
                    </button>
                </div>
            </div>
        </div>

        <div class="mt-2">
            <a href="#contactModal" data-bs-toggle="modal" data-bs-target="#contactModal">Need Help Accessing Your
                Contravention?</a>
        </div>

        <!-- Modal -->
        <div class="modal" id="contactModal" tabindex="-1" role="dialog" aria-labelledby="contactModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div class="modal-content p-4">
                    <div class="modal-header border-0 pt-2">
                        <span class="modal-title" id="contactModalLabel">
                            Need Help Accessing Your Contravention?
                        </span>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                            <i class="far fa-times-circle fa-lg"></i>
                        </button>
                    </div>
                    <div class="modal-body py-0">
                        <p>For support accessing the details of your Notice of Administrative Penalty or help making a fine payment,
                            scheduling a review, uploading supporting documents, or any technical issues experienced with the SafeRoads
                            portal, please contact:</p>
                        <p>
                            <b>SafeRoads Alberta</b>
                            <br>Phone: <a href="tel:780-427-7233">780-427-7233</a>
                            <br>Toll free: <a href="tel:310-0000">310-0000</a> before the number (in Alberta)
                            <br>Email: <a href="mailto:saferoads@gov.ab.ca">saferoads&#64;gov.ab.ca</a>
                        </p>
                        <p>SafeRoads Alberta is open from 8:15 a.m. to 12:00 p.m. and 1:00 p.m. to 4:30 p.m., Monday through Friday.
                            We are closed weekends and on Alberta Public Service paid holidays.</p>
                        <p>Be advised that you will be required to provide personal identifying information for verification purposes
                            and to assist with your enquiry.</p>
                    </div>
                    <div class="modal-footer border-0">
                        <button type="button" class="btn-modal goa-button mt-2" data-bs-dismiss="modal">Okay</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-fussion" [fullScreen]="true">
    <p style="color: white"> Searching... </p>
</ngx-spinner>