<!-- Driver Portal View -->
<app-contravention-page *ngIf="!isRSGPortal">
    <ng-container content>
        <form #requestForm="ngForm">
        <div class="d-flex flex-column">
            <h1>Supporting Documents</h1>
            <h3 class="border-bottom pb-4 mb-2">Notice(s). {{getNotices()}}</h3>

            <div class="card goa-callout goa--info">
                <p class="mt-0">
                    Any supporting documents you wish to have considered as part of your 
                    late review request <b>must be uploaded now.</b>
                    You will <u>not</u> be allowed to upload further supporting documents once 
                    this request application is submitted.
                </p>
                <p>
                    If you are awaiting further documents, <b>do not file</b> your request at this time. 
                    Only file your request for a late review when you can upload all of the documents 
                    that you want to have considered.
                    Please note: <b>Your submission must still be made promptly</b> in accordance with section 10 
                    of the <a class="fst-italic" href="https://kings-printer.alberta.ca/documents/Regs/2020_217.pdf" target="_blank">Provincial Administrative Penalties Regulation</a>.
                </p>
            </div>

            <div class="card mt-4">
                <div class="card-header">
                    Supporting Documents
                </div>
                <div class="card-body">  
                    <div *ngFor="let doc of documents; let i = index; trackBy: trackDocument">
                        <file-upload [document]="doc" [fileUploadModel]="fileUploadModel"
                            [isPrimaryDocument]="doc.forcePrimaryDocument"
                            (onCancelEvent)="onCancelDcoument($event)"
                            (onRemoveEvent)="onRemoveDocument($event)">
                        </file-upload>
                    </div>
                    
                    <div class="mb-3 row">
                        <div class="col-lg-12">                    
                            <button class="goa-button goa--secondary float-end" (click)="AddOptionalDocument()">Add Additional Document</button>                    
                        </div>
                    </div> 
                    <div *ngIf="errorMessage.length > 0">
                        <span class="error">{{errorMessage}}</span>
                    </div>
                </div>
            </div>

            <div class="mb-3">
                <div class="row">
                    <div class="col-12 col-sm-6">                
                        <button class="goa-button goa--secondary btn-block" [routerLink]="['../details']">Back to Late Review Request Form</button>
                    </div>
                    <div class="col-12 col-sm-6 text-end">                
                        <button class="goa-button btn-block" (click)="continueRequest(requestForm.valid)">Proceed to Payment Method</button>
                    </div>
                </div>
            </div>
        </div>
        </form>
    </ng-container>
</app-contravention-page>

<!-- Registry Portal View -->
<app-contravention-page *ngIf="isRSGPortal">
    <ng-container content>
        <form #requestForm="ngForm">
        <div class="d-flex flex-column">
            <h1>Supporting Documents</h1>
            <h3 class="border-bottom pb-4 mb-2">Notice(s). {{getNotices()}}</h3>

            <div class="card border-0">
                <div class="goa-callout goa--information mt-1">
                    <h6 class="mt-0">Add Documents</h6>
                    <p>
                        Please inform the client that any supporting documents that they wish to have considered as part of their late review request <b>must be uploaded now</b>. They will <u>not</u> be allowed to upload further documents once this request is submitted.
                    </p>
                    <p>
                        If they are awaiting further documents, <b>do not file</b> the request at this time. They should only submit the request for a late review when they can upload all of the documents that they want to have considered.
                    </p>
                    <p>
                        <b>Their submission must still be made promptly</b> in accordance with section 10 of the <a class="fst-italic" href="https://kings-printer.alberta.ca/documents/Regs/2020_217.pdf" target="_blank">Provincial Administrative Penalties Regulation</a>.
                    </p>
                </div>
            </div>

            <div class="card mt-4">
                <div class="card-header">
                    Supporting Documents
                </div>
                <div class="card-body">  
                    <div *ngFor="let doc of documents; let i = index; trackBy: trackDocument">
                        <file-upload [document]="doc" [fileUploadModel]="fileUploadModel"
                            [isPrimaryDocument]="doc.forcePrimaryDocument"
                            (onCancelEvent)="onCancelDcoument($event)"
                            (onRemoveEvent)="onRemoveDocument($event)">
                        </file-upload>
                    </div>
                    
                    <div class="mb-3 row">
                        <div class="col-lg-12">                    
                            <button class="goa-button goa--secondary float-end" (click)="AddOptionalDocument()">Add Additional Document</button>                    
                        </div>
                    </div> 
                    <div *ngIf="errorMessage.length > 0">
                        <span class="error">{{errorMessage}}</span>
                    </div>
                </div>
            </div>

            <div class="mb-3">
                <div class="row">
                    <div class="col-12 col-sm-6">                
                        <button class="goa-button goa--secondary btn-block" [routerLink]="['../details']">Return to Late Review Request Details</button>
                    </div>
                    <div class="col-12 col-sm-6 text-end">                
                        <button class="goa-button btn-block" (click)="continueRequest(requestForm.valid)">Proceed to Payment</button>
                    </div>
                </div>
            </div>
        </div>
        </form>
    </ng-container>
</app-contravention-page>