<form #reportForm="ngForm">
    <div class="d-flex flex-column card border-0">
        <div class="mb-4 not-for-print">
            <a class="lnk-back" [routerLink]="['../']">Back to Registry Home Page</a>
        </div>

        <div class="row">
            <div class="col-12 col-md-8">        
                <h1 class="mb-3">Service Payments</h1>
            </div>
            <div class="col-12 col-md-4 d-flex align-self-end justify-content-end">
                <div class="float-end">
                    <div class="print-icon not-for-print" (click)="printReport()"></div>
                </div>
            </div>
        </div>

        <div class="mb-3 row">
            <div class="col-12 col-md-3">
                <label for="fromDate" class="required">From Date</label>
                <input required #fromDateControl="ngModel" type="text" bsDatepicker [bsConfig]="datePickerConfig" maxlength="10" class="form-control" id="fromDate" name="fromDate" placeholder="mm/dd/yyyy" [(ngModel)]="dailyBalanceRequest.fromDate" [ngClass]="{'is-invalid':(fromDateControl.touched || reportForm.submitted) && fromDateControl.invalid}">
                <div class="invalid-feedback">
                    <div *ngIf="fromDateControl.hasError('required')">From Date is required</div>
                    <div *ngIf="!fromDateControl.hasError('required')">Invalid From Date</div>
                </div>
            </div>
            <div class="col-12 col-md-3">
                <label for="toDate" class="required">To Date</label>
                <input required isLessThanStartDate="{{dailyBalanceRequest.fromDate | date:'yyyy-MM-dd'}}" #toDateControl="ngModel" type="text" bsDatepicker [bsConfig]="datePickerConfig" maxlength="10" class="form-control" id="toDate" name="toDate" placeholder="mm/dd/yyyy" [(ngModel)]="dailyBalanceRequest.toDate" [ngClass]="{'is-invalid':(toDateControl.touched || reportForm.submitted) && toDateControl.invalid}">
                <div class="invalid-feedback">
                    <div *ngIf="toDateControl.hasError('required')">To Date is required</div>
                    <div *ngIf="!toDateControl.hasError('required') && toDateControl.hasError('EarlierDate')">To Date can't be earlier than the From Date</div>
                    <div *ngIf="!toDateControl.hasError('required') && !toDateControl.hasError('EarlierDate')">Invalid To Date</div>
                </div>
            </div>
        </div>
        <div class="row not-for-print">
            <div class="col-12 col-md-6">
                <p>Narrow results by:</p>
            </div>
            <div class="col-12 col-md-6 d-flex justify-content-end">
                <button class="btn-link" (click)="onResetFilter()">Reset Filters</button>
            </div>
        </div>
        <div class="row d-flex align-items-end">
            <div class="col-12 col-md-3">
                <label for="registryAgentId">Registry Agent ID</label>
                <input type="text" class="form-control" id="registryAgentId" name="registryAgentId" placeholder="Enter Registry Agent ID" [(ngModel)]="dailyBalanceRequest.registryAgentId">
            </div>
            <div class="col-12 col-md-3">
                <label for="recordNumber">File Number</label>
                <input type="text" class="form-control" id="recordNumber" name="recordNumber" placeholder="Enter Contravention/Review #" [(ngModel)]="dailyBalanceRequest.recordNumber">
            </div>
            <div class="col-12 col-md-3">
                <label for="transactionId">Transaction ID</label>
                <input [appTransactionIdFormatter]="true" [appTransactionIdValidator]="true" #transactionIDControl="ngModel" type="text" maxlength="19" class="form-control" id="transactionID" name="transactionID" placeholder="Enter Payment TX" [(ngModel)]="dailyBalanceRequest.transactionId" [ngClass]="{'is-invalid': (transactionIDControl.touched || reportForm.submitted) && transactionIDControl.invalid}">
                <div class="invalid-feedback">
                    <div>A valid Payment TX is required</div>
                </div>
            </div>
            <div class="col-12 col-md-3">
                <label for="transactionType" class="required mb-1">Transaction Type</label>
                <div class="form-check">
                    <input [required]="!dailyBalanceRequest.IsFineTransactionRequired" class="form-check-input mt-2" #isReviewFineControl="ngModel" type="checkbox" id="isReviewFine" name="isReviewFine" [(ngModel)]="dailyBalanceRequest.IsReviewTransactionRequired" [ngClass]="{'is-invalid': (isCurrentFineControl.touched || isReviewFineControl.touched || reportForm.submitted) && isReviewFineControl.invalid}">
                    <label class="form-check-label mb-0" for="isReviewFine">SR-RVW1 (Reviews)</label>
                </div>
                <div class="form-check">
                    <input [required]="!dailyBalanceRequest.IsReviewTransactionRequired" class="form-check-input mt-2" #isCurrentFineControl="ngModel" type="checkbox" id="isCurrentFine" name="isCurrentFine" [(ngModel)]="dailyBalanceRequest.IsFineTransactionRequired" [ngClass]="{'is-invalid': (isCurrentFineControl.touched || isReviewFineControl.touched || reportForm.submitted) && isCurrentFineControl.invalid}">
                    <label class="form-check-label mb-0" for="isCurrentFine">SRNTCPAY (Current Fines)</label>
                </div>
                <div class="error" *ngIf="!dailyBalanceRequest.IsFineTransactionRequired && !dailyBalanceRequest.IsReviewTransactionRequired">
                    <div>Select a transaction type</div>
                </div>
            </div>
        </div>
        <div class="mb-3 row not-for-print">
            <div class="col-12 col-md-9"></div>
            <div class="col-12 col-md-3">
                <button class="goa-button goa--primary btn-block" (click)="onSearchClick(reportForm.valid)">Search</button>
            </div>
        </div>

    </div>

    <div class="card mt-4">
        <div class="card-header">
            Payments
        </div>
        <div class="card-body py-0"> 
            <table>
                <thead>
                    <tr>                                                
                        <th class="date-cell">Transaction Date & Time <span class="small-label">(MST)</span></th>
                        <th class="agent-cell">Registry Agent ID</th>
                        <th class="record-no-cell">File Number</th>
                        <th class="transaction-id-cell">Transaction ID</th>
                        <th class="transaction-type-cell">Transaction Type</th>
                        <th class="payer-cell">Payer</th>
                        <th class="amount-cell">Transaction Amount</th>
                    </tr>
                </thead>
                <tbody *ngIf="dailyBalanceResponseList?.length > 0">
                    <tr *ngFor="let payment of dailyBalanceResponseList">
                        <td class="date-cell">{{payment.transactionDateTime}}</td>
                        <td class="agent-cell">{{payment.registryAgentId}}</td>
                        <td class="record-no-cell">{{payment.recordNumber}}</td>
                        <td class="transaction-id-cell">{{payment.transactionId}}</td>
                        <td class="transaction-type-cell">{{payment.transactionType}}</td>
                        <td class="payer-cell">{{payment.payer}}</td>
                        <td class="amount-cell">{{payment.transactionAmount | currency}}</td>
                    </tr>                      
                </tbody>
                <tbody *ngIf="!dailyBalanceResponseList || dailyBalanceResponseList.length == 0">
                    <tr>
                        <td colspan="7">No records to display</td>
                    </tr>
                </tbody>
            </table>                    
        </div>   
        <div class="card-footer">
            <div class="d-flex justify-content-between align-items-center h6 mb-0">
                <h5>Total balance</h5>
                <h5>{{totalAmount | currency}}</h5>
            </div>
        </div>     
    </div> 
</form>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-fussion" [fullScreen]="true"><p style="color: white" > Loading... </p></ngx-spinner>      