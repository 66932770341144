import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TypeTable } from '@apis/shared/enums/type-table.enum';
import { CommonUtil } from '@apis/shared/helpers/common-util';
import { DocumentModel } from '@apis/shared/models/document-model.model';
import { LateReviewRequest } from '@apis/shared/models/late-review-request.model';
import { SectionDetailsModel } from '@apis/shared/models/section-details.model';
import { DocumentService } from '@apis/shared/services/document.service';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import * as fileSaver from 'file-saver';
import { DocumentType } from '@apis/shared/models/types/document-type.model';
import { RepresentationTypes } from '@apis/shared/enums/app.enum';
import { Constants } from '@apis/shared/helpers/constants';

@Component({
  selector: 'app-late-review-request',
  templateUrl: './late-review-request.component.html',
  styleUrls: ['./late-review-request.component.scss']
})
export class LateReviewRequestComponent implements OnInit {
  @Input() lateReviewRequest: LateReviewRequest;
  @Output() onRequestReqview = new EventEmitter();


  requestDetailsInformation: SectionDetailsModel[] = [];
  representationGroundsInformation: SectionDetailsModel[] = [];

  constructor(
    private localStorageService: LocalStorageService,
    private documentService: DocumentService,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    // Request details
    this.requestDetailsInformation.push(new SectionDetailsModel('Application Submitted Date', 
      this.datePipe.transform(this.lateReviewRequest.requestDate, 'mediumDate')));
    this.requestDetailsInformation.push(new SectionDetailsModel('Date Decision Issued',
      this.lateReviewRequest.decisionDate == null ? "-" : this.datePipe.transform(this.lateReviewRequest.decisionDate, 'mediumDate')));
    this.requestDetailsInformation.push(new SectionDetailsModel('Review Decision', 
      this.localStorageService.getTypeItemDescriptionById(this.lateReviewRequest.requestDecisionTypeId, TypeTable.RequestDecisionType)));

    if (this.lateReviewRequest.representation) {
      // Representation Informaton
      const repTypeId = this.lateReviewRequest.representation.representationTypeId;

      const repTypeName = this.localStorageService.getRepresentationTypes()
                              .find(x=>x.id == repTypeId)?.name;

      this.representationGroundsInformation.push(new SectionDetailsModel('Representation Type', repTypeName, true));

      this.representationGroundsInformation.push(new SectionDetailsModel(`Representative First Name`, this.lateReviewRequest.representation.firstName));
      this.representationGroundsInformation.push(new SectionDetailsModel(`Representative Last Name`, this.lateReviewRequest.representation.lastName));

      if (+repTypeId === RepresentationTypes.Agent || +repTypeId === RepresentationTypes.Lawyer){
        this.representationGroundsInformation.push(new SectionDetailsModel(`Practice Name`, this.lateReviewRequest.representation.practiceName, true));
      }

      if (+repTypeId === RepresentationTypes.Other){
        this.representationGroundsInformation.push(new SectionDetailsModel('Other Representation Type', this.lateReviewRequest.representation.otherRepresentationType, true));
      }
    } else {
      this.representationGroundsInformation.push(new SectionDetailsModel('Representation Type', 'Self', true));
    }

    // Review Details Information
    this.representationGroundsInformation.push(new SectionDetailsModel('Grounds for Request', 
      this.lateReviewRequest.requestReason, true));
  }

  onRequestReviewClick() {
    this.onRequestReqview.emit();
  }
  
  hasDecisionDocument() {
    return this.lateReviewRequest.documents.some(
        (document) => document.documentTypeId == 30
      );
      return 
  }

  getDecisionDocument() {
    let decisionDocument = this.lateReviewRequest.documents.find(
        (document) => document.documentTypeId == 30
      );

      if (decisionDocument) {
        var documentType: DocumentType = this.localStorageService
          .getDocumentTypes()
          .find((x) => x.id == decisionDocument.documentTypeId);
        var decisionDocumentModel = new DocumentModel(
          documentType?.name,
          decisionDocument.documentName,
          decisionDocument.documentExtension,
          decisionDocument.contentGuid,
          !documentType?.isSubmittableAtLaterDate
        );
        decisionDocumentModel.version = decisionDocument.version;
        return decisionDocumentModel;
      }

      return null;
  }

  DownloadDocument(document: DocumentModel) {
    var storageFileName = `${document.contentGuid}.${document.documentExtension}`;

    if (document.version == Constants.DocumentVersion.VERSION3) {
      this.documentService.getDocumentSasUri("", "Requests", this.lateReviewRequest.lateReviewRequestNumber, storageFileName, document.documentName)
      .subscribe((result: any) => {
        window.open(result.documentSasUri, "_blank");
      });

      return;
    }

    this.documentService
      .downloadDocument(
        '',
        'Requests',
        this.lateReviewRequest.lateReviewRequestNumber,
        storageFileName,
        document.documentName
      )
      .subscribe((result: any) => {
        if (result) {
          fileSaver.saveAs(new Blob([result]), document.documentName);
        }
      });
  }
}
