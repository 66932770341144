<!-- Driver Portal View -->
<div class="d-flex flex-column" *ngIf="!isRSGPortal">
    <h1>Application Submitted</h1>   
    <div class="header-container border-bottom pb-4 mb-2">
        <div>
            <h3 class="mb-0">No. {{review.reviewNumber}}</h3>
        </div>
        <div>
            <button class="btn-block goa-button btn-print" (click)="onPrintReceiptClick()">Download Receipt</button>   
        </div>                 
    </div> 

    <div class="card border-0">
        <p>
            Your application for {{reviewContext.toLowerCase()}} was submitted successfully. 
            <a [routerLink]="['../../../review', review.reviewSequence, 'details']">Click here</a> to view the details of your review.
        </p>
        <p>
            Due to the potential for delayed or missed messages sent via email and SMS, 
            it is your responsibility to check the status of your request in the SafeRoads portal. 
            Please contact SafeRoads Alberta at <a href="mailto:saferoads@gov.ab.ca">saferoads&#64;gov.ab.ca</a> if you have any questions.
        </p>
    </div>

    <div class="mb-3">
        <div class="row pt-3">
            <div class="col-12 col-sm-6">
                <button class="goa-button goa--secondary btn-block" [routerLink]="['../../../']">Return to Seizure Details</button>
            </div>
            <div class="col-12 col-sm-6 text-end">
                <button class="goa-button btn-block" (click)="continueRequest()">Proceed to Supporting Documents</button>
            </div>
        </div>
    </div>
</div>

<!-- Registry Portal View -->
<div class="d-flex flex-column" *ngIf="isRSGPortal">
    <div class="card goa-callout goa--emergency mb-4 mt-0">
        <h2 class="my-0">Close this SafeRoads (APIS) browser tab to return to MOVES</h2>
    </div>
    <h1>Application Submitted</h1>   
    <h3 class="mb-0">No. {{review.reviewNumber}}</h3>
    <div class="card border-0">
        <p>
            The application for {{reviewContext.toLowerCase()}} was submitted successfully. 
            The client will receive a confirmation of the review request via their preferred notification method.
        </p>
    </div>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-fussion" [fullScreen]="true"><p style="color: white" > Loading... </p></ngx-spinner>  
