<app-contravention-page>
    <ng-container content>
        <form #paymentForm="ngForm">
        <div class="d-flex flex-column">
            <h1 class="">Select a Payment Amount</h1>

            <div class="card goa-callout goa--info">
                <h6 class="mt-0">Payment will confirm your Notice of Administrative Penalty</h6>
                <p>
                    I understand that I have a right to dispute a Notice of Administrative Penalty by submitting 
                    a request for review within seven (7) days from the date of issuance. 
                    A payment of any amount will confirm this Notice of Administrative Penalty and you will 
                    no longer be able to apply for a review.
                </p>
                <p>
                    If you have a review scheduled, making a payment will cancel the review.
                </p>
            </div>

            <div class="card mt-3 mt-lg-4">
                <div class="card-header">
                    Representation Type
                </div>
                <div class="card-body">
                    <div class="mb-3 row">
                        <div class="col-lg-12">
                            <label for="payRepresentationType">Are you the Recipient of the Contravention Notice?</label>
                            <select class="form-select" id="payRepresentationType" name="payRepresentationType"
                                [(ngModel)]="paymentRequest.isSelfSubmit" (change)="onRepresentationTypeChange()">
                                <option [ngValue]="true">Yes, I am making payment on the behalf of myself</option>
                                <option [ngValue]="false">No, I am making payment on the behalf of the recipient</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>

            <app-representation *ngIf="!paymentRequest.isSelfSubmit" [model]="paymentRequest.representation"
                    [isSubmitClicked]="paymentForm.submitted" [consentRequired]="false"
                    [isYouth]="contraventionOverview.isRecipientYouth"></app-representation>

            <div class="card goa-callout goa--info" *ngIf="false">
                <h6 class="mt-0">Payment Notice</h6>
                <p>
                    If you do not wish to make a payment for a contravention at this time,
                    uncheck the contravention.
                </p>                
            </div>

            <div *ngFor="let li of paymentRequest.lineItems;">
                <app-contravention-line-item [contraventionInformation]="getContravention(li.recordNumber)" 
                    [lineItem]="li"
                    [isSubmitClicked]="paymentForm.submitted"></app-contravention-line-item>
            </div>

            <div class="card mt-4">
                <div class="card-header">
                    <div class="d-flex justify-content-between align-items-center">
                        <div><h5>Total Payment Amount</h5></div>
                        <div><h5>{{ getTotalPaymentAmount() | currency }}</h5></div>
                    </div>
                </div>
            </div>

            <div>
                <span class="error">{{errorMessage}}</span>
            </div>

            <div class="mb-3">
                <div class="row pt-3">
                    <div class="col-12 col-sm-6">                
                        <button class="goa-button goa--secondary btn-block" [routerLink]="['../../']">Back to Contravention Overview</button>
                    </div>
                    <div class="col-12 col-sm-6 text-end">                
                        <button class="goa-button goa--primary btn-block" (click)="continuePayment(paymentForm.valid)">Proceed to Payment Method</button>
                    </div>
                </div>
            </div>
        </div>
        </form>
    </ng-container>
</app-contravention-page>