<div *ngIf="currentTermCondition">
    <div class="card goa-callout goa--information mb-3 mt-0" *ngIf="canShowUpdateAlert()">
        <h6 class="mt-0">We've Updated Our Terms & Conditions</h6>
        <p>
            Please be advised that we have updated our Terms and Conditions for Collection of Personal Information and Use of a Representative effective {{ currentTermCondition.documentDate | date:'mediumDate' }}.
        </p>
    </div>
    <div class="card checkbox-card mt-2">
        <div class="checkbox">
            <input required type="checkbox" id="terms" name="terms" class="me-2"
                #terms="ngModel" [(ngModel)]="isTermsAccepted" [ngClass]="{'is-invalid': isSubmitClicked && terms.invalid }">
            <label class="required" for="terms" [innerHTML]="currentTermCondition.driverCheckboxStatement"></label>
            <div class="invalid-feedback">
                <div>{{currentTermCondition.driverCheckboxErrorMessage}}</div>
            </div>
        </div>
    </div>
</div>