<div class="card mt-4">
    <div class="card-header">
        Representation
    </div>
    <div class="card-body">
        <div class="mb-3 row" *ngIf="showSameAsRequester">
            <div class="col-lg-6">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="sameAsRequesterCheckbox" name="sameAsRequesterCheckbox"  [(ngModel)]="model.sameAsRequester" (change)="onSameAsRequesterChange()">
                    <label class="form-check-label" for="sameAsRequesterCheckbox">
                        Same as requester
                    </label>
                </div>
            </div>
        </div>
        <div class="mb-3 row">
            <div class="col-lg-6">
                <label for="representationType" class="required">Type of Acting Representation</label>
                <select class="form-select" id="representationType" name="representationType" required
                    [(ngModel)]="model.representationTypeId" (change)="onRepresentationTypeChange()"
                    #representationType="ngModel" [ngClass]="{ 'is-invalid': isSubmitClicked && representationType.invalid }">
                    <option [ngValue]="null">Select a Representation Type</option>
                    <option *ngFor="let repType of representationTypesList" [value]="repType.id">{{repType.name}}</option>
                </select>
                <div class="invalid-feedback">
                    <div>Type of Acting Representation is required</div>
                </div>
            </div>
            <div class="col-lg-6 d-flex flex-row-reverse" data-bs-toggle="modal" data-bs-target="#aboutRepModal">
                <div class="represent-tip">
                    <div class="question-icon">                                                                
                        <i class="far fa-question-circle fa-lg"></i>
                    </div>
                    About Representation Types
                </div>
            </div>                
        </div>
        <ng-container *ngIf="model.representationTypeId !== null">
            <!-- If sameAsRequester, use registryAgentInformation names, and disable text fields -->
            <div *ngIf="model.sameAsRequester; else notSameAsRequester">
                <div class="mb-3 row">
                    <div class="col-lg-6">
                        <label for="firstName" class="required">{{repTitle}}First Name</label>
                            <input disabled type="text" class="form-control" id="firstName" name="firstName"
                            [(ngModel)]="registryAgentInformation.payerFirstName" #firstName="ngModel"
                            [ngClass]="{ 'is-invalid': isSubmitClicked && firstName.invalid }">
                        <div class="invalid-feedback">
                            <div>{{repTitle}}First Name is required</div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <label for="lastName" class="required">{{repTitle}}Last Name</label>
                            <input disabled type="text" class="form-control" id="lastName" name="lastName"
                            [(ngModel)]="registryAgentInformation.payerLastName" #lastName="ngModel"
                            [ngClass]="{ 'is-invalid': isSubmitClicked && lastName.invalid }">
                        <div class="invalid-feedback">
                            <div>{{repTitle}}Last Name is required</div>
                        </div>
                    </div>
                </div>
            </div>
            <ng-template #notSameAsRequester>
                <div class="mb-3 row">
                    <div class="col-lg-6">
                        <label for="firstName" class="required">{{repTitle}}First Name</label>
                            <input type="text" class="form-control" id="firstName" name="firstName"
                            [(ngModel)]="model.firstName" #firstName="ngModel" required
                            [ngClass]="{ 'is-invalid': isSubmitClicked && firstName.invalid }">
                        <div class="invalid-feedback">
                            <div>{{repTitle}}First Name is required</div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <label for="lastName" class="required">{{repTitle}}Last Name</label>
                        <input type="text" class="form-control" id="lastName" name="lastName"
                            [(ngModel)]="model.lastName" #lastName="ngModel" required
                            [ngClass]="{ 'is-invalid': isSubmitClicked && lastName.invalid }">
                        <div class="invalid-feedback">
                            <div>{{repTitle}}Last Name is required</div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </ng-container>
        <ng-container *ngIf="model.representationTypeId == representationTypes.Agent || model.representationTypeId == representationTypes.Lawyer">
            <div class="mb-3 row">
                <div class="col-lg-12">
                    <label for="practiceName" class="required">{{repTitle}}Practice Name</label>
                    <input type="text" class="form-control" id="practiceName" name="practiceName"
                        [(ngModel)]="model.practiceName" #practiceName="ngModel" required
                        [ngClass]="{ 'is-invalid': isSubmitClicked && practiceName.invalid }">
                    <div class="invalid-feedback">
                        <div>{{repTitle}}Practice Name is required</div>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="model.representationTypeId == representationTypes.Other">
            <div class="mb-3 row">
                <div class="col-lg-12">
                    <label for="otherRepresentationType" class="required">Specify Other Representation Type</label>
                    <input type="text" class="form-control" id="otherRepresentationType" name="otherRepresentationType"
                        [(ngModel)]="model.otherRepresentationType" #otherRepresentationType="ngModel" required
                        [ngClass]="{ 'is-invalid': isSubmitClicked && otherRepresentationType.invalid }">
                    <div class="invalid-feedback">
                        <div>Other Representation Type is required</div>
                    </div>
                </div>   
            </div>
        </ng-container>

        <div class="goa-callout goa--important" *ngIf="consentRequired != false">
            <h6 class="mt-0">Consent to Representation Required</h6>
            <div *ngIf="isRSGPortal; else notRSGPortal">
                <div *ngIf="isLateReviewRequest; else notLateReviewRequest">
                    <p>
                        Please advise the client that the driver who was issued the contravention must complete and sign a Consent to Representation form and this form must be uploaded with this application.
                    </p>
                    <p>
                        <a href="assets/SRA12675 Consent to Representation Rev 2024-02.pdf" download="Consent to Representation" target="_blank">Download the Consent to Representation Form</a>
                        print it, and provide the hard copy to the client
                    </p>
                </div>
                <ng-template #notLateReviewRequest>
                    <div *ngIf="reviewTypeId == reviewTypes.IrsReview">
                        <p>
                            Please advise the client that the driver who was issued the contravention must complete and sign a Consent to Representation form and this form must be uploaded to the SafeRoads portal before the scheduled review.
                        </p>
                        <p>
                            Download the <a href="assets/SRA12675 Consent to Representation Rev 2024-02.pdf" download="Consent to Representation" target="_blank">Consent to Representation Form</a>
                            print it, and provide the hard copy to the client
                        </p>
                    </div>

                    <div *ngIf="reviewTypeId == reviewTypes.SuspendedDriverVehicleSeizureReview">
                        <p>
                            Please advise the client that the driver who was issued the seizure notice must complete and sign a Consent to Representation form and this form must be uploaded to the SafeRoads portal before the scheduled review.
                        </p>
                        <p>
                            Download the <a href="assets/SRA12675 Consent to Representation Rev 2024-02.pdf" download="Consent to Representation" target="_blank">Consent to Representation Form</a>
                            print it, and provide the hard copy to the client
                        </p>
                    </div>

                    <div *ngIf="reviewTypeId == reviewTypes.ThirdPartySeizureReview">
                        <p>
                            Please advise the client that the registered owner of the vehicle must complete and sign a Consent to Representation form and this form must be uploaded to the SafeRoads portal before the scheduled review.
                        </p>
                        <p>
                            Download the <a href="assets/SRA12675 Consent to Representation Rev 2024-02.pdf" download="Consent to Representation" target="_blank">Consent to Representation Form</a>
                            print it, and provide the hard copy to the client
                        </p>
                    </div>
                </ng-template>
            </div>

            <ng-template #notRSGPortal>
                <p>
                    If you choose to be represented, 
                    this must be arranged in advance of any action the representation is directed to take on your behalf. 
                    If you have chosen a representation to act on your behalf, 
                    a completed <a href="assets/SRA12675 Consent to Representation Rev 2024-02.pdf" download="Consent to Representation" target="_blank">Consent to Representation</a> 
                    form must be submitted to the SafeRoads portal prior to the scheduled review.
                </p>
                <p *ngIf="!hideRepresenationMessage">
                    Your representation must be available for an oral review as scheduled. 
                    In the case of a written review, the representation need not be present 
                    for the scheduled written review. Adjournments will not be granted where 
                    the only reason for an adjournment is that your representation is 
                    not available at the scheduled review time.
                </p>
            </ng-template>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal" id="aboutRepModal" tabindex="-1" role="dialog" aria-labelledby="aboutRepModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content p-4">
            <div class="modal-header border-0 pt-2">
                <span class="modal-title h4" id="aboutRepModalLabel">
                    About Representation Types
                </span>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                    <i class="far fa-times-circle fa-lg"></i>
                </button>
            </div>
            <div class="modal-body py-0">
                <h4>Agent</h4>
                <p>An agent is a professional representation in exchange for a fee.</p>
                <h4>Lawyer</h4>
                <p>Lawyer representation is typically on behalf of a law practice by whom you are being represented.</p>
                <h4>Parent/Guardian</h4>
                <p>Parent or legal guardian means the custodial parent or legal guardian for a person under eighteen (18) years of age.</p>
                <h4>Other</h4>
                <p>If none of the above types apply, select other and specify the type of representation.</p>
            </div>
            <div class="modal-footer border-0">
                <button type="button" class="btn-modal goa-button mt-2" data-bs-dismiss="modal">Okay</button>
            </div>
        </div>
    </div>
</div>