<!-- Driver Portal View -->
<app-vehicle-seizure-page *ngIf="!isRSGPortal" (onBeforeNavigate)="cancelRequest()">
    <ng-container content>
        <form *ngIf="review" #requestForm="ngForm">
            <div class="d-flex flex-column">
                <h1>Schedule Review</h1>
                <div class="d-flex w-100 border-bottom mb-2">
                    <h3>No. {{review.seizureNumber}}</h3>                    
                    <div class="countdown">
                        <countdown-timer #cd [key]="countdownKey" [lapseMessage]="lapseMessage"></countdown-timer>
                    </div>
                </div>

                <div class="card border-0">
                    <p>
                        What day would you like your matter to be reviewed? Please note: you <b><u>do
                                not</u></b> attend a written review.
                    </p>
                    <p *ngIf="availabilityViewForWritten?.length > 0">
                        If you require assistance with your review request, please contact SafeRoads Alberta at <a href="tel:780-427-7233">780-427-SAFE (7233)</a> or by email to <a href="mailto:saferoads@gov.ab.ca">saferoads&#64;gov.ab.ca</a>.
                    </p>
                    <div *ngIf="availabilityView">
                        <h3 class="display">{{availabilityView.startDate | date:'MMM dd, yyyy'}} -
                            {{availabilityView.endDate |
                            date:'MMM dd, yyyy'}}</h3>
                        <button (click)="onRefreshClick()"
                            class="goa-button goa--secondary m-0 mt-3 ms-auto refreshBtn">
                            Refresh
                        </button>
                    </div>
                </div>

                <div *ngIf="availabilityViewForWritten!=null" class="card border-0">
                    <div *ngIf="availabilityViewForWritten.length == 0" class="h6">
                        There are no available review dates. Please contact SafeRoads Alberta at <a href="tel:780-427-7233">780-427-SAFE (7233)</a> or by email to <a href="mailto:saferoads@gov.ab.ca">saferoads&#64;gov.ab.ca</a> for assistance with your review request.
                    </div>
                    <ul *ngIf="availabilityViewForWritten.length > 0">
                        <li class="cal-dateslot"
                            [ngClass]="{ 'cal-dateslot-selected': availableInfo === selAvailableInfo }"
                            *ngFor="let availableInfo of availabilityViewForWritten"
                            (click)="onDateSelected(availableInfo)">
                            <span>{{ availableInfo.date | date:'longDate' }}</span>
                            <br>
                            <b>{{ availableInfo.date | date:'EEEE' }}</b>
                        </li>
                    </ul>
                </div>
                <div *ngIf="isAvailabilityRequestFailed" class="card border-0 text-danger">
                    <p>
                        An error occurred. Please contact SafeRoads Alberta at <a href="tel:780-427-7233">780-427-SAFE (7233)</a> or by email to <a href="mailto:saferoads@gov.ab.ca">saferoads&#64;gov.ab.ca</a> for assistance with your review request.
                    </p>
                </div>
                <div class="card border-0 text-danger">
                    <p>
                        {{ errorMessage }}
                    </p>
                </div>
                <div class="card border-0" *ngIf="documentSubmissionCutoffDate">
                    <p>
                        By selecting this day, you must submit all your supporting documents by
                        <b>11:59pm</b> on <b>{{ documentSubmissionCutoffDate | date:'fullDate':'UTC'}}</b>.
                    </p>
                </div>

                <div class="mb-3">
                    <div class="row">
                        <div class="col-12 col-sm-6">
                            <button class="goa-button goa--secondary btn-block"
                                [routerLink]="['../details']">Return to Seizure Review Details</button>
                        </div>
                        <div class="col-12 col-sm-6 text-end">
                            <button class="goa-button btn-block" (click)="continueRequest(requestForm.valid)">Proceed to
                                Payment Method</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </ng-container>
</app-vehicle-seizure-page>

<!-- Registry Portal View -->
<app-vehicle-seizure-page *ngIf="isRSGPortal" (onBeforeNavigate)="cancelRequest()">
    <ng-container content>
        <form *ngIf="review" #requestForm="ngForm">
            <div class="d-flex flex-column">
                <h1>Schedule Review</h1>
                <div class="d-flex w-100 border-bottom mb-2">
                    <h3>No. {{review.seizureNumber}}</h3>
                    <div class="countdown">
                        <countdown-timer #cd [key]="countdownKey" [lapseMessage]="lapseMessage"></countdown-timer>
                    </div>
                </div>

                <div class="card border-0">
                    <p>
                        Please ask the client to choose a day when they wish their contravention to be reviewed. Please note: the client <b><u>does not</u></b> attend a written review.
                    </p>
                    <div *ngIf="availabilityView">
                        <h3 class="display">{{availabilityView.startDate | date:'MMM dd, yyyy'}} -
                            {{availabilityView.endDate |
                            date:'MMM dd, yyyy'}}</h3>
                        <button (click)="onRefreshClick()"
                            class="goa-button goa--secondary m-0 mt-3 ms-auto refreshBtn">
                            Refresh
                        </button>
                    </div>
                </div>

                <div *ngIf="availabilityViewForWritten!=null" class="card border-0">
                    <div *ngIf="availabilityViewForWritten.length == 0" class="h6">
                        There are no available review dates. Please contact SafeRoads Alberta at <a href="tel:780-427-7233">780-427-SAFE (7233)</a> or by email to <a href="mailto:saferoads@gov.ab.ca">saferoads&#64;gov.ab.ca</a> for assistance with your review request.
                    </div>
                    <ul *ngIf="availabilityViewForWritten.length > 0">
                        <li class="cal-dateslot"
                            [ngClass]="{ 'cal-dateslot-selected': availableInfo === selAvailableInfo }"
                            *ngFor="let availableInfo of availabilityViewForWritten"
                            (click)="onDateSelected(availableInfo)">
                            <span>{{ availableInfo.date | date:'longDate' }}</span>
                            <br>
                            <b>{{ availableInfo.date | date:'EEEE' }}</b>
                        </li>
                    </ul>
                </div>
                <div *ngIf="isAvailabilityRequestFailed" class="card border-0 text-danger">
                    <p>
                        An error occurred. Please contact SafeRoads Alberta at <a href="tel:780-427-7233">780-427-SAFE (7233)</a> or by email to <a href="mailto:saferoads@gov.ab.ca">saferoads&#64;gov.ab.ca</a> for assistance with your review request.
                    </p>
                </div>
                <div class="card border-0 text-danger">
                    <p>
                        {{ errorMessage }}
                    </p>
                </div>
                <div class="card border-0" *ngIf="documentSubmissionCutoffDate">
                    <p>
                        By selecting this day, you must submit all your supporting documents by
                        <b>11:59pm</b> on <b>{{ documentSubmissionCutoffDate | date:'fullDate':'UTC'}}</b>.
                    </p>
                </div>

                <div class="mb-3">
                    <div class="row">
                        <div class="col-12 col-sm-6">
                            <button class="goa-button goa--secondary btn-block"
                                [routerLink]="['../details']">Return to Seizure Review Details</button>
                        </div>
                        <div class="col-12 col-sm-6 text-end">
                            <button class="goa-button btn-block" (click)="continueRequest(requestForm.valid)">Proceed to
                                Payment</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </ng-container>
</app-vehicle-seizure-page>