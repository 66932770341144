import { DatePipe, CurrencyPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  ReviewStatusTypes,
  EventTypes,
  ReviewOutcomeTypes,
  DocumentTypes,
  TimeToPayState,
  ReviewState,
  ReviewTypes,
  RequestTypes,
  ReviewComplexity,
  ContraventionStatusTypes,
} from '@apis/shared/enums/app.enum';
import { TypeTable } from '@apis/shared/enums/type-table.enum';
import { ContraventionOverview } from '@apis/shared/models/contravention-overview.model';
import { Contravention } from '@apis/shared/models/contravention.model';
import { DocumentModel } from '@apis/shared/models/document-model.model';
import { ReviewItem } from '@apis/shared/models/review-item.model';
import { OnlinePaymentRequest } from '@apis/shared/models/online-payment-request.model';
import { Review } from '@apis/shared/models/review.model';
import { SectionDetailsModel } from '@apis/shared/models/section-details.model';
import { TransactionLineItem } from '@apis/shared/models/transaction-line-item.model';
import { DocumentService } from '@apis/shared/services/document.service';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { ContraventionPaymentRequest } from '../../../shared/models/contravention-payment-request.model';
import { ContraventionService } from '../../../shared/services/contravention.service';
import { DriverBaseComponent } from '../../driver-base.component';
import { LateReviewRequest } from '@apis/shared/models/late-review-request.model';
import { LateReviewRequestItem } from '@apis/shared/models/late-review-request-item.model';
import { ContraventionInformation } from '@apis/shared/models/contravention-information.model';
import { SeizureInformation } from '@apis/shared/models/seizure-information.model';
import { RsgAgentProfile } from '@apis/shared/models/rsg-agent-profile.model';
import { RegistryService } from '../../../shared/services/registry.service';
import { Constants } from '@apis/shared/helpers/constants';
import { ReviewApplicant } from '@apis/shared/models/review-applicant.model';
declare var $: any

@Component({
  selector: 'app-contravention-overview',
  templateUrl: './contravention-overview.component.html',
  styleUrls: ['./contravention-overview.component.scss'],
})
export class ContraventionOverviewComponent extends DriverBaseComponent
  implements OnInit {
  contraventionOverview: ContraventionOverview;
  contraventionNumber: string;
  notices: string;
  recipientName: string;
  isReviewAvailable: boolean = false;
  reviewAlreadyExists: boolean = false;
  hasImmidiateCancellations: boolean = true;

  irsContraventions: ContraventionInformation[] = [];
  irsSeizures: SeizureInformation[] = [];
  tsaContraventions: ContraventionInformation[] = [];
  sdpSeizures: SeizureInformation[] = [];

  TimeToPayState = TimeToPayState;
  ReviewState = ReviewState;
  rsgAgentProfile: RsgAgentProfile;
  hasTransactionId: boolean = false;
  canShowReviewTime: boolean = true;
  ReviewTypes = ReviewTypes;
  isIRSPenaltyTableVisible: boolean = false;
  Constants = Constants;

  constructor(
    router: Router,
    private activatedRoute: ActivatedRoute,
    private contraventionService: ContraventionService,
    private documentService: DocumentService,
    private registryService: RegistryService,
    private localStorageService: LocalStorageService,
    private readonly spinner: NgxSpinnerService,
    private datePipe: DatePipe,
    private currencyPipe: CurrencyPipe
  ) {
    super(router);
  }

  ngOnInit(): void {
    this.spinner.show();
    this.rsgAgentProfile = this.localStorageService.getRegistryAgentProfile();
    this.hasTransactionId = this.rsgAgentProfile && this.rsgAgentProfile.transactionId != null && this.rsgAgentProfile.transactionId != "";
    this.activatedRoute.paramMap.subscribe((paramMap) => {
      this.contraventionNumber = paramMap.get('contraventionNumber');
      this.loadContravention();
    });
  }

  loadContravention() {
    this.contraventionService
      .getContraventionOverview(this.contraventionNumber)
      .subscribe((contraventionOverview: ContraventionOverview) => {

        this.localStorageService.setItem<ContraventionOverview>('ContraventionOverview', contraventionOverview);

        const pickupModalEnabled = this.localStorageService.getEnableLicencePickupModal();
        this.localStorageService.setEnableLicencePickupModal(false);

        if (contraventionOverview) {
          this.contraventionOverview = contraventionOverview;

          this.recipientName = this.contraventionOverview.recipientFirstName;

          if (this.contraventionOverview.recipientMiddleName)
          {
            this.recipientName += ' ' + this.contraventionOverview.recipientMiddleName;
          }

          this.recipientName += ' ' + this.contraventionOverview.recipientLastName;

          this.contraventionOverview.contraventions.forEach(c => {
            if (this.notices) {
              this.notices += `, ${c.contraventionNumber}`;
            } else {
              this.notices = c.contraventionNumber;
            }
          });

          this.isReviewAvailable = (contraventionOverview.irsReviewState == ReviewState.ReviewAvailable 
            || contraventionOverview.tsaReviewState == ReviewState.ReviewAvailable);

          this.irsContraventions = this.contraventionOverview.contraventions.filter(c => 
            this.localStorageService.getContraventionTypes().find(ct => ct.id == c.contraventionTypeId).isIRS);
        
          this.irsSeizures = this.contraventionOverview.seizures.filter(s => s.seizureTypeId == 1);

          this.tsaContraventions = this.contraventionOverview.contraventions.filter(c => 
            !this.localStorageService.getContraventionTypes().find(ct => ct.id == c.contraventionTypeId).isIRS);

          this.sdpSeizures = this.contraventionOverview.seizures.filter(s => s.seizureTypeId != 1);

          this.isIRSPenaltyTableVisible = new Date(this.contraventionOverview.submissionDate) >= new Date(+Constants.Driver.IRS_PENALTY_TABLE_EFFECTIVE_DATE.substring(0, 4), +Constants.Driver.IRS_PENALTY_TABLE_EFFECTIVE_DATE.substring(5, 7)-1, +Constants.Driver.IRS_PENALTY_TABLE_EFFECTIVE_DATE.substring(8, 10));

          if (pickupModalEnabled && contraventionOverview.showLicencePickupNotification) {
            $("#licencePickupModal").modal('show');
          }
        } else {
          this.router.navigate(['../../contraventions/search']);
        }
        this.spinner.hide();
      });
  }

  makePayment() {
    const paymentRequest = new ContraventionPaymentRequest();
    
    paymentRequest.isSelfSubmit = true;
    paymentRequest.paymentAmount = 0;
    paymentRequest.recordNumber = this.contraventionNumber;

    this.localStorageService.setContraventionPaymentRequest(paymentRequest);
    
    this.router.navigate(['payment'], {relativeTo: this.activatedRoute});
  }

  makeRegistryPayment() {
    this.registryService.checkContraventionPaymentsAsync(this.contraventionNumber).subscribe((result:boolean)=>{
        if(result) {
        $('#contravention-payment-status-modal').modal('show');
          return;
        }
        else
          $('#confirmModal').modal('show');
      })
  }


  RequestTime() {
    this.router.navigate(['time-to-pay-request/apply'], {relativeTo: this.activatedRoute}); 
  }

  RequestAdditionalTime() {
    this.router.navigate(['additional-time-to-pay-request/apply'], {relativeTo: this.activatedRoute}); 
  }

  viewReviewDetails() {
    const reviewType = this.contraventionOverview.recentIrsReviewNumber
      ? ReviewTypes.IrsReview
      : ReviewTypes.TrafficReview;

    const reviewSequence = reviewType == ReviewTypes.IrsReview 
      ? this.contraventionOverview.recentIrsReviewSequence 
      : this.contraventionOverview.recentTsaReviewSequence;

    this.router.navigate(['review', reviewSequence, reviewType, 'details'], {relativeTo: this.activatedRoute});
  }

  viewActivityHistory() {
    this.router.navigate(['activity-history'], {relativeTo: this.activatedRoute});
  }
  
  viewPaymentHistory() {
    this.router.navigate(['payment/history'], {relativeTo: this.activatedRoute});
  }

  startReviewRequest() {

    //edge case test. Ensure there isn't a review already in progress before continuing
    this.reviewAlreadyExists = false;
    this.contraventionService.getContraventionOverview(this.contraventionNumber)
      .subscribe((contraventionOverview: ContraventionOverview) => {

        let isReviewAvailable = (contraventionOverview.irsReviewState == ReviewState.ReviewAvailable 
          || contraventionOverview.irsReviewState == ReviewState.LateReviewApproved
          || contraventionOverview.tsaReviewState == ReviewState.ReviewAvailable
          || contraventionOverview.tsaReviewState == ReviewState.LateReviewApproved);

        if (isReviewAvailable) {

          // Initialize review request
          const review= new Review({
            contraventionNumber: this.contraventionNumber,
            reviewMethodTypeId: null,
            isSelfSubmit: true,
            isAccompainedByInterpreter: null,
            contactMethodTypeId: null,
            reviewScheduleRequest: null,
            paymentRequest: new OnlinePaymentRequest(),
            reviewApplicant: new ReviewApplicant({
                vehicleInterestTypeId: null
            }),
            termsConditionsVersion: this.getLatestTermsAndConditionsVersion(),
            complexity: null
          });

          if (this.contraventionOverview.irsReviewEligibleContraventions?.length > 0
            && (this.contraventionOverview.tsaReviewEligibleContraventions == null
              || this.contraventionOverview.tsaReviewEligibleContraventions?.length == 0))
          {
            review.reviewTypeId = ReviewTypes.IrsReview;  
          }

          if (this.contraventionOverview.tsaReviewEligibleContraventions?.length > 0
            && (this.contraventionOverview.irsReviewEligibleContraventions == null
              || this.contraventionOverview.irsReviewEligibleContraventions?.length == 0))
          {
            // if all TSA eligible contraventions are ATE then set review type as AteReview
            var isIssuedByAte = this.contraventionOverview.tsaReviewEligibleContraventions.every(cn => 
              this.contraventionOverview.contraventions.find(c => c.contraventionNumber == cn).isIssuedByAte);

            if (isIssuedByAte) {
              review.reviewTypeId = ReviewTypes.AteReview;
              review.complexity = ReviewComplexity.Low;
            } else {
              review.reviewTypeId = ReviewTypes.TrafficReview;
            }
          }

          // Save request to local storage
          this.localStorageService.setReviewRequest(review);

          // Initialize temporary folder location to upload documents
          this.localStorageService.setTempFolderName(Guid.create().toString())

          // Navigate to review request landing page
          this.router.navigate(['review/apply/terms'], {relativeTo: this.activatedRoute});

        } else {
          this.reviewAlreadyExists = true;
        }
        
    });    

  }

  startLateReviewRequest() {
    const requestTypes = this.localStorageService.getRequestTypes();
    let requestFeeAmount = 0;

    requestFeeAmount = requestTypes.find(t => t.id == RequestTypes.LateReview).requestFee;

    // Initialize late review request
    const lateReviewRequest= new LateReviewRequest({
      feeAmount: requestFeeAmount,
      isSelfSubmit: true,
      contactMethodTypeId: null,
      paymentRequest: new OnlinePaymentRequest({
        paymentAmount: requestFeeAmount
      }),
      reviewApplicant: new ReviewApplicant({
        vehicleInterestTypeId: null
      }),
      termsConditionsVersion: this.getLatestTermsAndConditionsVersion()
    });

    this.contraventionOverview.irsReviewEligibleContraventions?.forEach(cn => {
      lateReviewRequest.lateReviewRequestItems.push(new LateReviewRequestItem({
        recordNumber: cn
      }));
    });

    this.contraventionOverview.tsaReviewEligibleContraventions?.forEach(cn => {
      lateReviewRequest.lateReviewRequestItems.push(new LateReviewRequestItem({
        recordNumber: cn
      }));
    });

    // Save request to local storage
    this.localStorageService.setLateReviewRequest(lateReviewRequest);

    //Initialize temporary folder location to upload documents
    this.localStorageService.setTempFolderName(Guid.create().toString())

    // Navigate to late review request landing page
    if (this.isRSGPortal)
      this.router.navigate(['late-review-request/apply/terms'], {relativeTo: this.activatedRoute});
    else
      this.router.navigate(['late-review-request/apply/details'], {relativeTo: this.activatedRoute});
  }

  canMakePayment(): boolean {
    return this.tsaContraventions.concat(this.irsContraventions)
            .some(c => c.contraventionStatusTypeId != ContraventionStatusTypes.Paid 
                            && c.contraventionStatusTypeId != ContraventionStatusTypes.Cancelled
                            && c.contraventionStatusTypeId != ContraventionStatusTypes.Uncollectable
                            && c.contraventionStatusTypeId != ContraventionStatusTypes.Stay);
  }

  canRequestLateReview(): boolean {
    return this.tsaContraventions.concat(this.irsContraventions).every(c => c.isJudicialReviewDecisionPending != true)
      && (this.contraventionOverview.irsReviewState == ReviewState.LateReviewAvailable
      || this.contraventionOverview.irsReviewState == ReviewState.LateReviewAvailableAfterCancellation
      || this.contraventionOverview.irsReviewState == ReviewState.LateReviewAvailableToMakeLateEvidence
      || this.contraventionOverview.tsaReviewState == ReviewState.LateReviewAvailable);
  }

  getLatestTermsAndConditionsVersion(): string {
    return this.localStorageService
               .getTermsAndConditionsList()
               .find(t => t.expiryDate == null)
               .version;
  }
}
