export const Constants = {
    SubmissionVersion: {
        PHASE1: "P1",
        PHASE2: "P2",
    },
    DocumentVersion: {
        VERSION1: "V1",
        VERSION2: "V2",
        VERSION3: "V3"
    },
    Settings: {
        LOOKUP_VERSION: "LookupVersion",
        INTAKE_MAINTENANCE_MESSAGE: "IntakeMaintenanceMessage",
        DRIVER_MAINTENANCE_MESSAGE: "DriverMaintenanceMessage",
        ADMIN_MAINTENANCE_MESSAGE: "AdminMaintenanceMessage",
        INTAKE_MAINTENANCE_MESSAGE_HEADER: "IntakeMaintenanceMessageHeader",
        DRIVER_MAINTENANCE_MESSAGE_HEADER: "DriverMaintenanceMessageHeader",
        ADMIN_MAINTENANCE_MESSAGE_HEADER: "AdminMaintenanceMessageHeader",
        PAPER_SUBMISSION_OPTION: "PaperSubmissionOption",
        HEALTH_CHECK_TIMEOUT_LENGTH: "HealthCheckTimeoutLength",
        ENABLE_ONE_DOCUMENT_SPLIT: "EnableOneDocumentSplit"
    },
    Driver: {
        TermConditionNotificationPeriod: 30,
        SupplementalDocuments: [21, 22, 23, 26, 27, 28, 37],
        SeizureReviewDocuments: [21, 22, 23, 26, 27, 28, 31, 32, 33, 37, 38],
        ReservationLifeTime: 600,
        IRS_PENALTY_TABLE_EFFECTIVE_DATE: "2022-05-18",
        PUBLISH_ADDITIONAL_FIELDS_EFFECTIVE_DATE: "2023-05-17",
        SHOW_SEIZURE_TYPE_AND_DOCUMENT_METADATA_EFFECTIVE_DATE: "2023-06-01"
    },
    Role : {
        BUSINESS_ANALYST:"BusinessAnalyst",
        BUSINESS_SUPER_USER:"BusinessSuperUser",
        SENIOR_ADJUDICATOR:"Senior Adjudicator",
        DIRECTOR_ASSISTANT:"Director Assistant",
        CASE_ADMINISTRATOR:"Case Administrator",
        CASE_COORDINATOR:"Case Coordinator"
    },
    Resource : {
        CONTRAVENTION:"Contravention",
        REQUEST:"Request",
        REVIEW:"Review",
        SEIZURE:"Seizure",
        SIDE_NAV_COMPONENT:"SideNavComponent",
        SUPPORTING_DOCUMENT:"SupportingDocument",
        DASHBOARD:"Dashboard",
        MANAGE_ADJUDICATORS_DASHBOARD:"ManageAdjudicatorsDashBoard",
        TRAFFIC_DISCLOSURE:"TrafficDisclosure",
        TOW_COMPANIES:"TowCompanies",
        POLICE_INTAKE_REPORTS: "PoliceIntakeReports",
        POLICE_INTAKE_DASHBOARD: "PoliceIntakeDashboard",
        POLICE_INTAKE_SUBMISSION:"PoliceIntakeSubmission",
        POLICE_INTAKE_CANCELLATION_REQUEST:"PoliceIntakeCancellationRequest",
        JUDICIAL_REVIEW:"JudicialReview",
        REVIEW_REPRESENTATION:"ReviewRepresentation",
        FAILED_ATTENDANCE_DECLARATION_RELEASE_DATE: "2022-06-16",
        RETURN_LICENCE_BY_MAIL_TEXT: "Return by regular mail",
    },
    Permission : {
        ACTIVITY_NOTE:"ActivityNote",
        UPLOAD_DOCUMENT:"UploadDocument",
        APPROVE_OR_DENY_REQUEST:"ApproveOrDenyRequest",
        ASSIGN_ADJUDICATOR:"AssignAdjudicator",
        ATTEND_REVIEW:"AttendReview",
        CANCEL_REVIEW:"CancelReview",
        CHANGE_CONTACT_METHOD:"ChangeContactMethod",
        CHANGE_DECISION_DUE_DATE:"ChangeDecisionDueDate",
        CHANGE_REVIEW_METHOD:"ChangeReviewMethod",
        CHANGE_REVIEW_STATUS:"ChangeReviewStatus",
        CONTRAVENTIONS:"CONTRAVENTIONS",
        DATA_EDITS:"DataEdits",
        DECISION_AND_UPLOAD:"DecisionAndUpload",
        SUBMIT_DECISION:"SubmitDecision",
        DELETE_DECISION:"DeleteDecision",
        DELETE_DISCLOSURE_DOCUMENT: "DeleteDisclosureDocument",
        DELETE_DOCUMENT:"DeleteDocument",
        DOWNLOAD_DECISION:"DownloadDecision",
        ENABLE_LATE_REVIEW:"EnableLateReview",
        EXPORT_LIST:"ExportList",
        FINANCE:"FINANCE",
        IMMEDIATE_CANCEL:"ImmediateCancel",
        PAYMENT_HISTORY:"PaymentHistory",
        REASSIGN_ADJUDICATOR:"ReassignAdjudicator",
        REPORTS:"REPORTS",
        REQUESTS:"REQUESTS",
        RESCHEDULE_REVIEW:"RescheduleReview",
        REVIEW_ACTIVITY:"ReviewActivity",
        REVIEW_OUTCOME:"ReviewOutcome",
        REVIEW_OUTCOME_READ_ONLY:"ReviewOutcomeReadOnly",
        REVIEW_REQUEST_EXTENSION:"ReviewRequestExtension",
        REVIEWS:"REVIEWS",
        REVIEW_UPLOADS:"ReviewUploads",
        REVIEW_UPLOADS_EDIT:"ReviewUploadsEdit",
        REVIEW_FILE_CLOSURE:"ReviewFileClosure",
        SCHEDULE_REVIEW:"ScheduleReview",
        SEIZURES:"SEIZURES",
        UPDATE_CONTACT_METHOD:"UpdateContactMethod",
        UPLOAD_REDATED_DOCUMENT:"UploadRedatedDocument",
        USERS:"USERS",
        VIEW_SUPPORTING_DOCUMENTS:"ViewSupportingDocuments",
        ADD_ADDITIONAL_DOCUMENT:"AddAdditionalDocument",
        UPDATE_DOCUMENT:"UpdateDocument",
        VIEW_DOWNLOAD_RECIPIENT_SUBMISSIONS:"ViewDownloadRecipientSubmissions",
        VIEW_DOWNLOAD_LEA_SUBMISSIONS:"ViewDownloadLEASubmissions",
        DASHBOARD:"DASHBOARD",
        MANAGE_ADJUDICATORS:"ManageAdjudicators",
        MANAGE_ADJUDICATORS_EDIT:"ManageAdjudicatorsEdit",
        MANAGE_REVIEWS:"ManageReviews",
        MANAGE_REVIEWS_EDIT:"ManageReviewsEdit",
        VIEW_CALENDAR:"ViewCalendar",
        DOWNLOAD_REPORTS: "DownloadReports",
        EDIT_REVIEW_MAX_BOOKINGS:"EditReviewMaxBookings",
        VIEW_INTAKE_DASHBOARD: "ViewIntakeDashboard",
        SUBMIT_CONTRAVENTIONS_AND_SEIZURES:"SubmitContraventionsAndSeizures",
        SUBMIT_SUPPORTING_DOCUMENTS:"SubmitSupportingDocuments",        
        CANCEL_SINGLE_NOTICE: "CancelSingleNotice",
        CANCEL_MULTIPLE_NOTICES: "CancelMultipleNotices",
        SUBMIT_SHARED_SUPPORTING_DOCUMENTS:"SubmitSharedSupportingDocuments",
        CANCEL_REQUEST:"CancelRequest",
        JUDICIAL_REVIEWS:"JUDICIAL-REVIEWS",
        SERVE_JUDICIAL_REVIEW:"ServeJudicialReview",
        STAY_ORDER_EDIT:"StayOrderEdit",
        AVAILABILITY_REPORT: "AvailabilityReport",
        VIEW_REVIEW_CANCELLATION_REASON: "ViewReviewCancellationReason",
        TRAFFIC_DISCLOSURE: "TRAFFIC-DISCLOSURE",
        LOG_REFUND: "LogRefund",
        SUBMIT_TRAFFIC_TICKET: "SubmitTrafficTicket",
        WITHDRAW_TRAFFIC_TICKET: "WithdrawTrafficTicket",
        FINE_UNCOLLECTABLE:"FineUncollectable",
        VIEW_DRIVER_LOGS: "ViewDriverLogs",
        ENABLE_REVIEW: "EnableReview",
        TOW_COMPANIES: "TOW-COMPANIES",
        EDIT_TOW_LOT: "EditTowLot",
    },
    Registry: {
        TRANS22_INFO_SHEET_NAME: "Suspended Driver Program Vehicle Seizure Review: Information Sheet (TRANS22)",
        TRANS22_INFO_SHEET_LINK: "https://open.alberta.ca/publications/suspended-driver-program-vehicle-seizure-review-information-sheet",
        TRANS23_INFO_SHEET_NAME: "Affected Person Vehicle Seizure Review: Information Sheet (TRANS23)",
        TRANS23_INFO_SHEET_LINK: "https://open.alberta.ca/publications/affected-person-vehicle-seizure-review-information-sheet",
        TRANS29_INFO_SHEET_NAME: "Late Review Request: Information Sheet (TRANS29)",
        TRANS29_INFO_SHEET_LINK: "https://open.alberta.ca/publications/late-review-request-information-sheet",
        TRANS30_INFO_SHEET_NAME: "Immediate Roadside Sanctions Review: Information Sheet (TRANS30)",
        TRANS30_INFO_SHEET_LINK: "https://open.alberta.ca/publications/immediate-roadside-sanctions-review-request-information-sheet",
        LATE_EVIDENCE_INFO_SHEET_NAME: "Late Review: Late Evidence Applications (Information Sheet)",
        LATE_EVIDENCE_INFO_SHEET_LINK: "https://open.alberta.ca/publications/saferoads-late-review-late-evidence-applications",
        TRAFFIC_REVIEW_SHEET_NAME: "Traffic Review Information Sheet",
        TRAFFIC_REVIEW_SHEET_LINK: "https://open.alberta.ca/publications/immediate-roadside-sanctions-review-request-information-sheet",
    },
    Intake: {
        Messages: {
            TICKETS_HEALTH_STATUS_MESSAGE: 'Warning: JOIN is unavailable. You may not be able to issue traffic tickets',
        },
        PAPER_SUBMISSION_DEADLINE: "3022-03-15",
        PAPER_LESS_SUBMISSION_RELEASE_DATE: "2022-03-15",
        ADDITIONAL_RECIPIENT_AND_VEHICLE_FIELDS_RELEASE_DATE: "2022-05-17",
        ORAL_REVIEW_ELECTION_RELEASE_DATE: "2022-06-15",
        AI_3RD_TEST_EFFECTIVE_DATE: "2022-04-26",
        DRE_PROCESS_CHANGE_EFFECTIVE_DATE: "2022-06-23",
        BODYCAM_VIDEO_EFFECTIVE_DATE: "2023-08-01",
        BODYCAM_VIDEO_REMOVED_DATE: "2023-08-09",
        ADDITIONAL_DETAILS_DATE_EFFECTIVE_DATE: "2024-05-16"
    },
    Adjudication: {
        REVIEW_LAST_VIEWED_MODAL_EXPIRES_MINUTES: 15,
        DOCUMENT_PUBLISHED_DATE_EFFECTIVE_DATE: "2022-11-24",
        ADDITIONAL_CONDITION_MESSAGE_EFFECTIVE_DATE: "2023-04-17",
        HIDE_WITNESS_AND_PAY_CENTRE_EFFECTIVE_DATE: "2023-06-01",
        DOCUMENT_SUBMITTER_EFFECTIVE_DATE: "2023-06-08",
        CHANGE_DISPLAYED_INFORMATION_EFFECTIVE_DATE: "2023-09-01",
        LATE_REVIEW_REQUEST_LEA_SUBMISSIONS_EFFECTIVE_DATE: "2024-11-18"
    },
    Status: {
        HEALTHCHECK_HEALTHY_TEXT: 'healthy',
    },
    Configuration: {
        APP_REFRESH_INTERVAL_HOURS: 6
    },
    Mask: {
        YEAR_MASK: "0000",
        TIME_MASK: "h0:m0",
        TIME_MASK_PATTERNS: { 'h': { pattern: new RegExp('\[0-2\]')}, 'm': { pattern: new RegExp('\[0-5\]')}, '0': { pattern: new RegExp('\[0-9]')}}
    }
}
