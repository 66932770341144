<!-- Driver Portal View -->
<app-contravention-page *ngIf="!isRSGPortal">
    <ng-container content>
        <form #requestForm="ngForm"  [mustMatch]="['contactEmailAddress', 'confirmContactEmailAddress', 'contactPhoneNumber', 'confirmContactPhoneNumber']">
            <div class="d-flex flex-column">
                <h1>Request a Late Review</h1>
                <h3 class="border-bottom pb-4 mb-2">Notice(s). {{getNotices()}}</h3>

                <div class="card goa-callout goa--info">
                    <p class="mt-0 callout-title">Requesting a Late Review</p>
                    <p>You may request a late review within 12 months of the date of issuance. 
                        The grounds for a late review are set out in Section 10 of the <a class="fst-italic" href="https://kings-printer.alberta.ca/documents/Regs/2020_217.pdf" target="_blank">Provincial Administrative Penalties Regulation</a> and depending on your situation may include that:</p>
                    <ul>
                        <li>
                            You were <b>unaware</b> of the Notice of Administrative Penalty and on becoming aware of it took prompt action to notify the Director of SafeRoads
                        </li>
                        <li>
                            You experienced <b>physical or mental incapacity</b>, and you were unable to respond to the Notice within 7 days of the issuance of the Notice and on regaining capacity took prompt action to notify the Director of SafeRoads
                        </li>
                        <li>
                            You experienced <b>unforeseen and unavoidable events</b>, and you were unable to respond to the Notice within 7 days of the issuance of the Notice, and took prompt action to notify the Director of SafeRoads of the events
                        </li>
                    </ul>
                </div>

                <div class="card mt-3 mt-lg-4">
                    <div class="card-header">
                        Representation Type
                    </div>
                    <div class="card-body">
                        <div class="mb-3 row">
                            <div class="col-lg-12">
                                <label for="reviewType">Are you the Recipient of the Contravention Notice?</label>
                                <select class="form-select" id="reviewType" name="reviewType" 
                                    [(ngModel)]="lateReviewRequest.isSelfSubmit" (change)="onReviewTypeChange()">
                                    <option [ngValue]="true">Yes, I am submitting this application on behalf of myself</option>
                                    <option [ngValue]="false">No, I am submitting this application on behalf of another</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <app-representation *ngIf="!lateReviewRequest.isSelfSubmit"
                    [model]="lateReviewRequest.representation"
                    [isSubmitClicked]="requestForm.submitted"
                    [hideRepresenationMessage]="true"
                    [isYouth]="contraventionOverview.isRecipientYouth"></app-representation>

                <div class="my-3 card">
                    <div class="card-header">
                        Late Review Request
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-3">Contravention Number</div>
                            <div class="col-9">Traffic Safety Act Section</div>
                        </div>
                        <div class="row line-item-row" *ngFor="let lateReviewRequestItem of this.lateReviewRequest.lateReviewRequestItems">
                            <div class="col-3">
                                {{lateReviewRequestItem.recordNumber}}
                            </div>
                            <div class="col-9">
                                <div>
                                    {{getPrimaryCharge(lateReviewRequestItem.recordNumber)}}
                                </div>
                                <div>
                                    {{getSecondaryCharge(lateReviewRequestItem.recordNumber)}}
                                </div>
                                <div>
                                    {{getTertiaryCharge(lateReviewRequestItem.recordNumber)}}
                                </div>
                            </div>
                        </div>
                        <div class="mb-3 row mt-3">
                            <div class="col-12">
                                <label for="requestReason" class="required">Grounds for Request</label>
                                <textarea class="form-control" 
                                    id="requestReason"
                                    name="requestReason"
                                    rows="6" maxlength="5000"
                                    required noWhiteSpace [ngClass]="{ 'is-invalid': requestForm.submitted && requestReason.invalid }"
                                    [(ngModel)]="lateReviewRequest.requestReason" #requestReason="ngModel"
                                    placeholder="Please Provide Your Reason For Requesting a Late Review (Max 5000 Characters)"></textarea>
                                <div class="invalid-feedback">
                                    <div>Grounds for Request is required</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <app-contact-information [model]="lateReviewRequest" [isSubmitClicked]="requestForm.submitted"></app-contact-information>

                <div class="card border-0">
                    <app-foip></app-foip>
                </div>

                <div class="mt-2">
                    <app-terms-conditions [isSubmitClicked]="requestForm.submitted"></app-terms-conditions>
                </div>

                <div class="mb-3">
                    <div class="row pt-3">
                        <div class="col-12 col-sm-6">                
                            <button class="goa-button goa--secondary btn-block" [routerLink]="['../../../']">Return to Contravention Overview</button>
                        </div>
                        <div class="col-12 col-sm-6 text-end">                
                            <button class="goa-button btn-block" (click)="continueRequest(requestForm.valid)">Proceed to Supporting Documents</button>
                        </div>
                    </div>
                </div>	
            </div>
        </form>
    </ng-container>
</app-contravention-page>

<!-- Registry Portal View -->
<app-contravention-page *ngIf="isRSGPortal">
    <ng-container content>
        <form #requestForm="ngForm"  [mustMatch]="['contactEmailAddress', 'confirmContactEmailAddress', 'contactPhoneNumber', 'confirmContactPhoneNumber']">
            <div class="d-flex flex-column">
                <h1>Late Review Request Details</h1>
                <h3 class="border-bottom pb-4 mb-2">Notice(s). {{getNotices()}}</h3>

                <div class="card border-0">
                    <div class="goa-callout goa--information mt-1">
                        <h6 class="mt-0">Collect Review Details</h6>
                        <p>
                            The driver may request a late review within 12 months of the date of issuance. An application for late review is limited 
                            to the grounds listed in Section 10 of Provincial Administrative Penalties Regulation. Depending on the ground under which the client 
                            qualifies, some or all of the following grounds may apply:
                        </p>
                        <ul class="list ms-4">
                            <li>Where the recipient was unaware of the Notice of Administrative Penalty, if on becoming aware of it the recipient took prompt action to notify the Director</li>
                            <li>Where the recipient experienced physical or mental incapacity rendering the recipient unable to respond to the Notice of Administrative Penalty within 7 days of the issuance of the Notice, if on regaining capacity the recipient took prompt action to notify the Director</li>
                            <li>Where the recipient experienced unforeseen and unavoidable events rendering the recipient unable to respond to the Notice of Administrative Penalty within 7 days of the issuance of the Notice, if the recipient took prompt action to notify the Director</li>
                        </ul>
                        <p>Collect the following information from the client to submit a request for a late review.</p>
                    </div>
                </div>

                <app-registry-agent-information [registryAgentInformation]="registryAgentInformation"></app-registry-agent-information>
        
                <app-registry-payer-information
                [registryAgentInformation]="registryAgentInformation"
                [isFormSubmitted]="requestForm.submitted"
                (requesterTypeChange)="onRequesterTypeChange($event)"
                sectionHeader="Requester"
                sectionLabel="Who is requesting this review?">
                </app-registry-payer-information>

                <app-representation *ngIf="!lateReviewRequest.isSelfSubmit" [model]="lateReviewRequest.representation"
                [isSubmitClicked]="requestForm.submitted"
                [hideRepresenationMessage]="true"
                [isYouth]="contraventionOverview.isRecipientYouth"
                [isRSGPortal]="isRSGPortal"
                [isLateReviewRequest]="true"
                [registryAgentInformation]="registryAgentInformation"
                [showSameAsRequester]="true"></app-representation>

                <div class="card mt-4">
                    <div class="card-header">
                        Late Review Request
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-3">Contravention Number</div>
                            <div class="col-9">Traffic Safety Act Section</div>
                        </div>
                        <div class="row line-item-row" *ngFor="let lateReviewRequestItem of this.lateReviewRequest.lateReviewRequestItems">
                            <div class="col-3">
                                {{lateReviewRequestItem.recordNumber}}
                            </div>
                            <div class="col-9">
                                <div>
                                    {{getPrimaryCharge(lateReviewRequestItem.recordNumber)}}
                                </div>
                                <div>
                                    {{getSecondaryCharge(lateReviewRequestItem.recordNumber)}}
                                </div>
                                <div>
                                    {{getTertiaryCharge(lateReviewRequestItem.recordNumber)}}
                                </div>
                            </div>
                        </div>
                        <div class="mb-3 row mt-3">
                            <div class="col-12">
                                <label for="requestReason" class="required">Grounds for Request</label>
                                <textarea class="form-control" 
                                    id="requestReason"
                                    name="requestReason"
                                    rows="6" maxlength="5000"
                                    required noWhiteSpace [ngClass]="{ 'is-invalid': requestForm.submitted && requestReason.invalid }"
                                    [(ngModel)]="lateReviewRequest.requestReason" #requestReason="ngModel"
                                    placeholder="Please Provide Your Reason For Requesting a Late Review (Max 5000 Characters)"></textarea>
                                <div class="invalid-feedback">
                                    <div>Grounds for Request is required</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <app-contact-information [model]="lateReviewRequest" [isSubmitClicked]="requestForm.submitted"></app-contact-information>

                <div class="mb-3">
                    <div class="row pt-3">
                        <div class="col-12 col-sm-6">                
                            <button class="goa-button goa--secondary btn-block" [routerLink]="['../terms']">Return to Terms & Conditions</button>
                        </div>
                        <div class="col-12 col-sm-6 text-end">                
                            <button class="goa-button btn-block" (click)="continueRequest(requestForm.valid)">Proceed to Supporting Documents</button>
                        </div>
                    </div>
                </div>	
            </div>
        </form>
    </ng-container>
</app-contravention-page>