<!-- Driver Portal View -->
<app-contravention-page *ngIf="!isRSGPortal">
    <ng-container content>
        <form *ngIf="review" #rescheduleReviewForm="ngForm">
            <div class="d-flex flex-column">                
                <h1>{{ review.reviewStatusTypeId == ReviewStatusTypes.New ? "Schedule Review" : "Edit / Reschedule Review" }}</h1>
                <h3 class="border-bottom pb-4 mb-2">No. {{review.reviewNumber}}</h3>
                <div class="card goa-callout goa--emergency mt-3" *ngIf="isRequestFailed">
                    <h6 class="mt-0">Reschedule Request Failed</h6>
                    <p *ngIf="requestFailedMessage; else noMessageSpecified">
                        {{requestFailedMessage}}
                    </p>
                    <ng-template #noMessageSpecified>
                        <p>
                            Your request can't be completed right now. Please try again later.
                        </p>
                    </ng-template>
                </div>
                <div class="card mt-3 border-0">
                    <div class="mb-3 row">
                        <div class="col-lg-6">
                            <label for="reviewMethod" class="required">Review Method</label>
                            <div class="ms-1 question-icon" data-bs-toggle="modal" data-bs-target="#aboutRreviewMethodModal">
                                <i class="far fa-question-circle fa-lg"></i>
                            </div>
                            <div class="mt-2">
                                <fieldset [disabled]="review.isAllowedToEditReviewMethod == false">
                                    <div class="form-check custom-control-inline"
                                        *ngFor="let reviewMethodType of reviewMethodTypes; let i=index;">
                                        <input type="radio" class="form-check-input"
                                            [(ngModel)]="review.reviewMethodTypeId" [value]="reviewMethodType.id"
                                            id="reviewMethodType_{{reviewMethodType.id}}" name="reviewMethodTypeId"
                                            (change)="onReviewMethodChange()" />
                                        <label class="form-check-label"
                                            for="reviewMethodType_{{reviewMethodType.id}}">{{reviewMethodType.name}}</label>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </div>    

                    <ng-container *ngIf="review.reviewMethodTypeId==2">
                        <div *ngIf="failedAttendanceDeclarationActive">
                            <app-failed-attendance-declaration
                            [review]="review"
                            [isFormSubmitted]="rescheduleReviewForm.submitted"
                            [isDisabled]="!review.isRescheduleAllowed && review.reviewStatusTypeId != ReviewStatusTypes.New"
                            [isRSGPortal]="isRSGPortal">
                            </app-failed-attendance-declaration>
                        </div>
                        <div>
                            <p>
                                Please select a timeslot below to schedule a review.
                            </p>
                            <p *ngIf="availabilityView?.availabilityInformationList?.length > 0">
                                If you require assistance with your review request, please contact SafeRoads Alberta at <a href="tel:780-427-7233">780-427-SAFE (7233)</a> or by email to <a href="mailto:saferoads@gov.ab.ca">saferoads&#64;gov.ab.ca</a>.
                            </p>
                        </div>
                        <div>
                            <app-auto-scheduler class="adj-cal" [scheduleDate]="newReviewDateUtc"
                                [scheduleTime]="review.reviewTime" [schedule]="availabilityView"
                                (onRefresh)="onScheduleRefreshClick()"
                                (scheduleDateSelected)="onScheduleDateSelected($event)">
                            </app-auto-scheduler>
                        </div>
                        <div *ngIf="isAvailabilityRequestFailed" class="card border-0 text-danger">
                            <p>
                                An error occurred. Please contact SafeRoads Alberta at <a href="tel:780-427-7233">780-427-SAFE (7233)</a> or by email to <a href="mailto:saferoads@gov.ab.ca">saferoads&#64;gov.ab.ca</a> for assistance with your review request.
                            </p>
                        </div>
                        <div *ngIf="documentSubmissionCutoffDate">
                            <p>
                                By selecting this day, you must submit all your supporting documents by
                                <b>11:59pm</b> on <b>{{ documentSubmissionCutoffDate | date:'fullDate':'UTC' }}</b>.
                            </p>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="review.reviewMethodTypeId==1">
                        <div>
                            <p>
                                What day would you like your matter to be reviewed? Please note: you
                                <b><u>do not</u></b> attend a written review.
                            </p>
                            <p *ngIf="availabilityViewForWritten?.length > 0">
                                If you require assistance with your review request, please contact SafeRoads Alberta at <a href="tel:780-427-7233">780-427-SAFE (7233)</a> or by email to <a href="mailto:saferoads@gov.ab.ca">saferoads&#64;gov.ab.ca</a>.
                            </p>
                            <div *ngIf="availabilityView">
                                <h3 class="display">{{availabilityView.startDate | date:'MMM dd, yyyy'}} - {{availabilityView.endDate |
                                    date:'MMM dd, yyyy'}}</h3>
                                <button (click)="onScheduleRefreshClick()" class="goa-button goa--secondary m-0 mt-3 ms-auto refreshBtn">
                                    Refresh
                                </button>
                            </div>
                        </div>                    
    
                        <div *ngIf="availabilityViewForWritten!=null">
                            <div *ngIf="availabilityViewForWritten.length == 0" class="h6">
                                There are no available review dates. Please contact SafeRoads Alberta at <a href="tel:780-427-7233">780-427-SAFE (7233)</a> or by email to <a href="mailto:saferoads@gov.ab.ca">saferoads&#64;gov.ab.ca</a> for assistance with your review request.
                            </div>
                            <ul *ngIf="availabilityViewForWritten.length > 0">
                                <li class="cal-dateslot"
                                    [ngClass]="{ 'cal-dateslot-selected': availableInfo === selAvailableInfoForWritten }"
                                    *ngFor="let availableInfo of availabilityViewForWritten"
                                    (click)="onDateSelected(availableInfo)">
                                    <span>{{ availableInfo.date | date:'longDate' }}</span>
                                    <br>
                                    <b>{{ availableInfo.date | date:'EEEE' }}</b>
                                </li>
                            </ul>
                        </div>
    
                        <div *ngIf="isAvailabilityRequestFailed" class="card border-0 text-danger">
                            <p>
                                An error occurred. Please contact SafeRoads Alberta at <a href="tel:780-427-7233">780-427-SAFE (7233)</a> or by email to <a href="mailto:saferoads@gov.ab.ca">saferoads&#64;gov.ab.ca</a> for assistance with your review request.
                            </p>
                        </div>
                        <div *ngIf="documentSubmissionCutoffDate">
                            <p>
                                By selecting this day, you must submit all your supporting documents by
                                <b>11:59pm</b> on <b>{{ documentSubmissionCutoffDate | date:'fullDate':'UTC'}}.</b>
                            </p>
                        </div>
                    </ng-container>

                </div>

                <div class="mt-2" *ngIf="review.reviewStatusTypeId == ReviewStatusTypes.New">
                    <app-terms-conditions [isSubmitClicked]="rescheduleReviewForm.submitted"></app-terms-conditions>
                </div>

                <div class="mb-3 pt-2">
                    <div class="row pt-3">
                        <div class="col-12 col-sm-6" *ngIf="review.reviewStatusTypeId == ReviewStatusTypes.New; else NotNewReview">
                            <button class="goa-button goa--secondary btn-block" [routerLink]="['../../../..']">
                                Return to Contravention Overview
                            </button>
                        </div>
                        <ng-template #NotNewReview>
                            <div class="col-12 col-sm-6">
                                <button class="goa-button goa--secondary btn-block" [routerLink]="['../details']">
                                    Return to Review
                                </button>
                            </div>
                        </ng-template>
                        <div class="col-12 col-sm-6 text-end">
                            <button class="goa-button btn-block"
                                [disabled]="formSubmitted || isSubmitDisabled"
                                (click)="onRescheduleClick($event)">
                                Save Changes
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </ng-container>
</app-contravention-page>

<app-contravention-page *ngIf="isRSGPortal">
    <ng-container content>
        <form *ngIf="review" #rescheduleReviewForm="ngForm">
            <div class="d-flex flex-column">
                <h1>Edit / Reschedule Review</h1>
                <h3 class="border-bottom pb-4 mb-2">No. {{review.reviewNumber}}</h3>
                <div class="card goa-callout goa--emergency mt-3" *ngIf="isRequestFailed">
                    <h6 class="mt-0">Reschedule Request Failed</h6>
                    <p *ngIf="requestFailedMessage; else noMessageSpecified">
                        {{requestFailedMessage}}
                    </p>
                    <ng-template #noMessageSpecified>
                        <p>
                            Your request can't be completed right now. Please try again later.
                        </p>
                    </ng-template>
                </div>
                <div class="card mt-3 border-0">
                    <div class="mb-3 row">
                        <div class="col-lg-6">
                            <label for="reviewMethod" class="required">Review Method</label>
                            <div class="ms-1 question-icon" data-bs-toggle="modal" data-bs-target="#aboutRreviewMethodModal">
                                <i class="far fa-question-circle fa-lg"></i>
                            </div>
                            <div class="mt-2">
                                <div class="form-check custom-control-inline"
                                    *ngFor="let reviewMethodType of reviewMethodTypes; let i=index;">
                                    <input type="radio" class="form-check-input"
                                        [disabled]="review.isAllowedToEditReviewMethod == false"
                                        [(ngModel)]="review.reviewMethodTypeId" [value]="reviewMethodType.id"
                                        id="reviewMethodType_{{reviewMethodType.id}}" name="reviewMethodTypeId"
                                        (change)="onReviewMethodChange()" />
                                    <label class="form-check-label"
                                        for="reviewMethodType_{{reviewMethodType.id}}">{{reviewMethodType.name}}</label>
                                </div>
                            </div>
                        </div>
                    </div>    

                    <ng-container *ngIf="review.reviewMethodTypeId==2">
                        <div *ngIf="failedAttendanceDeclarationActive">
                            <app-failed-attendance-declaration
                            [review]="review"
                            [isFormSubmitted]="rescheduleReviewForm.submitted"
                            [isDisabled]="!review.isRescheduleAllowed && review.reviewStatusTypeId != ReviewStatusTypes.New"
                            [isRSGPortal]="isRSGPortal">
                            </app-failed-attendance-declaration>
                        </div>
                        <div>
                            <p>
                                Please ask the client to choose a timeslot when they wish their contravention to be reviewed.
                            </p>
                        </div>
                        <div>
                            <app-auto-scheduler class="adj-cal" [scheduleDate]="newReviewDateUtc"
                                [scheduleTime]="review.reviewTime" [schedule]="availabilityView"
                                (onRefresh)="onScheduleRefreshClick()"
                                (scheduleDateSelected)="onScheduleDateSelected($event)">
                            </app-auto-scheduler>
                        </div>
                        <div *ngIf="isAvailabilityRequestFailed" class="card border-0 text-danger">
                            <p>
                                An error occurred. Please contact SafeRoads Alberta at <a href="tel:780-427-7233">780-427-SAFE (7233)</a> or by email to <a href="mailto:saferoads@gov.ab.ca">saferoads&#64;gov.ab.ca</a> for assistance with your review request.
                            </p>
                        </div>
                        <div *ngIf="documentSubmissionCutoffDate">
                            <p>
                                By selecting this day, you must submit all your supporting documents by
                                <b>11:59pm</b> on <b>{{ documentSubmissionCutoffDate | date:'fullDate':'UTC' }}</b>.
                            </p>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="review.reviewMethodTypeId==1">
                        <div>
                            <p>
                                Please ask the client to choose a day when they wish their matter to be reviewed. Please note: the client <b><u>does not</u></b> attend a written review.                                
                            </p>
                            <div *ngIf="availabilityView">
                                <h3 class="display">{{availabilityView.startDate | date:'MMM dd, yyyy'}} - {{availabilityView.endDate |
                                    date:'MMM dd, yyyy'}}</h3>
                                <button (click)="onScheduleRefreshClick()" class="goa-button goa--secondary m-0 mt-3 ms-auto refreshBtn">
                                    Refresh
                                </button>
                            </div>
                        </div>                    
    
                        <div *ngIf="availabilityViewForWritten!=null">
                            <div *ngIf="availabilityViewForWritten.length == 0" class="h6">
                                There are no available review dates. Please contact SafeRoads Alberta at <a href="tel:780-427-7233">780-427-SAFE (7233)</a> or by email to <a href="mailto:saferoads@gov.ab.ca">saferoads&#64;gov.ab.ca</a> for assistance with your review request.
                            </div>
                            <ul *ngIf="availabilityViewForWritten.length > 0">
                                <li class="cal-dateslot"
                                    [ngClass]="{ 'cal-dateslot-selected': availableInfo === selAvailableInfoForWritten }"
                                    *ngFor="let availableInfo of availabilityViewForWritten"
                                    (click)="onDateSelected(availableInfo)">
                                    <span>{{ availableInfo.date | date:'longDate' }}</span>
                                    <br>
                                    <b>{{ availableInfo.date | date:'EEEE' }}</b>
                                </li>
                            </ul>
                        </div>
    
                        <div *ngIf="isAvailabilityRequestFailed" class="card border-0 text-danger">
                            <p>
                                An error occurred. Please contact SafeRoads Alberta at <a href="tel:780-427-7233">780-427-SAFE (7233)</a> or by email to <a href="mailto:saferoads@gov.ab.ca">saferoads&#64;gov.ab.ca</a> for assistance with your review request.
                            </p>
                        </div>
                        <div *ngIf="documentSubmissionCutoffDate">
                            <p>
                                By selecting this day, you must submit all your supporting documents by
                                <b>11:59pm</b> on <b>{{ documentSubmissionCutoffDate | date:'fullDate':'UTC'}}.</b>
                            </p>
                        </div>
                    </ng-container>

                </div>

                <div class="mt-2" *ngIf="review.reviewStatusTypeId == ReviewStatusTypes.New">
                    <app-review-terms-conditions *ngIf="review.reviewTypeId==ReviewTypes.IrsReview"
                    [infoSheetName]="Constants.Registry.TRANS30_INFO_SHEET_NAME"
                    [infoSheetLink]="Constants.Registry.TRANS30_INFO_SHEET_LINK"
                    [lateEvidenceInfoSheetName]="Constants.Registry.LATE_EVIDENCE_INFO_SHEET_NAME"
                    [lateEvidenceInfoSheetLink]="Constants.Registry.LATE_EVIDENCE_INFO_SHEET_LINK"
                    [isSubmitClicked]="rescheduleReviewForm.submitted"></app-review-terms-conditions>

                    <app-review-terms-conditions *ngIf="review.reviewTypeId==ReviewTypes.TrafficReview"
                    [infoSheetName]="Constants.Registry.TRAFFIC_REVIEW_SHEET_NAME"
                    [infoSheetLink]="Constants.Registry.TRAFFIC_REVIEW_SHEET_LINK"
                    [isSubmitClicked]="rescheduleReviewForm.submitted"></app-review-terms-conditions>
                </div>

                <div class="mb-3 pt-2">
                    <div class="row pt-3">
                        <div class="col-12 col-sm-6" *ngIf="review.reviewStatusTypeId == ReviewStatusTypes.New; else NotNewReview">
                            <button class="goa-button goa--secondary btn-block" [routerLink]="['../../../..']">
                                Return to Contravention Overview
                            </button>
                        </div>
                        <ng-template #NotNewReview>
                            <div class="col-12 col-sm-6">
                                <button class="goa-button goa--secondary btn-block" [routerLink]="['../details']">
                                    Return to Review
                                </button>
                            </div>
                        </ng-template>
                        <div class="col-12 col-sm-6 text-end">
                            <button class="goa-button btn-block"
                                [disabled]="(rescheduleReviewForm.invalid && formSubmitted) || isSubmitDisabled"
                                (click)="onRescheduleClick($event)">
                                Save Changes
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </ng-container>
</app-contravention-page>

<!-- Confirm review method change modal -->
<div class="modal fade" id="confirmModal" tabindex="-1" role="dialog" aria-labelledby="confirmModalLabel"
    aria-hidden="true" data-bs-backdrop='static'>
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content p-4">
            <div class="modal-header border-0 pt-2">
                <span class="modal-title h4" id="confirmModalLabel">
                    Confirm Review Method Change
                </span>
                <div class="close-icon" (click)="cancelReviewMethodChange()" data-bs-dismiss="modal">
                    <i class="far fa-times-circle fa-lg"></i>
                </div>
            </div>
            <div class="modal-body py-0">
                <div *ngIf="review && review.reviewMethodTypeId==1">
                    Are you sure you would like to change your review method from <b>Oral</b> to <b>Written</b>?.
                </div>
                <div *ngIf="review && review.reviewMethodTypeId==2">
                    Are you sure you would like to change your review method from <b>Written</b> to <b>Oral</b>?.
                </div>
                <div class="button-container float-end">
                    <button id="btnGoBack" type="button" class="goa-button goa--secondary"
                        data-bs-dismiss="modal" (click)="cancelReviewMethodChange()">Cancel</button>
                    <button id="btnConfirm" type="button" class="goa-button goa--primary me-0" data-bs-dismiss="modal"
                        (click)="changeReviewMethod()">Confirm</button>
                </div>
            </div>
        </div>
    </div>
</div>

<!--Confirm date selected modal -->
<div class="modal fade" id="confirmDateModal" tabindex="-1" role="dialog" aria-labelledby="confirmDateModal"
    aria-hidden="true" data-bs-backdrop='static'>
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content p-4">
            <div class="modal-header border-0 pt-2">
                <span class="modal-title h4" id="confirmDateModal">
                    Confirm Review
                </span>
                <div class="close-icon" data-bs-dismiss="modal">
                    <i class="far fa-times-circle fa-lg"></i>
                </div>
            </div>
            <div class="modal-body py-0">
                <div *ngIf="review && review.reviewMethodTypeId==1">
                    Are you sure you would like to {{ review.reviewStatusTypeId == ReviewStatusTypes.New ? "schedule" : "reschedule" }} your review to <b>{{newReviewDateUtc | date:'fullDate':'UTC'}}</b>?
                </div>
                <div *ngIf="review && review.reviewMethodTypeId==2">
                    Are you sure you would like to {{ review.reviewStatusTypeId == ReviewStatusTypes.New ? "schedule" : "reschedule" }} your review to <b>{{newReviewDateUtc | date:'fullDate':'UTC'}} at {{review.reviewTime | time}}</b>?
                </div>
                <div class="button-container float-end">
                    <button id="btnGoBack" type="button" class="goa-button goa--secondary"
                        data-bs-dismiss="modal">Cancel</button>
                    <button id="btnConfirm" type="button" class="goa-button goa--primary me-0" data-bs-dismiss="modal"
                        (click)="selectReviewDate()">Confirm</button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal" id="aboutRreviewMethodModal" tabindex="-1" role="dialog" aria-labelledby="aboutRreviewMethodModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content p-4">
            <div class="modal-header border-0 pt-2">
                <span class="modal-title h4" id="aboutRreviewMethodModal">
                    Oral vs. Written Review Methods
                </span>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                    <i class="far fa-times-circle fa-lg"></i>
                </button>
            </div>
            <div class="modal-body py-0" *ngIf="!isRSGPortal">
                <h4>Oral Review</h4>
                <p>You will meet with an adjudicator by videoconference or by phone. 
                    A videoconferencing link or telephone number will be provided to connect at the scheduled review time. 
                    Your review will be 30 minutes in length.</p>
                <h4>Written Review</h4>
                <p>You will provide a written submission with your reasons for disputing the Notice of Administrative Penalty, 
                    along with other supporting documents, if applicable. 
                    You do not meet with an adjudicator or attend the review.</p>
                <h4>Decision</h4>
                <p>For both review methods, the adjudicator will make their decision to confirm 
                    or cancel your Notice of Administrative Penalty in writing, 
                    within 30 days from the date you were issued the Notice(s).</p>
                <p>
                    For details, visit <a target="_blank" href="https://www.saferoads.com">SafeRoads Alberta</a>.
                </p>
            </div>
            <div class="modal-body py-0" *ngIf="isRSGPortal">
                <h4>Oral Review</h4>
                <p>The client will meet with an adjudicator by videoconference or by phone. 
                    A videoconferencing link or telephone number will be provided to connect at the scheduled review time. 
                    The review will be 30 minutes in length.</p>
                <h4>Written Review</h4>
                <p>The client will provide a written submission with their reasons for disputing the Notice of Administrative Penalty, 
                    along with other supporting documents, if applicable. 
                    The client does not meet with an adjudicator or attend the review.</p>
                <h4>Decision</h4>
                <p>For both review methods, the adjudicator will make their decision to confirm or cancel the Notice 
                    of Administrative Penalty in writing, within 30 days from the date the client was issued the Notice(s).</p>
            </div>
        </div>
    </div>
</div>