import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TypeTable } from '@apis/shared/enums/type-table.enum';
import { VehicleSeizure } from '@apis/shared/models/vehicle-seizure.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { DocumentModel } from '@apis/shared/models/document-model.model';
import { DocumentService } from '@apis/shared/services/document.service';
import * as fileSaver from 'file-saver';
import { DocumentType } from '@apis/shared/models/types/document-type.model';
import { LateReviewRequest } from '@apis/shared/models/late-review-request.model';
import { DriverBaseComponent } from '../../../driver-base.component';
import { Constants } from '@apis/shared/helpers/constants';

@Component({
  selector: 'app-seizure-late-review-decision',
  templateUrl: './seizure-late-review-decision.component.html',
  styleUrls: ['./seizure-late-review-decision.component.scss']
})
export class SeizureLateReviewDecisionComponent extends DriverBaseComponent implements OnInit {
  vehicleSeizure: VehicleSeizure;
  lateReviewRequest: LateReviewRequest;
  decisionLetterDocument: DocumentModel;

  TypeTable = TypeTable;

  constructor(
    router: Router,
    private activatedRoute: ActivatedRoute,
    private documentService: DocumentService,
    private localStorageService: LocalStorageService
  ) {
    super(router);
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((paramMap) => {
      const seizureNumber = paramMap.get('seizureNumber');
      this.vehicleSeizure = this.localStorageService.getItem<VehicleSeizure>(seizureNumber);

      if (this.vehicleSeizure.lateReviewRequestItems?.length > 0) {
        this.lateReviewRequest = this.vehicleSeizure.lateReviewRequestItems[0].lateReviewRequest;
      } else {
        this.router.navigate(['../../']);
      }

      let decisionDocument = this.lateReviewRequest.documents.find(
        (document) => document.documentTypeId == 30
      );

      if (decisionDocument) {
        var documentType: DocumentType = this.localStorageService
          .getDocumentTypes()
          .find((x) => x.id == decisionDocument.documentTypeId);
        this.decisionLetterDocument = new DocumentModel(
          documentType?.name,
          decisionDocument.documentName,
          decisionDocument.documentExtension,
          decisionDocument.contentGuid,
          !documentType?.isSubmittableAtLaterDate
        );
        this.decisionLetterDocument.version = decisionDocument.version;
      }
    });
  }

  DownloadDocument(document: DocumentModel) {
    var storageFileName = `${document.contentGuid}.${document.documentExtension}`;

    if (document.version == Constants.DocumentVersion.VERSION3) {
      this.documentService.getDocumentSasUri("", "Requests", this.lateReviewRequest.lateReviewRequestNumber, storageFileName, document.documentName)
      .subscribe((result: any) => {
        window.open(result.documentSasUri, "_blank");
      });

      return;
    }

    this.documentService
      .downloadDocument(
        '',
        'Requests',
        this.lateReviewRequest.lateReviewRequestNumber,
        storageFileName,
        document.documentName
      )
      .subscribe((result: any) => {
        if (result) {
          fileSaver.saveAs(new Blob([result]), document.documentName);
        }
      });
  }
}

