import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Form, FormControl, NgForm, NgModel, Validators } from '@angular/forms';
import { EventTypes, DocumentTypes, ReviewMethodTypes, ReviewStatusTypes, ReviewTypes, RepresentationTypes, ReviewOutcomeTypes, DecisionTypes } from '@apis/shared/enums/app.enum';
import { Contravention } from '@apis/shared/models/contravention.model';
import { DocumentModel } from '@apis/shared/models/document-model.model';
import { DocumentType } from '@apis/shared/models/types/document-type.model';
import { Review } from '@apis/shared/models/review.model';
import { LocalStorageService } from '@apis/shared/services/local-storage.service';
import { DocumentService } from '@apis/shared/services/document.service';
import { ReviewService } from 'apps/driver/src/app/shared/services/review.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as fileSaver from 'file-saver';
import { finalize } from 'rxjs/operators';
import { DateUtil } from '@apis/shared/helpers/date-util';
import { Constants } from '@apis/shared/helpers/constants';
import { SectionDetailsModel } from '@apis/shared/models/section-details.model';
import { TermCondition } from '@apis/shared/models/term-condition.model';
import { CommonUtil } from '@apis/shared/helpers/common-util';
import { DatePipe } from '@angular/common';
import { ViewContainerRef } from '@angular/core';
import { UploadReviewConsentModalComponent } from 'apps/driver/src/app/shared/components/modals/upload-review-consent-modal/upload-review-consent-modal.component';
import * as moment from 'moment';
import { DriverBaseComponent } from '../../../../driver-base.component';
import { ContraventionOverview } from '@apis/shared/models/contravention-overview.model';
import { ContraventionService } from 'apps/driver/src/app/shared/services/contravention.service';
import { ContraventionPageComponent } from '../../../contravention-page/contravention-page.component';
import { DomSanitizer } from '@angular/platform-browser';


declare var $: any

@Component({
  selector: 'app-review-details-view',
  templateUrl: './review-details-view.component.html',
  styleUrls: ['./review-details-view.component.scss']
})
export class ReviewDetailsViewComponent extends DriverBaseComponent implements OnInit {
  @ViewChild('masterPage') masterPage: ContraventionPageComponent;
  
  contraventionNumber: string;
  review: Review;
  reviewMethodName: string;
  reviewDecision: string;
  isConsentAccepted = false;
  isDecisionAvailable = false;
  isCancelFailed = false;
  maxReviewSequence: number;
  reviewSequence: number;
  reviewTypeId: number;
  showDocumentMetadataActive: boolean;
  contraventionOverview: ContraventionOverview;

  ReviewStatusTypes = ReviewStatusTypes;
  ReviewMethodTypes = ReviewMethodTypes;
  ReviewTypes = ReviewTypes;

  registryAgentInformation: SectionDetailsModel[] = [];
  representationInformation: SectionDetailsModel[] = [];
  failedAttendanceDeclarationInformation: SectionDetailsModel[] = [];
  supportingDocuments: DocumentModel[] = [];
  consentToRepresentationDocuments: DocumentModel[] = [];
  reviewUploads: DocumentModel[] = [];
  decisionLetterDocument: DocumentModel;
  termCondition: TermCondition;

  reviewDate: string = '-';
  reviewTime: string = '-';
  reviewStartTime: string;
  reviewEndTime: string;

  isReviewCancelled: boolean;
  isReviewAbandoned: boolean;

  constructor(
    router: Router,
    private activatedRoute: ActivatedRoute,
    private documentService: DocumentService,
    private localStorageService: LocalStorageService,
    private reviewService: ReviewService,
    private readonly spinner: NgxSpinnerService,
    private datePipe: DatePipe,
    readonly viewContainerRef: ViewContainerRef,
    readonly contraventionService: ContraventionService,
    private sanitizer: DomSanitizer) {
    super(router);
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((paramMap) => {
      this.contraventionNumber = paramMap.get('contraventionNumber');
      this.reviewSequence = Number.parseInt(paramMap.get('reviewSequence'));
      this.reviewTypeId = Number.parseInt(paramMap.get('reviewType'));

      // Load ContraventionOverview. (Cannot use old value from localstorage; needs to be updated after a new review is created)
      setTimeout(() => {
        this.masterPage.showSpinner();
        this.contraventionService
          .getContraventionOverview(this.contraventionNumber)
          .subscribe((contraventionOverview: ContraventionOverview) => {

            if (contraventionOverview) {
              this.localStorageService.setItem<ContraventionOverview>('ContraventionOverview', contraventionOverview);
              this.contraventionOverview = contraventionOverview;
              this.loadReview();
            } else {
              this.masterPage.hideSpinner();
              this.router.navigate(['../../../../'], { relativeTo: this.activatedRoute });
            }
          },
          (error) => {
            this.masterPage.hideSpinner();
            this.router.navigate(['../../../../'], { relativeTo: this.activatedRoute });
          });
      })
    });
  }

  loadReview() {
    this.masterPage.showSpinner();

    this.contraventionService.getReviewInSequenceAsync(this.contraventionNumber, this.reviewSequence, this.reviewTypeId)
      .subscribe((review) => {
        review.contraventionNumber = this.contraventionNumber;

        this.localStorageService.setItem(`Review_${this.reviewSequence}_${this.reviewTypeId}`, review);
        
        if (review.reviewStatusTypeId == ReviewStatusTypes.New) {
          this.masterPage.hideSpinner();
          this.router.navigate(['../reschedule'], { relativeTo: this.activatedRoute });  
        } else {
          this.populateData(review);
        }
        
        this.masterPage.hideSpinner();
      },
      (error) => {
        this.masterPage.hideSpinner();
        this.router.navigate(['../../../../'], { relativeTo: this.activatedRoute });
      });
  }

  populateData(review: Review) {
    this.registryAgentInformation = [];
    this.representationInformation = [];
    this.failedAttendanceDeclarationInformation = [];
    this.supportingDocuments = [];
    this.consentToRepresentationDocuments = [];
    this.reviewUploads = [];

    this.review = review;

    this.termCondition = this.localStorageService.getTermsAndConditionsList().find(t => t.version == this.review.termsConditionsVersion);

    this.maxReviewSequence = Math.max(this.contraventionOverview.recentIrsReviewSequence, this.contraventionOverview.recentTsaReviewSequence);

    this.isReviewCancelled = this.review.reviewStatusTypeId == ReviewStatusTypes.Cancelled;

    if (this.review.outcome) {
      this.isReviewAbandoned = this.review.outcome.reviewOutcomeTypeId == ReviewOutcomeTypes.AbandonedLateEvidence || this.review.outcome.reviewOutcomeTypeId == ReviewOutcomeTypes.AbandonedOther;
    }

    this.reviewMethodName = this.localStorageService.getReviewMethodTypes().find(x => x.id == this.review.reviewMethodTypeId)?.name;

    if (this.isRSGPortal && this.review.isSubmittedByRegistry) {
      let registryAgentInfo = this.review.registryAgentInformation[0];

      if (!registryAgentInfo.isPaidByRecipient) {
        this.registryAgentInformation.push(new SectionDetailsModel('First Name', registryAgentInfo.payerFirstName));
        this.registryAgentInformation.push(new SectionDetailsModel('Last Name', registryAgentInfo.payerLastName));
      }
    }

    if (this.review.representation) {
      // Representation Informaton
      const repTypeId = this.review.representation.representationTypeId;

      const repTypeName = this.localStorageService.getRepresentationTypes()
        .find(x => x.id == repTypeId)?.name;
      this.representationInformation.push(new SectionDetailsModel(`Representation Type`, repTypeName, true))

      this.representationInformation.push(new SectionDetailsModel(`Representative First Name`, this.review.representation.firstName));
      this.representationInformation.push(new SectionDetailsModel(`Representative Last Name`, this.review.representation.lastName));

      if (+repTypeId === RepresentationTypes.Agent || +repTypeId === RepresentationTypes.Lawyer) {
        this.representationInformation.push(new SectionDetailsModel(`Practice Name`, this.review.representation.practiceName, true));
      }

      if (+repTypeId === RepresentationTypes.Other) {
        this.representationInformation.push(new SectionDetailsModel('Other Representation Type', this.review.representation.otherRepresentationType, true));
      }
    }
    else {
      this.representationInformation.push(new SectionDetailsModel(`Representation Type`, 'Self', true))
    }

    // Display failed attendance declaration, if the review was requested after the feature's release
    if (new Date(this.review.requestDate) >= new Date(+Constants.Resource.FAILED_ATTENDANCE_DECLARATION_RELEASE_DATE.substring(0, 4), +Constants.Resource.FAILED_ATTENDANCE_DECLARATION_RELEASE_DATE.substring(5, 7)-1, +Constants.Resource.FAILED_ATTENDANCE_DECLARATION_RELEASE_DATE.substring(8, 10))) {
      if (this.review.reviewMethodTypeId == ReviewMethodTypes.Oral) {
        const declarationText = this.localStorageService.getDeclarationTypes().find(x => x.id == this.review.failedAttendanceDeclarationTypeId)?.formattedDescription;
        const declarationSafeHTML = this.sanitizer.bypassSecurityTrustHtml(declarationText);
        this.failedAttendanceDeclarationInformation.push(new SectionDetailsModel('If you fail to attend the review hearing, how do you elect to proceed?',
          declarationSafeHTML, true, false, true));
      }
    }

    // Supporting Documents Section
    this.showDocumentMetadataActive = new Date(this.review.requestDate) >= new Date(+Constants.Driver.SHOW_SEIZURE_TYPE_AND_DOCUMENT_METADATA_EFFECTIVE_DATE.substring(0, 4), +Constants.Driver.SHOW_SEIZURE_TYPE_AND_DOCUMENT_METADATA_EFFECTIVE_DATE.substring(5, 7) - 1, +Constants.Driver.SHOW_SEIZURE_TYPE_AND_DOCUMENT_METADATA_EFFECTIVE_DATE.substring(8, 10));

    this.review.documents.forEach(document => {
      var documentType: DocumentType = this.localStorageService.getDocumentTypes().find(x => x.id == document.documentTypeId);
      var documentModel = new DocumentModel(documentType?.name, document.documentName, document.documentExtension, document.contentGuid, !documentType?.isSubmittableAtLaterDate, document.uploadedDate);
      documentModel.documentSize = document.documentSize;
      documentModel.version = document.version;

      if (+document.documentTypeId == DocumentTypes.ReviewDecisionLetter) {
        // Review decision letter
        this.decisionLetterDocument = documentModel;
      } else if (+document.documentTypeId == DocumentTypes.ReviewUploads) {
        if (document.isPublished) {
          this.reviewUploads.push(documentModel);
        }
      }
      else {
        // Show documents that are related to Driver
        if (Constants.Driver.SupplementalDocuments.includes(+document.documentTypeId)) {

          if (+document.documentTypeId == DocumentTypes.RecipientsConsentOfRepresentation) {
            this.consentToRepresentationDocuments.push(documentModel);
          } else {
            this.supportingDocuments.push(documentModel);
          }

        }
      }
    });

    if (this.review.reviewStatusTypeId == ReviewStatusTypes.Complete) {
      this.isDecisionAvailable = true;
      this.reviewDecision = this.review.reviewItems[0].decisionTypeId == DecisionTypes.Confirm ? 'Contravention Confirmed'
      : this.review.reviewItems[0].decisionTypeId == DecisionTypes.VaryAndConfirm ? "Contravention Confirmed at Lower Occurrence Level" 
      : 'Contravention Cancelled';
    } else {
      this.isDecisionAvailable = false;
    }

    if (this.review.reviewDate) {
      this.reviewDate = this.datePipe.transform(this.review.reviewDate, 'fullDate');

      this.reviewStartTime = this.review.reviewTime.substr(0, 5);
      let d = new Date('01 01 1980 ' + this.review.reviewTime);
      d.setMinutes(d.getMinutes() + 30);
      this.reviewEndTime = d.toTimeString().substr(0, 5);

      this.reviewTime = `${this.reviewStartTime} - ${this.reviewEndTime}`;
    }
  }

  DownloadDocument(document: DocumentModel) {
    var storageFileName = `${document.contentGuid}.${document.documentExtension}`;

    if (document.version == Constants.DocumentVersion.VERSION3) {
      this.documentService.getDocumentSasUri("", "Reviews", this.review.reviewNumber, storageFileName, document.documentName)
      .subscribe((result: any) => {
        window.open(result.documentSasUri, "_blank");
      });

      return;
    }

    this.documentService.downloadDocument("", "Reviews", this.review.reviewNumber, storageFileName, document.documentName)
      .subscribe((result: any) => {
        if (result) {
          fileSaver.saveAs(new Blob([result]), document.documentName);
        }
      });
  }

  onAddConsentFormClick() {
    this.viewContainerRef.clear();
    const componentRef = this.viewContainerRef.createComponent(UploadReviewConsentModalComponent);

    componentRef.instance.review = Object.assign({}, this.review);
    componentRef.instance.close.subscribe((result: Review) => {
      if (result != null) {
        setTimeout(() => {
          this.loadReview();
        });
      }

      componentRef.destroy();
    });
  }

  onNewerReviewsclick() {
    const reviewSequence = Math.max(this.contraventionOverview.recentIrsReviewSequence
      ,this.contraventionOverview.recentTsaReviewSequence);

    if (this.reviewSequence + 1 <= reviewSequence) {
      this.router.navigate(['../../../', this.reviewSequence + 1, this.reviewTypeId, 'details'], { relativeTo: this.activatedRoute });
    }
  }

  onPastReviewsclick() {
    if (this.reviewSequence - 1 > 0) {
      this.router.navigate(['../../../', this.reviewSequence - 1, this.reviewTypeId, 'details'], { relativeTo: this.activatedRoute });
    }
  }

  onTabItemClick(reviewTypeId: number) {
    this.router.navigate(['../../', reviewTypeId, 'details'], { relativeTo: this.activatedRoute });
  }

  onCancelReviewClick() {
    this.isConsentAccepted = false;
    this.isCancelFailed = false;
    $('#cancelReviewModal').modal('show');
  }

  onConfirmClick() {
    this.spinner.show();

    this.reviewService
      .cancelReview(this.review)
      .pipe(
        finalize(() => {
          this.spinner.hide();
        })
      )
      .subscribe(
        (result) => {
          $('#cancelReviewModal').modal('hide');
          this.router.navigate(['../../../../'], {
            relativeTo: this.activatedRoute,
          });
        },
        (error) => {
          if (error.error && error.error.errorCode == "2004") {
            this.isCancelFailed = true;
          }

          window.scroll(0, 0);
        });
  }

  isReviewAvailable(): boolean {
    if (this.review.reviewMethodTypeId == ReviewMethodTypes.Oral 
        && this.review.reviewStatusTypeId != ReviewStatusTypes.Cancelled
        && !this.isDecisionAvailable
        && this.review.reviewDateTimeUtc) {
      var reviewDateTimeUtc = new Date(this.review.reviewDateTimeUtc);
      var reviewEndDateTimeUtc = reviewDateTimeUtc.setMinutes(reviewDateTimeUtc.getMinutes() + 31);
      return +moment.utc() < +reviewEndDateTimeUtc;
    }

    return false;
  }

  joinMeeting() {
    window.open(this.review.videoConferenceUrl, "_blank");
  }

  isReviewTypeSelected(reviewTypeId: number) {
    return this.reviewTypeId == reviewTypeId;
  }

  reviewTypeExistsInSequence(reviewTypeId: number) {
    var reviewTypeExists = false;

    this.review.reviewTypesInSequence.forEach(t => {
      if (t == reviewTypeId) reviewTypeExists = true;
    });

    return reviewTypeExists;
  }

  getContraventionNumber(contraventionId: number) {
    return this.contraventionOverview.contraventions.find(c => c.contraventionId == contraventionId).contraventionNumber;
  }

  getChargeSection(contraventionId: number) {
    const contraventionTypeId = this.getTopContraventionType(contraventionId);

    return this.localStorageService.getContraventionTypes().find(t => t.id == contraventionTypeId).formattedChargeCode;
  }

  getChargeDescription(contraventionId: number) {
    const contraventionTypeId = this.getTopContraventionType(contraventionId);

    return this.localStorageService.getContraventionTypes().find(t => t.id == contraventionTypeId).name;
  }

  getTopContraventionType(contraventionId: number) {
    var contraventionInfo = this.contraventionOverview.contraventions.find(c => c.contraventionId == contraventionId);

    if (contraventionInfo.tertiaryContraventionTypeId != null) {
      return contraventionInfo.tertiaryContraventionTypeId;
    } else if (contraventionInfo.secondaryContraventionTypeId != null) {
      return contraventionInfo.secondaryContraventionTypeId;
    } else {
      return contraventionInfo.contraventionTypeId;
    }
  }

  getReviewStatus(reviewStatusTypeId: number) {
    if (reviewStatusTypeId == ReviewStatusTypes.Cancelled) return 'Confirmed';
    return this.localStorageService.getReviewStatusTypes().find(t => t.id == reviewStatusTypeId).name;
  }

  getReviewOutcome(reviewOutcomeTypeId: number) {
    return this.localStorageService.getReviewOutcomeTypes().find(t => t.id == reviewOutcomeTypeId).name;
  }
}
