
<!-- Driver Portal View -->
<div class="d-flex flex-column" *ngIf="!isRSGPortal">
    <h1>Time to Pay Request Approved</h1>    
    <h3 class="border-bottom pb-4 mb-2">Notice(s): {{paymentTimeRequest.contraventionList.join(', ')}}</h3>

    <div class="card border-0">
        <p>
            Your time to pay request was received and has been approved. 
            Your new payment due date is: {{dueDate | date:'mediumDate'}}. 
        </p>
    </div>

    <div class="card mt-4">
        <div class="card-header">
            Request Summary
        </div>
        <div class="card-body">
            <app-section-details sectionTitle="Request Information" [sectionDetails]="requestInformation"></app-section-details>
            <app-section-details *ngIf="!paymentTimeRequest.isSelfSubmit"
                    sectionTitle="Representation" [sectionDetails]="representationInformation"></app-section-details>
            
            <div class="border-bottom mt-3 pb-1">Contravention Information</div>
            <div class="row mt-3 mb-2">
                <div class="col-4 label">
                    Contravention Number
                </div>
                <div class="col-4 label">
                    Traffic Safety Act Section
                </div>
                <div class="col-4 label">
                    Occurrence Date
                </div>
            </div>
            <div *ngFor="let c of this.contraventionsInRequest; let i = index;" class="row">
                <div class="col-4 value">
                    {{c.contraventionNumber}}
                </div>
                <div class="col-4 value">
                    {{getChargeCodes(c)}}
                </div>
                <div class="col-4 value" *ngIf="i == 0">
                    {{c.occurrenceDate | date:'mediumDate' }}
                </div>
            </div>
        </div>
    </div>

    <div class="mb-3">
        <div class="row">
            <div class="col-12 col-sm-12">                
                <button class="goa-button float-end me-0" [routerLink]="['../']">Return to Contravention Overview</button>
            </div>
        </div>
    </div>
</div>

<!-- Registry Portal View -->
<div class="d-flex flex-column" *ngIf="isRSGPortal">
    <h1>Time to Pay Request Approved</h1>    
    <div class="border-bottom">
        <div class="row">
            <div class="col-12 col-sm-10">        
                <h3>Notice(s): {{paymentTimeRequest.contraventionList.join(', ')}}</h3>
            </div>
            <div class="col-12 col-sm-2 d-flex align-self-end justify-content-end">
                <div class="float-end">
                    <div class="print-icon not-for-print" (click)="printSummary()"></div>
                </div>
            </div>
        </div>
    </div>

    <div class="card border-0">
        <p>
            The time to pay request was received and has been approved.
            The new payment due date is {{dueDate | date:'mediumDate'}}.
        </p>
    </div>

    <div class="card mt-4">
        <div class="card-header">
            Request Summary
        </div>
        <div class="card-body">
            <app-section-details sectionTitle="Request Information" [sectionDetails]="requestInformation"></app-section-details>
            
            <div class="border-bottom mt-3 pb-1">Contravention Information</div>
            <div class="row mt-3 mb-2">
                <div class="col-4 label">
                    Contravention Number
                </div>
                <div class="col-4 label">
                    Traffic Safety Act Section
                </div>
                <div class="col-4 label">
                    Occurrence Date
                </div>
            </div>
            <div *ngFor="let c of this.contraventionsInRequest; let i = index;" class="row">
                <div class="col-4 value">
                    {{c.contraventionNumber}}
                </div>
                <div class="col-4 value">
                    {{getChargeCodes(c)}}
                </div>
                <div class="col-4 value" *ngIf="i == 0">
                    {{c.occurrenceDate | date:'mediumDate' }}
                </div>
            </div>
        </div>
    </div>

    <div class="mb-3 not-for-print">
        <div class="row">
            <div class="col-12 col-sm-12">                
                <button class="goa-button float-end me-0" [routerLink]="['../']">Return to Contravention Overview</button>
            </div>
        </div>
    </div>
</div>