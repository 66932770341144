<div class="page-not-found">
    <div class="col-lg-12 col-sm-8">
        <div class="page-not-found-heading">Sorry, the page could not be found</div>
        <div class="page-not-found-message">The URL you entered in your web browser might be wrong or the page may have been moved.</div>
        <div class="border-0 d-flex flex-row-reverse">
            <button *ngIf="!isRSGPortal" type="button" class="goa-button mt-2 me-0 px-4" 
                [routerLink]="['/']">Back to Look Up</button>
            <button *ngIf="isRSGPortal" type="button" class="goa-button mt-2 me-0 px-4" 
                [routerLink]="['/rsg/']">Back to Look Up</button>    
        </div>
    </div>    
</div>
